import { CommissionTypeType } from '../graphql-types';
import { CommissionEntity } from './commission.entity';
export interface DailyToDoAdditionalData {
    kagetecImage?: boolean;
    preventEarlyExecution?: boolean;
    isRequiredToComplete?: boolean;
    // is checkt from the frontend if the constructiondiary step x is saved
    autoCompleteOnStepSave?: number;
}
export interface CommissionTypeEntityAdditionalData {
    CanHaveChildCommissions?: boolean;
    AuftragsNummer?: any; // for number generation
    ConstructionDiary?: {
        dailyTodos: {
            name: string;
            unicId: number;
            additionalData?: DailyToDoAdditionalData;
            time: {
                hour: number;
                minute: number;
            };
        }[];
    };
    WizardSteps?: CommissionWizardSteps[];
    IsDefault?: boolean;
}
export enum CommissionWizardSteps {
    Stammdaten = 'stammdaten',
    Adresse = 'adresse',
    Terminierung = 'terminierung',
    Kapazitaet = 'kapazitaet',
    Ressourcen = 'ressourcen',
    Fristen = 'fristen',
    Planungsdaten = 'planungsdaten',
    Mitarbeiter = 'mitarbeiter',
    AllgDaten = 'allg_daten',
}
export enum CommissionTypeEntityIdEnum {
    gericht = 1,
}
export enum CommissionTypeNameEnum {
    Bauprojekt = 'Bauprojekt',
    Rapport = 'Rapport',
    Gericht = 'Gerichtsauftrag',
    Projekt = 'Großprojekt',
}
export class CommissionTypeEntity {
    constructor(
        public Id: number = null,
        public Name: CommissionTypeNameEnum | string = null,
        public PartnerId: number = null,
        public AdditionalData: CommissionTypeEntityAdditionalData = null,
        public PartnerSpecificationTypeId: number = null,
        public GeneratedEmployeeResourceSchedules: any[] = null,
        public StatusBackofficeIds: number[] | null = null,
    ) {
        if (this.Name === CommissionTypeNameEnum.Projekt) {
            this.commissionFormFieldNames.set('Auftragsnummer', 'Projektnr.');
        }
    }
    public static readonly GQLFields = `
    additionalData
    id
    name
    partnerId
    partnerSpecificationTypeId
    generatedEmployeeResourceSchedules
    statusBackofficeIds`;
    public Clone(override: Partial<CommissionTypeEntity> = {}): CommissionTypeEntity {
        const attr = { ...this, ...override };
        return Object.assign(new CommissionTypeEntity(), attr);
    }
    private readonly commissionFormFieldNames: Map<keyof CommissionEntity, string> = new Map([]);
    public get EntityPropertyNames() {
        return this.commissionFormFieldNames;
    }
}
export function CommissionTypeEntityFromBackend(res: CommissionTypeType): CommissionTypeEntity {
    return new CommissionTypeEntity(res.id, res.name, res.partnerId, res.additionalData as any, res.partnerSpecificationTypeId, res.generatedEmployeeResourceSchedules, res.statusBackofficeIds?.length ? res.statusBackofficeIds : null);
}
