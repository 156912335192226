<div class="flex gap-4 my-4 justify-between">
    <div class="grid grid-cols-2 grid-borders p-1-children mb-auto borders bold-borders company-container">
        <h2 class="m-0 font-bold col-start-1 col-end-3">Verkäufer:</h2>
        <ng-container *ngIf="EInvoice.Seller.Name as value" [ngTemplateOutlet]="valueTemplate" [ngTemplateOutletContext]="{ value: value, label: 'Name' }"></ng-container>
        <ng-container *ngIf="EInvoice.Seller.Address as value" [ngTemplateOutlet]="addressTemplate2" [ngTemplateOutletContext]="value"></ng-container>
        <ng-container *ngIf="EInvoice.Seller.Email as value" [ngTemplateOutlet]="valueTemplate" [ngTemplateOutletContext]="{ value: value, label: 'E-Mail' }"></ng-container>
        <ng-container *ngIf="EInvoice.Seller.Description as value" [ngTemplateOutlet]="valueTemplate" [ngTemplateOutletContext]="{ value: value, label: 'Beschreibung' }"></ng-container>
        <ng-container *ngIf="EInvoice.Seller.SupplierNumber as value" [ngTemplateOutlet]="valueTemplate" [ngTemplateOutletContext]="{ value: value, label: 'Lieferantennummer' }"></ng-container>
        <ng-container *ngIf="EInvoice.Seller.UstId as value" [ngTemplateOutlet]="valueTemplate" [ngTemplateOutletContext]="{ value: value, label: 'USt-IdNr' }"></ng-container>
        <ng-container *ngIf="EInvoice.Seller.LegalOrganization as value" [ngTemplateOutlet]="valueTemplate" [ngTemplateOutletContext]="{ value: value.Name + ' ' + value.Id, label: legalOrganizationLabel }"></ng-container>

        <ng-container *ngIf="EInvoice.Seller.TaxInfo | length">
            <span class="font-bold"> Steuerinfo </span>
            <span>
                <ng-container *ngFor="let item of EInvoice.Seller.TaxInfo; let last = last" class="col-start-1 col-end-3 font-bold">
                    <mat-chip disabled style="opacity: 1" class="mr-2"> <ng-container [ngTemplateOutlet]="companyTaxInfoTemplate" [ngTemplateOutletContext]="item"> </ng-container></mat-chip>
                </ng-container>
            </span>
        </ng-container>
        <ng-container *ngIf="EInvoice.Seller.Ids as ids">
            <span *ngFor="let item of ids" class="col-start-1 col-end-3 font-bold">
                <ng-container [ngTemplateOutlet]="globalIdsTemplate" [ngTemplateOutletContext]="item"> </ng-container>
            </span>
        </ng-container>

        <div class="flex flex-col borders-y borders-column bold-borders p-1-children p-0 mb-auto col-start-1 col-end-3" *ngIf="EInvoice.Seller.ContactPerson as value">
            <ng-container [ngTemplateOutlet]="contactPersonTemplate" [ngTemplateOutletContext]="value"></ng-container>
        </div>
    </div>
    <div class="grid grid-cols-2 grid-borders p-1-children mb-auto borders bold-borders company-container">
        <h2 class="m-0 font-bold col-start-1 col-end-3">Käufer:</h2>

        <ng-container *ngIf="EInvoice.Buyer.Name as value" [ngTemplateOutlet]="valueTemplate" [ngTemplateOutletContext]="{ value: value, label: 'Name' }"></ng-container>
        <ng-container *ngIf="EInvoice.Buyer.Address as value" [ngTemplateOutlet]="addressTemplate2" [ngTemplateOutletContext]="value"></ng-container>
        <ng-container *ngIf="EInvoice.Buyer.Email as value" [ngTemplateOutlet]="valueTemplate" [ngTemplateOutletContext]="{ value: value, label: 'E-Mail' }"></ng-container>
        <ng-container *ngIf="EInvoice.Buyer.CustomerNumber as value" [ngTemplateOutlet]="valueTemplate" [ngTemplateOutletContext]="{ value: value, label: 'Kundennummer' }"></ng-container>
        <ng-container *ngIf="EInvoice.Buyer.UstId as value" [ngTemplateOutlet]="valueTemplate" [ngTemplateOutletContext]="{ value: value, label: 'USt-IdNr' }"></ng-container>
        <ng-container *ngIf="EInvoice.Buyer.LegalOrganization as value" [ngTemplateOutlet]="valueTemplate" [ngTemplateOutletContext]="{ value: value.Name + ' ' + value.Id, label: legalOrganizationLabel }"></ng-container>

        <ng-container *ngIf="EInvoice.Buyer.TaxInfo | length">
            <span class="font-bold"> Steuerinfo </span>
            <span>
                <ng-container *ngFor="let item of EInvoice.Seller.TaxInfo; let last = last" class="col-start-1 col-end-3 font-bold">
                    <mat-chip disabled style="opacity: 1" class="mr-2"> <ng-container [ngTemplateOutlet]="companyTaxInfoTemplate" [ngTemplateOutletContext]="item"> </ng-container></mat-chip>
                </ng-container>
            </span>
        </ng-container>
        <div class="flex flex-col borders-y borders-column bold-borders p-1-children p-0 mb-auto col-start-1 col-end-3" *ngIf="EInvoice.Buyer.ContactPerson as value">
            <ng-container [ngTemplateOutlet]="contactPersonTemplate" [ngTemplateOutletContext]="value"></ng-container>
        </div>
<!--        <div class="mb-auto border-none col-start-1 col-end-3">-->
            <div class="grid grid-cols-2 borders-column p-1-children borders-y p-0 bold-borders col-start-1 col-end-3" *ngIf="EInvoice.ShippingParty">
                <h2 class="m-0 font-bold col-start-1 col-end-3">Empfänger:</h2>
                <ng-container *ngIf="EInvoice.ShippingParty.Name as value" [ngTemplateOutlet]="valueTemplate" [ngTemplateOutletContext]="{ value: value, label: 'Name' }"></ng-container>
                <ng-container *ngIf="EInvoice.ShippingParty.Address as value" [ngTemplateOutlet]="addressTemplate2" [ngTemplateOutletContext]="value"></ng-container>
                <ng-container *ngIf="EInvoice.ShippingParty.Email as value" [ngTemplateOutlet]="valueTemplate" [ngTemplateOutletContext]="{ value: value, label: 'E-Mail' }"></ng-container>
                <ng-container *ngIf="EInvoice.ShippingParty.Id | length" [ngTemplateOutlet]="valueTemplate" [ngTemplateOutletContext]="{ value: EInvoice.ShippingParty.Id.join(', '), label: 'Kennung des Lieferorts' }"></ng-container>
                <ng-container *ngIf="EInvoice.ShippingParty.LegalOrganization as value" [ngTemplateOutlet]="valueTemplate" [ngTemplateOutletContext]="{ value: value.Name + ' ' + value.Id, label: legalOrganizationLabel }"></ng-container>

                <ng-container *ngIf="EInvoice.ShippingParty.TaxInfo | length">
                    <span class="font-bold"> Steuerinfo </span>
                    <span>
                        <ng-container *ngFor="let item of EInvoice.ShippingParty.TaxInfo; let last = last" class="col-start-1 col-end-3 font-bold">
                            <mat-chip disabled style="opacity: 1" class="mr-2"> <ng-container [ngTemplateOutlet]="companyTaxInfoTemplate" [ngTemplateOutletContext]="item"> </ng-container></mat-chip>
                        </ng-container>
                    </span>
                </ng-container>
                <ng-container *ngIf="EInvoice.ShippingParty.GlobalId as ids">
                    <span *ngFor="let item of ids" class="col-start-1 col-end-3 font-bold">
                        <ng-container [ngTemplateOutlet]="globalIdsTemplate" [ngTemplateOutletContext]="item"> </ng-container>
                    </span>
                </ng-container>
                <div class="flex flex-col borders-y borders-column bold-borders p-1-children p-0 mb-auto col-start-1 col-end-3" *ngIf="EInvoice.ShippingParty.ContactPerson as value" style="border-bottom: none">
                    <ng-container [ngTemplateOutlet]="contactPersonTemplate" [ngTemplateOutletContext]="value"></ng-container>
                </div>
            </div>
<!--        </div>-->
    </div>
</div>
<h2>{{eInvoiceInvoiceTypeNames.get(EInvoice.Type)}} {{EInvoice.InvoiceNumber}}</h2>
<div class="flex">
    <div class="flex flex-col gap-2 p-2 borders bold-borders grow" *ngIf="EInvoice.Note"  style="flex-basis:30rem; border-bottom: none; border-right: none;">
        <span>Notiz:</span>
        <span class="whitespace-pre-wrap font-bold">{{ EInvoice.Note }}</span>
    </div>
    <div class="flex flex-col p-1-children borders-column ml-auto borders bold-borders" style="flex-basis:30rem; border-bottom: none;">
        <ng-container [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: EInvoice.InvoiceDate | date, label: 'Datum' }"></ng-container>
        <ng-container *ngIf="EInvoice.DueDate as value" [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: value | date, label: 'Fällig' }"></ng-container>
        <ng-container *ngIf="EInvoice.DeliveryNoteNumber as value" [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: value, label: 'Liefernummer' }"></ng-container>
        <ng-container *ngIf="EInvoice.ShippingDate as value" [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: value | date, label: 'Lieferdatum' }"></ng-container>
        <ng-container *ngIf="EInvoice.CommissionNumber as value" [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: value, label: 'Auftragsnummer' }"></ng-container>
        <ng-container *ngIf="EInvoice.ContractNumber as value" [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: value, label: 'Vertragsnummer' }"></ng-container>
        <ng-container [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: EInvoice.BuyerReference, label: 'Leitweg ID' }"></ng-container>
        <ng-container *ngIf="EInvoice.OrderNumber as value" [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: value, label: 'Bestellnummer' }"></ng-container>

        <ng-container *ngIf="EInvoice.InvoiceReferencedId as value" [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: value, label: 'Vorausgegangene Rechnungsnummer' }"></ng-container>
        <ng-container *ngIf="EInvoice.InvoiceReferencedDate as value" [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: value, label: 'Vorausgegangenes Rechnungsdatum' }"></ng-container>
    </div>
</div>
<div class="borders-column borders bold-borders bold-borders-children">
    <app-e-invoice-view-lines [lines]="EInvoice.Lines" class="block"></app-e-invoice-view-lines>
    <div class="flex borders-row">
        <div class="grow"></div>
        <div class="flex flex-col borders-column p-1-children ml-auto mb-auto" style="flex-basis: 20rem">
            <ng-container [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: EInvoice.TotalAmount | currency: EInvoice.CurrencyCode, label: 'Summe' }"></ng-container>
            <ng-container *ngIf="EInvoice.ChargeTotalAmount as value" [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: value | currency: EInvoice.CurrencyCode, label: 'Summe Zuschläge' }"></ng-container>
            <ng-container *ngIf="EInvoice.AllowanceTotalAmount as value" [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: value | currency: EInvoice.CurrencyCode, label: 'Summe Abschläge' }"></ng-container>
            <ng-container [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: EInvoice.TaxBasisTotalAmount | currency: EInvoice.CurrencyCode, label: 'Summe netto' }"></ng-container>
            <ng-container [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: EInvoice.TaxTotalAmount | currency: EInvoice.CurrencyCode, label: 'Summe Steuern' }"></ng-container>
            <ng-container [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: EInvoice.GrandTotalAmount | currency: EInvoice.CurrencyCode, label: 'Summe gesamt' }"></ng-container>

            <ng-container *ngIf="EInvoice.TotalPrepaidAmount as value" [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: value | currency: EInvoice.CurrencyCode, label: 'Vorauszahlungen' }"></ng-container>

            <ng-container [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: EInvoice.DuePayableAmount | currency: EInvoice.CurrencyCode, label: 'Fällig' }"></ng-container>
        </div>
    </div>
    <div class="flex borders-row">
        <div class="flex flex-col borders-column p-1-children grow" *ngIf="EInvoice.PaymentTerms || EInvoice.BillingPeriodFrom || EInvoice.BillingPeriodTo || (EInvoice.TradeAccountingAccountIds | length)">
            <span *ngIf="EInvoice.PaymentTerms as value">Zahlungsbedingungen: <span class="font-bold">{{ value }}</span></span>
            <span *ngIf="EInvoice.BillingPeriodFrom || EInvoice.BillingPeriodTo">
                Zahlbar <span *ngIf="EInvoice.BillingPeriodFrom">von {{ EInvoice.BillingPeriodFrom | date }} </span> <span *ngIf="EInvoice.BillingPeriodTo"> bis {{ EInvoice.BillingPeriodTo | date }}</span>
            </span>
            <ng-container *ngIf="EInvoice.TradeAccountingAccountIds | length">
                <span>
                    <span> Buchungsreferenz (Käufer): </span>
                    <ng-container *ngFor="let item of EInvoice.TradeAccountingAccountIds; let last = last" class="col-start-1 col-end-3 font-bold">
                        <mat-chip disabled style="opacity: 1" class="mr-2">{{ item }}</mat-chip>
                    </ng-container>
                </span>
            </ng-container>
        </div>
        <div class="flex flex-col borders-column p-1-children grow" *ngIf="EInvoice.PaymentInfo">
            <span class="font-bold">Zahlungsinformationen:</span>
            <ng-container *ngIf="EInvoice.PaymentInfo.ReceiverId | length" [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: EInvoice.PaymentInfo.ReceiverId.join(', '), label: 'Empfängerkennungen' }"></ng-container>
            <ng-container *ngIf="EInvoice.PaymentInfo.ReceiverName as value" [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: value, label: 'Empfänger' }"></ng-container>
            <ng-container *ngIf="EInvoice.PaymentInfo.ReceiverLegalId as value" [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: value, label: 'Handelsregisternummer' }"></ng-container>
            <ng-container *ngIf="EInvoice.PaymentInfo.ReceiverLegalIdSchema as value" [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: get_ISO_IEC_17_6523_label(value), label: 'Handelsregisternummer' }"></ng-container>
            <ng-container *ngIf="EInvoice.PaymentInfo.ReceiverSchemeId as value" [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: value, label: 'Globale Id des Zahlungsempfängers' }"></ng-container>
            <ng-container *ngIf="EInvoice.PaymentInfo.ReceiverSchemeIdSchema as value" [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: get_ISO_IEC_17_6523_label(value), label: 'Handelsregisternummer' }"></ng-container>
            <ng-container *ngIf="EInvoice.PaymentInfo.CreditorReferenceId as value" [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: value, label: 'Gläubiger-ID (SEPA)' }"></ng-container>
            <ng-container *ngIf="EInvoice.PaymentInfo.PaymentReference as value" [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: value, label: 'Verwendungszweck' }"></ng-container>
            <ng-container *ngIf="EInvoice.PaymentInfo.PaymentType as value" [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: paymentTypeNames.get(value), label: 'Zahlungsart' }"></ng-container>
            <ng-container *ngIf="EInvoice.PaymentInfo.PaymentMandateReference as value" [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: value, label: 'Mandatsreferenz' }"></ng-container>
            <ng-container *ngIf="EInvoice.PaymentInfo.DebitorIban as value" [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: value, label: 'Iban Debitor' }"></ng-container>
            <ng-container *ngIf="EInvoice.PaymentInfo.DebitorBic as value" [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: value, label: 'Bic Debitor' }"></ng-container>
            <ng-container *ngIf="EInvoice.PaymentInfo.CreditorIban as value" [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: value, label: 'Iban Kreditor' }"></ng-container>
            <ng-container *ngIf="EInvoice.PaymentInfo.CreditorBic as value" [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: value, label: 'Bic Kreditor' }"></ng-container>
            <ng-container *ngIf="EInvoice.PaymentInfo.Note as value" [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: value, label: 'Notiz' }"></ng-container>
            <ng-container *ngIf="EInvoice.PaymentInfo.CardAccountNumberId as value" [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: value, label: 'Zahlungskartennummer' }"></ng-container>
            <ng-container *ngIf="EInvoice.PaymentInfo.CardAccountNetworkId as value" [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: value, label: 'Netzwerk-ID des Kartenkontos' }"></ng-container>
            <ng-container *ngIf="EInvoice.PaymentInfo.CardAccountHolderName as value" [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: value, label: 'Zahlungskarteninhaber' }"></ng-container>
        </div>
    </div>
    <div class="flex flex-wrap gap-4 p-2" *ngIf="EInvoice.TaxInfos && EInvoice.TaxInfos.length > 0">
        <h2 class="m-0 font-bold w-full">Steuerinformationen:</h2>
        <div *ngFor="let taxInfo of EInvoice.TaxInfos" class="grow flex flex-col borders-column borders p-1-children">
            <span class="font-bold col-span-full">{{ taxInfo.Code }}:</span>
            <ng-container [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: taxInfo.CalculatedAmount | currency: EInvoice.CurrencyCode, label: 'Spezifischer Steuerbetrag' }"></ng-container>
            <ng-container [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: taxInfo.BasisAmount | currency: EInvoice.CurrencyCode, label: 'Steuerbasisbetrag' }"></ng-container>
            <ng-container *ngIf="taxInfo.RateApplicablePercent != null" [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: (taxInfo.RateApplicablePercent / 100) | percent, label: 'Umsatzsteuersatz' }"></ng-container>
            <ng-container *ngIf="taxInfo.ExemptionReason as value" [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value, label: 'Steuerbefreiungsgrund' }"></ng-container>
            <ng-container *ngIf="taxInfo.ExemptionReasonCode as value" [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value, label: 'Steuerbefreiungscode' }"></ng-container>

            <ng-container *ngIf="taxInfo.DueDateTypeCode as value" [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: eInvoiceDueDateTypeCodeNames.get(value), label: 'Steuerfälligkeit' }"></ng-container>
            <div class="flex" *ngIf="taxInfo.CategoryCode as value">
                <span class="grow flex-basis-0">Steuerart</span>
                <app-abbr class="font-bold grow flex-basis-0" [title]="eInvoiceTaxCategoryCodeDescriptions.get(value)">{{ eInvoiceTaxCategoryCodeNames.get(value) || '' }}</app-abbr>
            </div>
        </div>
    </div>
    <div class="flex flex-wrap gap-4 p-2" *ngIf="EInvoice.AllowanceCharges && EInvoice.AllowanceCharges.length > 0">
        <h2 class="m-0 font-bold w-full">Zu-/ Abschläge:</h2>
        <app-e-invoice-allowance-charge-view class="grow" *ngFor="let charge of EInvoice.AllowanceCharges" [charge]="charge" [currencyCode]="EInvoice.CurrencyCode"></app-e-invoice-allowance-charge-view>

    </div>
</div>

<ng-template #valueTemplate let-value="value" let-label="label" let-hint="hint">
    <span>{{ label }} <app-hint-icon *ngIf="hint" [hint]="hint"></app-hint-icon></span>
    <span class="font-bold">{{ value || '' }}</span>
</ng-template>
<ng-template #valueTemplateFlex let-value="value" let-label="label" let-hint="hint">
    <div class="flex">
        <span class=" grow flex-basis-0">{{ label }} <app-hint-icon *ngIf="hint" [hint]="hint"></app-hint-icon></span>
        <span class=" font-bold grow flex-basis-0">{{ value || '' }}</span>
    </div>
</ng-template>
<ng-template #contactPersonTemplate let-Name="Name" let-Phone="Phone" let-Email="Email">
<!--    <div class="grid grid-cols-2 borders-y borders-column bold-borders p-1-children p-0 mb-auto grow">-->
        <h3 class="m-0 font-bold col-start-1 col-end-3">Kontaktperson:</h3>

        <ng-container *ngIf="Name as value" [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value, label: 'Name' }"></ng-container>
        <ng-container *ngIf="Phone as value" [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value, label: 'Telefon' }"></ng-container>
        <ng-container *ngIf="Email as value" [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value, label: 'E-Mail' }"></ng-container>
<!--    </div>-->
</ng-template>
<ng-template #addressTemplate2 let-PostcodeCode="PostcodeCode" let-LineOne="LineOne" let-LineTwo="LineTwo" let-LineThree="LineThree" let-City="City" let-CountryId="CountryId">
    <span>Adresse:</span>
    <div class="font-bold">
        <span *ngIf="LineOne">{{ LineOne }}<br></span>
        <span *ngIf="LineTwo">{{ LineTwo }}<br></span>
        <span *ngIf="LineThree">{{ LineThree }}<br></span>
        <span *ngIf="PostcodeCode || City">{{ PostcodeCode }} {{ City }}<br></span>
        <span *ngIf="CountryId">{{ CountryId | country}}<br></span>
    </div>
</ng-template>
<ng-template #globalIdsTemplate let-scheme="scheme" let-Id="Id" let-IdSchema="IdSchema"> {{ Id }} </ng-template>
<ng-template #companyTaxInfoTemplate let-Id="Id" let-Schema="Schema"> {{ Id }} </ng-template>
