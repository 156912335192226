import { CommonModule, formatCurrency, formatDate, getCurrencySymbol } from '@angular/common';
import { Component, DEFAULT_CURRENCY_CODE, Inject, Input, LOCALE_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, firstValueFrom } from 'rxjs';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';
import { MaterialToSupplierDataService } from '../../../dave-data-module/services/material-to-supplier-data.service';
import { State } from '../../../dave-data-module/State';
import { getCustomerDictionary, getCustomersFetched, getNotDeletedCustomers } from '../../../dave-data-module/State/selectors/customers.selectors';
import { getMaterialGroups } from '../../../dave-data-module/State/selectors/material-group.selectors';
import { DaveListCardModule } from '../../../dave-list-card/dave-list-card.module';
import { AppButtonModule } from '../../../dave-utils-module/app-button-module/app-button.module';
import { SelectSearchOption } from '../../../dave-utils-module/select-search/components/select-search/select-search.component';
import { isNotNullOrUndefined, MathRound } from '../../../helper/helper';
import { CustomLabelService } from '../../../services/custom-label.service';
import { EditMaterialToSupplierComponentDialogReturnData, EditMaterialToSupplierDialogComponent, EditMaterialToSupplierDialogComponentDialogData } from '../edit-material-to-supplier-dialog/edit-material-to-supplier-dialog.component';

interface MaterialToSupplierTableData {
    SupplierName: string;
    Date: string;
    Cost: string;
    cost_as_float: number;
    UpdatedAt: Date;
    ArticleNumber: string;
    SupplierId: number;
    MaterialGroupId: number;
    MaterialId: number;
    costsForSort: number;
    updatedAtForSort: number;
}

@Component({
    selector: 'app-material-to-supplier-list-card[materialId]',
    standalone: true,
    imports: [CommonModule, AppButtonModule, DaveListCardModule, FontAwesomeModule],
    templateUrl: './material-to-supplier-list-card.component.html',
    styleUrls: ['./material-to-supplier-list-card.component.scss'],
})
export class MaterialToSupplierListCardComponent {
    protected MaterialId$ = new BehaviorSubject<number>(null);
    @Input() set materialId(id: number) {
        if (this.MaterialId$.value !== id) {
            this.MaterialId$.next(id);
        }
    }

    public MaterialToSupplierTableDataLoading$ = new BehaviorSubject(false);
    public MaterialToSupplierTableData$ = this.MaterialId$.pipe(
        filter(isNotNullOrUndefined),
        tap(() => this.MaterialToSupplierTableDataLoading$.next(true)),
        switchMap((mId) =>
            combineLatest([
                this.materialToSupplierDataService.getMaterialToSupplierByMaterialId$(mId),
                this.store.select(getCustomersFetched).pipe(
                    filter((v) => !!v),
                    switchMap(() => this.store.select(getCustomerDictionary)),
                ),
            ]).pipe(
                tap(() => this.MaterialToSupplierTableDataLoading$.next(false)),
                map(([supplierEntries, customers]) => {
                    // const supplierEntries = Object.keys(materialToSupplierDictionary)
                    //     .filter(key => key.startsWith(mId.toString()))
                    //     .map(key => materialToSupplierDictionary[key]);
                    if (!supplierEntries.length) {
                        return new MatTableDataSource<MaterialToSupplierTableData>([]);
                    }
                    return new MatTableDataSource<MaterialToSupplierTableData>(
                        supplierEntries.map<MaterialToSupplierTableData>((s) => ({
                            SupplierId: s.SupplierId,
                            MaterialGroupId: s.MaterialGroupId,
                            SupplierName: s.SupplierId ? customers[s.SupplierId]?.Name || 'Unbekannt' : '',
                            Date: formatDate(s.UpdatedAt, 'shortDate', this.locale),
                            Cost: formatCurrency(s.Cost / 100, this.locale, getCurrencySymbol(this.defaultCurrencyCode, 'narrow')),
                            cost_as_float: isNotNullOrUndefined(s.Cost) ? MathRound(s.Cost / 100, true) : undefined,
                            UpdatedAt: s.UpdatedAt,
                            ArticleNumber: s.ArticleNumber,
                            MaterialId: s.MaterialId,
                            costsForSort: s.Cost,
                            updatedAtForSort: s.CreatedAt.getTime(),
                        })),
                    );
                }),
                tap(
                    (dataSource) =>
                        (dataSource.sortingDataAccessor = (object, key) => {
                            switch (key) {
                                case 'Date':
                                    return object.updatedAtForSort;
                                case 'Cost':
                                    return object.costsForSort;
                                default:
                                    return object[key].trim().toLowerCase();
                            }
                        }),
                ),
            ),
        ),
    );

    constructor(
        private store: Store<State>,
        private materialToSupplierDataService: MaterialToSupplierDataService,
        private dialog: MatDialog,
        protected cls: CustomLabelService,
        @Inject(LOCALE_ID) private locale: string,
        @Inject(DEFAULT_CURRENCY_CODE) private defaultCurrencyCode: string,
    ) {}

    onAddSupplier(s: MaterialToSupplierTableData) {
        if (s != null) {
            const customer = this.findCustomerById(s.SupplierId);

            const productGroup = this.findProductGroupById(s.MaterialGroupId);
            this.dialog
                .open<EditMaterialToSupplierDialogComponent, EditMaterialToSupplierDialogComponentDialogData, EditMaterialToSupplierComponentDialogReturnData>(EditMaterialToSupplierDialogComponent, {
                    ...EditMaterialToSupplierDialogComponent.DefaultConfig,
                    data: {
                        customer: customer,
                        articlenumber: s.ArticleNumber,
                        cost: s.cost_as_float,
                        productGroup: productGroup,
                        materialId: s.MaterialId,
                    },
                })
                .afterClosed()
                .subscribe((result) => {});
        } else {
            firstValueFrom(this.MaterialId$).then((materialId) => {
                if (materialId) {
                    this.dialog
                        .open<EditMaterialToSupplierDialogComponent, EditMaterialToSupplierDialogComponentDialogData, EditMaterialToSupplierComponentDialogReturnData>(EditMaterialToSupplierDialogComponent, {
                            ...EditMaterialToSupplierDialogComponent.DefaultConfig,
                            data: {
                                materialId: materialId,
                            },
                        })
                        .afterClosed()
                        .subscribe((result) => {});
                }
            });
        }
    }
    findCustomerById(id: number): SelectSearchOption<{ Id: number }> {
        let customer: SelectSearchOption<{ Id: number }>;
        this.store
            .select(getNotDeletedCustomers)
            .pipe(
                first(),
                map((customers) => customers.find((c) => c.Id === id)),
            )
            .subscribe((foundCustomer) => {
                if (foundCustomer) {
                    customer = { Id: foundCustomer.Id, optionLabel: foundCustomer.DisplayName };
                }
            });
        return customer;
    }
    findProductGroupById(id: number): SelectSearchOption<{ Id: number }> {
        let productGroup: SelectSearchOption<{ Id: number }>;
        this.store
            .select(getMaterialGroups)
            .pipe(
                first(),
                map((groups) => groups.find((group) => group.Id === id)),
            )
            .subscribe((foundGroup) => {
                if (foundGroup) {
                    productGroup = { Id: foundGroup.Id, optionLabel: foundGroup.Name };
                }
            });
        return productGroup;
    }
}
