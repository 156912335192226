<div class="grow flex flex-col borders-column borders p-1-children">
    <span class="font-bold col-span-full">{{ charge.ChargeIndicator ? 'Zuschlag' : 'Abschlag' }}:</span>
    <ng-container *ngIf="charge.CalculationPercent != null" [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: (charge.CalculationPercent / 100) | percent, label: charge.ChargeIndicator ? 'Zuschlag in %' : 'Abschlag in %' }"></ng-container>
    <ng-container *ngIf="charge.BasisAmount as value" [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: value | currency: currencyCode, label: 'Grundbetrag' }"></ng-container>

    <ng-container [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: charge.ActualAmount | currency: currencyCode, label: 'Betrag' }"></ng-container>
    <ng-container *ngIf="charge.ReasonCode as value" [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: eInvoiceSpecifiedTradeAllowanceChargeCodeNames.get(value), label: 'Begründungscode' }"></ng-container>
    <ng-container *ngIf="charge.Reason as value" [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value, label: 'Begründung' }"></ng-container>

    <ng-container *ngIf="charge.TaxCode as value" [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value, label: 'Steuercode', hint: 'UNTDID 5153' }"></ng-container>

    <ng-container *ngIf="charge.RateApplicablePercent != null" [ngTemplateOutlet]="valueTemplateFlex" [ngTemplateOutletContext]="{ value: (charge.RateApplicablePercent / 100) | percent, label: 'Umsatzsteuersatz' }"></ng-container>
    <div class="flex" *ngIf="charge.TaxCategoryCode as value">
        <span class="grow flex-basis-0">Steuerart</span>
        <app-abbr class="font-bold grow flex-basis-0" [title]="eInvoiceTaxCategoryCodeDescriptions.get(value)">{{ eInvoiceTaxCategoryCodeNames.get(value) || '' }}</app-abbr>
    </div>
</div>

<ng-template #valueTemplateFlex let-value="value" let-label="label" let-hint="hint">
    <div class="flex">
        <span class="grow flex-basis-0">{{ label }} <app-hint-icon *ngIf="hint" [hint]="hint"></app-hint-icon></span>
        <span class="grow font-bold flex-basis-0">{{ value || '' }}</span>
    </div>
</ng-template>

