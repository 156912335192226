import { createEntityAdapter,EntityState } from '@ngrx/entity';
import { createReducer,on } from '@ngrx/store';
import { AccountsReceivableLedgerEntity } from '../../entities/accounts-receivable-ledger.entity';
import { AccountsReceivableLedgerActionTypes } from '../actions/accounting.actions';

export interface ARLState extends EntityState<AccountsReceivableLedgerEntity> {
    fetched: boolean;
    // latestUpdatedAt: Date | null;
}

export const ARL_FEATURE_KEY = 'arl';

const adapter = createEntityAdapter<AccountsReceivableLedgerEntity>({
    selectId: (Entity) => Entity.Id,
});

const initialState = adapter.getInitialState({
    fetched: false,
    // latestUpdatedAt: null,
});

/* ############# */
/* ## REDUCER ## */
/* ############# */

export const arlReducer = createReducer(
    initialState,

    on(AccountsReceivableLedgerActionTypes.UpdateAccountsReceivableLedger, (state, { Payload /*, updateLatestUpdatedAt*/ }) => {
        if (state.fetched) {
            return adapter.setMany(Payload, { ...state });
        } else {
            return adapter.setAll(Payload, { ...state, fetched: true });
        }
    }),
    on(AccountsReceivableLedgerActionTypes.UpdateSingleAccountsReceivableLedger, (state, { Payload }) => adapter.setOne(Payload, { ...state })),
    on(AccountsReceivableLedgerActionTypes.DeleteAccountsReceivableLedgerSuccess, (state, { Payload }) => adapter.removeOne(Payload, { ...state })),
);

/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllARL = selectAll;
export const selectARLEntities = selectEntities;

export const selectARLFetched = (state: ARLState) => state.fetched;
// export const selectARLLatestUpdatedAt = (state: ARLState) => state.latestUpdatedAt;
