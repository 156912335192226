import { FileOpenerOpenRequest, GetTimeFromTimestamp } from '@dave/types';

export class FileOpenerOpenRequestEntity {
    constructor(
        public Id: number = null,
        public TokenId: number = null,
        public DocumentId: number = null,
        public VersionId: number = null,
        public Reached: boolean = null,
        public Opened: boolean = null,
        public CreatedAt: Date = null,
        public UpdatedAt: Date = null,
        public DeletedAt: Date | undefined = null,
    ) {}
    public static EntityName = 'Offene Datei';
    public static EntityPropertyNames: Map<keyof FileOpenerOpenRequestEntity, string> = new Map([]);
    // public get DisplayName(): string {
    //     return ''
    // }

    public Clone(override: Partial<FileOpenerOpenRequestEntity> = {}): FileOpenerOpenRequestEntity {
        const attr = { ...this, ...override };
        return Object.assign(new FileOpenerOpenRequestEntity(), attr);
    }
}
export function FileOpenerOpenRequestEntityFromBackend(res: FileOpenerOpenRequest): FileOpenerOpenRequestEntity {
    return new FileOpenerOpenRequestEntity(
        +res.Id,
        res.TokenId && +res.TokenId,
        res.DocumentId && +res.DocumentId,
        res.VersionId && +res.VersionId,
        res.Reached,
        res.Opened,
        GetTimeFromTimestamp(+res.CreatedAt),
        GetTimeFromTimestamp(+res.UpdatedAt),
        res.DeletedAt ? GetTimeFromTimestamp(+res.DeletedAt) : null,
    );
}
