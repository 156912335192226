<mat-drawer-container>
    <mat-drawer #drawer position="end">
        <app-table-config-form [columns]="TableColumnsSorted$ | async" [selectedColumns]="selectedColumns$ | async"  (selectedColumnsChange)="selectedColumnsChange($event)"></app-table-config-form>
    </mat-drawer>
<section #wrapperElement class="wrapper">
<mat-card class="mat-card" >
    <div class="card-headline box-shadow-top">
        <h3 mat-dialog-title >Buchhaltungsdaten exportieren</h3>

        <div class="header-button-wrapper">
            <button app-round-button matTooltip="Umsatz hinzufügen" (click)="addBV(); $event.stopPropagation();"  *ngIf="hasCreatePermission$ | async"><fa-icon icon="plus"></fa-icon></button>
            <div *ngIf="!Multiselect">
                <button app-round-button Inverted [Disabled]="(Data$|async)?.data?.length === 0"
                        matTooltip="Mehrfachauswahl" (click)="ToggleMultiselect()">
                    <fa-icon icon="check-square"></fa-icon>
                </button>
            </div>
            <div *ngIf="Multiselect">
                <button app-round-button Inverted matTooltip="Abbrechen" (click)="ToggleMultiselect()">
                    <fa-icon icon="times"></fa-icon>
                </button>
            </div>
            <button app-round-button [Disabled]="Multiselect"
                    (click)="bVFilter.Toggle(); $event.stopPropagation();"
                    [matBadge]="FilterAmount$ | async"
                    [matBadgeHidden]="!(FilterAmount$ | async)"
                    matTooltip="Filter"
                    [matBadgeOverlap]="true"
                    class="mat-badge-more-overlay"
            >
                <fa-icon icon="filter"></fa-icon>
            </button>
            <button class="header-button" app-round-button [matBadge]="disabledColumns$ | async" matTooltip="Tabelle anpassen" matTooltipPosition="left" (click)="drawer.toggle()">
                <fa-icon icon="cog"></fa-icon>
            </button>
        </div>
    </div>
    <ng-container>
        <div class="table-wrapper">
            <table mat-table [dataSource]="Data$ | async" class="full-width-table fixed-header" *ngIf="TableColumns" cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)" matSort>
                <ng-container *ngFor="let column of TableColumns" [matColumnDef]="column.name">
                    <th mat-header-cell mat-header-row *matHeaderCellDef mat-sort-header cdkDrag class="drag-header" cdkDragBoundary=".mat-header-row" [cdkDragPreviewContainer]="wrapperElement" cdkDragPreviewClass="mat-elevation-z2">
                        {{ column.header }}
                    </th>
                    <td mat-cell *matCellDef="let row">
                        {{ row[column.name] }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="select">
                    <th mat-header-cell  *matHeaderCellDef style="padding: 0 1rem; margin: 5rem">
                        <mat-checkbox (change)="toggleAllSelections()" [checked]="isAllSelected() | async"
                                      [indeterminate]="(SelectedBvs$ | async)?.length > 0 && !(isAllSelected() | async)">
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row" style="width: 2rem">
                        <mat-checkbox (click)="$event.stopPropagation()"
                                      (change)="CheckBoxSelectBv($event, row)"
                                      [checked]="IsSelected(row)"
                                      [class.selected]="selection.isSelected(row)"
                        >
                        </mat-checkbox>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="availableColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: availableColumns;" (click)="openEditDialog(row)"
                    [class.selected]="IsSelected(row)"></tr>
            </table>
            <table *ngIf="(Data$ | async)?.data?.length === 0" style="width: 100% ; text-align: center">
                <h2>
                    Keine Datensätze in diesem Zeitraum vorhanden
                </h2>
            </table>
        </div>
        <div class="box-shadow-bottom">
            <mat-dialog-actions class="footer">
                <button app-button mat-dialog-close Inverted Color="cancel">Abbrechen</button>
                <div style="display: flex; gap: 0.5rem">
                    <button app-button (click)="export()" [Disabled]="(SelectedBvs$|async)?.length === 0"
                            [IsLoading]="ls.IsLoading$ | async">auswahl Exportieren
                    </button>
                    <button app-button *ngIf="!Multiselect" [Disabled]="(Data$ | async)?.data?.length === 0" (click)="exportAll()">Alle Exportieren</button>
                </div>
            </mat-dialog-actions>
        </div>
    </ng-container>
</mat-card>
</section>
<app-filter-card UnlimitedHeight #bVFilter [Settings]="BvFilterSettings | async"
                 [Values]="BvFilterValues$ | async" (ValuesChange)="BvFilterValues$.next($event)"></app-filter-card>
</mat-drawer-container>

