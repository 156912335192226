import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, take, tap } from 'rxjs/operators';
import { EmailFolderEntity } from '../entities/emailFolder.entity';
import { State } from '../State';
import { EmailFolderActionTypes } from '../State/actions/email-folder.actions';
import { getEmailFolders, getEmailFoldersFetched } from '../State/selectors/email-folders.selectors';

@Injectable({
    providedIn: 'root',
})
export class EmailFolderResolver implements Resolve<boolean> {
    constructor(private store: Store<State>) {}

    resolve() {
        const value$ = this.store.select(getEmailFoldersFetched);

        value$
            .pipe(
                filter((value) => !value),
                take(1),
                tap(() => {
                    this.store.dispatch(EmailFolderActionTypes.LoadEmailFolders());
                }),
            )
            .subscribe();

        return value$.pipe(
            filter((value) => !!value),
            take(1),
        );
    }
}
