import { createReducer, on } from '@ngrx/store';
import { AccountsReceivableLedgerEntity } from '../../entities/accounts-receivable-ledger.entity';
import { LedgerImportEntity } from '../../entities/ledger-import.entity';
import { AccountsReceivableLedgerTemplateEntity } from '../../entities/accounts-receivable-ledger-template.entity';
import {
    AccountsReceivableLedgerActionTypes,
    AccountsReceivableLedgerTemplateActionTypes, ARLTemplateTypeActions,
    LedgerImportActionTypes, QuantityTypeActions,
} from '../actions/accounting.actions';
import { QuantityTypeEntity } from '../../entities/quantity-type.entity';
import { ARLTemplateTypeEntity } from '../../entities/a-r-l-template-type.entity';
import { SfdtTemplateEntity } from '../../entities/sfdt-template.entity';

export const QUANTITY_TYPE_KEY = 'quantity_types';
export const accountingFeatureKey = 'accounting';

export interface AccountingState {
    accountsReceivableLedgerTemplates: AccountsReceivableLedgerTemplateEntity[];
    quantityTypes: QuantityTypeEntity[];
    aRLTemplateTypes: ARLTemplateTypeEntity[];
    sfdtTemplates: SfdtTemplateEntity[];
}

export const initialState: AccountingState = {
    accountsReceivableLedgerTemplates: null,
    quantityTypes: null,
    aRLTemplateTypes: null,
    sfdtTemplates: null
};

export const accountingReducer = createReducer(
    initialState,
    on(AccountsReceivableLedgerTemplateActionTypes.UpdateAccountsReceivableLedgerTemplate, (state, { Payload }) => ({
        ...state,
        accountsReceivableLedgerTemplates: Payload,
    })),
    on(QuantityTypeActions.UpdateQuantityTypes, (state, { Payload }) => ({
        ...state,
        quantityTypes: Payload,
    })),
    on(ARLTemplateTypeActions.UpdateARLTemplateType, (state, { Payload }) => ({
        ...state,
        aRLTemplateTypes: Payload,
    })),
    on(LedgerImportActionTypes.UpdateSFDTTemplate, (state, { Payload }) => ({
        ...state,
        sfdtTemplates: Payload,
    })),
);
