<mat-menu #uploadMenu>
    <button mat-menu-item (click)="onFileUploadChange()">
        <span>Vom Computer</span>
    </button>
    <button mat-menu-item (click)="onDmsFileUploadClick(true, null)">
        <span>Aus dem DMS</span>
    </button>
</mat-menu>
<!--<input type="file" id="file" (change)="onFileUploadChange($event, null)" hidden multiple accept="image/*, application/pdf" #fileUpload />-->

<mat-expansion-panel [(expanded)]="expanded">
    <mat-expansion-panel-header [collapsedHeight]="DaveHeaderHeight">
        <h3>Pläne</h3>
        <span class="spacer"></span>
        <button app-round-button (click)="$event.stopPropagation(); drawer?.toggle()" [matTooltip]="'Liste der Störungen ' + (drawer?.opened ? 'ausblenden' : 'einblenden')" [Active]="drawer?.opened" [disabled]="(plans$ | async)?.length === 0"><fa-icon icon="list"></fa-icon></button>
        <button app-round-button (click)="$event.stopPropagation(); onStackOrderButtonClick()" [disabled]="(plans$ | async)?.length === 0"><fa-icon icon="layer-group" matTooltip="Pläne bearbeiten"></fa-icon></button>
        <button app-round-button (click)="$event.stopPropagation();" [matMenuTriggerFor]="uploadMenu"><fa-icon icon="upload" matTooltip="Plan hinzufügen"></fa-icon></button>
    </mat-expansion-panel-header>
    <mat-card-content>
        <!--        <mat-list style="width: 15rem">-->
        <!--                        <app-event-list-entry *ngFor="let event of events$ | async" [Event]="event"></app-event-list-entry>-->
        <!--        </mat-list>-->

        <mat-drawer-container *ngIf="(plans$ | async)?.length; else noContent" class="transparent">
            <mat-drawer mode="side" #drawer>
                <div class="scrollbar">
                    <table mat-table matSort [dataSource]="eventTableDataSource" class="event-table">
                        <ng-container *ngFor="let column of ColumnConfig" [matColumnDef]="column.name">
                            <th mat-header-cell *matHeaderCellDef [mat-sort-header]="column.name">{{ column.header }}</th>
                            <td mat-cell [style.border-left-color]="rowData.color || 'transparent'" *matCellDef="let rowData">{{ rowData[column.name] }}</td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row class="clickable-list-item" *matRowDef="let row; columns: displayedColumns" (click)="onTableRowClick(row)"></tr>
                    </table>
                </div>
            </mat-drawer>
            <mat-drawer-content *ngIf="plans$ | async as plans">
                <mat-tab-group style="flex-grow: 1" (selectedTabChange)="planSelected$.next(plans[$event.index])">
                    <mat-tab #tab [label]="plan.documentName" *ngFor="let plan of plans">
                        <ng-container *ngIf="tab.isActive">
                            <button app-round-button class="tooltip-button" (click)="map.toggleMarkerTooltipsPermanent()" [Active]="map.MarkerTooltipsPermanent" [matTooltip]="map.MarkerTooltipsPermanent ? 'Ids ausblenden' : 'alle Ids anzeigen'"><fa-icon [icon]="'message'"></fa-icon></button>
                            <app-leaflet-wrapper (MapInitialized)="setEvents2(map, plan.documentId)" #map [InitialDocument]="plan"></app-leaflet-wrapper>
                        </ng-container>
                    </mat-tab>
                </mat-tab-group>
            </mat-drawer-content>
        </mat-drawer-container>
    </mat-card-content>
</mat-expansion-panel>
<!--<ng-template #singleFileUpload let-fileId="fileId">-->
<!--    <input type="file" (change)="onFileUploadChange($event, fileId)" hidden accept="image/*, application/pdf" />-->
<!--</ng-template>-->
<ng-template #noContent>
        <p>Keine Pläne vorhanden</p>
</ng-template>
