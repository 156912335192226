import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, firstValueFrom, map, of, Subject, switchMap } from 'rxjs';
import { filter, shareReplay, startWith, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { CommissionWizardComponent, CommissionWizardComponentDialogData } from '../dave-commission-module/components/commission-wizard/commission-wizard.component';
import {
    createCommissionFormGroup,
    getCommissionFormSubscriptions,
    getCommissionLocationOptions,
    resetCommissionFormGroup,
    saveCommission,
    setCommissionDetailListTemplateEntries,
} from '../dave-commission-module/components/detail-commission/helper';
import { CommissionResolver } from '../dave-data-module/guards/commission.resolver';
import { CommissionTypeResolver } from '../dave-data-module/guards/commissionType.resolver';
import { CustomerResolver } from '../dave-data-module/guards/customer.resolver';
import { OfficeResolver } from '../dave-data-module/guards/office.resolver';
import { UserToCommissionResolver } from '../dave-data-module/guards/user-to-commission.resolver';
import { State } from '../dave-data-module/State';
import { BaseActionTypes } from '../dave-data-module/State/actions/base.actions';
import { CommissionActionTypes } from '../dave-data-module/State/actions/commission.actions';
import { getCommissionById, getCommissionsFetched } from '../dave-data-module/State/selectors/commission.selector';
import { getCommissionTypeById, getCommissionTypes, getCommissionTypesFetched } from '../dave-data-module/State/selectors/commissionType.selectors';
import { getCustomers, getCustomersFetched } from '../dave-data-module/State/selectors/customers.selectors';
import { getEntityRole, getEntityRoleFetched } from '../dave-data-module/State/selectors/entity-role.selector';
import { getOffices, getOfficesFetched } from '../dave-data-module/State/selectors/offices.selectors';
import { getPartner } from '../dave-data-module/State/selectors/partners.selectors';
import {
    getUserToCommissionFetched,
    getUserToCommissions,
} from '../dave-data-module/State/selectors/user-to-commission.selector';
import { getUser } from '../dave-data-module/State/selectors/users.selectors';
import { CommissionNameService } from '../dave-utils-module/dave-shared-components-module/services/commission-name.service';
import { SelectSearchData } from '../dave-utils-module/select-search/components/select-search-legacy/select-search-legacy.component';
import { Address } from '../helper/helper';
import { CustomLabelService } from './custom-label.service';
import { getFetched$ } from '../dave-data-module/helper/helper';
import { EntityRoleResolver } from '../dave-data-module/guards/entity-role.resolver';

@Injectable({
    providedIn: 'root',
})
export class CommissionWizardDialogService {
    constructor(
        private store: Store<State>,

        private dialog: MatDialog,

        private cls: CustomLabelService,
        private commissionRes: CommissionResolver,
        private commissionTypeRes: CommissionTypeResolver,
        private customerRes: CustomerResolver,
        private officeRes: OfficeResolver,
        private userToCommissionResolver: UserToCommissionResolver,
        private entityRoleResolver: EntityRoleResolver,
        public CNS: CommissionNameService,
        private actions$: Actions,

        private router: Router,
    ) {}
    OpenDialog(commissionId: number) {
        const userToCommissionFromCommission$ = getFetched$(this.store, getUserToCommissionFetched, getUserToCommissions, this.userToCommissionResolver).pipe(map((u2cs) => u2cs.filter((u2c) => u2c.CommissionId === commissionId)));
        const commissionEntityRoles$ = combineLatest([userToCommissionFromCommission$, this.store.select(getUser)]).pipe(
            map(([u2cs, user]) => u2cs?.filter((u) => u.UserId === user.Id)),
            switchMap((u2c) => getFetched$(this.store, getEntityRoleFetched, getEntityRole, this.entityRoleResolver).pipe(map((er) => er.filter((e) => u2c.some((u) => u.RoleId === e.Id))))),
        );
        return firstValueFrom(
            combineLatest([
                // this.actions$.pipe(ofType(CommissionActionTypes.UpdateOne, BaseActionTypes.ErrorAction)),
                getFetched$(this.store, getCommissionTypesFetched, getCommissionTypes, this.commissionTypeRes),
                getFetched$(this.store, getCustomersFetched, getCustomers, this.customerRes),
                getFetched$(this.store, getOfficesFetched, getOffices, this.officeRes),
                this.store.select(getPartner),
                this.cls.getSingle$('Customer'),
                commissionEntityRoles$,
            ]),
        ).then(([commissionTypes, customers, offices, partner, CustomerName, entityRoles]) => {
            const form = createCommissionFormGroup();
            const CommissionLocationForm = new FormControl<Address>({
                Street: '',
                PostalCode: '',
                City: '',
                Country: '',
            });
            const SearchLocationForm = new FormControl<SelectSearchData>(null);

            const dialogBeforeClosed = new Subject<void>();

            let dialogRef: MatDialogRef<CommissionWizardComponent>;
            const subs = [...getCommissionFormSubscriptions(form, this.store) /*...getCommissionFormAddressSearchSubscriptions(form, this.store, CommissionLocationForm, SearchLocationForm)*/];
            dialogBeforeClosed.subscribe(() => {
                subs.forEach((s) => s.unsubscribe());
            });

            const commission$ = getFetched$(this.store, getCommissionsFetched, getCommissionById({ id: commissionId }), this.commissionRes).pipe(shareReplay({refCount: true, bufferSize: 1}));
            commission$
                .pipe(takeUntil(dialogBeforeClosed))
                .subscribe((commission) => {
                    resetCommissionFormGroup(commission, form);
                });
            const detailListTemplateEntries$ = combineLatest([
                commission$,
                form.controls.CommissionTypeId.valueChanges.pipe(
                    startWith(form.controls.CommissionTypeId.value),
                    switchMap((ct) => (ct?.Id ? this.store.select(getCommissionTypeById({ id: ct.Id })) : of(null))),
                ),
                this.cls.getSingle$('Commission'),
                getCommissionLocationOptions(this.store),
            ]).pipe(
                takeUntil(dialogBeforeClosed),
                map(([commission, currentCommissionTypeEntity, commissionLabel, commissionLocationOptions]) => {
                    const detailListTemplateEntries = {};

                    setCommissionDetailListTemplateEntries(
                        commission,
                        detailListTemplateEntries,
                        [
                            'AbgabeterminAG',
                            'AbgabeterminQM',
                            'LetzteFrist',
                            'NachlassZulage',
                            'VorarbeiterBonus',

                            'Customer',
                            'CommissionType',
                            'Sache',
                            'CommissonNumber',
                            'CommissonNumberAuto',
                            'InterneNummer',
                            'InterneNummerAuto',
                            'CustomerLocation',
                            'Location',
                            'Auftragseingang',
                            'StartDate',
                            'PlanedStartDate',
                            'EndDate',
                            'PlanedEndDate',
                            'Schadensdatum',
                            'Versicherungssumme',
                            'Schadennummer',
                            'Versicherungsnummer',
                            'AutomaticAccountsReceivableLedger',
                            'AutomaticAccountsReceivableLedgerByClockIns',
                            'ProjectInfo',
                            'OrderNoCustomer',
                            'CompleteBusinessVolume',
                            'CostsCompleteBusinessVolume',
                            'PlanedWorkingHours',
                        ],
                        form,
                        entityRoles,
                        {
                            CustomerName,
                            CommissionName: this.CNS.getName,
                            customers,
                            offices,
                            commissionType: null,
                            commissionTypes,
                            partner,
                            currentCommissionTypeEntity,
                            currentCommissionType: currentCommissionTypeEntity,
                            SearchLocationForm,
                            CommissionLocationForm,
                            commissionLocationOptions,
                        },
                        commissionLabel,
                    );
                    return detailListTemplateEntries;
                }),
            );
            return firstValueFrom(detailListTemplateEntries$).then((detailListTemplateEntries) => {
                console.log('open wizard');
                dialogRef = this.dialog.open<CommissionWizardComponent, CommissionWizardComponentDialogData>(CommissionWizardComponent, {
                    ...CommissionWizardComponent.DefaultConfig,
                    data: {
                        commissionId: commissionId,
                        detailListTemplateEntries,
                        statusBackofficeForm: form.controls.StatusBackofficeId,
                    },
                });
                dialogRef.beforeClosed().subscribe(() => dialogBeforeClosed.next());
                dialogRef.componentInstance.Save.pipe(takeUntil(dialogBeforeClosed), withLatestFrom(this.store.select(getCommissionById({ id: commissionId })))).subscribe(([{ wizardPage, routerLink }, commission]) => {
                    if (routerLink) {
                        firstValueFrom(this.actions$.pipe(ofType(CommissionActionTypes.UpdateMany, BaseActionTypes.ErrorAction))).then((action) => {
                            if (action.type === CommissionActionTypes.UpdateMany.type) {
                                this.router.navigate(routerLink);
                            }
                        });
                    }
                    saveCommission(this.store, commission, wizardPage, form, CommissionLocationForm);
                    if (wizardPage === null) {
                        // wizard finished
                        dialogRef.close();
                    }
                });
                return dialogRef;
            });
        });
    }
}
