import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { environment } from '../../../environments/environment';
import { DaveMutationChangeGeneratedDocumentsArgs, DocumentUserType, FilesType } from '../graphql-types';
import { BackendDateTimestamp } from '../helper/backend-frontend-conversion.helper';
import { DocumentUserEntity, DocumentUserEntityFromBackend, DocumentUserEntityGateway } from "./document-user.entity";
import {
    FileVersionTypeFromFileBackend,
    VersionEntity,
    VersionEntityFromLegacyBackend,
    VersionEntityFromFileBackend, VersionEntityFromBackend
} from "./version.entity";
import { Document, GetTimeFromTimestamp } from '@dave/types';
export interface UploadFileArgs {
    file: Blob;
    documentId?: number;
    description?: string;
    name?: string;
    customerIds?: number[];
    commissionIds?: number[];
    transmissionIds?: number[];
    tagIds?: number[];
    hidden?: boolean;
    eventIds?: number[];
}
export enum FileEntityDocumentTypes {
    invoice = 'invoice',
    offer = 'offer',
    contract = 'contract',
    order = 'order',
    assignment = 'assignment',
    delivery_note = 'delivery_note',
    assessment = 'assessment',
    order_confirmation = 'order_confirmation',
    protocol = 'protocol',
    audit_report = 'audit_report',
    blueprint = 'blueprint',
}

export interface FileMetaData {
    document_type?: FileEntityDocumentTypes;
    constructionDiaryIds?: number[];
    email?: {
        cid: string;
    };
    isInComment?: boolean;
    isCalendarFromEventId?: number;
}

export enum FileEntityDocumentType {
    Invoice = 'invoice',
    Offer = 'offer',
    Contract = 'contract',
    Order = 'order',
    Assignment = 'assignment',
    Delivery_note = 'delivery_note',
    Assessment = 'assessment',
    Order_confirmation = 'order_confirmation',
    Protocol = 'protocol',
    Audit_report = 'audit_report',
}
export enum DocumentState {
    DocumentStateNew = "DocumentStateNew",
    DocumentStateSigned = "DocumentStateSigned",
    DocumentStateEditing = "DocumentStateEditing",
    DocumentStateSend = "DocumentStateSend",
    DocumentStateConfirmed = "DocumentStateConfirmed",
    DocumentStateBooked = "DocumentStateBooked",
    DocumentStateCharged = "DocumentStateCharged",
    DocumentStateApproved = "DocumentStateApproved",
    DocumentStateChecked = "DocumentStateChecked",
    DocumentStateInRelease = "DocumentStateInRelease",
    DocumentStateReleased = "DocumentStateReleased",
    DocumentStateCanceled = "DocumentStateCanceled"
}
export enum DocumentStateFromGql {

    Approved = 'Approved',
    Booked = 'Booked',
    Canceled = 'Canceled',
    Charged = 'Charged',
    Checked = 'Checked',
    Confirmed = 'Confirmed',
    Editing = 'Editing',
    InRelease = 'InRelease',
    New = 'New',
    Released = 'Released',
    Send = 'Send',
    Signed = 'Signed',
}
export enum DocumentStateFromFileBackend {
    DocumentStateNew = "new",
    DocumentStateSigned = "signed",
    DocumentStateEditing = "editing",
    DocumentStateSend = "send",
    DocumentStateConfirmed = "confirmed",
    DocumentStateBooked = "booked",
    DocumentStateCharged = "charged",
    DocumentStateApproved = "approved",
    DocumentStateChecked = "checked",
    DocumentStateInRelease = "in_release",
    DocumentStateReleased = "released",
    DocumentStateCanceled = "canceled",
}
const gqlDocumentStateDocumentState: Map<DocumentStateFromGql, DocumentState> = new Map<DocumentStateFromGql, DocumentState>([
    [DocumentStateFromGql.New, DocumentState.DocumentStateNew ],
    [DocumentStateFromGql.Signed, DocumentState.DocumentStateSigned ],
    [DocumentStateFromGql.Editing, DocumentState.DocumentStateEditing ],
    [DocumentStateFromGql.Send, DocumentState.DocumentStateSend ],
    [DocumentStateFromGql.Confirmed, DocumentState.DocumentStateConfirmed ],
    [DocumentStateFromGql.Booked, DocumentState.DocumentStateBooked ],
    [DocumentStateFromGql.Charged, DocumentState.DocumentStateCharged ],
    [DocumentStateFromGql.Approved, DocumentState.DocumentStateApproved ],
    [DocumentStateFromGql.Checked, DocumentState.DocumentStateChecked ],
    [DocumentStateFromGql.InRelease, DocumentState.DocumentStateInRelease ],
    [DocumentStateFromGql.Released, DocumentState.DocumentStateReleased ],
    [DocumentStateFromGql.Canceled, DocumentState.DocumentStateCanceled ],
])
const documentStateGqlDocumentState: Map<DocumentState, DocumentStateFromGql> = new Map<DocumentState, DocumentStateFromGql>([
    [DocumentState.DocumentStateNew, DocumentStateFromGql.New ],
    [DocumentState.DocumentStateSigned, DocumentStateFromGql.Signed ],
    [DocumentState.DocumentStateEditing, DocumentStateFromGql.Editing ],
    [DocumentState.DocumentStateSend, DocumentStateFromGql.Send ],
    [DocumentState.DocumentStateConfirmed, DocumentStateFromGql.Confirmed ],
    [DocumentState.DocumentStateBooked, DocumentStateFromGql.Booked ],
    [DocumentState.DocumentStateCharged, DocumentStateFromGql.Charged ],
    [DocumentState.DocumentStateApproved, DocumentStateFromGql.Approved ],
    [DocumentState.DocumentStateChecked, DocumentStateFromGql.Checked ],
    [DocumentState.DocumentStateInRelease, DocumentStateFromGql.InRelease ],
    [DocumentState.DocumentStateReleased, DocumentStateFromGql.Released ],
    [DocumentState.DocumentStateCanceled, DocumentStateFromGql.Canceled ],
])

export const getDocumentStateFromGQLDocumentState = (v: FilesType['state']): DocumentState => {
    return gqlDocumentStateDocumentState.get(v as unknown as DocumentStateFromGql);
}
export const getGQLDocumentStateFromDocumentState = (v: DocumentState): FilesType['state'] => {
    return documentStateGqlDocumentState.get(v) as unknown as FilesType['state'];
}



const fileBackendDocumentStateDocumentState: Map<DocumentStateFromFileBackend, DocumentState> = new Map<DocumentStateFromFileBackend, DocumentState>([
    [DocumentStateFromFileBackend.DocumentStateNew, DocumentState.DocumentStateNew ],
    [DocumentStateFromFileBackend.DocumentStateSigned, DocumentState.DocumentStateSigned ],
    [DocumentStateFromFileBackend.DocumentStateEditing, DocumentState.DocumentStateEditing ],
    [DocumentStateFromFileBackend.DocumentStateSend, DocumentState.DocumentStateSend ],
    [DocumentStateFromFileBackend.DocumentStateConfirmed, DocumentState.DocumentStateConfirmed ],
    [DocumentStateFromFileBackend.DocumentStateBooked, DocumentState.DocumentStateBooked ],
    [DocumentStateFromFileBackend.DocumentStateCharged, DocumentState.DocumentStateCharged ],
    [DocumentStateFromFileBackend.DocumentStateApproved, DocumentState.DocumentStateApproved ],
    [DocumentStateFromFileBackend.DocumentStateChecked, DocumentState.DocumentStateChecked ],
    [DocumentStateFromFileBackend.DocumentStateInRelease, DocumentState.DocumentStateInRelease ],
    [DocumentStateFromFileBackend.DocumentStateReleased, DocumentState.DocumentStateReleased ],
    [DocumentStateFromFileBackend.DocumentStateCanceled, DocumentState.DocumentStateCanceled ],
])
const documentStateFileBackendDocumentState: Map<DocumentState, DocumentStateFromFileBackend> = new Map<DocumentState, DocumentStateFromFileBackend>([
    [DocumentState.DocumentStateNew, DocumentStateFromFileBackend.DocumentStateNew ],
    [DocumentState.DocumentStateSigned, DocumentStateFromFileBackend.DocumentStateSigned ],
    [DocumentState.DocumentStateEditing, DocumentStateFromFileBackend.DocumentStateEditing ],
    [DocumentState.DocumentStateSend, DocumentStateFromFileBackend.DocumentStateSend ],
    [DocumentState.DocumentStateConfirmed, DocumentStateFromFileBackend.DocumentStateConfirmed ],
    [DocumentState.DocumentStateBooked, DocumentStateFromFileBackend.DocumentStateBooked ],
    [DocumentState.DocumentStateCharged, DocumentStateFromFileBackend.DocumentStateCharged ],
    [DocumentState.DocumentStateApproved, DocumentStateFromFileBackend.DocumentStateApproved ],
    [DocumentState.DocumentStateChecked, DocumentStateFromFileBackend.DocumentStateChecked ],
    [DocumentState.DocumentStateInRelease, DocumentStateFromFileBackend.DocumentStateInRelease ],
    [DocumentState.DocumentStateReleased, DocumentStateFromFileBackend.DocumentStateReleased ],
    [DocumentState.DocumentStateCanceled, DocumentStateFromFileBackend.DocumentStateCanceled ],
])

export const getDocumentStateFromFILEBACKENDDocumentState = (v: FileTypeFromFileBackend['state']): DocumentState => {
    return fileBackendDocumentStateDocumentState.get(v as unknown as DocumentStateFromFileBackend);
}
export const getFILEBACKENDDocumentStateFromDocumentState = (v: DocumentState): FileTypeFromFileBackend['state'] => {
    return documentStateFileBackendDocumentState.get(v) as unknown as FileTypeFromFileBackend['state'];
}

export const FileEntityDocumentStateNames: Map<DocumentState, string> = new Map<DocumentState, string>([
    [DocumentState.DocumentStateNew, 'neu'],
    [DocumentState.DocumentStateSigned, 'unterschrieben'],
    [DocumentState.DocumentStateEditing, 'in bearbeitung'],
    [DocumentState.DocumentStateSend, 'versendet'],
    [DocumentState.DocumentStateConfirmed, 'bestätigt'],
    [DocumentState.DocumentStateBooked, 'gebucht'],
    [DocumentState.DocumentStateCharged, 'verrechnet'],
    [DocumentState.DocumentStateApproved, 'genehmigt'],
    [DocumentState.DocumentStateChecked, 'geprüft'],
    [DocumentState.DocumentStateInRelease, 'in Freigabe'],
    [DocumentState.DocumentStateReleased, 'freigegeben'],
    [DocumentState.DocumentStateCanceled, 'storniert'],
]);

export const FileEntityDocumentTypeNames: Map<string, string> = new Map<FileEntityDocumentType, string>([
    [FileEntityDocumentType.Invoice, 'Rechnung'],
    [FileEntityDocumentType.Offer, 'Angebot'],
    [FileEntityDocumentType.Contract, 'Vertrag'],
    [FileEntityDocumentType.Order, 'Bestellung'],
    [FileEntityDocumentType.Assignment, 'Auftrag'],
    [FileEntityDocumentType.Delivery_note, 'Lieferschein'],
    [FileEntityDocumentType.Assessment, 'Gutachten'],
    [FileEntityDocumentType.Order_confirmation, 'Auftragsbestätigung'],
    [FileEntityDocumentType.Protocol, 'Protokoll'],
    [FileEntityDocumentType.Audit_report, 'Auditbericht'],
]);
export const NEW_DOCUMENT_DEFAULT_NAME = 'Neues Dokument'
export class FileEntity {
    public static EntityPropertyNames: Map<keyof FileEntity, string> = new Map([
        ['Name', 'Name'],
        ['Description', 'Beschreibung'],
    ])
    public static readonly GqlFields = `
                commission_Ids
            created_At
            customer_Ids
            description
            deleted_At
            event_Ids
            hidden
            shared
            id
            name
            tag_Ids
            versions {
                ${VersionEntity.GqlFields}
            }
            users {
                ${DocumentUserEntity.GqlFields}
            }
            updated_At
            transmission_Ids
            mIME_Type
            folder_Id
            partner_Id
            metaData
            can_edit
            state
            is_email_inline
`;

    constructor(
        public Id: number = null,
        public Name: string = null,
        public Description: string = null,
        public Hidden: boolean = null,
        public Shared: boolean = null,
        public MIMEType: string = null,
        public CreatedAt: Date = null,
        public UpdatedAt: Date = null,
        public Versions: VersionEntity[] = null,
        public Users: DocumentUserEntity[] | null = null,
        public EventIds: number[] = null,
        public CustomerId: number = null,
        public ContractId: number = null,
        public VideoId: number = null,
        public TagIds: number[] = null,
        public DeletedAt: Date = null,
        public FolderId: number = null,
        public MIMETypeDescription: string = null,
        public PartnerId: number = null,
        public MetaData: FileMetaData = null,
        public CanEdit: boolean = false,
        public State: DocumentState = null,
        public LinkedDocumentId: number = null,
        public IsEmailInline: boolean = null,
    ) {
    }

    get FileIcon(): IconProp {
        return getFileIconByMimeType(this.MIMEType);
    }

    public Clone(override: Partial<FileEntity> = {}): FileEntity {
        const attr = {...this, ...override};
        return Object.assign(new FileEntity(), attr);
    }

    public GetLastVersion(): VersionEntity {
        if (!this.Versions || !this.Versions[0]) {
            console.error('File Corrupt, has no versions', this);
            return;
        }
        return this.Versions.slice().sort((a, b) => b.Number - a.Number)[0];
    }

    public GetDocumentType(): string {
        switch (this.MetaData.document_type) {
            case 'invoice':
                return 'Rechnung';
            case 'offer':
                return 'Angebot';
            case 'contract':
                return 'Vertrag';
            case 'order':
                return 'Bestellung';
            case 'assignment':
                return 'Auftrag';
            case 'delivery_note':
                return 'Lieferschein';
            case 'assessment':
                return 'Gutachten';
            case 'order_confirmation':
                return 'Auftragsbestätigung';
            case 'protocol':
                return 'Protokoll';
            case 'audit_report':
                return 'Auditbericht';
            case 'blueprint':
                return 'Plan';
            default:
                return 'Dokument';
        }
    }
}
export interface FileTypeFromFileBackend {
    can_edit: boolean;
    commission_ids: number[];
    created_at: string;
    customer_ids: number[];
    deleted_at: string | null;
    description: string;
    event_ids: number[];
    folder_id: number | null;
    hidden: boolean;
    id: number;
    metadata: FileMetaData;
    mime_type: string;
    name: string;
    partner_id: number;
    shared: boolean;
    tag_ids: number[];
    transmission_ids: number[];
    updated_at: string;
    users: DocumentUserType[];
    versions: FileVersionTypeFromFileBackend[];
    state: DocumentStateFromFileBackend;
    linked_document_id: number;
    is_email_inline: boolean
}
export function FileEntityFromGateway(res: Document): FileEntity {
    const mimeType = res.MimeType ? mimeTypes.find(m => m.mimeTyp === res.MimeType.split(';')[0])?.description?.replace('-Datei', '') : null;
    let metaData = null;
    if (res.Metadata !== undefined) {
        try {
            metaData = JSON.parse(res.Metadata);
        } catch (e) {
            console.error('Broken Metadata in File with Id = ' + res.Id);
            metaData = null;
        }
    }
    return new FileEntity(
        res.Id && +res.Id,
        res.Name,
        res.Description,
        res.Hidden,
        res.Shared,
        res.MimeType,
        res.CreatedAt ? GetTimeFromTimestamp(+res.CreatedAt) : null,
        res.UpdatedAt ? GetTimeFromTimestamp(+res.UpdatedAt) : null,
        res.Versions.map(v => VersionEntityFromBackend(v, +res.Id)),
        res.Users ? res.Users.map(u => DocumentUserEntityGateway(u)) : null,
        res.EventIds && res.EventIds.map(id => +id),
        res.CustomerIds.length ? +res.CustomerIds[0] : null,
        res.CommissionIds.length ? +res.CommissionIds[0] : null,
        res.TransmissionIds.length ? +res.TransmissionIds[0] : null,
        res.TagIds && res.TagIds.map(id => +id),
        res.DeletedAt ? GetTimeFromTimestamp(+res.DeletedAt) : null,
        res.FolderId && +res.FolderId,
        mimeType,
        res.PartnerId && +res.PartnerId,
        metaData,
        res.CanEdit,
        res.State ? DocumentState[res.State] : null,
        res.LinkedDocumentId && +res.LinkedDocumentId,
        res.IsEmailInline,
    );
}
export function FileEntityFromFileBackend(res: FileTypeFromFileBackend): FileEntity {

    const mimeType = res.mime_type ? mimeTypes.find(m => m.mimeTyp === res.mime_type.split(';')[0])?.description?.replace('-Datei', '') : null;
    return new FileEntity(
        res.id,
        res.name,
        res.description,
        res.hidden,
        res.shared,
        res.mime_type,
        res.created_at ? BackendDateTimestamp(res.created_at) : null,
        res.updated_at ? BackendDateTimestamp(res.updated_at) : null,
        res.versions.map(v => VersionEntityFromFileBackend(v, res.id)),
        res.users ? res.users.map(u => DocumentUserEntityFromBackend(u)) : null,
        res.event_ids,
        res.customer_ids.length ? res.customer_ids[0] : null,
        res.commission_ids.length ? res.commission_ids[0] : null,
        res.transmission_ids.length ? res.transmission_ids[0] : null,
        res.tag_ids,
        res.deleted_at ? BackendDateTimestamp(res.deleted_at) : null,
        res.folder_id,
        mimeType,
        res.partner_id,
        res.metadata,
        res.can_edit,
        res.state ? getDocumentStateFromFILEBACKENDDocumentState(res.state) : null,
        res.linked_document_id,
        res.is_email_inline,
    );
}

export const getFileIconByMimeType = (type: string) => {
    if (type) {
        switch (type) {
            case 'application/pdf':
                return 'file-pdf';
            case 'text/csv':
                return 'file-csv';
            case 'text/plain':
            case 'application/rtf':
                return 'file-text';
            case 'application/gzip':
            case 'application/x-tar':
            case 'application/vnd.rar':
            case 'application/zip':
            case 'application/x-7z-compressed':
                return 'file-archive';
            case 'application/vnd.ms-excel':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                return 'file-excel';
            case 'application/vnd.ms-powerpoint':
            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                return 'file-powerpoint';
            case 'application/msword':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return 'file-word';
            case 'application/x-sh':
            case 'text/javascript':
            case 'application/x-csh':
            case 'text/html':
            case 'application/x-httpd-php':
            case 'application/java-archive':
                return 'file-code';
        }
        if (type.slice(0, 6) === 'image/') {
            return 'file-image';
        } else if (type.slice(0, 6) === 'audio/' || type === 'application/x-cdf') {
            return 'file-audio';
        } else if (type.slice(0, 6) === 'video/') {
            return 'file-video';
        }
    }
    return 'file';
}

export function FileEntityFromBackend(res: FilesType): FileEntity {
    const mimeType = res.mIME_Type ? mimeTypes.find(m => m.mimeTyp === res.mIME_Type.split(';')[0])?.description?.replace('-Datei', '') : null;

    if (!environment.production && res.mIME_Type && !mimeType) {
        console.warn('MIME-Typ nicht gefunden', res.mIME_Type);
    }
    if (!res.versions || !res.versions[0]) {
        console.warn('File Corrupt, has no versions', res);
    }
    let metaData = null;
    if (res.metaData !== undefined) {
        try {
            metaData = JSON.parse(res.metaData);
        } catch (e) {
            console.error('Broken Metadata in File with Id = ' + res.id);
            metaData = null;
        }
    }

    return new FileEntity(
        res.id,
        res.name,
        res.description,
        res.hidden,
        res.shared,
        res.mIME_Type,
        res.created_At ? BackendDateTimestamp(res.created_At) : null,
        res.updated_At ? BackendDateTimestamp(res.updated_At) : null,
        res.versions.map(v => VersionEntityFromLegacyBackend(v, res.id)),
        res.users ? res.users.map(u => DocumentUserEntityFromBackend(u)) : null,
        res.event_Ids,
        res.customer_Ids.length ? res.customer_Ids[0] : null,
        res.commission_Ids.length ? res.commission_Ids[0] : null,
        res.transmission_Ids.length ? res.transmission_Ids[0] : null,
        res.tag_Ids,
        res.deleted_At ? BackendDateTimestamp(res.deleted_At) : null,
        res.folder_Id,
        mimeType,
        res.partner_Id,
        metaData,
        res.can_edit,
        res.state ? getDocumentStateFromGQLDocumentState(res.state) : null,
        null, //todo in gateway einbauen oder das gateway nicht mehr nutzen
        res.is_email_inline,
    );
}

export function GetFileMimeTypeText(input:string|null): string|null {
    return input ? mimeTypes.find(m => m.mimeTyp === input.split(';')[0])?.description?.replace('-Datei', '') : null;
}
export const FileSizeLabels = ['B', 'KB', 'MB', 'GB'];
const mimeTypes = [
    {
        mimeTyp: 'application/acad',
        fileExtension: '*.dwg',
        description: 'AutoCAD-Datei (nach NCSA)',
    },
    {
        mimeTyp: 'application/applefile',
        fileExtension: '&nbsp;',
        description: 'AppleFile-Datei',
    },
    {
        mimeTyp: 'application/astound',
        fileExtension: '*.asd *.asn',
        description: 'Astound-Datei',
    },
    {
        mimeTyp: 'application/dsptype',
        fileExtension: '*.tsp',
        description: 'TSP-Datei',
    },
    {
        mimeTyp: 'application/dxf',
        fileExtension: '*.dxf',
        description: 'AutoCAD-Datei (nach CERN)',
    },
    {
        mimeTyp: 'application/force-download',
        fileExtension: '*.reg',
        description: 'RegistrierungsDatei',
    },
    {
        mimeTyp: 'application/futuresplash',
        fileExtension: '*.spl',
        description: 'Flash Futuresplash-Datei',
    },
    {
        mimeTyp: 'application/gzip',
        fileExtension: '*.gz',
        description: 'GNU Zip-Datei',
    },
    {
        mimeTyp: 'application/javascript',
        fileExtension: '*.js',
        description: 'serverseitige JavaScript-Datei',
    },
    {
        mimeTyp: 'application/json',
        fileExtension: '*.json',
        description: 'enthält einen String in JavaScript-Objekt-Notation',
    },
    {
        mimeTyp: 'application/listenup',
        fileExtension: '*.ptlk',
        description: 'Listenup-Datei',
    },
    {
        mimeTyp: 'application/mac-binhex40',
        fileExtension: '*.hqx',
        description: 'Macintosh BinärDatei',
    },
    {
        mimeTyp: 'application/mbedlet',
        fileExtension: '*.mbd',
        description: 'Mbedlet-Datei',
    },
    {
        mimeTyp: 'application/mif',
        fileExtension: '*.mif',
        description: 'FrameMaker Interchange Format Datei',
    },
    {
        mimeTyp: 'application/msexcel',
        fileExtension: '*.xls *.xla',
        description: 'Microsoft Excel Datei',
    },
    {
        mimeTyp: 'application/mshelp',
        fileExtension: '*.hlp *.chm',
        description: 'Microsoft Windows Hilfe Datei',
    },
    {
        mimeTyp: 'application/mspowerpoint',
        fileExtension: '*.ppt *.ppz *.pps *.pot',
        description: 'Microsoft Powerpoint Datei',
    },
    {
        mimeTyp: 'application/msword',
        fileExtension: '*.doc *.dot',
        description: 'Microsoft Word Datei',
    },
    {
        mimeTyp: 'application/octet-stream',
        fileExtension: '*.bin *.file *.com *.class *.ini',
        description: 'Nicht näher spezifizierte Daten, z.B. ausführbare Datei',
    },
    {
        mimeTyp: 'application/oda',
        fileExtension: '*.oda',
        description: 'Oda-Datei',
    },
    {
        mimeTyp: 'application/pdf',
        fileExtension: '*.pdf',
        description: 'PDF-Datei',
    },
    {
        mimeTyp: 'application/postscript',
        fileExtension: '*.ai *.eps *.ps',
        description: 'PostScript-Datei',
    },
    {
        mimeTyp: 'application/rtc',
        fileExtension: '*.rtc',
        description: 'RTC-Datei',
    },
    {
        mimeTyp: 'application/rtf',
        fileExtension: '*.rtf',
        description: 'RTF-Datei',
    },
    {
        mimeTyp: 'application/studiom',
        fileExtension: '*.smp',
        description: 'Studiom-Datei',
    },
    {
        mimeTyp: 'application/toolbook',
        fileExtension: '*.tbk',
        description: 'Toolbook-Datei',
    },
    {
        mimeTyp: 'application/vocaltec-media-desc',
        fileExtension: '*.vmd',
        description: 'Vocaltec Mediadesc-Datei',
    },
    {
        mimeTyp: 'application/vocaltec-media-file',
        fileExtension: '*.vmf',
        description: 'Vocaltec Media-Datei',
    },
    {
        mimeTyp: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        fileExtension: '*.xlsx',
        description: 'Excel (OpenOffice Calc)',
    },
    {
        mimeTyp: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        fileExtension: '*.docx',
        description: 'Word (OpenOffice Writer)',
    },
    {
        mimeTyp: 'application/xhtml+xml',
        fileExtension: '*.htm *.html *.shtml *.xhtml',
        description: 'XHTML-Datei',
    },
    {
        mimeTyp: 'application/xml',
        fileExtension: '*.xml',
        description: 'XML-Datei',
    },
    {
        mimeTyp: 'application/x-bcpio',
        fileExtension: '*.bcpio',
        description: 'BCPIO-Datei',
    },
    {
        mimeTyp: 'application/x-compress',
        fileExtension: '*.z',
        description: 'zlib-komprimierte Datei',
    },
    {
        mimeTyp: 'application/x-cpio',
        fileExtension: '*.cpio',
        description: 'CPIO-Datei',
    },
    {
        mimeTyp: 'application/x-csh',
        fileExtension: '*.csh',
        description: 'C-Shellscript-Datei',
    },
    {
        mimeTyp: 'application/x-director',
        fileExtension: '*.dcr *.dir *.dxr',
        description: 'Macromedia Director-Datei',
    },
    {
        mimeTyp: 'application/x-dvi',
        fileExtension: '*.dvi',
        description: 'DVI-Datei',
    },
    {
        mimeTyp: 'application/x-envoy',
        fileExtension: '*.evy',
        description: 'Envoy-Datei',
    },
    {
        mimeTyp: 'application/x-gtar',
        fileExtension: '*.gtar',
        description: 'GNU tar-ArchivDatei',
    },
    {
        mimeTyp: 'application/x-hdf',
        fileExtension: '*.hdf',
        description: 'HDF-Datei',
    },
    {
        mimeTyp: 'application/x-httpd-php',
        fileExtension: '*.php *.phtml',
        description: 'PHP-Datei',
    },
    {
        mimeTyp: 'application/x-latex',
        fileExtension: '*.latex',
        description: 'LaTeX-QuellDatei',
    },
    {
        mimeTyp: 'application/x-macbinary',
        fileExtension: '*.bin',
        description: 'Macintosh BinärDatei',
    },
    {
        mimeTyp: 'application/x-mif',
        fileExtension: '*.mif',
        description: 'FrameMaker Interchange Format Datei',
    },
    {
        mimeTyp: 'application/x-netcdf',
        fileExtension: '*.nc *.cdf',
        description: 'Unidata CDF-Datei',
    },
    {
        mimeTyp: 'application/x-nschat',
        fileExtension: '*.nsc',
        description: 'NS Chat-Datei',
    },
    {
        mimeTyp: 'application/x-sh',
        fileExtension: '*.sh',
        description: 'Bourne Shellscript-Datei',
    },
    {
        mimeTyp: 'application/x-shar',
        fileExtension: '*.shar',
        description: 'Shell-ArchivDatei',
    },
    {
        mimeTyp: 'application/x-shockwave-flash',
        fileExtension: '*.swf *.cab',
        description: 'Flash Shockwave-Datei',
    },
    {
        mimeTyp: 'application/x-sprite',
        fileExtension: '*.spr *.sprite',
        description: 'Sprite-Datei',
    },
    {
        mimeTyp: 'application/x-stuffit',
        fileExtension: '*.sit',
        description: 'Stuffit-Datei',
    },
    {
        mimeTyp: 'application/x-supercard',
        fileExtension: '*.sca',
        description: 'Supercard-Datei',
    },
    {
        mimeTyp: 'application/x-sv4cpio',
        fileExtension: '*.sv4cpio',
        description: 'CPIO-Datei',
    },
    {
        mimeTyp: 'application/x-sv4crc',
        fileExtension: '*.sv4crc',
        description: 'CPIO-Datei mit CRC',
    },
    {
        mimeTyp: 'application/x-tar',
        fileExtension: '*.tar',
        description: 'tar-ArchivDatei',
    },
    {
        mimeTyp: 'application/x-tcl',
        fileExtension: '*.tcl',
        description: 'TCL ScriptDatei',
    },
    {
        mimeTyp: 'application/x-tex',
        fileExtension: '*.tex',
        description: 'TeX-Datei',
    },
    {
        mimeTyp: 'application/x-texinfo',
        fileExtension: '*.texinfo *.texi',
        description: 'Texinfo-Datei',
    },
    {
        mimeTyp: 'application/x-troff',
        fileExtension: '*.t *.tr *.roff',
        description: 'TROFF-Datei (Unix)',
    },
    {
        mimeTyp: 'application/x-troff-man',
        fileExtension: '*.man *.troff',
        description: 'TROFF-Datei mit MAN-Makros (Unix)',
    },
    {
        mimeTyp: 'application/x-troff-me',
        fileExtension: '*.me *.troff',
        description: 'TROFF-Datei mit ME-Makros (Unix)',
    },
    {
        mimeTyp: 'application/x-troff-ms',
        fileExtension: '*.me *.troff',
        description: 'TROFF-Datei mit MS-Makros (Unix)',
    },
    {
        mimeTyp: 'application/x-ustar',
        fileExtension: '*.ustar',
        description: 'tar-ArchivDatei (Posix)',
    },
    {
        mimeTyp: 'application/x-wais-source',
        fileExtension: '*.src',
        description: 'WAIS QuellDatei',
    },
    {
        mimeTyp: 'application/x-www-form-urlencoded',
        fileExtension: '&nbsp;',
        description: 'HTML-Formulardaten an CGI',
    },
    {
        mimeTyp: 'application/zip',
        fileExtension: '*.zip',
        description: 'ZIP-ArchivDatei',
    },
    {
        mimeTyp: 'audio/basic',
        fileExtension: '*.au *.snd',
        description: 'Sound-Datei',
    },
    {
        mimeTyp: 'audio/echospeech',
        fileExtension: '*.es',
        description: 'Echospeed-Datei',
    },
    {
        mimeTyp: 'audio/mpeg',
        fileExtension: '*.mp3',
        description: 'MP3-Datei',
    },
    {
        mimeTyp: 'audio/mp4',
        fileExtension: '*.mp4',
        description: 'MP4-Datei',
    },
    {
        mimeTyp: 'audio/ogg',
        fileExtension: '*.ogg',
        description: 'OGG-Datei',
    },
    {
        mimeTyp: 'audio/tsplayer',
        fileExtension: '*.tsi',
        description: 'TS-Player-Datei',
    },
    {
        mimeTyp: 'audio/voxware',
        fileExtension: '*.vox',
        description: 'Vox-Datei',
    },
    {
        mimeTyp: 'audio/wav',
        fileExtension: '*.wav',
        description: 'WAV-Datei',
    },
    {
        mimeTyp: 'audio/x-aiff',
        fileExtension: '*.aif *.aiff *.aifc',
        description: 'AIFF-Sound-Datei',
    },
    {
        mimeTyp: 'audio/x-dspeeh',
        fileExtension: '*.dus *.cht',
        description: 'SprachDatei',
    },
    {
        mimeTyp: 'audio/x-midi',
        fileExtension: '*.mid *.midi',
        description: 'MIDI-Datei',
    },
    {
        mimeTyp: 'audio/x-mpeg',
        fileExtension: '*.mp2',
        description: 'MPEG-AudioDatei',
    },
    {
        mimeTyp: 'audio/x-pn-realaudio',
        fileExtension: '*.ram *.ra',
        description: 'RealAudio-Datei',
    },
    {
        mimeTyp: 'audio/x-pn-realaudio-plugin',
        fileExtension: '*.rpm',
        description: 'RealAudio-Plugin-Datei',
    },
    {
        mimeTyp: 'audio/x-qt-stream',
        fileExtension: '*.stream',
        description: 'Quicktime-Streaming-Datei',
    },
    {
        mimeTyp: 'drawing/x-dwf',
        fileExtension: '*.dwf',
        description: 'Drawing-Datei',
    },
    {
        mimeTyp: 'image/bmp',
        fileExtension: '*.bmp',
        description: 'Windows Bitmap-Datei',
    },
    {
        mimeTyp: 'image/x-bmp',
        fileExtension: '*.bmp',
        description: 'Windows Bitmap-Datei',
    },
    {
        mimeTyp: 'image/x-ms-bmp',
        fileExtension: '*.bmp',
        description: 'Windows Bitmap-Datei',
    },
    {
        mimeTyp: 'image/cis-cod',
        fileExtension: '*.cod',
        description: 'CIS-Cod-Datei',
    },
    {
        mimeTyp: 'image/cmu-raster',
        fileExtension: '*.ras',
        description: 'CMU-Raster-Datei',
    },
    {
        mimeTyp: 'image/fif',
        fileExtension: '*.fif',
        description: 'FIF-Datei',
    },
    {
        mimeTyp: 'image/gif',
        fileExtension: '*.gif',
        description: 'GIF-Datei',
    },
    {
        mimeTyp: 'image/ief',
        fileExtension: '*.ief',
        description: 'IEF-Datei',
    },
    {
        mimeTyp: 'image/jpeg',
        fileExtension: '*.jpeg *.jpg *.jpe',
        description: 'JPEG-Datei',
    },
    {
        mimeTyp: 'image/png',
        fileExtension: '*.png',
        description: 'PNG-Datei',
    },
    {
        mimeTyp: 'image/svg+xml',
        fileExtension: '*.svg',
        description: 'SVG-Datei',
    },
    {
        mimeTyp: 'image/tiff',
        fileExtension: '*.tiff *.tif',
        description: 'TIFF-Datei',
    },
    {
        mimeTyp: 'image/vasa',
        fileExtension: '*.mcf',
        description: 'Vasa-Datei',
    },
    {
        mimeTyp: 'image/vnd.wap.wbmp',
        fileExtension: '*.wbmp',
        description: 'Bitmap-Datei (WAP)',
    },
    {
        mimeTyp: 'image/x-freehand',
        fileExtension: '*.fh4 *.fh5 *.fhc',
        description: 'Freehand-Datei',
    },
    {
        mimeTyp: 'image/x-icon',
        fileExtension: '*.ico',
        description: 'Icon-Datei (z.&nbsp;B. Favoriten-Icons)',
    },
    {
        mimeTyp: 'image/x-portable-anymap',
        fileExtension: '*.pnm',
        description: 'PBM Anymap Datei',
    },
    {
        mimeTyp: 'image/x-portable-bitmap',
        fileExtension: '*.pbm',
        description: 'PBM Bitmap Datei',
    },
    {
        mimeTyp: 'image/x-portable-graymap',
        fileExtension: '*.pgm',
        description: 'PBM Graymap Datei',
    },
    {
        mimeTyp: 'image/x-portable-pixmap',
        fileExtension: '*.ppm',
        description: 'PBM Pixmap Datei',
    },
    {
        mimeTyp: 'image/x-rgb',
        fileExtension: '*.rgb',
        description: 'RGB-Datei',
    },
    {
        mimeTyp: 'image/x-windowdump',
        fileExtension: '*.xwd',
        description: 'X-Windows Dump',
    },
    {
        mimeTyp: 'image/x-xbitmap',
        fileExtension: '*.xbm',
        description: 'XBM-Datei',
    },
    {
        mimeTyp: 'image/x-xpixmap',
        fileExtension: '*.xpm',
        description: 'XPM-Datei',
    },
    {
        mimeTyp: 'message/external-body',
        fileExtension: '&nbsp;',
        description: 'Nachricht mit externem Inhalt',
    },
    {
        mimeTyp: 'message/http',
        fileExtension: '&nbsp;',
        description: 'HTTP-Headernachricht',
    },
    {
        mimeTyp: 'message/news',
        fileExtension: '&nbsp;',
        description: 'Newsgroup-Nachricht',
    },
    {
        mimeTyp: 'message/partial',
        fileExtension: '&nbsp;',
        description: 'Nachricht mit Teilinhalt',
    },
    {
        mimeTyp: 'message/rfc822',
        fileExtension: '&nbsp;',
        description: 'Nachricht nach RFC (2)822',
    },
    {
        mimeTyp: 'model/vrml',
        fileExtension: '*.wrl',
        description: 'Visualisierung virtueller Welten (VRML)',
    },
    {
        mimeTyp: 'multipart/alternative',
        fileExtension: '&nbsp;',
        description: 'mehrteilige Daten; jeder Teil ist eine zu den anderen gleichwertige Alternative',
    },
    {
        mimeTyp: 'multipart/byteranges',
        fileExtension: '&nbsp;',
        description: 'mehrteilige Daten mit Byte-Angaben',
    },
    {
        mimeTyp: 'multipart/digest',
        fileExtension: '&nbsp;',
        description: 'mehrteilige Daten / Auswahl',
    },
    {
        mimeTyp: 'multipart/encrypted',
        fileExtension: '&nbsp;',
        description: 'mehrteilige Daten verschlüsselt',
    },
    {
        mimeTyp: 'multipart/form-data',
        fileExtension: '&nbsp;',
        description: 'mehrteilige Daten aus HTML-Formular (z.&nbsp;B. File-Upload)',
    },
    {
        mimeTyp: 'multipart/mixed',
        fileExtension: '&nbsp;',
        description: 'mehrteilige Daten ohne Bezug der Teile untereinander',
    },
    {
        mimeTyp: 'multipart/parallel',
        fileExtension: '&nbsp;',
        description: 'mehrteilige Daten parallel',
    },
    {
        mimeTyp: 'multipart/related',
        fileExtension: '&nbsp;',
        description: 'mehrteilige Daten mit Abhängigkeiten der Teile voneinander',
    },
    {
        mimeTyp: 'multipart/report',
        fileExtension: '&nbsp;',
        description: 'mehrteilige Daten / Bericht',
    },
    {
        mimeTyp: 'multipart/signed',
        fileExtension: '&nbsp;',
        description: 'mehrteilige Daten / bezeichnet',
    },
    {
        mimeTyp: 'multipart/voice-message',
        fileExtension: '&nbsp;',
        description: 'mehrteilige Daten / Sprachnachricht',
    },
    {
        mimeTyp: 'text/calendar',
        fileExtension: '*.ics',
        description: 'iCalendar-Datei',
    },
    {
        mimeTyp: 'text/comma-separated-values',
        fileExtension: '*.csv',
        description: 'kommaseparierte DatenDatei',
    },
    {
        mimeTyp: 'text/css',
        fileExtension: '*.css',
        description: 'CSS Stylesheet-Datei',
    },
    {
        mimeTyp: 'text/html',
        fileExtension: '*.htm *.html *.shtml',
        description: 'HTML-Datei',
    },
    {
        mimeTyp: 'text/javascript',
        fileExtension: '*.js',
        description: 'JavaScript-Datei',
    },
    {
        mimeTyp: 'text/plain',
        fileExtension: '*.txt',
        description: 'reine TextDatei',
    },
    {
        mimeTyp: 'text/richtext',
        fileExtension: '*.rtx',
        description: 'Richtext-Datei',
    },
    {
        mimeTyp: 'text/rtf',
        fileExtension: '*.rtf',
        description: 'RTF-Datei',
    },
    {
        mimeTyp: 'text/tab-separated-values',
        fileExtension: '*.tsv',
        description: 'tabulator-separierte DatenDatei',
    },
    {
        mimeTyp: 'text/vnd.wap.wml',
        fileExtension: '*.wml',
        description: 'WML-Datei (WAP)',
    },
    {
        mimeTyp: 'application/vnd.wap.wmlc',
        fileExtension: '*.wmlc',
        description: 'WMLC-Datei (WAP)',
    },
    {
        mimeTyp: 'text/vnd.wap.wmlscript',
        fileExtension: '*.wmls',
        description: 'WML-ScriptDatei (WAP)',
    },
    {
        mimeTyp: 'application/vnd.wap.wmlscriptc',
        fileExtension: '*.wmlsc',
        description: 'WML-Script-C-Datei (WAP)',
    },
    {
        mimeTyp: 'text/xml',
        fileExtension: '*.xml',
        description: 'XML-Datei',
    },
    {
        mimeTyp: 'text/xml-external-parsed-entity',
        fileExtension: '&nbsp;',
        description: 'extern geparste XML-Datei',
    },
    {
        mimeTyp: 'text/x-setext',
        fileExtension: '*.etx',
        description: 'SeText-Datei',
    },
    {
        mimeTyp: 'text/x-sgml',
        fileExtension: '*.sgm *.sgml',
        description: 'SGML-Datei',
    },
    {
        mimeTyp: 'text/x-speech',
        fileExtension: '*.talk *.spc',
        description: 'Speech-Datei',
    },
    {
        mimeTyp: 'video/mpeg',
        fileExtension: '*.mpeg *.mpg *.mpe',
        description: 'MPEG-VideoDatei',
    },
    {
        mimeTyp: 'video/mp4',
        fileExtension: '*.mp4',
        description: 'MP4-VideoDatei',
    },
    {
        mimeTyp: 'video/ogg',
        fileExtension: '*.ogg *.ogv',
        description: 'OGG-Datei',
    },
    {
        mimeTyp: 'video/quicktime',
        fileExtension: '*.qt *.mov',
        description: 'Quicktime-Datei',
    },
    {
        mimeTyp: 'video/vnd.vivo',
        fileExtension: '*.viv *.vivo',
        description: 'Vivo-Datei',
    },
    {
        mimeTyp: 'video/webm',
        fileExtension: '*.webm',
        description: 'Webm-VideoDatei',
    },
    {
        mimeTyp: 'video/x-msvideo',
        fileExtension: '*.avi',
        description: 'Microsoft AVI-Datei',
    },
    {
        mimeTyp: 'video/x-sgi-movie',
        fileExtension: '*.movie',
        description: 'Movie-Datei',
    },
    {
        mimeTyp: 'video/3gpp',
        fileExtension: '*.3gp',
        description: '3GP Mobile Datei',
    },
    {
        mimeTyp: 'workbook/formulaone',
        fileExtension: '*.vts *.vtts',
        description: 'FormulaOne-Datei',
    },
    {
        mimeTyp: 'x-world/x-3dmf',
        fileExtension: '*=mf *=m *.qd3d *.qd3',
        description: '3DMF-Datei',
    },
    {
        mimeTyp: 'x-world/x-vrml',
        fileExtension: '*.wrl',
        description: 'Visualisierung virtueller Welten (VRML)',
    },
];
