<mat-expansion-panel *ngIf="Expandable" #panel class="expansion-panel-without-padding">
    <mat-expansion-panel-header class="card-headline" style="padding: 0 15px 0 7px">
        <div class="header-content">
            <fa-stack matCardAvatar>
                <fa-icon [icon]="MaterialPageMeta.Icon" stackItemSize="1x"></fa-icon>
            </fa-stack>
            <h3>Material</h3>
        </div>
        <div class="header-button-wrapper">
            <mat-form-field floatLabel="never" [class.invisible]="!searchVisible" (click)="$event.stopPropagation()" style="width: 12rem">
                <mat-label>Suche</mat-label>
                <input matInput type="search" [formControl]="searchForm" #searchInput />
                <button class="clear-search" *ngIf="searchForm.value" mat-icon-button aria-label="Clear" (click)="searchForm.setValue('')">
                    <!--                        <fa-icon icon="times"></fa-icon>-->
                </button>
            </mat-form-field>
            <button *ngIf="!searchVisible && (materialLists$ | async)?.data?.length && panel.expanded" app-round-button matTooltip="Zus. Spalten anzeigen" (click)="$event.stopPropagation()" [matMenuTriggerFor]="menu">
                <fa-icon icon="table-columns"></fa-icon>
            </button>
            <button *ngIf="panel.expanded" style="margin: auto 0 auto auto" app-round-button (click)="$event.stopPropagation(); toggleSearch()" [matTooltip]="searchVisible ? 'abbrechen' : 'suchen'">
                <fa-icon [icon]="searchVisible ? 'times' : 'search'"></fa-icon>
            </button>
            <button *ngIf="!dialogRef && !searchVisible" matTooltip="Vollbild" app-round-button (click)="openDialog(); $event.stopPropagation()">
                <fa-icon icon="expand"></fa-icon>
            </button>
        </div>
    </mat-expansion-panel-header>
    <ng-container [ngTemplateOutlet]="content"></ng-container>
</mat-expansion-panel>
<div *ngIf="!Expandable">
    <div class="card-headline" style="padding: 0 0.5rem 0 0.5rem">
        <div class="header-content">
            <h3>Material</h3>
        </div>
        <div class="header-button-wrapper" *ngIf="Expanded">
            <div class="header-button-wrapper">
                <mat-form-field floatLabel="never" [class.invisible]="!searchVisible" (click)="$event.stopPropagation()" style="width: 15rem; margin-right: 8px; height: auto">
                    <mat-label>Suche</mat-label>
                    <input matInput type="search" [formControl]="searchForm" #searchInput />
                    <button class="clear-search" *ngIf="searchForm.value" mat-icon-button aria-label="Clear" (click)="searchForm.setValue('')">
                        <fa-icon icon="times"></fa-icon>
                    </button>
                </mat-form-field>
                <button *ngIf="!searchVisible && (materialLists$ | async)?.data?.length" app-round-button matTooltip="Zus. Spalten anzeigen" [matMenuTriggerFor]="menu">
                    <fa-icon icon="table-columns"></fa-icon>
                </button>
                <button style="margin: auto 0 auto auto" app-round-button (click)="$event.stopPropagation(); toggleSearch()" [matTooltip]="searchVisible ? 'abbrechen' : 'suchen'">
                    <fa-icon [icon]="searchVisible ? 'times' : 'search'"></fa-icon>
                </button>
                <button *ngIf="!searchVisible" app-round-button [matMenuTriggerFor]="addMenu" (click)="$event.stopPropagation()" matTooltip="Material zuweisen">
                    <app-double-icon [Icon]="MaterialPageMeta.Icon" Icon2="plus"></app-double-icon>
                </button>
                <mat-menu #addMenu>
                    <button (click)="openNewPopup()" mat-menu-item>
                        <fa-icon class="mat-icon" icon="plus"></fa-icon>
                        <span>Materialbedarf</span>
                    </button>
                    <button (click)="createMaterialToCommission()" mat-menu-item>
                        <fa-icon class="mat-icon" icon="plus"></fa-icon>
                        <span>Materialverbrauch</span>
                    </button>
                </mat-menu>
            </div>
        </div>
    </div>
    <ng-container [ngTemplateOutlet]="content"></ng-container>
    <ng-container [ngTemplateOutlet]="footer"></ng-container>
</div>

<ng-template #content>
    <mat-tab-group mat-align-tabs="center">
        <mat-tab>
            <ng-template mat-tab-label>Material</ng-template>
            <div class="scroll-wrapper">
                <table *ngIf="materialLists$ | async" mat-table class="fixed-header" [dataSource]="materialLists$ | async">
                    <ng-container matColumnDef="MaterialName" style="height: 2.3rem; overflow: hidden">
                        <th mat-header-cell *matHeaderCellDef>Material</th>
                        <td mat-cell *matCellDef="let element">{{ element.MaterialName }}</td>
                    </ng-container>
                    <ng-container matColumnDef="Quantity">
                        <th mat-header-cell *matHeaderCellDef>Anzahl</th>
                        <td mat-cell *matCellDef="let element">{{ element.Quantity }}</td>
                    </ng-container>
                    <ng-container matColumnDef="QuantityType">
                        <th mat-header-cell *matHeaderCellDef>Einheit</th>
                        <td mat-cell *matCellDef="let element">{{ element.QuantityType }}</td>
                    </ng-container>
                    <ng-container matColumnDef="CreatedAt">
                        <th mat-header-cell *matHeaderCellDef>Datum</th>
                        <td mat-cell *matCellDef="let element">{{ element.CreatedAt }}</td>
                    </ng-container>
                    <ng-container matColumnDef="Status">
                        <th mat-header-cell *matHeaderCellDef>Status</th>
                        <td mat-cell *matCellDef="let element">{{ element.Status }}</td>
                    </ng-container>
                    <ng-container matColumnDef="User">
                        <th mat-header-cell *matHeaderCellDef>Bearbeiter</th>
                        <td mat-cell *matCellDef="let element">{{ element.User }}</td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="MaterialColumns"></tr>
                    <tr mat-row *matRowDef="let rowData; columns: MaterialColumns"></tr>

                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" [attr.colspan]="MaterialColumns?.length">
                            <span>Keine Materialien vorhanden</span>
                        </td>
                    </tr>
                </table>
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>verbrauchtes Material</ng-template>
            <div class="scroll-wrapper p-4">
                <mat-accordion>
                    <mat-expansion-panel *ngFor="let material of materialToCommissions$ | async">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{ material.MaterialName }}
                            </mat-panel-title>
                            <mat-panel-description style="flex-grow: 0; flex-basis: unset;">
                                <span [class.app-foreground-warn]="(material.CurrentSum$ | async) > material.TargetSum">{{ material.CurrentSum$ | async }} / {{ material.TargetSum }}</span>
                                <button mat-icon-button (click)="createMaterialToCommission(material); $event.stopPropagation();"><fa-icon icon="plus"></fa-icon></button>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <table  mat-table class="fixed-header" [dataSource]="material.m2cs$ | async">
                            <ng-container matColumnDef="Quantity">
                                <th mat-header-cell *matHeaderCellDef>Anzahl</th>
                                <td mat-cell *matCellDef="let element">{{ element.Quantity }}</td>
                            </ng-container>
                            <ng-container matColumnDef="QuantityType">
                                <th mat-header-cell *matHeaderCellDef>Einheit</th>
                                <td mat-cell *matCellDef="let element">{{ element.QuantityType }}</td>
                            </ng-container>
                            <ng-container matColumnDef="Date">
                                <th mat-header-cell *matHeaderCellDef>Datum</th>
                                <td mat-cell *matCellDef="let element">{{ element.Date }}</td>
                            </ng-container>
                            <ng-container matColumnDef="Name">
                                <th mat-header-cell *matHeaderCellDef>Bezeichnung</th>
                                <td mat-cell *matCellDef="let element">{{ element.Name }}</td>
                            </ng-container>
                            <ng-container matColumnDef="User">
                                <th mat-header-cell *matHeaderCellDef>Bearbeiter</th>
                                <td mat-cell *matCellDef="let element">{{ element.User }}</td>
                            </ng-container>
                            <ng-container matColumnDef="editButton">
                                <th mat-header-cell *matHeaderCellDef style="width: 40px"></th>
                                <td mat-cell *matCellDef="let element" style="width: 40px">
                                    <button mat-icon-button (click)="editMaterialToCommission(element.MaterialToCommissionId)"><fa-icon icon="pencil-alt"></fa-icon></button>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="MaterialToCommissionColumns"></tr>
                            <tr mat-row *matRowDef="let rowData; columns: MaterialToCommissionColumns"></tr>
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" [attr.colspan]="MaterialToCommissionColumns.length">
                                    <span>Keine Materialverbräuche vorhanden</span>
                                </td>
                            </tr>
                        </table>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </mat-tab>
    </mat-tab-group>
</ng-template>
<ng-template #footer>
    <mat-dialog-actions class="actions" *ngIf="Expanded">
        <button app-button Inverted mat-dialog-close Color="cancel">schließen</button>
    </mat-dialog-actions>
</ng-template>

<mat-menu #menu="matMenu">
    <!--                    <button mat-menu-item (click)="toggleColumn('Lieferant')">-->
    <!--                        {{ visibility.Lieferant ? 'Lieferant anzeigen' : 'Lieferant ausblenden' }}-->
    <!--                    </button>-->
    <button mat-menu-item (click)="toggleColumn('Status')">
        {{ visibility.Status ? 'Status anzeigen' : 'Status ausblenden' }}
    </button>
    <button mat-menu-item (click)="toggleColumn('User')">
        {{ visibility.User ? 'Bearbeiter anzeigen' : 'Bearbeiter ausblenden' }}
    </button>
</mat-menu>
