import { createFeatureSelector, createSelector } from '@ngrx/store';
import { customersFeatureKey, CustomerState, selectAllCustomer, selectCustomerEntities, selectCustomerFetched, selectCustomerLatestUpdatedAt } from '../reducers/customers.reducer';
import { Dictionary } from '@ngrx/entity';
import { CustomerTypeEntity } from '../../entities/customer-type.entity';

const selectCustomer = createFeatureSelector<CustomerState>(customersFeatureKey);
const getCustomerEntitys = createSelector(selectCustomer, selectCustomerEntities);

export const getCustomers = createSelector(selectCustomer, selectAllCustomer);
export const getCustomerDictionary = getCustomerEntitys;
export const getCustomersFetched = createSelector(selectCustomer, selectCustomerFetched);
export const getCustomerLatestUpdatedAt = createSelector(selectCustomer, selectCustomerLatestUpdatedAt);
export const getCustomerById = (props: { id: number }) => createSelector(getCustomerEntitys, (dictionary) => props?.id && dictionary[props.id]);

export const getNotDeletedCustomers = createSelector(getCustomers, (data) => data.filter((c) => !c.Deleted));
export const getNotDeletedSuppliers = createSelector(getNotDeletedCustomers, (data) => data.filter((c) => c.IsSupplier === true));
export const getCustomerTypeFetched = createSelector(selectCustomer, (data) => !!data.customerTypes);
export const getCustomerTypes = createSelector(selectCustomer, (data) => data.customerTypes);
export const getCustomerTypeDictionary = createSelector(selectCustomer, (data) => {
    const d = {};
    data.customerTypes?.forEach(e => d[e.Id] = e);
    return d as Dictionary<CustomerTypeEntity>;
});

export const getCustomerLocations = createSelector(selectCustomer, (data) => data.customerLocations);
