import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap } from 'rxjs/operators';
import {
    EventEntity,
    EventStateEnum,
    EventStateEnumNameMap,
    ITaskPriority,
    taskEventStates,
} from '../../../dave-data-module/entities/event.entity';
import { State } from '../../../dave-data-module/State';
import { EventsActionTypes } from '../../../dave-data-module/State/actions/events.actions';
import { getCommissionDictionary } from '../../../dave-data-module/State/selectors/commission.selector';
import { getEventById, getTasks, getTasksFetched } from '../../../dave-data-module/State/selectors/events.selectors';
import { getUser } from '../../../dave-data-module/State/selectors/users.selectors';
import {
    DetailTasksComponent,
    DetailTasksComponentDialogData,
    DetailTasksComponentDialogDataInitialValues
} from "../../../dave-event-card/components/detail-tasks/detail-tasks.component";
import { BreakpointObserverService } from '../../../dave-utils-module/dave-shared-components-module/services/breakpoint-observer.service';
import { isNotNullOrUndefined } from '../../../helper/helper';
import {
    getUserToCommissionCommissionIdsMap, getUserToCommissionDictionary, getUserToCommissionFetched,
} from '../../../dave-data-module/State/selectors/user-to-commission.selector';
import { getFetched$ } from '../../../dave-data-module/helper/helper';
import { UserToCommissionResolver } from '../../../dave-data-module/guards/user-to-commission.resolver';

export interface TaskBoardFilter {
    CommissionIds?: number[];
    UserId?: number[];
    AutorId?: number[];
    State?: EventStateEnum[];
    MaxEventEndDate?: Date;
    MinEventEndDate?: Date;
    Priority?: ITaskPriority[];
    MilestoneIds?: number[];
}

interface lane {
    Headline: string;
    Id: EventStateEnum;
    EventIds: number[];
}
export interface ITaskBoardSort {
    sortBy: 'endDate' | 'priority';
    direction: 'asc' | 'dsc';
}
@Component({
    selector: 'app-tasks-board[FileClicked]',
    templateUrl: './tasks-board.component.html',
    styleUrls: ['./tasks-board.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TasksBoardComponent implements OnInit {
    @Input() ShowNewButton = true;
    @Input() NewTaskInitialValues: DetailTasksComponentDialogDataInitialValues;
    @Input()
    set Search(search: string) {
        this.search$.next(search);
    }
    @Input()
    set Filter(filter: TaskBoardFilter) {
        this.filter$.next(filter);
    }
    @Input()
    set Sort(sort: ITaskBoardSort) {
        this.sort$.next(sort);
    }
    @Input() PreFilter: {
        authorOrUserToEventOrUserToCommission?: boolean;
        eventIds?: number[];
    };
    @Output() FileClicked = new EventEmitter<number>();

    public isCompletedCollapsed = true;

    private search$ = new BehaviorSubject<string>('');
    private filter$ = new BehaviorSubject<TaskBoardFilter>({});
    protected subscriptions: Subscription[] = [];
    public DisableDragNDrop$ = combineLatest([this.bd.TouchQuery, this.bd.MobileQuery]).pipe(map(([isTouch, mobile]) => isTouch && mobile));

    public sort$: BehaviorSubject<ITaskBoardSort> = new BehaviorSubject({ sortBy: 'endDate', direction: 'dsc' });
    private preFilteredAndSortedTasks$: Observable<EventEntity[]> = this.store$.select(getTasksFetched).pipe(
        filter((v) => !!v),
        switchMap(() => this.onInit$),
        filter((v) => !!v),
        switchMap(() => this.store$.select(getTasks).pipe(
            map((tasks) => tasks.filter((t) => !this.PreFilter || !this.PreFilter.eventIds || this.PreFilter.eventIds.includes(t.Id))),
        )),
        switchMap((tasks) =>
            this.PreFilter && this.PreFilter.authorOrUserToEventOrUserToCommission
                ? combineLatest([getFetched$(this.store$, getUserToCommissionFetched, getUserToCommissionCommissionIdsMap), getFetched$(this.store$, getUserToCommissionFetched, getUserToCommissionDictionary), this.store$.select(getUser)]).pipe(
                      map(([userToCommissions, userToCommissionDict, user]) =>
                          tasks.filter((t) => t.UserId === user.Id || t.UserIds?.includes(user.Id) || (t.CommissionId && userToCommissions.get(t.CommissionId)?.some(id => userToCommissionDict[id].UserId === user.Id))),
                      ),
                  )
                : of(tasks),
        ),
        map((tasks) =>
            tasks.sort((a, b) => {
                const aDate = a.EventEndDate;
                const bDate = b.EventEndDate;
                if (aDate == null && bDate == null) {
                    return 0;
                }
                if (aDate == null && bDate != null) {
                    return 1;
                }
                if (aDate != null && bDate == null) {
                    return -1;
                }
                return aDate.getTime() - bDate.getTime();
            }),
        ),
        switchMap((tasks) =>
            this.filter$.pipe(
                map((filter) => {
                    let filteredTasks = tasks.slice();
                    if (filter?.AutorId?.length) {
                        filteredTasks = filteredTasks.filter((e) => filter.AutorId.includes(e.UserId));
                    }
                    if (filter?.CommissionIds?.length) {
                        filteredTasks = filteredTasks.filter((e) => filter.CommissionIds.includes(e.CommissionId));
                    }
                    if (filter?.MilestoneIds?.length) {
                        filteredTasks = filteredTasks.filter((e) => filter.MilestoneIds.some(mId => e.MilestoneIds.includes(mId)));
                    }
                    if (filter?.UserId?.length) {
                        filteredTasks = filteredTasks.filter((e) => filter.UserId.some(fid => e.UserIds?.includes(fid)));
                    }
                    if (filter?.State) {
                        filteredTasks = filteredTasks.filter((e) => filter.State.includes(e.State));
                    }
                    if (filter?.MaxEventEndDate) {
                        filteredTasks = filteredTasks.filter((e) => e.EventEndDate && filter.MaxEventEndDate.getTime() > e.EventEndDate.getTime());
                    }
                    if (filter?.MinEventEndDate) {
                        filteredTasks = filteredTasks.filter((e) => e.EventEndDate && filter.MinEventEndDate.getTime() < e.EventEndDate.getTime());
                    }
                    if (filter?.Priority?.length) {
                        filteredTasks = filteredTasks.filter((e) => isNotNullOrUndefined(e.AdditionalData?.Priority) && filter.Priority.includes(e.AdditionalData.Priority));
                    }
                    return filteredTasks;
                }),
            ),
        ),
        switchMap((tasks) =>
            this.sort$.pipe(
                distinctUntilChanged((a, b) => a.sortBy === b.sortBy && a.direction === b.direction),
                map((sort) => {
                    switch (sort.sortBy) {
                        case 'endDate':
                            return sort.direction === 'dsc' ? tasks : tasks.slice().reverse();
                        case 'priority':
                            const sortTask = tasks.slice().sort((a, b) => {
                                if (a.AdditionalData?.Priority === b.AdditionalData?.Priority) {
                                    return 0;
                                }
                                if (!a.AdditionalData?.Priority) {
                                    return -1;
                                }
                                if (!b.AdditionalData?.Priority) {
                                    return 1;
                                }
                                return a.AdditionalData.Priority - b.AdditionalData.Priority;
                            });
                            return sort.direction === 'asc' ? sortTask : sortTask.reverse();
                    }
                }),
            ),
        ),
        ).pipe(
        switchMap((tasks) =>
            this.search$.pipe(
                map((value) => (value || '').toLowerCase()),
                map((searchTerm) =>
                    (searchTerm
                        ? tasks.filter((data) => ['', data?.Hint || '', data?.Description || '', data?.Name || ''].map((value) => value?.trim().toLowerCase()).some((value) => value?.includes(searchTerm)))
                        : tasks
                    ),
                ),
            ),
        ),
        shareReplay({ bufferSize: 1, refCount: true }),
    );
    public NewSwimLanes: {
        Headline: string;
        Id: EventStateEnum;
        eventIds$: Observable<number[]>;
    }[] = Object.values(taskEventStates).map((state) => ({
        Headline: EventStateEnumNameMap.get(state),
        Id: state,
        eventIds$: this.preFilteredAndSortedTasks$.pipe(
            map((tasks) => tasks.filter((t) => t.State === state).map((t) => t.Id)),
            distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        ),
    }));
    private onInit$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    constructor(private store$: Store<State>, private dialog: MatDialog, private bd: BreakpointObserverService, u2cResolver: UserToCommissionResolver) {
        firstValueFrom(this.store$.select(getUserToCommissionFetched)).then(f => {
            if (!f) {
                u2cResolver.resolve();
            }
        })
    }
    ngOnInit(): void {
        this.onInit$.next(true);
    }

    toggleCompleted() {
        this.isCompletedCollapsed = !this.isCompletedCollapsed;
    }


    Drop(event: CdkDragDrop<EventStateEnum, EventStateEnum, number>) {
        if (event.previousContainer !== event.container) {
            const id = event.item.data;
            const state = event.container.data;
            firstValueFrom(this.store$.select(getEventById({ id }))).then((e) => {
                this.store$.dispatch(EventsActionTypes.UpdateEvent({ Payload: e.Clone({ State: event.container.data }) }));
            });
            this.store$.dispatch(EventsActionTypes.ModifyEvent({ Payload: { id, state } }));
        }
    }
    OpenAddTaskDialog() {
        this.dialog.open<DetailTasksComponent, DetailTasksComponentDialogData>(DetailTasksComponent, {
            ...DetailTasksComponent.DefaultConfig,
            data: {
                EventId: null,
                InitialValues: {
                    CommissionId: this.filter$.value?.CommissionIds?.length === 1 ? this.filter$.value?.CommissionIds[0] : null,
                    ...(this.NewTaskInitialValues || {})
                },
            },
        });
    }

    TrackLanes(index: number, lane: { Id: EventStateEnum }) {
        return lane.Id;
    }
}
