import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy,Component,Inject } from '@angular/core';
import { MatDialogConfig,MatDialogModule,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DaveLoadingModule } from '../../../dave-loading/dave-loading.module';
import { AppButtonModule } from '../../../dave-utils-module/app-button-module/app-button.module';
import { appMatDialogDefaultConfig } from '../../../helper/helper';
import { EInvoiceDataService } from '../../services/e-invoice-data.service';
import { EInvoiceViewComponent } from '../e-invoice-view/e-invoice-view.component';

export interface EInvoiceViewDialogComponentDialogData {
    versionId: number,
}
export type EInvoiceViewDialogComponentDialogReturnData = undefined;
@Component({
    selector: 'app-e-invoice-view-dialog',
    templateUrl: './e-invoice-view-dialog.component.html',
    styleUrls: ['./e-invoice-view-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, EInvoiceViewComponent, MatDialogModule, AppButtonModule, DaveLoadingModule],
})
export class EInvoiceViewDialogComponent {
    public static DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        panelClass: [...appMatDialogDefaultConfig.panelClass, 'custom-dialog-class-fullscreen'],
    };
    eInvoiceEntity$: Promise<EInvoiceViewComponent['EInvoice']>;

    constructor(data: EInvoiceDataService, @Inject(MAT_DIALOG_DATA) dialogData: EInvoiceViewDialogComponentDialogData) {
        if (!dialogData.versionId) {
            throw new Error('versionId is required');
        }
        this.eInvoiceEntity$ = data.getEInvoiceFromVersion(dialogData.versionId);
    }
}
