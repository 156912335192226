<app-detail-view-template *ngIf="Person$ | async as data; else loadingTemplate">
    <ng-container *appDetailViewButtons>
        <button *ngIf="!(Editing$ | async) && !data?.Deleted" app-round-button Inverted class="header-button" matTooltip="Bearbeiten" aria-label="Bearbeiten" (click)="Editing$.next(true)">
            <fa-icon icon="pencil-alt"></fa-icon>
        </button>
        <button *ngIf="!(Editing$ | async)" app-round-button  class="header-button" Inverted matTooltip="E-Mail senden" aria-label="E-Mail senden" (click)="OpenMailDialog$.next()">
            <fa-icon icon="paper-plane"></fa-icon>
        </button>
        <button *ngIf="!(Editing$ | async)" app-round-button Inverted class="header-button" aria-label="Schnell anlegen" matTooltip="Schnell Hinzufügen" (click)="CreateEvent()">
            <div class="icon-mutiple">
                <fa-icon icon="history" size="xs"></fa-icon>
                <fa-icon icon="plus" size="xs"></fa-icon>
            </div>
        </button>
        <button *ngIf="Editing$ | async as Editing" app-round-button Color="green" class="header-button" matTooltip="Speichern" aria-label="Speichern" [Disabled]="FormInvalid || FormEmpty" (click)="Save()">
            <fa-icon icon="save"></fa-icon>
        </button>
        <button *ngIf="Editing$ | async" app-round-button Inverted class="header-button" matTooltip="Zurücksetzen" aria-label="Zurücksetzen" (click)="Editing$.next(false)">
            <fa-icon icon="undo"></fa-icon>
        </button>
        <button *ngIf="!data?.Deleted && Editing$ | async as Editing" app-round-button Color="red" class="header-button" matTooltip="Löschen" aria-label="Löschen" (click)="DeletePerson(data)" [Disabled]="data.Id === null">
            <fa-icon icon="trash"></fa-icon>
        </button>
    </ng-container>

    <ng-container *appDetailViewLeftContent>
        <app-person-detail-form
            #Form
            [Editing]="Editing$ | async"
            (dirtyChanged)="FormDirty = $event"
            (formEmpty)="FormEmpty = $event"
            (formInvalidChanged)="FormInvalid = $event"
            (saved)="Saved($event)"
            [Person]="Person$ | async"
        ></app-person-detail-form>
    </ng-container>

    <ng-container *appDetailViewRightContent>
        <app-detail-commission-event-list
            *ngIf="!(Editing$ | async) && (ps.Has(ps.Permission.GetServiceHistory) | async)"
            [View]="{expanded: true, type: '', order: 0}"
            [PersonId]="(Person$ | async)?.Id"
        ></app-detail-commission-event-list>

<!--        <mat-card class="card detail-card card-fix" *ngIf="!(Editing$ | async)">-->
<!--            <div class="card-header">-->
<!--                <fa-icon class="icon" icon="history"></fa-icon>-->

<!--                <h3>Ereignisse</h3>-->
<!--            </div>-->
<!--            <cdk-virtual-scroll-viewport class="scroll-viewport events" itemSize="89" minBufferPx="200" maxBufferPx="400">-->
<!--                <app-event-list-entry-->
<!--                    class="full-width"-->
<!--                    *cdkVirtualFor="let rowData of EventList$ | async"-->
<!--                    [ShowRoutingButtons]="true"-->
<!--                    [Event]="rowData"-->
<!--                ></app-event-list-entry>-->
<!--            </cdk-virtual-scroll-viewport>-->
<!--            <div class="bottom-shadow"></div>-->
<!--        </mat-card>-->



            <app-dave-list-card
                class="detail-card"
                [Headline]="cls.getMultiple$('Commission') | async"
                [HeaderIcon]="CommissionMeta.Icon"
                [EmptyText]="'Keine ' + (cls.getMultiple$('Commission') | async) + ' vorhanden'"
                [Data]="Commissions$"
                [DisplayedCollums]="['InterneNummer', 'Description', 'Auftragsnummer']"
                [TableHeaderCollumsMap]="{
                InterneNummer: 'Interne Nummer',
                Description: CNS.getName,
                Auftragsnummer: 'Auftragsnummer'
            }"
                (EntryClicked$)="RouteToCommission($event)"
                [Clickable]="true"
                [IsExpandable]="true"
                style="max-height: 50vh"
            >
                <button headerButton app-round-button [matTooltip]="(cls.getSingle$('Commission') | async) + ' zuordnen'" (click)="addCommissionClick()">
                    <app-double-icon [Icon]="CommissionMeta.Icon" Icon2="plus"></app-double-icon>
                </button>
            </app-dave-list-card>

        <app-dave-list-card
            class="detail-card"
            [HeaderIcon]="CustomerMeta.Icon"
            [Headline]="CS.GetMultiple()"
            [EmptyText]="'Keine ' + CS.GetMultiple() + ' vorhanden'"
            [Data]="Customers$"
            [DisplayedCollums]="['Name']"
            (EntryClicked$)="RouteToCustomer($event)"
            [Clickable]="true"
        >
            <button app-round-button headerButton (click)="onAddCustomerClick()" [matTooltip]="CS.GetSingle() + ' zuweisen'">
                <app-double-icon [Icon]="CustomerMeta.Icon" Icon2="plus"></app-double-icon>
            </button>
        </app-dave-list-card>
    </ng-container>
</app-detail-view-template>

<ng-template #loadingTemplate>
    <app-loading></app-loading>
</ng-template>
