import { Injectable } from '@angular/core';
import { GetTimestampFromTime } from '@dave/types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, firstValueFrom, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { isNotNullOrUndefined } from '../../../helper/helper';
import { AppGatewayService } from '../../services/app-gateway.service';
import { BaseActionTypes } from '../actions/base.actions';
import { State } from '../index';
import {
    FileOpenerOpenRequestEntity,
    FileOpenerOpenRequestEntityFromBackend,
} from '../../entities/file-opener-open-request.entity';
import { FileOpenerOpenRequestActions } from '../actions/file-opener-open-request.actions';
import { getFileOpenerOpenRequestDictionary } from '../selectors/file-opener-open-request.selector';

const ErrorCodes = {
    Load: FileOpenerOpenRequestEntity.EntityName + ' Abrufen fehlgeschlagen',
    OpenFile: 'Datei öffnen fehlgeschlagen',
};

@Injectable()
export class FileOpenerOpenRequestEffects {
    errors$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FileOpenerOpenRequestActions.loadFailure, FileOpenerOpenRequestActions.openFileFailure),
            map((action) =>
                BaseActionTypes.ErrorAction({
                    Payload: {
                        ToasterMessage: action.Payload.ToasterMessage,
                        Err: action.Payload.Errors,
                    },
                }),
            ),
        ),
    );
    GetFileOpenerOpenRequest = createEffect(
        () =>
            this.actions$.pipe(
                ofType(FileOpenerOpenRequestActions.loadRequest),
                switchMap(({ Payload }) =>
                    this.gatewayService
                        .Request({ FileopenerGetOpenRequests: isNotNullOrUndefined(Payload?.updatedSince) ? { UpdatedAt: Payload.updatedSince } : {} })
                        .then((res) => {
                            console.log({Payload})
                            if (Object.keys(res?.Errors || {}).length === 0) {
                                if (!isNotNullOrUndefined(Payload.updatedSince)) {
                                    this.store$.dispatch(FileOpenerOpenRequestActions.loadSuccess({ Payload: res.FileopenerGetOpenRequests.OpenRequests.map(FileOpenerOpenRequestEntityFromBackend), updateLatestUpdatedAt: true }));
                                } else {
                                    if (res.FileopenerGetOpenRequests.OpenRequests.length) {
                                        firstValueFrom(this.store$.select(getFileOpenerOpenRequestDictionary)).then((fileOpenerOpenRequestes) => {
                                            const fRes = res.FileopenerGetOpenRequests.OpenRequests.filter((p) => {
                                                const fromState = fileOpenerOpenRequestes[p.Id];
                                                return !fromState || GetTimestampFromTime(fromState.UpdatedAt).toString() !== p.UpdatedAt;
                                            });
                                            this.store$.dispatch(
                                                FileOpenerOpenRequestActions.loadSuccess({
                                                    Payload: fRes.map(FileOpenerOpenRequestEntityFromBackend),
                                                    updateLatestUpdatedAt: true,
                                                }),
                                            );
                                        });
                                    } else {
                                        this.store$.dispatch(
                                            FileOpenerOpenRequestActions.loadSuccess({
                                                Payload: [],
                                                updateLatestUpdatedAt: false,
                                            }),
                                        );
                                    }
                                }
                            } else {
                                throw res.Errors;
                            }
                        })
                        .catch((err) => {
                            this.store$.dispatch(
                                FileOpenerOpenRequestActions.loadFailure({
                                    Payload: {
                                        ToasterMessage: ErrorCodes.Load,
                                        Errors: err,
                                    },
                                }),
                            );
                            return EMPTY;
                        }),
                ),
            ),
        { dispatch: false },
    );
    EditFileOpenerOpenRequest = createEffect(() =>
        this.actions$.pipe(
            ofType(FileOpenerOpenRequestActions.openFileRequest),
            switchMap(({ Payload }) =>
                this.gatewayService
                    .Request({ FileopenerOpenFile: Payload })
                    .then((res) => {
                        if (Object.keys(res?.Errors || {}).length === 0) {
                            return FileOpenerOpenRequestActions.openFileSuccess({ Payload: FileOpenerOpenRequestEntityFromBackend(res.FileopenerOpenFile.Request) });
                        } else {
                            throw res.Errors;
                        }
                    })
                    .catch((err) =>
                        FileOpenerOpenRequestActions.openFileFailure({
                            Payload: {
                                ToasterMessage: ErrorCodes.OpenFile,
                                Errors: err,
                            },
                        }),
                    ),
            ),
        ),
    );
    constructor(private actions$: Actions, private store$: Store<State>, private gatewayService: AppGatewayService) {}
}
