import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducer, MetaReducer, Store, StoreModule } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { reducers, State } from './State';
import { BaseActionTypes } from './State/actions/base.actions';
import { ARLTemplateTypeEffects } from './State/effects/a-r-l-template-type.effects';
import { AccountsReceivableLedgerTemplateEffects } from './State/effects/accounts-receivable-ledger-template.effects';
import { AccountsReceivableLedgerEffects } from './State/effects/accounts-receivable-ledger.effects';
import { ActivityEffects } from './State/effects/activity.effect';
import { AkkordToCommissionEffects } from './State/effects/akkord/akkord-to-commission.effect';
import { AkkordEffects } from './State/effects/akkord/akkord.effect';
import { BVCustomNameEffects } from './State/effects/b-v-custom-name.effect';
import { BookmarkEffects } from './State/effects/bookmark.effects';
import { BusinessVolumeEffects } from './State/effects/business-volume.effects';
import { ClockInTypeEffects } from './State/effects/clock-in-type.effects';
import { ClockInEffects } from './State/effects/clock-in.effects';
import { CommentEffects } from './State/effects/comment.effect';
import { CommissionEffects } from './State/effects/commission.effects';
import { CommissionTypeEffects } from './State/effects/commissionType.effects';
import { ComplaintTypeEffects } from './State/effects/complaintType.effects';
import { ConditionEffects } from './State/effects/condition.effects';
import { ConstructionDiaryEffects } from './State/effects/construction-diary.effects';
import { CustomerLocationsEffects } from './State/effects/customer-locations.effects';
import { CustomerTypesEffects } from './State/effects/customer-types.effects';
import { CustomerEffects } from './State/effects/customer.effects';
import { CustomerSpecificationTypeEffects } from './State/effects/customerSpecificationType.effects';
import { DamageFlowEffects } from './State/effects/damageflow.effects';
import { DamageFlowTypeEffects } from './State/effects/damageFlowType.effects';
import { DataEffects } from './State/effects/data.effects';
import { DetectionEffects } from './State/effects/detection.effects';
import { EmailConnectionEffects } from './State/effects/email-connection.effects';
import { EmailEffects } from './State/effects/email.effects';
import { EmailConnection2EmailSettingsTypeEffects } from './State/effects/emailConnection2EmailSettingsType.effects';
import { EmailFolderEffects } from './State/effects/emailFolder.effects';
import { EmailSettingsTypeEffects } from './State/effects/emailSettingsType.effects';
import { EmployeeToCommissionEffects } from './State/effects/employee-to-commission.effect';
import { EmployeeToVacationEffects } from './State/effects/employee-to-vacation.effects';
import { EmployeeEffects } from './State/effects/employee.effects';
import { EntityRoleEffects } from './State/effects/entity-role.effect';
import { EventTypesEffects } from './State/effects/event-types.effects';
import { EventEffects } from './State/effects/event.effects';
import { FileUserEffects } from './State/effects/file-user.effects';
import { FileEffects } from './State/effects/file.effects';
import { FolderUserEffects } from './State/effects/folder-user.effects';
import { FolderEffects } from './State/effects/folder.effects';
import { GeneratedDocumentsEffects } from './State/effects/generatedDocuments.effects';
import { GeneratedDocumentsTypeEffects } from './State/effects/generatedDocumentsType.effects';
import { GroupEffects } from './State/effects/group.effects';
import { JobSpecificationEffects } from './State/effects/job-specification.effect';
import { LedgerImportEffects } from './State/effects/ledger-import.effects';
import { LicenseEffects } from './State/effects/license.effect';
import { MandateTypeEffects } from './State/effects/mandate-type.effects';
import { MilestoneEffects } from './State/effects/milestone.effects';
import { NewsEffects } from './State/effects/news.effect';
import { OAuthTokenEffects } from './State/effects/o-auth-token.effect';
import { OfficeEffects } from './State/effects/office.effects';
import { PartnerOfficeEffects } from './State/effects/partner-office.effects';
import { PartnerEffects } from './State/effects/partner.effects';
import { PartnerListEffects } from './State/effects/partnerList.effects';
import { PersonEffects } from './State/effects/person.effects';
import { Person2EntityEffects } from './State/effects/person2entity.effects';
import { PersonTypeEffects } from './State/effects/personType.effects';
import { ProcessTemplateEffects } from './State/effects/process-template.effect';
import { ProcessEffects } from './State/effects/process.effect';
import { ProductEffects } from './State/effects/product.effects';
import { PublicFileEffects } from './State/effects/public-file.effects';
import { QMStatusEffects } from './State/effects/qMStatus.effects';
import { QuantityTypeEffects } from './State/effects/quantity-type.effects';
import { EmployeeResourceScheduleEffects } from './State/effects/resource-dispo/employee-resource-schedule.effects';
import { ResourceScheduleEffects } from './State/effects/resource-dispo/resource-schedule.effects';
import { ResourceTypeEffects } from './State/effects/resource-dispo/resource-type.effects';
import { ResourceEffects } from './State/effects/resource-dispo/resource.effects';
import { RoleEffects } from './State/effects/role.effects';
import { SalaryTypeEffects } from './State/effects/salary-type.effect';
import { Scan2DaveEmailEffects } from './State/effects/scan2DaveEmail.effects';
import { SepaEffects } from './State/effects/sepa.effects';
import { SettingsEffects } from './State/effects/settings.effects';
import { SharedCommissionsEffects } from './State/effects/sharedCommissions.effects';
import { StatusFromBackofficeEffects } from './State/effects/statusFromBackoffice.effects';
import { StatusFromSVEffects } from './State/effects/statusFromSV.effects';
import { TagEffects } from './State/effects/tag.effects';
import { TextTemplateLanguageEffects } from './State/effects/text-template-language.effects';
import { TextTemplatesEffects } from './State/effects/textTemplates.effects';
import { TransmissionEffects } from './State/effects/transmission.effects';
import { UserToCommissionEffects } from './State/effects/user-to-commission.effect';
import { UserToCustomerEffects } from './State/effects/user-to-customer.effect';
import { UserToEventEffects } from './State/effects/user-to-event.effect';
import { UserEffects } from './State/effects/user.effects';
import { User2CommissionShiftEffects } from './State/effects/user2CommissionShift.effect';
import { VarianteTypesEffects } from './State/effects/variante-types.effects';
import { ViewStyleSettingEffects } from './State/effects/viewStyleSetting.effect';
import { WorkDayEffects } from './State/effects/work-day.effects';
import { WorkedTimesEffects } from './State/effects/worked-times.effects';
import { EmployeeSalaryTypeEffects } from "./State/effects/employee-salary-type.effect";
import { SurchargeRateEffects } from './State/effects/surcharge-rate.effects';
import { ResourceToCommissionEffects } from './State/effects/resource-dispo/resource-to-commisson.effects';
import { ChartOfAccountsEffects } from './State/effects/chartOfAccount.effects';
import { MaterialListEffects } from './State/effects/material-list.effects';
import { MaterialEffects } from './State/effects/material.effects';
import { MaterialToCommissionEffects } from './State/effects/material-to-commission.effects';
import { MaterialGroupEffects } from './State/effects/material-group.effects';
import { MaterialToSupplierEffects } from './State/effects/materialToSupplier.effects';
import { MaterialPriceHistoryEffects } from './State/effects/materialPriceHistory.effects';
import { FileOpenerTokenEffects } from './State/effects/file-opener-token.effect';
import { FileOpenerOpenRequestEffects } from './State/effects/file-opener-open-request.effect';

function clearState(reducer: ActionReducer<State>) {
    return (state, action) => {
        if (action.type === BaseActionTypes.InitStore.type) {
            localStorage.removeItem('token');
            state = undefined;
        }
        return reducer(state, action);
    };
}

const metaReducers: MetaReducer<State>[] = !environment.production ? [clearState] : [clearState];

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forRoot(reducers, { metaReducers }),
        // Instrumentation must be imported after importing StoreModule (config is optional)
        EffectsModule.forRoot([
            BookmarkEffects,
            CustomerEffects,
            DataEffects,
            EmailConnectionEffects,
            EventTypesEffects,
            EventEffects,
            FileEffects,
            LicenseEffects,
            OfficeEffects,
            PartnerEffects,
            UserEffects,
            PartnerListEffects,
            CustomerTypesEffects,
            CustomerLocationsEffects,
            EmployeeEffects,
            ProductEffects,
            ConditionEffects,
            MandateTypeEffects,
            VarianteTypesEffects,
            SettingsEffects,
            RoleEffects,
            CommissionEffects,
            TagEffects,
            TransmissionEffects,
            DamageFlowEffects,
            CommissionTypeEffects,
            StatusFromBackofficeEffects,
            StatusFromSVEffects,
            QMStatusEffects,
            EmailConnection2EmailSettingsTypeEffects,
            EmailSettingsTypeEffects,
            GeneratedDocumentsEffects,
            GeneratedDocumentsTypeEffects,
            SharedCommissionsEffects,
            SepaEffects,
            PersonEffects,
            Person2EntityEffects,
            PersonTypeEffects,
            ComplaintTypeEffects,
            CustomerSpecificationTypeEffects,
            DamageFlowTypeEffects,
            EmailEffects,
            FolderEffects,
            AccountsReceivableLedgerEffects,
            LedgerImportEffects,
            AccountsReceivableLedgerTemplateEffects,
            QuantityTypeEffects,
            Scan2DaveEmailEffects,
            DetectionEffects,
            EmailFolderEffects,
            ARLTemplateTypeEffects,
            EmployeeToVacationEffects,
            PartnerOfficeEffects,
            ClockInEffects,
            WorkDayEffects,
            User2CommissionShiftEffects,
            ConstructionDiaryEffects,
            ViewStyleSettingEffects,
            FolderUserEffects,
            FileUserEffects,
            AkkordEffects,
            AkkordToCommissionEffects,
            UserToCommissionEffects,
            UserToCustomerEffects,
            EntityRoleEffects,
            TextTemplatesEffects,
            EmployeeToCommissionEffects,
            UserToEventEffects,
            CommentEffects,
            NewsEffects,
            TextTemplateLanguageEffects,
            BusinessVolumeEffects,
            ClockInTypeEffects,
            WorkedTimesEffects,
            JobSpecificationEffects,
            ResourceEffects,
            ResourceTypeEffects,
            ResourceScheduleEffects,
            EmployeeResourceScheduleEffects,
            PublicFileEffects,
            GroupEffects,
            ActivityEffects,
            ProcessEffects,
            ProcessTemplateEffects,
            OAuthTokenEffects,
            MilestoneEffects,
            BVCustomNameEffects,
            SalaryTypeEffects,
            EmployeeSalaryTypeEffects,
            SurchargeRateEffects,
            ResourceToCommissionEffects,
            ChartOfAccountsEffects,
            MaterialListEffects,
            MaterialEffects,
            MaterialToCommissionEffects,
            MaterialGroupEffects,
            MaterialToSupplierEffects,
            MaterialPriceHistoryEffects,
            FileOpenerTokenEffects,
            FileOpenerOpenRequestEffects,
        ]),
    ],
})
export class DaveDataStateModule {
    public constructor(private store: Store<State>) {
        this.exposeStoreToGlobal();
    }
    public exposeStoreToGlobal() {
        if (!environment.production) {
            globalThis.showStoreSnapshot = () => {
                this.store.pipe(first()).subscribe((snapshot) => console.log(snapshot));
            };
        }
    }
}
