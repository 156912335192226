import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MatDialogState, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DropzoneConfig } from 'ngx-dropzone-wrapper';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, startWith, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { BookmarkEntity } from 'src/app/dave-data-module/entities/bookmark.entity';
import { BookmarkActionTypes } from 'src/app/dave-data-module/State/actions/bookmarks.actions';
import { getBookmarkByEventId } from 'src/app/dave-data-module/State/selectors/bookmarks.selectors';
import { AppDialogService } from 'src/app/dave-utils-module/app-dialog-module/app-dialog.service';
import { EmailDialogComponent, EmailDialogData } from '../../../components/detail-views/detail-event/email-dialog/email-dialog.component';
import { CommentListComponent } from '../../../dave-comments/components/comment-list/comment-list.component';
import { CommentEntityTypeEnum } from '../../../dave-data-module/entities/comment.entity';
import { CommissionEntity } from '../../../dave-data-module/entities/commission.entity';
import { CustomerEntity } from '../../../dave-data-module/entities/customer.entity';
import { EmailEntity } from '../../../dave-data-module/entities/email.entity';
import { EmployeeEntity } from '../../../dave-data-module/entities/employee.entity';
import { EventStateEnum, EventStateEnumNameMap, taskEventStates, TaskPriorities, TaskPriorityNameColorIconMap } from '../../../dave-data-module/entities/event.entity';
import { FileEntity } from '../../../dave-data-module/entities/file.entity';
import { FolderEntity, FolderTypes } from '../../../dave-data-module/entities/folder.entity';
import { LedgerImportDocumentTypeNames, LedgerImportEntity } from '../../../dave-data-module/entities/ledger-import.entity';
import { MilestoneEntity } from '../../../dave-data-module/entities/milestone.entity';
import { PersonEntity } from '../../../dave-data-module/entities/person.entity';
import { Person2EntityEntity, Person2EntityEntityTypeEnum } from '../../../dave-data-module/entities/person2entity.entity';
import { ViewStyleConfig } from '../../../dave-data-module/entities/viewStyleSetting.entity';
import { CommissionResolver } from '../../../dave-data-module/guards/commission.resolver';
import { CustomerResolver } from '../../../dave-data-module/guards/customer.resolver';
import { EmployeeResolver } from '../../../dave-data-module/guards/employee.resolver';
import { EventTypeResolver } from '../../../dave-data-module/guards/event-type.resolver';
import { EventResolver } from '../../../dave-data-module/guards/event.resolver';
import { LedgerImportResolver } from '../../../dave-data-module/guards/ledger-import.resolver';
import { ProcessResolver } from '../../../dave-data-module/guards/process.resolver';
import { UserResolver } from '../../../dave-data-module/guards/user.resolver';
import { FrontendDateTimestamp } from '../../../dave-data-module/helper/backend-frontend-conversion.helper';
import { getFetched$ } from '../../../dave-data-module/helper/helper';
import { EmailDataService } from '../../../dave-data-module/services/email-data.service';
import { FileDataService } from '../../../dave-data-module/services/file-data.service';
import { FolderDataService } from '../../../dave-data-module/services/folder-data.service';
import { LedgerImportDataService } from '../../../dave-data-module/services/ledger-import-data.service';
import { State } from '../../../dave-data-module/State';
import { DaveActions } from '../../../dave-data-module/State/actions/actions';
import { BaseActionTypes } from '../../../dave-data-module/State/actions/base.actions';
import { EventsActionTypes } from '../../../dave-data-module/State/actions/events.actions';
import { FilesActionTypes, FileUploadParams, fileUploadParamsObj } from '../../../dave-data-module/State/actions/files.actions';
import { Person2EntityActionTypes } from '../../../dave-data-module/State/actions/person2entity.action';
import { ProcessActions } from '../../../dave-data-module/State/actions/process.actions';
import { getToken } from '../../../dave-data-module/State/selectors/base.selectors';
import { getCommissionById, getCommissions, getCommissionsActive } from '../../../dave-data-module/State/selectors/commission.selector';
import { getCustomers, getCustomersFetched } from '../../../dave-data-module/State/selectors/customers.selectors';
import { getEmployees, getEmployeesActive } from '../../../dave-data-module/State/selectors/employees.selectors';
import { getEventById, getTaskById } from '../../../dave-data-module/State/selectors/events.selectors';
import { getFileDictionary } from '../../../dave-data-module/State/selectors/files.selectors';
import { getLedgerImports, getLedgerImportsFetched } from '../../../dave-data-module/State/selectors/ledger-import.selector';
import { getMilestoneDictionary, getMilestones, getMilestonesFetched } from '../../../dave-data-module/State/selectors/milestone.selector';
import { getPartner } from '../../../dave-data-module/State/selectors/partners.selectors';
import { getPersons } from '../../../dave-data-module/State/selectors/person.selectors';
import { getPerson2Entities } from '../../../dave-data-module/State/selectors/person2entity.selectors';
import { getProcessById, getProcessFetched } from '../../../dave-data-module/State/selectors/process.selector';
import { getSetting, getUser, getUsers } from '../../../dave-data-module/State/selectors/users.selectors';
import { DaveFilePreviewComponent, DaveFilePreviewComponentDialogData } from '../../../dave-file-preview-dialog/components/dave-file-preview/dave-file-preview.component';
import { NewReportDialogService } from '../../../dave-reports/components/new-report-dialog/new-report-dialog.service';
import {
    DaveSelectFileFromDmsComponent,
    DaveSelectFileFromDmsComponentDialogData,
    DaveSelectFileFromDmsComponentReturnData,
} from '../../../dave-select-file-from-dms/components/dave-select-file-from-dms/dave-select-file-from-dms.component';
import { IDetailListTemplateData, IDetailListTemplateDataProperty } from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/detail-list-template/detail-list-template.component';
import { DropzoneComponent } from '../../../dave-utils-module/dave-shared-components-module/components/dropzone/dropzone.component';
import { BreakpointObserverService } from '../../../dave-utils-module/dave-shared-components-module/services/breakpoint-observer.service';
import { PermissionService } from '../../../dave-utils-module/dave-shared-components-module/services/permission.service';
import { SelectSearchData } from '../../../dave-utils-module/select-search/components/select-search-legacy/select-search-legacy.component';
import { appMatDialogDefaultConfig, isNotNullOrUndefined, uniqArray } from '../../../helper/helper';
import { AllCommissionMeta, AllReportsMeta, CommissionMeta, ContactBookMeta, CustomerAdministrationMeta, DMSPageMeta, EmailPageMeta, InvoiceEditorMeta, ReportsPageMeta, UserAdministrationMeta } from '../../../helper/page-metadata';
import { CustomLabelService } from '../../../services/custom-label.service';
import { LoadingService } from '../../../services/loading.service';
import { MilestoneResolver } from '../../../dave-data-module/guards/milestone.resolver';

export interface DetailTasksComponentDialogDataInitialValues {
    DocumentIds?: number[];
    PersonIds?: number[];
    CommissionId?: number;
    EventTypeId?: number;
    ParentEventId?: number;
    EmailId?: number;
    EndDate?: Date;
    Description?: string;
    Name?: string;
    UserIds?: number[];
    CustomerId?: number;
    ProcessId?: number;
    ProcessStepIndex?: number;
    MilestoneIds?: number[];
}
export interface DetailTasksComponentDialogData {
    //  CopyEventId?: number; //evnet to copy the data from
    InitialValues?: DetailTasksComponentDialogDataInitialValues;
    EventId?: number;
    DocumentIds?: number[];
}
@Component({
    selector: 'app-detail-tasks',
    templateUrl: './detail-tasks.component.html',
    styleUrls: ['./detail-tasks.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailTasksComponent implements OnInit, OnDestroy {
    @ViewChild('commentListComponent') commentListComponent: CommentListComponent;
    protected eventId$ = new BehaviorSubject<number | null>(null);
    public set EventId(id: number) {
        this.eventId$.next(id);
    }
    get EventId() {
        return this.eventId$.value;
    }
    protected SelectedTabIndex: number = 0;
    public BookmarkSet = false;
    protected UnseenComments$ = new BehaviorSubject<number | null>(null);
    public static DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        panelClass: [...appMatDialogDefaultConfig.panelClass, 'custom-dialog-class-small-fullscreen'],
        maxHeight: '95vh',
        height: '95vh',
        width: '64rem',
        autoFocus: false,
        hasBackdrop: false,
    };
    public LedgerImportEditorRoute = (id: number) => ['/', ReportsPageMeta.Path, AllReportsMeta.Path, InvoiceEditorMeta.Path, id];
    @ViewChild('Drpzone') Drpzone: DropzoneComponent;
    CommentEntityTypeEnum = CommentEntityTypeEnum;
    DMSMeta = DMSPageMeta;
    DMSNotMinimalistic = false;
    private uploadParams = [];
    protected uploadParamsObj: fileUploadParamsObj = { auto_set_folder: 'true' };
    private fileUploadUIds = [];
    public FilesToAssign$ = new BehaviorSubject<FileEntity[]>([]);
    public ImageIds: number[];
    public Folder$: Observable<FolderEntity>;
    public CommentFileUploadParams$: Observable<FileUploadParams>;
    public CanDelete$: Observable<boolean>;
    public AssignedFiles$: Observable<FileEntity[]>;
    public Bookmark$: Observable<BookmarkEntity>;
    public DropzoneConfig$: Observable<DropzoneConfig>;
    public Email$: Observable<EmailEntity | null>;
    public Form = new FormGroup({
        Name: new FormControl<string | null>(null),
        EndDate: new FormControl<Date | null>(null),
        Description: new FormControl<string | null>(null),
        Users: new FormControl<EmployeeEntity[]>(null),
        Persons: new FormControl<PersonEntity[]>(null),
        Priority: new FormControl<number>(0, [Validators.max(20), Validators.min(-20), Validators.required]),
        State: new FormControl<EventStateEnum>(EventStateEnum.New, Validators.required),
        Autor: new FormControl<string | null>({ value: null, disabled: true }),
        Customer: new FormControl<SelectSearchData>(null),
        Commission: new FormControl<SelectSearchData>(null),
        EventType: new FormControl<number | null>(null),
        Milestones: new FormControl<MilestoneEntity[]>(null),
    });
    public Data$: Observable<IDetailListTemplateData>;
    private filePreviewDialog: MatDialogRef<any>;
    private selectedPerson2Entity: Person2EntityEntity[] = [];
    public TaskNotFound = false;
    public CommentsViewConfig: Partial<ViewStyleConfig> = {
        expanded: true,
        headline: 'Kommentare',
    };
    public AssignFilesDefaultFolderId: number;
    private subscriptions: Subscription[] = [];
    public LastCommissionId: number;
    public CommissionForm$: Observable<IDetailListTemplateData> = this.Form.controls.Customer.valueChanges.pipe(
        startWith(this.Form.value.Customer),
        switchMap((cu) => this.store.select(getCommissions).pipe(map((co) => co.filter((c) => !cu?.Id || c.CustomerId === cu.Id)))),
        withLatestFrom(this.cls.getSingle$('Commission')),
        map(([commission, commissionLabel]) => ({
            Properties: [
                {
                    key: commissionLabel,
                    formControl: this.Form.controls.Commission,
                    options: {
                        specialInput: {
                            selectSearch: commission.map((c) => ({
                                optionValue: c.Id,
                                optionLabel: c.DisplayName,
                            })),
                        },
                    },
                },
            ],
        })),
    );
    public milestonesForm: IDetailListTemplateData = {
        Properties: [
            {
                key: 'Meilensteine',
                formControl: this.Form.controls.Milestones,
                options: {
                    specialInput: {
                        chipAutocomplete: {
                            MapFn: (option: MilestoneEntity) => option.Name,
                            CompareFn: (a: MilestoneEntity, b: MilestoneEntity) => a?.Id === b?.Id,
                            Options$: this.Form.controls.Commission.valueChanges.pipe(
                                startWith(null),
                                map(() => this.Form.controls.Commission.value),
                                switchMap((cu) =>
                                    (cu ? getFetched$(this.store, getMilestonesFetched, getMilestones).pipe(map((milestones) => milestones.filter((m) => m.CommissionId === cu.Id))) : of([])),
                                ),
                            ),
                            // onClick: (option: MilestoneEntity) => {
                            //     if (isNotNullOrUndefined(option)) {
                            //         this.router.navigate([UserAdministrationMeta.Path + '/' + option.Id + '']);
                            //         this.dialogRef.close();
                            //     }
                            // },
                            // initialPatchDefaultValue: true,
                        },
                    },
                },
            },
        ],
    };
    public CustomerPath = CustomerAdministrationMeta.Path;
    public CommissionPath = CommissionMeta.Path;
    public AllCommissionPath = AllCommissionMeta.Path;
    protected assignedLedgerImports$: Observable<LedgerImportEntity[]> = this.store.select(getLedgerImportsFetched).pipe(
        switchMap((fetched) => {
            if (fetched) {
                this.ledgerImportResolver.pollUpdated();
                return this.eventId$.pipe(switchMap((eventId) => (eventId ? this.store.select(getLedgerImports).pipe(map((lis) => lis.filter((li) => li.EventId === eventId))) : of([]))));
            } else {
                return this.eventId$.pipe(switchMap((eventId) => (eventId ? this.ledgerImportDataService.getLedgerImportByEventId$(eventId) : of([]))));
            }
        }),
    );
    protected assignedCustomer$: Observable<CustomerEntity> = combineLatest([
        this.eventId$.pipe(
            filter(isNotNullOrUndefined),
            switchMap((id) => this.store.select(getTaskById({ id }))),
        ),
        this.store.select(getCustomers),
    ]).pipe(
        map(([event, customers]) => {
            return customers.find((customer) => customer?.Id === event?.CustomerId);
        }),
    );

    protected assignedCommission$: Observable<CommissionEntity> = combineLatest([
        this.eventId$.pipe(
            filter(isNotNullOrUndefined),
            switchMap((id) => this.store.select(getTaskById({ id }))),
        ),
        this.store.select(getCommissionsActive),
    ]).pipe(
        map(([event, commissions]) => {
            return commissions.find((commission) => commission?.Id === event?.CommissionId);
        }),
    );

    private saveCommentPending$ = new BehaviorSubject(false);

    constructor(
        @Inject(MAT_DIALOG_DATA) public DialogData: DetailTasksComponentDialogData,
        private store: Store<State>,
        private appDialog: AppDialogService,
        private actions$: Actions<DaveActions>,
        private dialogRef: MatDialogRef<DetailTasksComponent>,
        private router: Router,
        private dialog: MatDialog,
        private fileDataService: FileDataService,
        employeeResolver: EmployeeResolver,
        userResolver: UserResolver,
        private eventResolver: EventResolver,
        eventTypeResolver: EventTypeResolver,
        public BS: BreakpointObserverService,
        protected cls: CustomLabelService,
        protected ls: LoadingService,
        private processResolver: ProcessResolver,
        private emailDataService: EmailDataService,
        private newReportDialogService: NewReportDialogService,
        private ledgerImportResolver: LedgerImportResolver,
        private ledgerImportDataService: LedgerImportDataService,
        public PS: PermissionService,
        private folderDataService: FolderDataService,
        customerResolver: CustomerResolver,
        commissionResolver: CommissionResolver,
        milestoneResolver: MilestoneResolver,
    ) {
        firstValueFrom(this.store.select(getMilestonesFetched)).then(fetched => {
            if (!fetched) {
                milestoneResolver.resolve();
            }
        })
        firstValueFrom(this.store.select(getCustomersFetched)).then((fetched) => {
            if (!fetched) {
                customerResolver.resolve();
            }
        });
        firstValueFrom(this.store.select(getCommissionsActive)).then((fetched) => {
            if (!fetched) {
                commissionResolver.resolve();
            }
        });
        employeeResolver.resolve();
        userResolver.resolve();
        eventTypeResolver.resolve();
        this.EventId = this.DialogData?.EventId;

        this.uploadParams['auto_set_folder'] = 'true';

        this.DropzoneConfig$ = this.store.select(getToken).pipe(
            map(
                (token) =>
                    new DropzoneConfig({
                        autoProcessQueue: !!this.EventId,
                        uploadMultiple: false,
                        paramName: () => 'file',
                        parallelUploads: 100,
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                        params: this.uploadParams,
                    }),
            ),
        );
        this.subscriptions.push(
            this.Form.controls.Commission.valueChanges.pipe(distinctUntilChanged((a, b) => a?.Id === b?.Id)).subscribe((commission) => {
                if (!!commission?.Id) {
                    const ids$ = this.store.select(getCommissionById({ id: commission.Id })).pipe(
                        take(1),
                        map((com) => ({ customerId: com?.CustomerId, commissionId: com?.Id })),
                    );
                    this.store
                        .select(getCommissionById({ id: commission.Id }))
                        .pipe(
                            take(1),
                            map((com) => ({ customerId: com?.CustomerId, commissionId: com?.Id })),
                            switchMap((ids) =>
                                ids.commissionId
                                    ? folderDataService
                                          .getFolderFromEntity(ids.commissionId, FolderTypes.commission)
                                          .pipe(
                                              switchMap((folder) => (folder ? folderDataService.getFolderByParent(folder.Id).pipe(map((children) => ({ ids, folders: [folder, ...children] }))) : of({ ids, folders: [] as FolderEntity[] }))),
                                          )
                                    : of({ ids, folders: [] as FolderEntity[] }),
                            ),
                        )
                        .subscribe(({ ids, folders }) => {
                            this.LastCommissionId = ids.commissionId;
                            this.Form.controls.Customer.setValue({ Name: '', Id: ids.customerId });
                            let FolderId: number;
                            let EventFolderId: number;
                            FolderId = folders.find((f) => f.EntityId === ids.commissionId && f.Type === FolderTypes.commission)?.Id;
                            EventFolderId = folders.find((fo) => fo.ParentId === FolderId && fo.Type === FolderTypes.event_root)?.Id;
                            if (FolderId !== null && FolderId !== undefined && !EventFolderId) {
                                this.AssignFilesDefaultFolderId = FolderId;
                            } else if (EventFolderId !== null && EventFolderId !== undefined) {
                                this.AssignFilesDefaultFolderId = EventFolderId;
                            }
                        });
                } else if (!commission && !!this.Form.value.Customer?.Id) {
                    folderDataService
                        .getFolderFromEntity(this.Form.value.Customer.Id, FolderTypes.customer)
                        .pipe(take(1))
                        .subscribe((folder) => {
                            const FolderId: number = folder?.Id;
                            if (FolderId !== null && FolderId !== undefined) {
                                this.AssignFilesDefaultFolderId = FolderId;
                            } else {
                                this.AssignFilesDefaultFolderId = null;
                            }
                        });
                } else if ((!commission && this.Form.value.Customer?.Id === null) || this.Form.value.Customer?.Id === undefined) {
                    this.AssignFilesDefaultFolderId = null;
                }
            }),
            this.Form.controls.Customer.valueChanges.subscribe((customer) => {
                if (!!customer && !!customer?.Id) {
                    combineLatest([this.store.select(getCommissionById({ id: this.LastCommissionId })), folderDataService.getFolderFromEntity(customer.Id, FolderTypes.customer)])
                        .pipe(take(1))
                        .subscribe(([commission, folder]) => {
                            if (!!commission && commission?.CustomerId !== customer.Id) {
                                this.Form.controls.Commission.setValue({ Name: '', Id: null });
                            }
                            let FolderId: number;
                            FolderId = folder?.Id;
                            if (FolderId !== null && FolderId !== undefined) {
                                this.AssignFilesDefaultFolderId = FolderId;
                            }
                        });
                } else if (!customer && (this.Form.value.Commission?.Id === null || this.Form.value.Commission?.Id === undefined)) {
                    this.AssignFilesDefaultFolderId = null;
                }
            }),
        );
    }
    public HandleBookmark(eventId: number, bookmarkId: number) {
        if (!eventId && !bookmarkId) {
            this.BookmarkSet = !this.BookmarkSet;
        } else {
            if (bookmarkId && eventId) {
                this.store.dispatch(BookmarkActionTypes.RemoveBookmark({ BookmarkIds: [bookmarkId] }));
            } else {
                this.store.dispatch(BookmarkActionTypes.AddBookmark({ EventId: eventId }));
            }
        }
    }
    ngOnInit(): void {
        if (this.EventId) {
            this.store
                .select(getUser)
                .pipe(take(1))
                .subscribe((user) => {
                    this.store.dispatch(EventsActionTypes.SetEventSeen({ Payload: { eventIds: [this.EventId], userId: user.Id } }));
                });
        }

        this.store
            .select(getSetting)
            .pipe(take(1))
            .subscribe((settings) => {
                this.BookmarkSet = settings.AutomaticEventBookmark;
            });

        this.CanDelete$ = combineLatest([this.store.select(getTaskById({ id: this.EventId })), this.store.select(getUser)]).pipe(map(([task, user]) => task?.UserId === user.Id));
        if (this.EventId) {
            this.uploadParamsObj['event_id'] = JSON.stringify([this.EventId]);
            this.uploadParams['event_id'] = JSON.stringify([this.EventId]);
            this.Folder$ = this.folderDataService.getFolderFromEntity(this.EventId, FolderTypes.event).pipe(shareReplay({ refCount: true, bufferSize: 1 }));
            this.AssignedFiles$ = this.Folder$.pipe(
                filter(isNotNullOrUndefined),
                switchMap((folder) => this.fileDataService.GetFilesStateFromFolder$(folder.Id, false)),
            );
        } else {
            this.fileDataService
                .GetFilesById(this.DialogData.InitialValues?.DocumentIds || [])
                .pipe(take(1))
                .subscribe((files) => {
                    let filesToAssign = files.filter((f) => this.DialogData.InitialValues.DocumentIds.includes(f.Id));
                    this.FilesToAssign$.next(filesToAssign.slice());
                });
        }
        this.setFileUploadParams(this.EventId);
        this.Data$ = combineLatest([
            this.store.select(getUsers).pipe(filter(isNotNullOrUndefined)),
            this.store.select(getPartner).pipe(filter(isNotNullOrUndefined)),
            this.store.select(getPersons).pipe(filter(isNotNullOrUndefined)),
            this.store.select(getPerson2Entities).pipe(filter(isNotNullOrUndefined)),
            this.store.select(getCustomers).pipe(filter(isNotNullOrUndefined)),
            this.store.select(getEmployees),
            this.store.select(getTaskById({ id: this.EventId })).pipe(switchMap((task) => (task?.CommissionId ? this.folderDataService.getFolderFromEntity(task.CommissionId, FolderTypes.commission) : of(null)))),
            this.store.select(getTaskById({ id: this.EventId })).pipe(switchMap((task) => (task?.CustomerId ? this.folderDataService.getFolderFromEntity(task.CustomerId, FolderTypes.customer) : of(null)))),
            this.cls.getSingle$('Customer'),
            getFetched$(this.store, getMilestonesFetched, getMilestoneDictionary),
        ]).pipe(
            switchMap(([users, partner, persons, p2e, customers, employees, commissionFolder, customerFolder, customerLabel, milestones]) =>
                this.store.select(getTaskById({ id: this.EventId })).pipe(
                    map((task) => {
                        if (this.EventId) {
                            if (!task) {
                                this.TaskNotFound = true;
                                console.error('Aufgabe nicht gefunden, Id: ', this.EventId);
                            } else {
                                if (this.TaskNotFound) {
                                    this.TaskNotFound = false;
                                }

                                this.Email$ = task.EmailId ? this.emailDataService.GetEmailById$(task.EmailId) : of(null);
                                this.selectedPerson2Entity = p2e?.filter((e) => e.EntityType === Person2EntityEntityTypeEnum.Event && task.Id == e.EntityId);
                                const personIds = this.selectedPerson2Entity?.map((e) => e.PersonId);
                                if (!!task.CommissionId) {
                                    this.AssignFilesDefaultFolderId = commissionFolder?.Id;
                                } else if (!!task.CustomerId && !task.CommissionId) {
                                    this.AssignFilesDefaultFolderId = customerFolder?.Id;
                                }
                                (this.Bookmark$ = this.store.select(getBookmarkByEventId({ id: this.EventId }))),
                                    this.Form.setValue({
                                        Name: task.Name,
                                        EndDate: task.EventEndDate,
                                        Description: task.Description,
                                        Users: employees.filter((e) => task.UserIds?.includes(e.UserId)),
                                        Persons: persons?.filter((e) => personIds?.includes(e.Id)) || [],
                                        Priority: task.AdditionalData?.Priority || 0,
                                        State: task.State,
                                        Autor: users.find((u) => u.Id === task.UserId)?.DisplayName || null,
                                        Customer: task.CustomerId ? { Name: '', Id: task.CustomerId } : null,
                                        Commission: task.CommissionId ? { Name: '', Id: task.CommissionId } : null,
                                        EventType: task.EventTypeId || null,
                                        Milestones: task.MilestoneIds ? task.MilestoneIds.map((id) => milestones[id]) : [],
                                    });
                            }

                        } else if (this.DialogData?.InitialValues) {
                            const initialValues = this.DialogData.InitialValues;
                            if (initialValues.MilestoneIds) {
                                this.Form.controls.Milestones.setValue(initialValues.MilestoneIds.map((id) => milestones[id]));
                            }
                            if (initialValues.PersonIds) {
                                this.Form.controls.Persons.setValue(persons?.filter((e) => initialValues.PersonIds?.includes(e.Id)));
                            }
                            if (initialValues.UserIds) {
                                this.Form.controls.Users.setValue(employees?.filter((u) => initialValues.UserIds.includes(u.UserId)));
                            }
                            if (initialValues.EndDate) {
                                this.Form.controls.EndDate.setValue(initialValues.EndDate);
                            }
                            if (initialValues.Description) {
                                this.Form.controls.Description.setValue(initialValues.Description);
                            }
                            if (initialValues.Name) {
                                this.Form.controls.Name.setValue(initialValues.Name);
                            }
                            if (initialValues.CommissionId) {
                                this.Form.controls.Commission.setValue({ Name: '', Id: initialValues.CommissionId });
                            }
                            if (initialValues.CustomerId) {
                                this.Form.controls.Customer.setValue({ Name: '', Id: initialValues.CustomerId });
                            }
                            if (initialValues.EventTypeId) {
                                this.Form.controls.EventType.setValue(initialValues.EventTypeId);
                            }
                        }

                        this.Form.markAsPristine();
                        this.Form.markAsUntouched();
                        const Properties: IDetailListTemplateDataProperty[] = [
                            {
                                key: 'Bezeichnung',
                                formControl: this.Form.controls.Name,
                            },
                            {
                                key: 'Priorität',
                                formControl: this.Form.controls.Priority,
                                options: {
                                    specialInput: {
                                        select: TaskPriorities.map((p) => ({
                                            optionValue: p,
                                            optionLabel: TaskPriorityNameColorIconMap.get(p).name,
                                            optionIcon: {
                                                iconProp: TaskPriorityNameColorIconMap.get(p).icon,
                                                color: TaskPriorityNameColorIconMap.get(p).color,
                                            },
                                        })),
                                    },
                                },
                            },
                            {
                                key: 'Frist',
                                formControl: this.Form.controls.EndDate,
                                options: {
                                    specialInput: {
                                        date: true,
                                    },
                                },
                            },
                            {
                                key: 'Status',
                                formControl: this.Form.controls.State,
                                options: {
                                    specialInput: {
                                        select: Object.values(taskEventStates).map((state) => ({
                                            optionValue: state,
                                            optionLabel: EventStateEnumNameMap.get(state),
                                        })),
                                    },
                                },
                            },
                            {
                                key: 'Beschreibung',
                                formControl: this.Form.controls.Description,
                                options: {
                                    specialInput: {
                                        textArea: {
                                            Fill: false,
                                        },
                                    },
                                },
                            },
                            {
                                key: 'Autor',
                                formControl: this.Form.controls.Autor,
                                hideFormControl: !this.EventId,
                            },
                            {
                                key: 'Bearbeiter',
                                formControl: this.Form.controls.Users,
                                options: {
                                    specialInput: {
                                        chipAutocomplete: {
                                            MapFn: (option: EmployeeEntity) => option.DisplayName + ', ' + option.Email,
                                            Options$: this.Form.controls.Users.valueChanges.pipe(
                                                switchMap((selectedEmployees) =>
                                                    this.store.select(getEmployeesActive).pipe(
                                                        map((employees) => employees.filter((e) => !!e.UserId)),
                                                        map((activeEmployees) => {
                                                            const missingEmployees = selectedEmployees.filter((u) => !activeEmployees.some((a) => a.Id === u.Id));
                                                            return [...activeEmployees, ...missingEmployees];
                                                        }),
                                                    ),
                                                ),
                                            ),
                                            onClick: (option: EmployeeEntity) => {
                                                if (isNotNullOrUndefined(option)) {
                                                    this.router.navigate([UserAdministrationMeta.Path + '/' + option.Id + '']);
                                                    this.dialogRef.close();
                                                }
                                            },
                                            initialPatchDefaultValue: true,
                                        },
                                    },
                                },
                            },
                            {
                                key: 'Beteiligte Personen',
                                formControl: this.Form.controls.Persons,
                                options: {
                                    specialInput: {
                                        chipAutocomplete: {
                                            MapFn: (option: PersonEntity) => option.DisplayName + ', ' + option.Email,
                                            Options: persons.filter((p) => p.PartnerId === partner.Id),
                                            onClick: (option: PersonEntity) => {
                                                this.router.navigate([ContactBookMeta.Path + '/' + option.Id + '']);
                                                this.dialogRef.close();
                                            },
                                            initialPatchDefaultValue: true,
                                        },
                                    },
                                },
                            },
                            {
                                key: customerLabel,
                                formControl: this.Form.controls.Customer,
                                options: {
                                    specialInput: {
                                        selectSearch: customers.map((c) => ({
                                            optionValue: c.Id,
                                            optionLabel: c.DisplayName,
                                        })),
                                    },
                                },
                            },
                        ];
                        return {
                            Properties,
                        };
                    }),
                ),
            ),
        );
    }
    async SaveTaskToGenerateComment() {
        if (this.Form.invalid) {
            this.store.dispatch(BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: 'Bitte überprüfen sie die eingaben und versuchen Sie es erneut' } }));
            this.Form.markAllAsTouched();
            return new Promise<string>((resolve, reject) => {
                reject('Task Form is invalid: ' + this.Form.errors);
            });
        }
        this.Save(false, false);
        return await firstValueFrom(
            this.actions$.pipe(
                ofType(EventsActionTypes.UpdateEvent),
                take(1),
                map(({ Payload }) => Payload.Id),
                tap((id) => this.setFileUploadParams(id)),
            ),
        );
    }
    private setFileUploadParams(eventId: number) {
        this.CommentFileUploadParams$ = (this.Folder$ || of(null)).pipe(
            map((folder) =>
                folder
                    ? new Map([
                          ['folder_id', folder.Id.toString()],
                          ['hidden', 'true'],
                      ])
                    : eventId
                    ? new Map([
                          ['auto_set_folder', 'true'],
                          ['event_id', '[' + eventId.toString() + ']'],
                          ['hidden', 'true'],
                      ])
                    : null,
            ),
        );
    }
    Save(closeDialog = true, saveComment = true) {
        if (this.Form.invalid) {
            return;
        }
        if (this.EventId) {
            firstValueFrom(this.store.select(getEventById({ id: this.EventId }))).then((event) => {
                const additionalData = { ...event.AdditionalData, Priority: this.Form.value.Priority };
                this.store.dispatch(
                    EventsActionTypes.ModifyEvent({
                        Payload: {
                            id: this.EventId,
                            description: this.Form.value.Description,
                            eventEndDate: FrontendDateTimestamp(this.Form.value.EndDate),
                            eventDate: this.Form.value.EndDate ? FrontendDateTimestamp(this.Form.value.EndDate) : null,
                            name: this.Form.value.Name,
                            userIds: this.Form.value.Users.map((u) => u.UserId),
                            state: this.Form.value.State,
                            additionalData: JSON.stringify(additionalData),
                            commissionId: this.Form.value.Commission?.Id || null,
                            customerId: this.Form.value.Customer?.Id || null,
                            eventTypeId: this.Form.value.EventType || null,
                            milestoneIds: this.Form.value.Milestones ? this.Form.value.Milestones.map(m => m.Id) : [],
                        },
                    }),
                );
            });
            if (this.commentListComponent?.IsDirty() && saveComment) {
                this.saveCommentPending$.next(true);
                this.commentListComponent.SaveComment().then(() => {
                    this.saveCommentPending$.next(false);
                });
            }
            this.eventResolver.resolve();
            //adding and deleting person2Entity
            let addIds: number[] = [];
            let delIds: number[] = [];
            this.Form.value.Persons.forEach((p) => {
                if (this.selectedPerson2Entity.filter((pe) => pe.PersonId === p.Id).length === 0) {
                    addIds.push(p.Id);
                }
            });

            this.selectedPerson2Entity.forEach((pe) => {
                if (this.Form.value.Persons.filter((p) => p.Id === pe.PersonId).length === 0) {
                    delIds.push(pe.Id);
                }
            });
            addIds.forEach((pId) => {
                this.store.dispatch(
                    Person2EntityActionTypes.AddPerson2Entity({
                        Payload: {
                            personId: pId,
                            entityId: this.EventId,
                            entityType: Person2EntityEntityTypeEnum.Event,
                        },
                    }),
                );
            });

            delIds.forEach((id) => {
                this.store.dispatch(
                    Person2EntityActionTypes.DeletePerson2Entity({
                        Payload: {
                            id: id,
                        },
                    }),
                );
            });
        } else {
            if (this.commentListComponent?.IsDirty() && saveComment) {
                this.saveCommentPending$.next(true);
            }
            this.actions$.pipe(ofType(EventsActionTypes.UpdateEvent), take(1)).subscribe(({ Payload }) => {
                this.EventId = Payload.Id;
                if (this.commentListComponent?.IsDirty() && saveComment) {
                    this.setFileUploadParams(Payload.Id);
                    this.commentListComponent.EntityId = Payload.Id;
                    if (this.commentListComponent.inlineEditorComponent) {
                        this.commentListComponent.inlineEditorComponent.EntityId = Payload.Id;
                    }
                    this.saveCommentPending$.next(true);
                    this.commentListComponent.SaveComment().then(() => {
                        this.saveCommentPending$.next(false);
                    });
                }
                if (this.BookmarkSet === true) {
                    this.HandleBookmark(this.EventId, null);
                }
                if (this.DialogData?.InitialValues?.ProcessId && isNotNullOrUndefined(this.DialogData.InitialValues.ProcessStepIndex)) {
                    firstValueFrom(
                        this.store.select(getProcessFetched).pipe(
                            tap((f) => {
                                if (!f) {
                                    //todo
                                    this.processResolver.resolve();
                                }
                            }),
                            filter((f) => f),
                            switchMap(() => this.store.select(getProcessById({ id: this.DialogData.InitialValues.ProcessId }))),
                        ),
                    ).then((process) => {
                        this.store.dispatch(
                            ProcessActions.change({
                                Payload: {
                                    Id: process.Id.toString(),
                                    AddTasksToStep: { [this.DialogData.InitialValues.ProcessStepIndex + '']: { TaskIds: [Payload.Id.toString()] } },
                                    EmailIds: process.EmailIds?.map((id) => id.toString()),
                                    SetDeadlines: {},
                                    PropertyConfigValues: {},
                                },
                            }),
                        );
                    });
                }
            });
            this.store.dispatch(
                EventsActionTypes.AddEvent({
                    Payload: {
                        description: this.Form.value.Description,
                        eventEndDate: FrontendDateTimestamp(this.Form.value.EndDate),
                        eventDate: FrontendDateTimestamp(this.Form.value.EndDate || new Date()),
                        name: this.Form.value.Name,
                        isTask: true,
                        userIds: this.Form.value.Users.map((u) => u.UserId),
                        personIds: this.Form.value.Persons.map((p) => p.Id),
                        commissionId: this.Form.value.Commission?.Id,
                        state: this.Form.value.State,
                        eventTypeId: this.Form.value.EventType,
                        additionalData: JSON.stringify({ Priority: this.Form.value.Priority, ParentEventId: this.DialogData.InitialValues?.ParentEventId }),
                        emailId: this.DialogData.InitialValues?.EmailId,
                        customerId: this.Form.value.Customer?.Id,
                        processId: this.DialogData.InitialValues?.ProcessId,
                        milestoneIds: this.Form.value.Milestones ? this.Form.value.Milestones.map(m => m.Id) : [],
                    },
                }),
            );
        }
        if (/*this.fileUploadUIds.length*/ this.Drpzone.getFileCount() || this.FilesToAssign$.value.length) {
            this.actions$.pipe(ofType(EventsActionTypes.UpdateEvent), take(1)).subscribe(({ Payload }) => {
                if (this.FilesToAssign$.value.length) {
                    this.FilesToAssign$.value.forEach((doc) =>
                        this.store.dispatch(
                            // FilesActionTypes.ModifyFileRequest({
                            //     Payload: {
                            //         DocumentId: doc.Id.toString(),
                            //         AutoSetFolder: true,
                            //         EventIds: [Payload.Id, ...(doc.EventIds || [])].map(e => e.toString()),
                            //     },
                            // }),
                            FilesActionTypes.CreateLinkRequest({
                                Payload: {
                                    DocumentId: doc.Id.toString(),
                                    EventId: Payload.Id.toString(),
                                    AutoSetFolder: true,
                                },
                            }),
                        ),
                    );
                }
                if (/*this.fileUploadUIds.length*/ this.Drpzone.getFileCount()) {
                    this.uploadParamsObj['event_id'] = JSON.stringify([Payload.Id]);
                    this.uploadParams['event_id'] = JSON.stringify([Payload.Id]);
                    // this.closeOnQueryComplete = closeDialog;
                    // this.Drpzone.directiveRef.dropzone().processQueue();
                    this.Drpzone.ProcessUploadAsync();
                    if (closeDialog) {
                        this.closeDialog();
                    }
                } else {
                    this.eventResolver.resolve();
                    if (closeDialog) {
                        this.closeDialog();
                    }
                }
            });
        } else if (closeDialog) {
            this.closeDialog();
        }
    }
    protected closeDialog() {
        firstValueFrom(this.saveCommentPending$.pipe(filter((pending) => !pending))).then(() => {
            this.dialogRef.close();
        });
    }
    OnRemoveFile(file: FileEntity) {
        this.FilesToAssign$.next(this.FilesToAssign$.value.filter((f) => f.Id !== file.Id));
    }

    Delete() {
        this.store
            .select(getTaskById({ id: this.EventId }))
            .pipe(take(1))
            .subscribe((e) => {
                this.appDialog
                    .OpenConfirmationDialog({
                        paragraph: `Aufgabe ${e.Name} wirklich löschen?`,
                        styleDelete: true,
                    })
                    .subscribe(([result]) => {
                        if (result) {
                            this.store.dispatch(EventsActionTypes.DeleteEvent({ Payload: this.EventId }));
                            this.dialogRef.close();
                        }
                    });
            });
    }

    OpenFilePreview(fileId: number) {
        if (this.filePreviewDialog?.getState() === MatDialogState.OPEN) {
            this.filePreviewDialog.close();
        }
        this.filePreviewDialog = this.dialog.open<DaveFilePreviewComponent, DaveFilePreviewComponentDialogData>(DaveFilePreviewComponent, {
            ...DaveFilePreviewComponent.DefaultConfig,
            data: {
                fileId,
            },
        });
    }
    openFilePreviewFromSmallFileCard(fileId: number) {
        if (this.filePreviewDialog?.getState() === MatDialogState.OPEN) {
            this.filePreviewDialog.close();
        }
        let ImageIdsFromSmallFileCard: number[] = [];
        const FtA = [...this.FilesToAssign$.value.map((f) => f.Id)];
        const allIds = uniqArray([...FtA, fileId].filter(isNotNullOrUndefined));
        this.fileDataService
            .GetFilesById(allIds)
            .pipe(
                take(1),
                map((files) => files.filter((f) => f.MIMEType.indexOf('image/') > -1).map((f) => f.Id)),
            )
            .subscribe((imgIds) => {
                ImageIdsFromSmallFileCard = imgIds;
            });

        this.filePreviewDialog = this.dialog.open<DaveFilePreviewComponent, DaveFilePreviewComponentDialogData>(DaveFilePreviewComponent, {
            ...DaveFilePreviewComponent.DefaultConfig,
            data: {
                fileId,
                fileExplorerContext: ImageIdsFromSmallFileCard
                    ? {
                          directoryFileIds: ImageIdsFromSmallFileCard,
                      }
                    : undefined,
            },
        });
    }
    public OpenFileUpload() {
        this.dialog
            .open<DaveSelectFileFromDmsComponent, DaveSelectFileFromDmsComponentDialogData, DaveSelectFileFromDmsComponentReturnData>(DaveSelectFileFromDmsComponent, {
                ...DaveSelectFileFromDmsComponent.DefaultConfig,
                data: {
                    moveWarning: '',
                    preSelectedFiles: [...this.FilesToAssign$.value.map((f) => f.Id)],
                    folderId: this.AssignFilesDefaultFolderId,
                },
            })
            .afterClosed()
            .pipe(
                take(1),
                filter((v) => !!v),
                withLatestFrom(this.store.select(getFileDictionary)),
                tap(([{ documents }, files]) => {
                    if (documents) {
                        if (this.EventId) {
                            documents.forEach((docId) =>
                                this.store.dispatch(
                                    // FilesActionTypes.ModifyFileRequest({
                                    //     Payload: {
                                    //         DocumentId: docId.toString(),
                                    //         AutoSetFolder: true,
                                    //         EventIds: [this.EventId, ...(files[docId].EventIds || [])].map(e => e.toString()),
                                    //     },
                                    // }),
                                    FilesActionTypes.CreateLinkRequest({
                                        Payload: {
                                            DocumentId: docId.toString(),
                                            EventId: this.EventId.toString(),
                                            AutoSetFolder: true,
                                        },
                                    }),
                                ),
                            );
                        } else {
                            this.FilesToAssign$.next(documents.map((Id) => files[Id]));
                        }
                    }
                }),
            )
            .subscribe();
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach((v) => v.unsubscribe());
    }

    GetUploadParamsAfterSave() {
        return firstValueFrom(this.CommentFileUploadParams$.pipe(filter(isNotNullOrUndefined)));
    }
    OpenMailDialog(email: EmailEntity) {
        firstValueFrom(this.store.select(getEventById({ id: this.EventId }))).then((event) => {
            this.dialog.open(EmailDialogComponent, {
                ...EmailDialogComponent.DefaultConfig,
                data: {
                    email,
                    event,
                } as EmailDialogData,
            });
        });
    }

    protected readonly EmailPageMeta = EmailPageMeta;

    protected readonly InvoiceEditorMeta = InvoiceEditorMeta;
    openNewReportDialog(eventId) {
        this.newReportDialogService
            .OpenNewReportDialog({
                DefaultValues: {
                    eventId,
                },
            })
            .subscribe((res) => {
                if (res) {
                    this.dialogRef.close();
                }
            });
    }
    onAddLedgerImportClick() {
        if (this.EventId) {
            this.openNewReportDialog(this.EventId);
        } else {
            this.SaveTaskToGenerateComment().then((res) => {
                this.openNewReportDialog(res);
            });
        }
    }

    protected readonly ReportsPageMeta = ReportsPageMeta;
    protected readonly LedgerImportDocumentTypeNames = LedgerImportDocumentTypeNames;

    onDropzoneUploadSuccess(files: FileEntity[]) {
        this.folderDataService.getFolderByIds(uniqArray(files.map((f) => f.FolderId)));
    }

    protected readonly CustomerAdministrationMeta = CustomerAdministrationMeta;
    protected readonly CommissionMeta = CommissionMeta;
}
