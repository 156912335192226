<section #wrapperElement class="wrapper">
    <ng-container [ngTemplateOutlet]="searchBar"></ng-container>
    <mat-nav-list
        class="global-stats-link"
        *ngIf="ListStyle === StatsPagePath && (PS.Has(PS.Permission.GetGlobalStatistics) | async)"
    >
        <a mat-list-item [routerLink]="GlobalStatsPath" routerLinkActive="active">
            Globale Statistik
            <fa-icon class="chevron-right" icon="chevron-right"></fa-icon>
        </a>
        <mat-divider></mat-divider>
    </mat-nav-list>

    <!-- Alle Dateien List-Header -->
    <mat-nav-list class="global-stats-link" *ngIf="ListStyle === DocsPath">
        <a mat-list-item [routerLink]="AllDocsPath" routerLinkActive="active">
            Alle Dateien
            <fa-icon class="chevron-right" icon="chevron-right"></fa-icon>
        </a>
        <mat-divider></mat-divider>
    </mat-nav-list>
    <cdk-virtual-scroll-viewport
        tvsItemSize="56"
        headerHeight="56"
        style="height: 100%; overflow-y: scroll"
        [bufferMultiplier]="1"
    >
        <mat-nav-list class="global-stats-link" *ngIf="AdditionalButton">
            <a
                mat-list-item
                [routerLink]="AdditionalButton.path"
                routerLinkActive="active"
                style="height: 55px; width: -webkit-fill-available"
            >
                {{AdditionalButton.label}}
                <fa-icon class="chevron-right" icon="chevron-right" style="margin: 0 .5rem"></fa-icon>
            </a>
            <mat-divider></mat-divider>
        </mat-nav-list>
        <!-- Alle Aufnahmen List-Header -->
        <mat-nav-list class="global-stats-link" *ngIf="ListStyle === VideodokumentationPath">
            <a
                mat-list-item
                [routerLink]="AllVideodokumentationenPath"
                routerLinkActive="active"
                style="height: 55px; width: -webkit-fill-available"
            >
                Alle Aufnahmen
                <fa-icon class="chevron-right" icon="chevron-right" style="margin: 0 .5rem"></fa-icon>
            </a>
            <mat-divider></mat-divider>
        </mat-nav-list>

        <!-- Alle Belege List-Header -->
        <mat-nav-list class="global-stats-link" *ngIf="ListStyle === ReportsMeta.Path">
            <a
                mat-list-item
                [routerLink]="AllReportsMeta.Path"
                routerLinkActive="active"
                style="height: 55px; width: -webkit-fill-available"
            >
                Alle Belege
                <!--                    <a app-round-button Inverted="true" [routerLink]="['/', ReportsMeta.Path, AllReportsMeta.Path, NewReportMeta.Path]" style="height: 48px; margin: 0 0 0 auto" (click)="$event.stopPropagation()">-->
                <!--                        <app-double-icon Icon="history" Icon2="plus"></app-double-icon>-->
                <!--                    </a>-->
                <fa-icon class="chevron-right" icon="chevron-right" style="margin: 0 .5rem"></fa-icon>
            </a>
            <mat-divider></mat-divider>
        </mat-nav-list>
        <mat-nav-list class="global-stats-link" *ngIf="ListStyle === BookingMeta.Path">
            <a mat-list-item [routerLink]="'alle'" routerLinkActive="active">
                Alle Buchungen
                <!--                    <a app-round-button Inverted="true" [routerLink]="['/', ReportsMeta.Path, AllReportsMeta.Path, NewReportMeta.Path]" style="height: 48px; margin: 0 0 0 auto" (click)="$event.stopPropagation()">-->
                <!--                        <app-double-icon Icon="history" Icon2="plus"></app-double-icon>-->
                <!--                    </a>-->
                <fa-icon class="chevron-right" icon="chevron-right" style="margin: 0 .5rem"></fa-icon>
            </a>
            <mat-divider></mat-divider>
        </mat-nav-list>

        <!-- Alle Bestellungen List-Header -->
        <mat-nav-list class="global-stats-link" *ngIf="ListStyle === OrdersPageMeta.Path">
            <a mat-list-item [routerLink]="AllOrdersMeta.Path" routerLinkActive="active" style="height: 55px">
                Alle Bestellungen
                <!--                    <a app-round-button Inverted="true" [routerLink]="['/', ReportsMeta.Path, AllReportsMeta.Path, NewReportMeta.Path]" style="height: 48px; margin: 0 0 0 auto" (click)="$event.stopPropagation()">-->
                <!--                        <app-double-icon Icon="history" Icon2="plus"></app-double-icon>-->
                <!--                    </a>-->
                <fa-icon class="chevron-right" icon="chevron-right" style="margin: 0 .5rem"></fa-icon>
            </a>
            <mat-divider></mat-divider>
        </mat-nav-list>

        <!-- Alle Aufträge List-Header -->
        <mat-nav-list class="global-stats-link" *ngIf="ListStyle === CommissionPath">
            <a mat-list-item (click)="Router.navigate(['alle'], { relativeTo: ActivatedRoute })" style="height: 55px">
                Alle {{cls.getMultiple$('Commission') | async}}

                <fa-icon class="chevron-right" icon="chevron-right" style="margin: 0 .5rem"></fa-icon>
            </a>
            <mat-divider></mat-divider>
        </mat-nav-list>

        <!-- Alle Ereignisse List-Header -->
        <mat-nav-list class="global-stats-link" *ngIf="ListStyle === 'history'">
            <a mat-list-item [routerLink]="AllEventsPath" routerLinkActive="active" style="height: 55px">
                Alle Ereignisse

                <fa-icon class="chevron-right" icon="chevron-right" style="margin: 0 .5rem"></fa-icon>
            </a>
            <mat-divider></mat-divider>
        </mat-nav-list>

        <!-- Platzhalter List-Header für Auftraggeberverwaltung und Berichte-->
        <mat-nav-list
            class="global-stats-link"
            *ngIf="(Mobile$ | async) && (ListStyle === 'kunden')"
        >
            <a mat-list-item style="height: 3.5rem"></a>
            <mat-divider></mat-divider>
        </mat-nav-list>

        <table mat-table [dataSource]="(DataSource$ | async) || PlaceHolder" matSort class="mat-elevation-z8 table" [matSortActive]="DefaultSort" matSortDirection="asc">
            <ng-container *ngFor="let column of Columns.filter(FilterCustomColumns)" [matColumnDef]="column.name">
                <th mat-header-cell *matHeaderCellDef [mat-sort-header]="column.name">{{ column.header }}</th>
                <td mat-cell *matCellDef="let rowData">{{ rowData[column.name] }}</td>
            </ng-container>


            <ng-container matColumnDef="Status">
                <th mat-header-cell *matHeaderCellDef class="statusheader"></th>
                <td mat-cell *matCellDef="let rowData">
                    <div class="status">
                        <span *ngIf="rowData.Status !== CustomerStatusEnum.Inaktiv && rowData.Status !== CustomerStatusEnum.Gesperrt" [matTooltip]="CustomerStatusTypeNames.get(CustomerStatusEnum.Aktiv)" class="aktiv-dot"></span>
                        <span *ngIf="rowData.Status === CustomerStatusEnum.Inaktiv" [matTooltip]="CustomerStatusTypeNames.get(CustomerStatusEnum.Inaktiv)" class="inaktiv-dot"></span>
                        <span *ngIf="rowData.Status === CustomerStatusEnum.Gesperrt" [matTooltip]="CustomerStatusTypeNames.get(CustomerStatusEnum.Gesperrt)" class="gesperrt-dot"></span>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="person">
                <th mat-header-cell *matHeaderCellDef style="width: 3.5rem"></th>
                <td mat-cell *matCellDef="let rowData">
                    <button mat-icon-button (click)="$event.stopPropagation(); OpenPersonPopup(rowData.Kunde);" [matTooltip]="rowData.Kunde.AnsprechpartnerText()">
                        <fa-icon icon="user" style="font-size: 1.5rem" ></fa-icon>
                    </button>
                </td>
            </ng-container>
            <!--<ng-container matColumnDef="chevron">
                <th
                    *matHeaderCellDef
                    mat-header-cell
                    [style.width.px]="
                        ListStyle === StatsPagePath
                            ? 126
                            : ListStyle === 'history' ||
                              ListStyle === CommissionPath ||
                              ListStyle === DocsPath ||
                              ListStyle === VideodokumentationPath
                            ? 68
                            : 9
                    "
                ></th>

                <td mat-cell *matCellDef="let rowData">
                    <div class="table-cell-wrapper">
                        <fa-icon class="chevron-right table-cell-content" icon="chevron-right"></fa-icon>
                    </div>
                </td>
            </ng-container>-->
            <!--<ng-container matColumnDef="Bewertung">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="Bewertung">Bewertung</th>
                <td mat-cell *matCellDef="let rowData">
                    <app-rating [Rating]="rowData.Rating" [Clickable]="false"></app-rating>
                </td>
            </ng-container>-->
            <ng-container matColumnDef="KdNr">
                <th mat-header-cell *matHeaderCellDef class="customerNoHeader" mat-sort-header="KdNr">KdNr</th>
                <td mat-cell *matCellDef="let rowData">{{ rowData.KdNr }}</td>
            </ng-container>
            <ng-container matColumnDef="Kunde">
                <!-- Inline-Style: Breite des Icons -->
                <th mat-header-cell *matHeaderCellDef mat-sort-header="Kunde">{{ CS.GetSingle$() | async }}</th>
                <td mat-cell *matCellDef="let rowData">
                    <div class="customer">

                        <p class="customer-name">
                            <span>
                                {{ rowData.Kunde.Name }}  {{ rowData.Kunde.Description }}
                            </span>
                        </p>
                    </div>
                </td>
            </ng-container>
            <tr *matHeaderRowDef="DisplayedColumns$ | async" mat-header-row class="table-row"></tr>
            <tr
                *matRowDef="let rowData; columns: DisplayedColumns$ | async"
                mat-row
                class="table-row"
                routerLinkActive="highlight"
                [routerLink]="rowData.RouterLink"
                (click)="highlight(rowData)"
            ></tr>
        </table>
    </cdk-virtual-scroll-viewport>
    <table *ngIf="!((DataSource$ | async)?.data.length > 0)">
        <tr class="table-row">
            <div class="table-cell-wrapper" style="justify-content: center">
                <td class="empty-table-text">
                    <div class="table-cell-content">Keine {{ CS.GetMultiple$() | async }} vorhanden</div>
                </td>
            </div>
        </tr>
    </table>
</section>
<app-filter-card UnlimitedHeight
    #filter
    [Settings]="FilterSettings$ | async"
    [Values]="FilterValues$ | async"
    (ValuesChange)="FilterValues$.next($event)"
></app-filter-card>

<ng-template #searchBar>
    <header class="header">
        <fa-icon class="icon" icon="building" *ngIf="!(Mobile$ | async)"></fa-icon>
        <div class="dark-theme title" *ngIf="ShowSearchBar$ | async; else searchBarInactive">
            <!-- search bar -->
            <mat-form-field
                floatLabel="never"
                color="accent"
                [ngStyle]="{ width: (IsSmallWidth$ | async) ? '100%' : '50%' }"
            >
                <mat-label>Suche nach {{ CS.GetMultiple$() | async }} und Ansprechpartnern</mat-label>
                <input type="text" [formControl]="SearchForm" matInput #input />
                <button class="clear-search" (click)="SearchForm.setValue('')" mat-icon-button *ngIf="SearchForm.value">
                    <fa-icon icon="times"></fa-icon>
                </button>
            </mat-form-field>
        </div>

        <!-- Header Auftraggeberliste -->
        <ng-template #searchBarInactive>
            <h3 style="margin-right: auto" class="headline" *ngIf="!(Mobile$ | async)">{{ CS.GetMultiple$() | async }}liste</h3>
        </ng-template>
        <!-- Suchen Button -->
        <button
            app-round-button
            Inverted="true"
            matTooltipPosition="left"
            [matTooltip]="(ShowSearchBar$ | async) ? 'Ausblenden' : 'Suche'"
            (click)="ShowSearchBar$.next(!ShowSearchBar$.value)"
        >
            <fa-icon [icon]="(ShowSearchBar$ | async) ? 'times' : 'search'"></fa-icon>
        </button>
        <!-- Kunden Filtern Button -->
        <button
            app-round-button
            Inverted="true"
            matTooltip="Kunden Filtern"
            matTooltipPosition="left"
            (click)="filter.Open()"
            [matBadge]="FilterAmount$ | async"
            [matBadgeHidden]="!(FilterAmount$ | async)"
        >
            <fa-icon icon="filter"></fa-icon>
        </button>

        <!-- Videodokumentation: Aufnahme hinzufügen -->
        <a
            app-round-button
            Inverted="true"
            [routerLink]="['/', VideoMeta.Path, AllVideodokumentationenPath, NewVideoMeta.Path]"
            (click)="$event.stopPropagation()"
            *ngIf="ListStyle === 'videodokumentation'"
        >
            <app-double-icon [Icon]="'video'" Icon2="plus"></app-double-icon>
        </a>

        <!-- Auftragsverwaltung: Auftrag hinzufügen -->
        <button
            app-round-button
            Inverted="true"
            (click)="$event.stopPropagation(); $event.stopImmediatePropagation(); CreateCommission()"
            [Disabled]="(!(PS.Has(PS.Permission.CreateCommission) | async)) || LS.isLoading"
            *ngIf="ListStyle === 'auftraege'"
            [matTooltip]="(cls.getSingle$('Commission') | async) + ' hinzufügen'"
        >
            <app-double-icon Icon="file-contract" Icon2="plus"></app-double-icon>
        </button>

        <!-- Chronik: neues Ereignis anlegen -->
        <a
            app-round-button
            Inverted="true"
            [routerLink]="['/', HistoryMeta.Path, AllEventsPath, 'neuesEreignis']"
            (click)="$event.stopPropagation()"
            *ngIf="ListStyle === 'history'"
            [matTooltip]="'Neues Ereignis hinzufügen'"
        >
            <app-double-icon Icon="history" Icon2="plus"></app-double-icon>
        </a>

        <!-- Auftraggeber hinzufügen Button -->
        <a
            app-round-button
            Inverted
            [matTooltip]="(CS.GetSingle$() | async) + ' hinzufügen'"
            matTooltipPosition="left"
            routerLink="/kunden/neuerKunde"
            *ngIf="(PS.Has(PS.Permission.CreateCustomer) | async) && ListStyle === 'kunden'"
        >
            <!--            <fa-icon icon="plus"></fa-icon>-->
            <app-double-icon Icon="building" Icon2="plus"></app-double-icon>
        </a>

        <!-- Rechung/Angebot erstellen Button -->
        <button
            app-round-button
            Inverted
            class="button"
            matTooltip="Rechnung/Angebot erstellen"
            matTooltipPosition="left"
            (click)="NewInvoice()"
            *ngIf="(PS.Has(PS.Permission.CreateLedgerImports) | async) && ListStyle === 'rechnungen_und_angebote'"
        >
            <app-double-icon [Icon]="ReportsPageMeta.Icon" Icon2="plus"></app-double-icon>
        </button>
        <app-add-report-button class="header-button" Inverted *ngIf="ListStyle === 'berichte' " [CustomerId]="customerId$ | async"></app-add-report-button>

    </header>
</ng-template>

<ng-template #noListItems>
    <p class="error-message">Keine Einträge vorhanden.</p>
</ng-template>

<ng-template #spacer>
    <p>--</p>
</ng-template>
<ng-template #personCell let-data="data">
    <app-person-list-entry [Person]="data"></app-person-list-entry>
</ng-template>
