import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    FILE_OPENER_OPEN_REQUEST_KEY,
    FileOpenerOpenRequestState, selectAllFileOpenerOpenRequest,
    selectFileOpenerOpenRequestEntities, selectFileOpenerOpenRequestFetched, selectFileOpenerOpenRequestLatestUpdatedAt,
} from '../reducers/file-opener-open-request.reducer';

const selectState = createFeatureSelector<FileOpenerOpenRequestState>(FILE_OPENER_OPEN_REQUEST_KEY);
export const getFileOpenerOpenRequestDictionary = createSelector(selectState, selectFileOpenerOpenRequestEntities);
export const getFileOpenerOpenRequestLatestUpdatedAt = createSelector(selectState, selectFileOpenerOpenRequestLatestUpdatedAt);
export const getFileOpenerOpenRequestFetched = createSelector(selectState, selectFileOpenerOpenRequestFetched);
export const getFileOpenerOpenRequestes = createSelector(selectState, selectAllFileOpenerOpenRequest);
export const getFileOpenerOpenRequestesActive = createSelector(getFileOpenerOpenRequestes, (entities) => entities.filter((entity) => !entity.DeletedAt));
export const getFileOpenerOpenRequestById = (props: { id: number }) => createSelector(getFileOpenerOpenRequestDictionary, (dictionary) => props.id && dictionary[props.id]);
