export const ISO_IEC_17_6523 = {
    '0002': 'System Information et Repertoire des Entreprise et des Etablissements: SIRENE',
    '0003': 'Codification Numerique des Etablissments Financiers En Belgique',
    '0004': 'NBS/OSI NETWORK',
    '0005': 'USA FED GOV OSI NETWORK',
    '0006': 'USA DOD OSI NETWORK',
    '0007': 'Organisationsnummer',
    '0008': 'LE NUMERO NATIONAL',
    '0009': 'SIRET-CODE',
    '0010': 'Organizational Identifiers for Structured Names under ISO 9541 Part 2',
    '0011': "International Code Designator for the Identification of OSI-based, Amateur Radio Organizations, Network Objects and Application Services.",
    '0012': 'European Computer Manufacturers Association: ECMA',
    '0013': 'VSA FTP CODE (FTP = File Transfer Protocol)',
    '0014': "NIST/OSI Implememts' Workshop",
    '0015': 'Electronic Data Interchange: EDI',
    '0016': 'EWOS Object Identifiers',
    '0017': 'COMMON LANGUAGE',
    '0018': 'SNA/OSI Network',
    '0019': 'Air Transport Industry Services Communications Network',
    '0020': 'European Laboratory for Particle Physics: CERN',
    '0021': 'SOCIETY FOR WORLDWIDE INTERBANK FINANCIAL, TELECOMMUNICATION S.W.I.F.T.',
    '0022': 'OSF Distributed Computing Object Identification',
    '0023': 'Nordic University and Research Network: NORDUnet',
    '0024': 'Digital Equipment Corporation: DEC',
    '0025': 'OSI ASIA-OCEANIA WORKSHOP',
    '0026': 'NATO ISO 6523 ICDE coding scheme',
    '0027': 'Aeronautical Telecommunications Network (ATN)',
    '0028': 'International Standard ISO 6523',
    '0029': 'The All-Union Classifier of Enterprises and Organisations',
    '0030': 'AT&T/OSI Network',
    '0031': 'EDI Partner Identification Code',
    '0032': 'Telecom Australia',
    '0033': 'S G W OSI Internetwork',
    '0034': 'Reuter Open Address Standard',
    '0035': 'ISO 6523 - ICD',
    '0036': 'TeleTrust Object Identifiers',
    '0037': 'LY-tunnus',
    '0038': 'The Australian GOSIP Network',
    '0039': 'The OZ DOD OSI Network',
    '0040': 'Unilever Group Companies',
    '0041': 'Citicorp Global Information Network',
    '0042': 'DBP Telekom Object Identifiers',
    '0043': 'HydroNETT',
    '0044': 'Thai Industrial Standards Institute (TISI)',
    '0045': 'ICI Company Identification System',
    '0046': 'FUNLOC',
    '0047': 'BULL ODI/DSA/UNIX Network',
    '0048': 'OSINZ',
    '0049': 'Auckland Area Health',
    '0050': 'Firmenich',
    '0051': 'AGFA-DIS',
    '0052': 'Society of Motion Picture and Television Engineers (SMPTE)',
    '0053': 'Migros_Network M_NETOPZ',
    '0054': 'ISO6523 - ICDPCR',
    '0055': 'Energy Net',
    '0056': 'Nokia Object Identifiers (NOI)',
    '0057': 'Saint Gobain',
    '0058': 'Siemens Corporate Network',
    '0059': 'DANZNET',
    '0060': 'Data Universal Numbering System (D-U-N-S Number)',
    '0061': 'SOFFEX OSI',
    '0062': 'KPN OVN',
    '0063': 'ascomOSINet',
    '0064': 'UTC: Uniforme Transport Code',
    '0065': 'SOLVAY OSI CODING',
    '0066': 'Roche Corporate Network',
    '0067': 'ZellwegerOSINet',
    '0068': 'Intel Corporation OSI',
    '0069': 'SITA Object Identifier Tree',
    '0070': 'DaimlerChrysler Corporate Network',
    '0071': 'LEGO /OSI NETWORK',
    '0072': 'NAVISTAR/OSI Network',
    '0073': 'ICD Formatted ATM address',
    '0074': 'ARINC',
    '0075': 'Alcanet/Alcatel-Alsthom Corporate Network',
    '0076': 'Sistema Italiano di Identificazione di ogetti gestito da UNINFO',
    '0077': 'Sistema Italiano di Indirizzamento di Reti OSI Gestito da UNINFO',
    '0078': 'Mitel terminal or switching equipment',
    '0079': 'ATM Forum',
    '0080': 'UK National Health Service Scheme, (EDIRA compliant)',
    '0081': 'International NSAP',
    '0082': `"Norwegian Telecommunications Authority's, NTA'S, EDI, identifier scheme (EDIRA compliant)"`,
    '0083': 'Advanced Telecommunications Modules Limited, Corporate Network',
    '0084': 'Athens Chamber of Commerce & Industry Scheme (EDIRA compliant)',
    '0085': 'Swiss Chambers of Commerce Scheme (EDIRA) compliant',
    '0086': 'United States Council for International Business (USCIB) Scheme, (EDIRA compliant)',
    '0087': 'National Federation of Chambers of Commerce & Industry of Belgium, Scheme (EDIRA compliant)',
    '0088': 'EAN Location Code',
    '0089': 'The Association of British Chambers of Commerce Ltd. Scheme, (EDIRA compliant)',
    '0090': 'Internet IP addressing - ISO 6523 ICD encoding',
    '0091': 'Cisco Sysytems / OSI Network',
    '0093': 'Revenue Canada Business Number Registration (EDIRA compliant)',
    '0094': 'DEUTSCHER INDUSTRIE- UND HANDELSTAG (DIHT) Scheme (EDIRA compliant)',
    '0095': 'Hewlett - Packard Company Internal AM Network',
    '0096': 'DANISH CHAMBER OF COMMERCE Scheme (EDIRA compliant)',
    '0097': 'FTI - Ediforum Italia, (EDIRA compliant)',
    '0098': 'CHAMBER OF COMMERCE TEL AVIV-JAFFA Scheme (EDIRA compliant)',
    '0099': 'Siemens Supervisory Systems Network',
    '0100': 'PNG_ICD Scheme',
    '0101': 'South African Code Allocation',
    '0102': 'HEAG',
    '0104': 'BT - ICD Coding System',
    '0105': 'Portuguese Chamber of Commerce and Industry Scheme (EDIRA compliant)',
    '0106': 'Vereniging van Kamers van Koophandel en Fabrieken in Nederland (Association of Chambers of Commerce and Industry in the Netherlands), Scheme (EDIRA compliant)',
    '0107': 'Association of Swedish Chambers of Commerce and Industry Scheme (EDIRA compliant)',
    '0108': 'Australian Chambers of Commerce and Industry Scheme (EDIRA compliant)',
    '0109': 'BellSouth ICD AESA (ATM End System Address)',
    '0110': 'Bell Atlantic',
    '0111': 'Object Identifiers',
    '0112': 'ISO register for Standards producing Organizations',
    '0113': 'OriginNet',
    '0114': 'Check Point Software Technologies',
    '0115': 'Pacific Bell Data Communications Network',
    '0116': 'PSS Object Identifiers',
    '0117': 'STENTOR-ICD CODING SYSTEM',
    '0118': "ATM-Network ZN'96",
    '0119': 'MCI / OSI Network',
    '0120': 'Advantis',
    '0121': 'Affable Software Data Interchange Codes',
    '0122': 'BB-DATA GmbH',
    '0123': 'BASF Company ATM-Network',
    '0124': 'IOTA Identifiers for Organizations for Telecommunications Addressing using the ICD system format defined in ISO/IEC 8348',
    '0125': 'Henkel Corporate Network (H-Net)',
    '0126': 'GTE/OSI Network',
    '0127': 'Dresdner Bank Corporate Network',
    '0128': 'BCNR (Swiss Clearing Bank Number)',
    '0129': 'BPI (Swiss Business Partner Identification) code',
    '0130': 'Directorates of the European Commission',
    '0131': 'Code for the Identification of National Organizations',
    '0132': 'Certicom Object Identifiers',
    '0133': 'TC68 OID',
    '0134': 'Infonet Services Corporation',
    '0135': 'SIA Object Identifiers',
    '0136': 'Cable & Wireless Global ATM End-System Address Plan',
    '0137': 'Global AESA scheme',
    '0138': 'France Telecom ATM End System Address Plan',
    '0139': 'Savvis Communications AESA:.',
    '0140': "Toshiba Organizations, Partners, And Suppliers' (TOPAS) Code",
    '0141': 'NATO Commercial and Government Entity system',
    '0142': 'SECETI Object Identifiers',
    '0143': 'EINESTEINet AG',
    '0144': 'DoDAAC (Department of Defense Activity Address Code)',
    '0145': 'DGCP (Direction Générale de la Comptabilité Publique)administrative accounting identification scheme',
    '0146': 'DGI (Direction Générale des Impots) code',
    '0147': 'Standard Company Code',
    '0148': 'ITU (International Telecommunications Union)Data Network Identification Codes (DNIC)',
    '0149': 'Global Business Identifier',
    '0150': 'Madge Networks Ltd- ICD ATM Addressing Scheme',
    '0151': 'Australian Business Number (ABN) Scheme',
    '0152': 'Edira Scheme Identifier Code',
    '0153': 'Concert Global Network Services ICD AESA',
    '0154': 'Identification number of economic subjects: (ICO)',
    '0155': 'Global Crossing AESA (ATM End System Address)',
    '0156': 'AUNA',
    '0157': 'ATM interconnection with the Dutch KPN Telecom',
    '0158': 'Identification number of economic subject (ICO) Act on State Statistics of 29 November 2001, § 27',
    '0159': 'ACTALIS Object Identifiers',
    '0160': 'GTIN - Global Trade Item Number',
    '0161': 'ECCMA Open Technical Directory',
    '0162': 'CEN/ISSS Object Identifier Scheme',
    '0163': 'US-EPA Facility Identifier',
    '0164': 'TELUS Corporation',
    '0165': 'FIEIE Object identifiers',
    '0166': 'Swissguide Identifier Scheme',
    '0167': 'Priority Telecom ATM End System Address Plan',
    '0168': 'Vodafone Ireland OSI Addressing',
    '0169': 'Swiss Federal Business Identification Number. Central Business names Index (zefix) Identification Number',
    '0170': 'Teikoku Company Code',
    '0171': 'Luxembourg CP & CPS (Certification Policy and Certification Practice Statement) Index',
    '0172': 'Project Group “Lists of Properties” (PROLIST®)',
    '0173': 'eCI@ss',
    '0174': 'StepNexus',
    '0175': 'Siemens AG',
    '0176': 'Paradine GmbH',
    '0177': 'Odette International Limited',
    '0178': 'Route1 MobiNET',
    '0179': 'Penango Object Identifiers',
    '0180': 'Lithuanian military PKI',
    '0183': "Numéro d'identification suisse des enterprises (IDE), Swiss Unique Business Identification Number (UIDB)",
    '0184': 'DIGSTORG',
    '0185': 'Perceval Object Code',
    '0186': 'TrustPoint Object Identifiers',
    '0187': 'Amazon Unique Identification Scheme',
    '0188': 'Corporate Number of The Social Security and Tax Number System',
    '0189': 'European Business Identifier (EBID)',
    '0190': 'Organisatie Indentificatie Nummer (OIN)',
    '0191': 'Company Code (Estonia)',
    '0192': 'Organisasjonsnummer',
    '0193': 'UBL.BE Party Identifier',
    '0194': 'KOIOS Open Technical Dictionary',
    '0195': 'Singapore Nationwide E-lnvoice Framework',
    '0196': 'Icelandic identifier - Íslensk kennitala',
    '0197': 'APPLiA Pl Standard',
    '0198': 'ERSTORG',
    '0199': 'Legal Entity Identifier (LEI)',
    '0200': 'Legal entity code (Lithuania)',
    '0201': 'Codice Univoco Unità Organizzativa iPA',
    '0202': 'Indirizzo di Posta Elettronica Certificata',
    '0203': 'eDelivery Network Participant identifier',
    '0204': 'Leitweg-ID',
    '0205': 'CODDEST',
    '0206': 'Registre du Commerce et de l’Industrie : RCI',
    '0207': 'PiLog Ontology Codification Identifier (POCI)',
    '0208': "Numero d'entreprise / ondernemingsnummer / Unternehmensnummer",
    '0209': 'GS1 identification keys',
    '0210': 'CODICE FISCALE',
    '0211': 'PARTITA IVA',
    '0212': 'Finnish Organization Identifier',
    '0213': 'Finnish Organization Value Add Tax Identifier',
    '0214': 'Tradeplace TradePI Standard',
    '0215': 'Net service ID',
    '0216': 'OVTcode',
    'XR01': 'DiGAVEID',
    'XR02': 'Freischaltcode',
    'XR03': 'IK',
}
