<ng-container *ngIf="Wrapped">
    <mat-card class="flex-space-between p-05" *ngIf="!Inline && !Editing && data?.Properties?.length; else notediting">
        <ng-container *ngTemplateOutlet="mainContent"></ng-container>
    </mat-card>
</ng-container>

<ng-container *ngIf="!Wrapped">
    <ng-container *ngTemplateOutlet="mainContent"></ng-container>
</ng-container>

<ng-template #mainContent>
    <ng-container *ngTemplateOutlet="notediting"></ng-container>
    <ng-container *ngTemplateOutlet="ngcontent"></ng-container>
</ng-template>

<ng-template #notediting>
    <ng-container *ngIf="!Editing && data?.Properties?.length">
        <div class="contentNotEditing">
            <h3 class="headline" *ngIf="data.Headline || data.HeaderIcon">
                <fa-icon *ngIf="data.HeaderIcon" [icon]="data.HeaderIcon"></fa-icon>
                {{ data.Headline }}
            </h3>
            <p *ngIf="data.Subline">{{data.Subline}}</p>
            <table class="main-table">
                <ng-container *ngFor="let property of data.Properties">
                    <tr *ngIf="property.value !== undefined && property.value !== null">
                        <td style="vertical-align: top" [style.width]="onlyIcons ? '1.5rem' : 'unset'">
                            <p class="table-text" *ngIf="!property?.onlyIcon">{{ property.key.length > 0 ? property.key + ': ' : '' }}</p>
                            <p class="table-text" *ngIf="property?.onlyIcon as icon"><fa-icon [icon]="icon"></fa-icon></p>
                        </td>
                        <!-- added != 0, since we are using an enum and MailTo equals 0 -->
                        <td *ngIf="!property?.options?.type && property?.options?.type != 0">
                            <p [class.wordBreak]="property?.options?.wordBreak" class="table-text">
                                {{ property.value }}
                            </p>
                        </td>
                        <td *ngIf="property?.options?.type === PropertyType.Percentage">
                            <p class="table-text">{{ property.value | percent }}</p>
                        </td>
                        <td *ngIf="property?.options?.type === PropertyType.Datum">
                            <p class="table-text">{{ property.value | date }}</p>
                        </td>
                        <td *ngIf="property?.options?.type === PropertyType.Boolean">
                            <p class="table-text">{{ property.value ? 'Ja' : 'Nein' }}</p>
                        </td>
                        <td *ngIf="property.options?.type === PropertyType.Link">
                            <a [href]="property.value?.includes('//') ? property.value : '//' + property.value" class="link table-text" target="_blank" rel="noreferrer noopener">{{
                                property.value
                            }}</a>
                        </td>
                        <td *ngIf="property.options?.type === PropertyType.RouterLink">
                            <a
                            [routerLink]="property.options.routerLinkPayload.routerLink"
                            [queryParams]="property.options.routerLinkPayload.queryParams"
                            [queryParamsHandling]="property.options.routerLinkPayload.queryParamsHandling"
                            class="link table-text"
                        >
                        {{ property.value }}
                        </a>
                        </td>
                        <td *ngIf="property.options?.type === PropertyType.Mailto">
<!--                            <a [href]="'mailto:' + property.value" class="link table-text">{{ property.value }}</a>-->
                            <a (click)="OpenMailDialog([property.value])" class="link table-text">{{ property.value }}</a>
                        </td>
                        <td *ngIf="property.options?.type === PropertyType.Tel">
                            <a [href]="'tel:' + property.value" class="link table-text">{{ property.value }}</a>
                        </td>
                        <td *ngIf="property.options?.type === PropertyType.Location">
                            <a [href]="" (click)="OpenMapDialog(property.options.specialInput.location.value)" class="link table-text">{{ property.value }}</a>
                        </td>
                        <td *ngIf="property.options?.type === PropertyType.DetailDialog">
                            <a (click)="OpenDetailDialog(property)" class="link table-text">{{ property.value }}</a>
                        </td>
                    </tr>
                </ng-container>
            </table>
        </div>
    </ng-container>
</ng-template>

<ng-container *ngIf="Wrapped">
    <mat-card *ngIf="!Inline && Editing && data; else editing">
        <ng-container *ngTemplateOutlet="mainContentEditing"></ng-container>
    </mat-card>
</ng-container>

<ng-container *ngIf="!Wrapped && Editing">
    <ng-container *ngTemplateOutlet="mainContentEditing"></ng-container>
</ng-container>

<ng-template #mainContentEditing>
    <ng-container *ngTemplateOutlet="editing"></ng-container>
    <ng-container *ngTemplateOutlet="ngcontent"></ng-container>
</ng-template>

<ng-template #editing>
    <ng-container *ngIf="Editing && data">
        <h3 class="headline" *ngIf="data.Headline || data.HeaderIcon">
            <fa-icon *ngIf="data.HeaderIcon" [icon]="data.HeaderIcon"></fa-icon>
            {{ data.Headline }}
        </h3>
        <p *ngIf="data.Subline">{{data.Subline}}</p>
        <ng-container *ngFor="let property of EditableProperties$ | async; let i = index">
            <form [formGroup]="FormGroup" (ngSubmit)="Submit.emit()" *ngIf="!property.hideFormControl" class="hover-form" [class.autofocus]="i === AutofocusIndex">
                <ng-container *ngIf="property.options?.specialInput; else normalInput">
                    <mat-form-field *ngIf="property.options.specialInput.colorPicker" class="form-field">
                        <mat-label>{{ property.key }}</mat-label>
                        <input matInput [ngxMatColorPicker]="picker" [formControl]="property.formControl" [disabled]="property.formControl.disabled">
                        <app-mat-color-picker-toggle matSuffix [for]="picker"></app-mat-color-picker-toggle>
                        <app-mat-color-preview matPrefix [for]="picker"></app-mat-color-preview>
                        <ngx-mat-color-picker #picker [disabled]="property.formControl.disabled" [touchUi]="BS.TouchQuery | async" [color]="'primary'"></ngx-mat-color-picker>
                    </mat-form-field>
                    <mat-form-field *ngIf="property.options.specialInput.fileName" class="form-field">
                        <mat-label>{{ property.key }}</mat-label>
                        <app-filename-input [formControl]="property.formControl" [disabled]="property.formControl.disabled"></app-filename-input>
<!--                        <input matInput [formControl]="property.formControl" [disabled]="property.formControl.disabled">-->
<!--                        <span matSuffix>-->
<!--                            <button mat-icon-button><fa-icon icon="pencil-alt"></fa-icon></button>-->
<!--                        </span>-->
                    </mat-form-field>
<!--                    <div class="datepicker-form" *ngIf="property.options.specialInput.colorPicker">-->
<!--                        <mat-label>{{property.key}}</mat-label>-->
<!--                        <div class="input-wrapper">-->
<!--                            <div class="color-picker-element-wrapper">-->
<!--                                <div-->
<!--                                    (colorPickerChange)="onEventLog('colorPickerChange', $event); property.formControl.setValue(ColorEntityFromString($event))"-->
<!--                                    [colorPicker]="property.formControl.value.getHexString()"-->
<!--                                    [style.background]="property.formControl.value.getHexString()"-->
<!--                                    [cpPresetColors]="['#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)']"-->
<!--                                    cpAlphaChannel="disabled"-->
<!--                                    cpPresetLabel="Vorschläge"-->
<!--                                    class="color-picker-element"-->
<!--                                    cpPresetColorsClass="preset-colors"-->
<!--                                    cpPosition="auto"-->
<!--                                    cpPositionOffset="50%"-->
<!--                                    cpDialogDisplay="inline"-->
<!--                                    #picker-->
<!--                                ></div>-->
<!--                            </div>-->
<!--                            <button app-round-button (click)="picker.click()"><fa-icon [icon]="'pencil-alt'"></fa-icon></button>-->
<!--                        </div>-->
<!--                    </div>-->

                    <ng-container *ngIf="property.options.specialInput.customTemplate" [ngTemplateOutlet]="property.options.specialInput.customTemplate" [ngTemplateOutletContext]="property"></ng-container>
                    <mat-form-field *ngIf="property.options.specialInput.timeSpan" class="form-field">
                        <mat-label>{{property.key}}</mat-label>
                        <mat-date-range-input
                            [rangePicker]="campaignOnePicker">
                            <input matStartDate placeholder="Von" [formControl]="property.options.specialInput.timeSpan.formControlFrom">
                            <input matEndDate placeholder="Bis" [formControl]="property.options.specialInput.timeSpan.formControlTo">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
                        <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
                    </mat-form-field>
                    <app-textarea
                        *ngIf="property.options?.specialInput?.textArea"
                        [Value]="property.formControl"
                        [Required]="HasRequiredField(property.formControl)"
                        [Fill]="property.options.specialInput.textArea.Fill"
                        [MinRows]="property.options.specialInput.textArea.MinRows"
                        style="overflow: hidden"
                    >
                        <span app-label>
                            <fa-icon [icon]="icon" *ngIf="property.onlyIcon as icon"></fa-icon>
                            {{ property.key }}
                        </span>
                    </app-textarea>
                    <app-chip-autocomplete
                        [initialPatchDefaultValue]="property.options.specialInput.chipAutocomplete.initialPatchDefaultValue"
                        #chipAutocompleteComponent
                        class="hover-form"
                        *ngIf="property.options.specialInput.chipAutocomplete"
                        [formControl]="property.formControl"
                        [OptionTemplate]="property.options.specialInput.chipAutocomplete.OptionTemplate"
                        [MapFn]="property.options.specialInput.chipAutocomplete.MapFn"
                        [CompareFn]="property.options.specialInput.chipAutocomplete.CompareFn ? property.options.specialInput.chipAutocomplete.CompareFn : chipAutocompleteComponent.CompareFn"
                        [Options]="property.options.specialInput.chipAutocomplete.Options || (property.options.specialInput.chipAutocomplete.Options$ | async)"
                        (EntryClick)="property.options.specialInput.chipAutocomplete.onClick ? property.options.specialInput.chipAutocomplete.onClick($event) : null"
                        (UnknownOptionSubmitted)="property.options.specialInput.chipAutocomplete.onUnknownOptionSubmitted ? property.options.specialInput.chipAutocomplete.onUnknownOptionSubmitted($event) : null"
                        [Placeholder]="property.key"
                        [ErrorMessage]="property.formControl.invalid ? GetErrorMessage(property.formControl) : ''"
                        [required]="hasRequiredField(property.formControl)"
                    ></app-chip-autocomplete>
                    <mat-form-field *ngIf="property.options.specialInput.select" class="form-field">
                        <mat-label>{{ property.key }}</mat-label>

                        <mat-select [formControl]="property.formControl">
                            <mat-option *ngIf="!HasRequiredField(property.formControl)" [value]="null">--</mat-option>

                            <mat-option *ngFor="let option of property.options.specialInput.select" [value]="option.optionValue">
                                <fa-icon
                                    *ngIf="option.optionIcon"
                                    [icon]="option.optionIcon.iconProp"
                                    style="margin-right: 1rem"
                                    [ngStyle]="{ color: option.optionIcon.color ? option.optionIcon.color : null }"
                                ></fa-icon
                                >{{ option.optionLabel }}
                            </mat-option>
                        </mat-select>
                        <fa-icon *ngIf="property.options?.showHint" icon="question-circle" [matTooltip]="property.options?.showHint" style="margin: 1rem" matSuffix> </fa-icon>
                        <fa-icon
                            *ngIf="SelectOptionIcon(property) as icon"
                            [icon]="icon.iconProp"
                            [ngStyle]="{ color: icon.color || 'unset' }"
                            style="margin-right: 1rem"
                            matPrefix
                        >
                        </fa-icon>
                    </mat-form-field>
                    <mat-form-field *ngIf="property.options.specialInput.singleSelectSearch" class="form-field">
                        <mat-label>
                            <fa-icon [icon]="icon" *ngIf="property.onlyIcon as icon"></fa-icon>
                            {{ property.key }}
                        </mat-label>
                        <fa-icon matSuffix icon="times" style="margin-left: 3px; cursor: pointer;" (click)="property.formControl.setValue(null);$event.stopPropagation();" *ngIf="!property.formControl.disabled"></fa-icon>
                        <app-select-search #selectSearch [OptionTemplate]="property.options.specialInput.singleSelectSearch.optionTemplate" [required]="HasRequiredField(property.formControl)" [formControl]="property.formControl" [Options]="property.options.specialInput.singleSelectSearch.options || (property.options.specialInput.singleSelectSearch.options$ | async)" [CompareOptions]="property.options.specialInput.singleSelectSearch.compareOptions || selectSearch.CompareOptions" [SearchFunction]="property.options.specialInput.singleSelectSearch.searchFunction || selectSearch.SearchFunction"></app-select-search>
                    </mat-form-field>
                    <app-select-search-legacy
                        class="form-field"
                        *ngIf="property.searchSelect"
                        [Control]="property.formControl"
                        [Placeholder]="property.key"
                        [Values]="property.searchSelect"
                        [Required]="HasRequiredField(property.formControl)"
                    >
                    </app-select-search-legacy>
                    <ng-container *ngIf="property.options?.specialInput?.location && !property.options?.specialInput?.location.options">
                        <app-textfield
                            *ngIf="property.options?.specialInput?.location?.formGroup && !property.options?.specialInput?.autocomplete"
                            Key="Straße"
                            [Value]="property.options.specialInput.location.formGroup.controls.Street"
                        ></app-textfield>
                        <mat-form-field *ngIf="property.options?.specialInput?.autocomplete && property.options?.specialInput?.location?.formGroup" style="width: 100%;">
                            <input
                                type="text"
                                matInput
                                [formControl]="property.options.specialInput.location.formGroup.controls.Street"
                                [matAutocomplete]="auto"
                                placeholder="Straße"
                            />
                            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                <mat-option
                                    *ngFor="let option of autoCompleteValues(property, property.options.specialInput.location.formGroup.controls.Street.value)"
                                    [value]="property.options?.specialInput.autocomplete.MapFn(option)"
                                >
                                    {{ property.options?.specialInput.autocomplete.MapFn(option) }}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                        <app-textfield
                            *ngIf="property.options?.specialInput?.location?.formGroup"
                            Key="Postleitzahl"
                            [Value]="property.options.specialInput.location.formGroup.controls.PostalCode"
                        ></app-textfield>
                        <app-textfield
                            *ngIf="property.options?.specialInput?.location?.formGroup"
                            Key="Ort"
                            [Value]="property.options.specialInput.location.formGroup.controls.City"
                        ></app-textfield>
                        <app-textfield
                            *ngIf="property.options?.specialInput?.location?.formGroup"
                            Key="Land"
                            [Value]="property.options.specialInput.location.formGroup.controls.Country"
                        ></app-textfield>
                    </ng-container>
                    <ng-container *ngIf="property.options?.specialInput?.location && property.options.specialInput.location.options">
                        <app-address-form [ngModelOptions]="{standalone: true}" [ngModel]="property.options.specialInput.location.formGroup.value" (ngModelChange)="property.options.specialInput.location.formGroup.setValue($event)" [SearchAddressOptions]="property.options.specialInput.location.options"></app-address-form>
                    </ng-container>
                    <mat-form-field *ngIf="property.options?.specialInput?.date as date" class="form-field">
                        <mat-label>
                            <fa-icon [icon]="icon" *ngIf="property.onlyIcon as icon"></fa-icon>
                            {{ property.key }}
                        </mat-label>
                        <input matInput [matDatepicker]="picker" (click)="picker.open()" [matDatepickerFilter]="date.matDatepickerFilter" [formControl]="property.formControl" />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker [dateClass]="date.dateClass" #picker></mat-datepicker>
                        <mat-error *ngIf="property.formControl.invalid && property.formControl.touched">{{ GetErrorMessage(property.formControl) }}</mat-error>
                    </mat-form-field>
                    <ng-container *ngIf="property.options?.specialInput?.weekDaySelector && property.formControl.value">
                        <span>{{ property.key }}</span>
                        <ul style="list-style-type: none;">
                            <li *ngFor="let name of WeekDayNames; let i = index">
                                <mat-checkbox
                                    *ngIf="property.options?.specialInput?.weekDaySelector === 'checkbox'"
                                    color="primary"
                                    [checked]="property.formControl.value[i]"
                                    (change)="UpdateArrayValue(property.formControl, i, $event.checked)"
                                >
                                    {{ name }}
                                </mat-checkbox>
                                <mat-checkbox
                                    *ngIf="property.options?.specialInput?.weekDaySelector === 'number'"
                                    color="primary"
                                    [checked]="property.formControl.value[i]"
                                    (change)="UpdateArrayValue(property.formControl, i, $event.checked ? 8 : null)"
                                >
                                    <div style="min-width: 2rem;">
                                        <span>{{ name }}</span>
                                    </div>
                                </mat-checkbox>
                                <mat-form-field>
                                    <input
                                        *ngIf="property.options?.specialInput?.weekDaySelector === 'number'"
                                        type="number"
                                        matInput
                                        [value]="property.formControl.value[i]"
                                        (input)="UpdateArrayValue(property.formControl, i, $event.target.value)"
                                    />
                                    <div *ngIf="property.options.suffix" matSuffix>{{ property.options.suffix }}</div>
                                </mat-form-field>
                            </li>
                        </ul>
                    </ng-container>
                    <mat-slide-toggle *ngIf="property.options?.specialInput?.boolean" [formControl]="property.formControl">{{ property.key }} </mat-slide-toggle>
                    <app-numberfield *ngIf="property.options?.specialInput?.number" [Required]="HasRequiredField(property.formControl)" [Hint]="property.options?.showHint" [Key]="property.key" [FormControl]="property.formControl"
                        ><div *ngIf="property.options.suffix" textfieldSuffix>
                            {{ property.options.suffix }}
                        </div></app-numberfield
                    >
                    <mat-form-field *ngIf="property.options?.specialInput?.autocomplete && !property.options?.specialInput?.location?.formGroup" style="width: 100%;">
                        <input type="text" matInput [placeholder]="property.key" [formControl]="property.formControl" [matAutocomplete]="auto" />
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                            <mat-option
                                *ngFor="let option of autoCompleteValues(property, property.formControl.value)"
                                [value]="property.options?.specialInput.autocomplete.MapFn(option)"
                            >
                                {{ property.options?.specialInput.autocomplete.MapFn(option) }}
                                <button
                                    *ngIf="property.options.specialInput.autocomplete.DeleteFn"
                                    style="height: 1.5rem; width: 1.5rem; margin-top: 0.5rem; float: right"
                                    app-round-button
                                    matTooltipPosition="left"
                                    matTooltip="Löschen"
                                    Color="red"
                                    (click)="property.options.specialInput.autocomplete.DeleteFn(option)"
                                    matToolTip="Vorlage löschen"
                                >
                                    <fa-icon icon="trash" size="xs"></fa-icon>
                                </button>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </ng-container>

                <ng-template #normalInput>
                    <app-textfield [Required]="HasRequiredField(property.formControl)" [Value]="property.formControl" [Hint]="property.options?.showHint">
                        <span app-label>
                            <fa-icon [icon]="icon" *ngIf="property.onlyIcon as icon"></fa-icon>
                            {{ property.key }}
                        </span>
                    </app-textfield>
                </ng-template>
                <ng-container *ngIf="property.options?.showHint != null && property.options?.specialInput && !property.options?.specialInput?.select && !property.options?.specialInput?.number" (click)="tooltip.toggle()">
                    <fa-icon icon="question-circle" [matTooltip]="property.options?.showHint" style="margin: 1rem" #tooltip="matTooltip"> </fa-icon>
                </ng-container>
                <!--      <app-textfield-->
                <!--        *ngFor="let property of data.Properties"-->
                <!--        [Key]="property.key"-->
                <!--        [Value]="property.formControl"-->
                <!--      ></app-textfield>-->
            </form>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #ngcontent>
    <div class="contentTemplate">
        <ng-content></ng-content>
    </div>
</ng-template>
