import { Moment } from 'moment/moment';
import { CommissionEntity } from '../dave-data-module/entities/commission.entity';
import { LedgerImportDocumentTypes } from '../dave-data-module/entities/ledger-import.entity';
import { getAddressString } from './helper';

export function calcLedgerImportDueDate(docType: LedgerImportDocumentTypes, invoiceDate: Moment, paymentTargetFromCustomer?: number) {
    if (invoiceDate && (docType === LedgerImportDocumentTypes.Offer || (docType === LedgerImportDocumentTypes.Invoice && !paymentTargetFromCustomer))) {
        return invoiceDate.clone().add(30, 'day');
    }
    return null;
}

export function getLedgerImportTitle(title: string, commission?: CommissionEntity) {
    if (commission) {
        return `Einsatzort: ${commission.Description ? commission.Description + ', ' : ''}${commission.Street ? commission.Street + ', ' : ''}${commission.PostalCode ? commission.PostalCode + ' ' : ''}${commission.City ? commission.City : ''}`;
    }
    return title;
}
// tsb
// titel auch bei vorlage überschreiben
// in wizard bezeichnung vom parent
//
// alle
// auftragsbestätigung soll nur neu bestätigt
// beim versenden auf bestätigt
