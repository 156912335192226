<app-detail-view-template>
    <ng-container *appDetailViewButtons>
        <!--        <div class="button-wrapper">-->
        <!--            <app-burger-button-wrapper class="editor-button-wrapper" [BurgerButtonMargin]="false">-->
        <app-detail-list-template [class.hide]="!ShowStatus" [Inline]="true" [Editing]="true" [Data]="StatusData$ | async"></app-detail-list-template>
        <button
            class="detail-view-buttons"
            Inverted
            app-round-button
            [Active]="ShowStatus"
            (click)="onToggleStatusClick()"
            matTooltip="Status ändern"
            aria-label="Status ändern"
            [IsLoading]="LS.IsLoading$ | async"
        >
            <fa-icon [icon]="ShowStatus ? 'chevron-right' : 'info'"></fa-icon>
        </button>
        <button
            class="detail-view-buttons"
            Inverted
            app-round-button
            (click)="SaveClick$.next()"
            matTooltip="Speichern"
            aria-label="Speichern"
            [IsLoading]="LS.IsLoading$ | async"
            Color="green"
            [Disabled]="nothingDirty$ | async"
        >
            <fa-icon icon="save"></fa-icon>
        </button>
        <button
            class="detail-view-buttons"
            Inverted
            app-round-button
            matTooltip="Löschen"
            aria-label="Löschen"
            Color="red"
            *ngIf="ShowDeleteButton$ | async"
            (click)="OpenDeleteDialog$.next()"
        >
            <fa-icon icon="trash"></fa-icon>
        </button>
<!--        1{{InfoiceFormGroup.dirty}}-->
<!--        2{{(SfdtIsDirty$ | async)}}-->
<!--        3{{arlTableComponent?.SomeFormDirty$ | async}}-->
        <mat-menu #documentTypeMenu>
            <button mat-menu-item *ngFor="let d of LiDocTypeOptions" (click)="CreateDocument(d.type)">{{ d.label }}</button>
        </mat-menu>
        <button class="detail-view-buttons" Inverted app-round-button matTooltip="Herunterladen" aria-label="Herunterladen" *ngIf="(LedgerImport$ | async)?.IsTemplate !== true" [matMenuTriggerFor]="downloadMenu">
            <fa-icon icon="download"></fa-icon>
        </button>
        <button class="detail-view-buttons" Inverted app-round-button matTooltip="E-Mail senden" aria-label="E-Mail senden" [matMenuTriggerFor]="emailMenu">
            <fa-icon icon="paper-plane"></fa-icon>
        </button>
        <button
            class="detail-view-buttons"
            [Disabled]="!(this.LedgerImport$ | async)?.AccountsReceivableLedgerIds?.length"
            (click)="onCreateCommissionClick()"
            *ngIf="(LedgerImport$ | async)?.CommissionId === null"
            Inverted
            app-round-button
            [matTooltip]="(cls.getSingle$('Commission') | async) + ' anlegen'"
            aria-label="{{ (cls.getSingle$('Commission') | async) + ' anlegen' }}"
        >
            <app-double-icon Icon2="plus" [Icon]="CommissionMeta.Icon"></app-double-icon>
        </button>
        <button
            class="detail-view-buttons"
            [Disabled]="!(this.LedgerImport$ | async)?.AccountsReceivableLedgerIds?.length"
            (click)="onAddToResToCommissionClick(commissionId)"
            *ngIf="(LedgerImport$ | async)?.CommissionId as commissionId"
            Inverted
            app-round-button
            [matTooltip]="'Ressourcen zu ' + (cls.getSingle$('Commission') | async) + ' hinzufügen'"
            aria-label="{{ 'Ressourcen zu ' + (cls.getSingle$('Commission') | async) + ' hinzufügen' }}"
        >
            <app-double-icon Icon2="arrow-right" [Icon]="CommissionMeta.Icon"></app-double-icon>
        </button>
        <button class="detail-view-buttons" Inverted app-round-button matTooltip="Wiedervorlage erstellen" aria-label="Wiedervorlage erstellen" *ngIf="!(LedgerImport$ | async)?.IsTemplate" (click)="CreateDocument('template')">
            <app-double-icon Icon="file-alt" Icon2="plus"></app-double-icon>
        </button>
        <button class="detail-view-buttons" Inverted app-round-button matTooltip="Dokument generieren" aria-label="Dokument generieren" [matMenuTriggerFor]="documentTypeMenu">
            <app-double-icon Icon="file-invoice-dollar" Icon2="plus"></app-double-icon>
        </button>
        <!--            <div class="dms-button-wrapper">-->
        <!--                <button-->
        <!--                    app-button-->
        <!--                    Lighter-->
        <!--                    class="wide-button"-->
        <!--                    *ngIf="LedgerImport$ | async as li"-->
        <!--                    [Disabled]="!li.DocumentId"-->
        <!--                    [routerLink]="['/', DMSPageMeta.Path]"-->
        <!--                    [queryParams]="{ documentId: li.DocumentId }"-->
        <!--                    matTooltip="Zum DMS"-->
        <!--                >-->
        <!--                    <fa-icon [icon]="DMSPageMeta.Icon"></fa-icon>-->
        <!--                </button>-->
        <!--            </div>-->
        <!--            </app-burger-button-wrapper>-->
        <mat-menu #downloadMenu="matMenu" style="margin: 0">
            <a mat-menu-item [class.hide]="!(DownloadLink$ | async)" [href]="DownloadLink$ | async" [download]="GetFilename(InfoiceFormGroup.value.ConsolidatedInvoiceId)" matTooltip="PDF Herunterladen">
                <fa-icon icon="file-pdf" class="mr-2"></fa-icon> PDF
            </a>
            <button mat-menu-item *ngIf="!(DownloadLink$ | async)" (click)="DownloadPdfWithoutFile$.next('pdf')" matTooltip="PDF Herunterladen"><fa-icon icon="file-pdf" class="mr-2"></fa-icon> PDF</button>
            <a mat-menu-item [href]="XInvoiceDownloadLink$ | async" [download]="GetFilename(InfoiceFormGroup.value.ConsolidatedInvoiceId)" matTooltip="X-Rechnung Herunterladen" *ngIf="XInvoiceDownloadLink$ | async">
                <fa-icon icon="file-excel" class="mr-2"></fa-icon> X-Rechnung
            </a>
            <button mat-menu-item *ngIf="!(XInvoiceDownloadLink$ | async)" (click)="DownloadPdfWithoutFile$.next('xInvoice')" matTooltip="X-Rechnung Herunterladen"><fa-icon icon="file-excel" class="mr-2"></fa-icon> X-Rechnung</button>
        </mat-menu>
        <mat-menu #emailMenu="matMenu" style="margin: 0">
            <button mat-menu-item (click)="onOpenMailDialog('pdf')" matTooltip="als PDF senden"><fa-icon icon="file-pdf" class="mr-2"></fa-icon> PDF</button>
            <button mat-menu-item (click)="onOpenMailDialog('xRechnung')" matTooltip="als X-Rechnung senden"><fa-icon icon="file-excel" class="mr-2"></fa-icon> X-Rechnung</button>
        </mat-menu>
        <!--        </div>-->
    </ng-container>
    <ng-container *appDetailViewTopContent>
        <div class="wrapper">
            <!-- das ist die mat-card mit rechnung bearbeiten-->
            <mat-card class="edit-invoice-wrapper" *ngIf="!ShowDocumentPreview || !IsMobile">
<!--                <mat-card-header class="card-header">-->
<!--                    -->
<!--                </mat-card-header>-->
                <mat-card-content #formWrapper class="scroll-wrapper">
                    <div class="card-header" style="width: 100%;">
                        <fa-icon matCardAvatar [icon]="InvoiceEditorMeta.Icon"></fa-icon>
                        <h2 *ngIf="LedgerImport$ | async as ledgerImport" style="margin: 0">
                            {{ (ledgerImport.IsTemplate ? 'Vorlage für ' : '') + LedgerImportDocumentTypeNames.get(ledgerImport.DocumentType) }}
                            bearbeiten
                        </h2>
                        <div class="header-buttons">
                            <div class="commission-theme theme-override" *ngIf="InfoiceFormGroup.value.CommissionId?.Id as id">
                                <a app-button class="wide-button" [matTooltip]="(cls.getSingle$('Commission')| async)" aria-label="{{(cls.getSingle$('Commission')| async)}}" [routerLink]="['/', CommissionMeta.Path, AllCommissionMeta.Path, id]"><fa-icon [icon]="CommissionMeta.Icon"></fa-icon></a>
                            </div>
                            <div class="admin-theme theme-override" *ngIf="InfoiceFormGroup.value.Customer?.Id as id">
                                <a app-button class="wide-button" [matTooltip]="(cls.getSingle$('Customer')| async)" aria-label="{{(cls.getSingle$('Customer')| async)}}" [routerLink]="['/', CustomerAdministrationMeta.Path, id]"><fa-icon [icon]="CustomerAdministrationMeta.Icon"></fa-icon></a>
                            </div>
                            <div class="{{ TaskPageMeta.Theme }} theme-override" *ngIf="(LedgerImport$ | async)?.EventId as id">
                                <button app-button class="wide-button" (click)="onOpenEventClick(id)"><fa-icon [icon]="TaskPageMeta.Icon"></fa-icon></button>
                            </div>
                            <div class="dms-theme theme-override" *ngIf="(LedgerImport$ | async)?.DocumentId as documentId">
                                <a app-button class="wide-button" [routerLink]="['/', DMSPageMeta.Path]" [queryParams]="{ documentId }" matTooltip="Zum DMS">
                                    <fa-icon [icon]="DMSPageMeta.Icon"></fa-icon>
                                </a>
                            </div>
                            <button app-button *ngIf="!ShowDocumentPreview" (click)="ShowDocumentPreview = !ShowDocumentPreview">Vorschau</button>
                        </div>
                    </div>
                    <div class="invoice-info-editing-wrapper">
                        <mat-accordion multi>
                            <app-detail-list-template class="p-0 py-4" [Inline]="false" [Editing]="true" [Data]="CustomerData | async"></app-detail-list-template>
                            <app-detail-list-template  class="p-0 py-4" [Inline]="false" [Editing]="true" [Data]="PersonData$ | async"></app-detail-list-template>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title> Empfänger Details </mat-panel-title>
                                </mat-expansion-panel-header>
                                <app-detail-list-template [Inline]="true" [Editing]="true" [Data]="AddressFormData"></app-detail-list-template>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title> Notiz </mat-panel-title>
                                </mat-expansion-panel-header>
                                <app-detail-list-template [Inline]="true" [Editing]="true" [Data]="NotesFormData"></app-detail-list-template>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <mat-accordion multi>
                            <app-detail-list-template class="p-0 py-4" [Inline]="false" [Editing]="InfoiceFormGroup.value.Customer ? false : true" [Data]="PartnerOfficeData$ | async"></app-detail-list-template>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title> Absender Details </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div style="position: relative; width: 200px">
                                    <button app-round-button style="position: absolute; top: 0.5rem; right: 0.5rem" (click)="logoUpload.click()">
                                        <fa-icon icon="upload"></fa-icon>
                                    </button>

                                    <img *ngIf="LogoUrl$ | async as logoUrl" [src]="logoUrl" style="width: 200px" />
                                    <div
                                        *ngIf="!(LogoUrl$ | async)"
                                        style="width: 200px; height: 150px; display: flex; align-items: center; justify-content: center; flex-direction: column; border-radius: 4px; background-color: rgba(0, 0, 0, 0.15)"
                                    >
                                        <fa-icon icon="camera" size="3x"></fa-icon>
                                        <h2 style="margin: 0">Logo</h2>
                                    </div>
                                    <input id="file" type="file" accept="image/*" hidden #logoUpload (change)="OnFileChange($event)" />
                                </div>
                                <app-detail-list-template [Inline]="true" [Editing]="true" [Data]="PartnerDetailData"></app-detail-list-template>
                            </mat-expansion-panel>
                            <app-detail-list-template class="p-0 py-4" [Inline]="false" [Editing]="true" [Data]="AssignedUserData | async"></app-detail-list-template>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title> Bearbeiter Details </mat-panel-title>
                                </mat-expansion-panel-header>
                                <app-detail-list-template [Inline]="true" [Editing]="true" [Data]="UserDetailData"></app-detail-list-template>
                            </mat-expansion-panel>
                            <mat-expansion-panel expanded>
                                <mat-expansion-panel-header>
                                    <mat-panel-title> Allgemeine Details </mat-panel-title>
                                </mat-expansion-panel-header>
                                <app-detail-list-template [Inline]="true" [Editing]="true" [Data]="FormData | async"></app-detail-list-template>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <mat-card style="width: 100%">
                        <app-textfield Key="Titel" [Value]="InfoiceFormGroup.controls.Title"></app-textfield>
                        <app-textarea [Fill]="true" Key="Text über Buchungstabelle" [Value]="InfoiceFormGroup.controls.TextTop"></app-textarea>
                    </mat-card>
                    <mat-card class="detail-list-card" style="width: 100%; max-height: unset;">
                        <mat-card-header class="card-header">
                            <fa-icon matCardAvatar [icon]="BookingPositionMetaIcon"></fa-icon>
                            <div class="title-shortener-workaround">
                                <h3 mat-card-title style="min-width: 0vw">Abr.-Pos.</h3>
                            </div>
                            <mat-slide-toggle style="margin: 0 1rem" [disabled]="DisableForms$ | async" [formControl]="InfoiceFormGroup.controls.HideNetto">Nur Brutto-Preise auswählen</mat-slide-toggle>
                            <mat-slide-toggle style="margin: 0 1rem" [disabled]="DisableForms$ | async" [formControl]="InfoiceFormGroup.controls.ShowTaxSum" *ngIf="InfoiceFormGroup.controls.HideNetto.getRawValue()">MwSt ausweisen</mat-slide-toggle>
                            <div class="header-button-wrapper">
                                <button *ngIf="PS.Has$(PS.Permissions.CreateLedgerImports) | async" app-round-button (click)="NewBookingPositionClick$.next()" [Disabled]="DisableForms$ | async" matTooltip="Abr.-Pos. hinzufügen">
                                    <app-double-icon [Icon]="'money-bill'" Icon2="plus"></app-double-icon>
                                </button>
                            </div>
                        </mat-card-header>
                        <!--                        <app-arl-table #arlTableComponent class="scroll-wrapper" [AddARLDefaultValues]="AddARLDefaultValues$ | async" (AddBookingPosition)="AddBookingPositionClick($event)" [arls]="SortedBookingPositionList$ | async" (arlsChange)="onArlTableChange($event)" [selected]="getSelected()" (selectedChange)="onSelectionChange($event)" (DeleteClick)="onArlDeleteClick($event)"></app-arl-table>-->
                        <app-arl-table
                            [disabled]="DisableForms$ | async"
                            #arlTableComponent
                            [arlIds]="InfoiceFormGroup.getRawValue().AccountsReceivableLedgerIds"
                            (arlIdsChange)="InfoiceFormGroup.controls.AccountsReceivableLedgerIds.setValue($event); InfoiceFormGroup.controls.AccountsReceivableLedgerIds.markAsDirty()"
                            [AddARLDefaultValues]="AddARLDefaultValues$ | async"
                            (FormValueChange)="arlTableValuesChanged$.next()"
                            (DeleteClick)="onArlDeleteClick($event)"
                            [showBruttoColumn]="InfoiceFormGroup.value.HideNetto"
                            [FilterMaterialsByCustomerId]="FilterMaterialsByCustomerId$ | async"
                            [UseAmountFromMaterialToSupplier]="!!(FilterMaterialsByCustomerId$ | async)"
                            [isOrder]="(LedgerImport$ | async)?.DocumentType === LedgerImportDocumentTypesEnum.Order"
                            [displayedColumns]="InfoiceFormGroup.value.ArlTableDisplayedColumns"
                            (displayedColumnsChange)="InfoiceFormGroup.controls.ArlTableDisplayedColumns.setValue($event)"
                        ></app-arl-table>
                    </mat-card>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title> Umsatzsteuer </mat-panel-title>
                            <mat-panel-description>
                                Steuern für alle Positionen anpassen
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div class="flex justify-between gap-4 items-center">
                            <mat-form-field>
                                <mat-select placeholder="Steuern in %" [formControl]="InfoiceFormGroup.controls.TaxType">
                                    <mat-option *ngFor="let option of taxOptions" [value]="option.value">
                                        {{option.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <button app-button [Disabled]="!InfoiceFormGroup.getRawValue().TaxType || (!enableButtonAlleUeberschreiben && (allArlsAlreadyFaveTaxType$ | async) ) ||   !InfoiceFormGroup.getRawValue().AccountsReceivableLedgerIds?.length" (click)="setAllTax()">alle überschreiben</button>
                        </div>
                    </mat-expansion-panel>
                    <mat-card style="width: 100%; max-height: unset; box-sizing: border-box;" class="p-0" *ngIf="showPartialInvoiceList$ | async">
                        <mat-card-header class="card-header">
                            <fa-icon matCardAvatar [icon]="BookingPositionMetaIcon"></fa-icon>
                            <h3 mat-card-title>Rechnungsaufstellung</h3>
                            <button style="margin-left: auto" *ngIf="PS.Has$(PS.Permissions.CreateLedgerImports) | async" app-round-button (click)="partialInvoiceTable.onAddArl()" [Disabled]="DisableForms$ | async" matTooltip="Abr.-Pos. hinzufügen">
                                <app-double-icon [Icon]="'money-bill'" Icon2="plus"></app-double-icon>
                            </button>
                        </mat-card-header>
                        <div class="scroll-wrapper">
                            <app-simple-arl-table
                                #partialInvoiceTable
                                [AddARLDefaultValues]="AddARLDefaultValues$ | async"
                                [disabled]="DisableForms$ | async"
                                [arlIds]="InfoiceFormGroup.getRawValue().AccountsReceivableLedgerIdsFromLedgerImports"
                                (arlIdsChange)="InfoiceFormGroup.controls.AccountsReceivableLedgerIdsFromLedgerImports.setValue($event); InfoiceFormGroup.controls.AccountsReceivableLedgerIdsFromLedgerImports.markAsDirty()"
                                (FormValueChange)="arlTableValuesChanged$.next()"
                                showSubHeader
                                showFooter
                                showSubFooter
                            >
                                <span class="font-bold" sub-header-Information>Gesamtbetrag</span>
<!--                                <ng-container sub-header-InvoiceDate></ng-container>-->
                                <span class="font-bold" sub-header-BaseCost>{{(subTotalFromForms$ | async)?.netto}}</span>
                                <span class="font-bold" sub-header-Tax>{{(subTotalFromForms$ | async)?.tax}}</span>
                                <span class="font-bold" sub-header-Amount>{{(subTotalFromForms$ | async)?.brutto}}</span>
<!--                                <ng-container sub-header-AlreadyPayed>{{}}</ng-container>-->

                                <span footer-Information class="uppercase font-bold">Summe</span>
<!--                                <ng-container footer-InvoiceDate>{{InfoiceFormGroup.getRawValue().InvoiceDate?.toDate() | date}}</ng-container>-->
                                <ng-container footer-BaseCost>{{(totalFromForms$ | async)?.netto}}</ng-container>
                                <ng-container footer-Tax>{{(totalFromForms$ | async)?.tax}}</ng-container>
                                <ng-container footer-Amount>{{(totalFromForms$ | async)?.brutto}}</ng-container>
                                <ng-container footer-AlreadyPayed>{{(alreadyPayedSum$ | async)?.netto}}</ng-container>

                                <span sub-footer-Information class="uppercase font-bold">offene Forderung</span>
<!--                                <ng-container sub-footer-InvoiceDate></ng-container>-->
                                <span class="font-bold" sub-footer-BaseCost>{{(alreadyPayedSumDifference$ | async)?.netto}}</span>
                                <span class="font-bold" sub-footer-Tax>{{(alreadyPayedSumDifference$ | async)?.tax}}</span>
                                <span class="font-bold" sub-footer-Amount>{{(alreadyPayedSumDifference$ | async)?.brutto}}</span>
<!--                                <ng-container sub-footer-AlreadyPayed></ng-container>-->

                            </app-simple-arl-table>
<!--                            <p class="flex justify-between px-4"><strong class="uppercase">offene Forderung</strong><strong>{{alreadyPayedSumDifference$ | async}}</strong></p>-->
                        </div>
                    </mat-card>
                    <mat-expansion-panel style="width: 100%">
                        <mat-expansion-panel-header>
                            <mat-panel-title> Rabatt </mat-panel-title>
                        </mat-expansion-panel-header>
                        <app-detail-list-template [Editing]="true" [Inline]="true" [Data]="DiscountData"></app-detail-list-template>
                    </mat-expansion-panel>
                    <mat-card style="width: 100%">
                        <app-textarea [Fill]="true" Key="Text unter Buchungstabelle" [Value]="InfoiceFormGroup.controls.TextBottom" style="padding-left: 1rem; padding-top: 1rem"></app-textarea>
                    </mat-card>
                    <mat-expansion-panel style="width: 100%">
                        <mat-expansion-panel-header>
                            <mat-panel-title> Skonto </mat-panel-title>
                        </mat-expansion-panel-header>
                        <app-detail-list-template [Editing]="true" [Inline]="true" [Data]="SkontoData"></app-detail-list-template>
                    </mat-expansion-panel>
                    <mat-expansion-panel style="width: 100%">
                        <mat-expansion-panel-header>
                            <mat-panel-title> Fußnote </mat-panel-title>
                            <mat-panel-description> Steuernummer, Bankverbindung </mat-panel-description>
                        </mat-expansion-panel-header>
                        <app-detail-list-template [Inline]="true" [Editing]="true" [Data]="FooterFormData"></app-detail-list-template>
                    </mat-expansion-panel>
                </mat-card-content>
            </mat-card>
            <!--Der container in des app-editors-->
            <mat-card class="app-editor-wrapper" *ngIf="ShowDocumentPreview">
                <!--        <mat-card-header>-->
                <div>
                    <div class="mat-card-title">
                        <span class="name">
                            <span *ngIf="(LedgerImport$ | async).PartialInvoiceCounter as count">{{count}}. </span>
                            <span>{{ LedgerImportDocTypeName$ | async }}</span>
                        </span>
                        <button mat-icon-button (click)="ShowDocumentPreview = false"><fa-icon icon="times"></fa-icon></button>
                    </div>
                    <div class="mat-card-subtitle" *ngIf="SfdtIsDirty$ | async">
                        <span><fa-icon icon="exclamation-triangle"></fa-icon> Das angezeigte Dokument entspricht nicht dem Original. Es wurde eine Veränderung an den Positionen vorgenommen. Bitte prüfen.</span>
                    </div>
                </div>
                <mat-progress-bar style="margin-top: -4px" *ngIf="previewPdfIsLoading$ | async" mode="indeterminate"></mat-progress-bar>

                <iframe #previewIframe *ngIf="pdfPreview$ | async as pdf" [src]="pdf" style="height: 100%;"></iframe>
            </mat-card>
        </div>
    </ng-container>
</app-detail-view-template>
