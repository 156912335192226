import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {debounceTime, filter, map, shareReplay, switchMap, take, tap} from 'rxjs/operators';
import { PersonEntity } from '../../../dave-data-module/entities/person.entity';
import { Person2EntityEntityTypeEnum } from '../../../dave-data-module/entities/person2entity.entity';
import { PersonResolver } from '../../../dave-data-module/guards/person.resolver';
import { State } from '../../../dave-data-module/State';
import { getCustomers } from '../../../dave-data-module/State/selectors/customers.selectors';
import { getPersons } from '../../../dave-data-module/State/selectors/person.selectors';
import { getPerson2Entities } from '../../../dave-data-module/State/selectors/person2entity.selectors';
import { getPersonTypes } from '../../../dave-data-module/State/selectors/personType.selectors';
import {
    appMatDialogDefaultConfig,
    isNotNullOrUndefined,
    SearchQueriesDebounceTime,
    stringSearch,
} from '../../../helper/helper';
import { PersonModalComponent, PersonModalComponentDialogData } from '../person-modal/person-modal';
import {
    BreakpointObserverService
} from "../../../dave-utils-module/dave-shared-components-module/services/breakpoint-observer.service";

export interface SelectPersonsComponentDialogData {
    HeaderText?: string;
    EntityId?: number;
    ButtonText?: string;
    SelectedPersonIds?: number[];
    newPersonDefaultValues?: PersonModalComponentDialogData['newPersonDefaultValues']
}

interface TableData {
    Title?: string;
    Salutation?: string;
    Lastname?: string;
    Firstname?: string;
    Organisation?: string;
    Email?: string;
    PhoneNumber?: string;
    ContactPerson?: string;
    Role?: string;
    Address?: string;
    DisplayName?: string;
    Person?: PersonEntity;
}

@Component({
    selector: 'app-select-persons',
    templateUrl: './select-persons.component.html',
    styleUrls: ['./select-persons.component.scss'],
})
export class SelectPersonsComponent implements AfterViewInit, OnDestroy {
    @ViewChild('searchString') searchStringInput!: ElementRef<HTMLInputElement>;
    public static DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        maxWidth: '90%',
        width: '1700px',
        height: '1000px',
        maxHeight: 'calc(100vh - 3.5rem)',
    };

    /** Der `MatSort` der Tabelle */
    @ViewChild(MatSort) private matSort?: MatSort;

    public SelectedRowIndex: number;
    public SelectedPersons$ = new BehaviorSubject<PersonEntity[]>([]);
    // public SelectedPersons: PersonEntity[] = [];

    public PersonColumnHeaders = {
        Title: 'Titel',
        Salutation: 'Anrede',
        Role: 'Rolle',
        Lastname: 'Nachname',
        Firstname: 'Vorname',
        Organisation: 'Organisation',
        Email: 'Email',
        PhoneNumber: 'Telefonnummer',
        ContactPerson: 'Ansprechpartner',
        Address: 'Adresse',
    };
    public PersonAutoColumns = ['Role', 'Salutation', 'Lastname', 'Firstname', 'Organisation',  'Email', 'PhoneNumber', 'ContactPerson', 'Address', 'Title'];
    public Multiselect$ = new BehaviorSubject(false);
    public Mobile$ = this.bs.MobileQuery
    public PersonColumns$: Observable<Array<keyof TableData | 'checkbox'>> = this.Mobile$.pipe(
        map(mobile => mobile ? ['Lastname', 'Firstname', 'Organisation', 'PhoneNumber'] : ['Role', 'Lastname', 'Firstname', 'Title', 'Salutation', 'Organisation', 'Email', 'PhoneNumber', 'ContactPerson', 'Address']),
        switchMap((cols: Array<keyof TableData | 'checkbox'>) => this.Multiselect$.pipe(map(ms => ms ? [ 'checkbox' as 'checkbox', ...cols] : cols))),
        shareReplay({refCount: true, bufferSize: 1})
    );
    private searchString: BehaviorSubject<string> = new BehaviorSubject<string>('');
    private subscription: Subscription;
    public DataSource$: BehaviorSubject<TableVirtualScrollDataSource<TableData>> = new BehaviorSubject(new TableVirtualScrollDataSource<TableData>([]));
    private newPersonDefaultValues: PersonModalComponentDialogData['newPersonDefaultValues'];
    constructor(
        private store: Store<State>,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA)
        public Dialogdata: SelectPersonsComponentDialogData,
        protected dialogRef: MatDialogRef<SelectPersonsComponent>,
        private personResolver: PersonResolver,
        private bs: BreakpointObserverService,
    ) {
        if (Dialogdata) {
            this.newPersonDefaultValues = Dialogdata.newPersonDefaultValues;
        }
        if (Dialogdata.EntityId !== null) {
            this.store
                .select(getPersons)
                .pipe(take(1))
                .subscribe(persons => {
                    this.SelectedPersons$.next(persons.filter(p => Dialogdata.SelectedPersonIds.includes(p.Id)));
                });
        }
    }
    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }
    ngAfterViewInit(): void {
        this.searchStringInput.nativeElement.focus();
        this.subscription = combineLatest([
            this.store.select(getPersons),
            this.store.select(getPerson2Entities).pipe(
                filter(isNotNullOrUndefined),
                map(v => v.filter(p => p.EntityType === Person2EntityEntityTypeEnum.Customer)),
            ),
            this.store.select(getCustomers),
            this.store.select(getPersonTypes),
        ])
            .pipe(
                filter(v => v.every(isNotNullOrUndefined)),
                map(([persons, customerPerson2entities, customers, personTypes]) => {
                    return new TableVirtualScrollDataSource(
                        persons
                            .filter(p => !p.Deleted)
                            .map<TableData>(person => {
                                const customerId = customerPerson2entities.find(p2e => p2e.PersonId === person.Id)?.EntityId;
                                return {
                                    Title: person.Title,
                                    Salutation: person.Salutation,
                                    Lastname: person.Lastname,
                                    Firstname: person.Firstname,
                                    Organisation: person.Organisation,
                                    Email: person.Email,
                                    PhoneNumber: person.PhoneNumber,
                                    ContactPerson: customerId ? customers.find(c => c.Id === customerId)?.DisplayName : '',
                                    Role: personTypes.find(t => t.Id === person.PersonTypeId)?.Name,
                                    Address: person.City,
                                    Person: person,
                                };
                            }),
                    );
                }),

                // Der Standard-`sortingDataAccessor` kommt mit numerischen Strings - wie der
                // Vertragsnummer - nicht klar und sortiert nicht. Workaround:
                tap(
                    dataSource =>
                        (dataSource.sortingDataAccessor = (object, key) => {
                            switch (key) {
                                case 'KdNr':
                                    return `${object[key]}`.trim().toLowerCase();
                                default:
                                    return object[key];
                            }
                        }),
                ),

                // Vergleichsfunktion zum Freitext-Filtern
                tap(
                    dataSource =>
                        (dataSource.filterPredicate = (data, searchTerm) =>
                            [data.Salutation, data.Title, data.Firstname, data.Lastname, data.Organisation, data.Email, data.PhoneNumber].some(value => value && stringSearch(value, searchTerm))),
                ),
                // this.matSort sollte hier immer gesetzt sein (da ngAfterViewInit gefeuert haben muss),
                // aber das weiß TypeScript natürlich nicht.
                tap(dataSource => {
                    if (this.matSort) {
                        dataSource.sort = this.matSort;
                    } else {
                        console.warn('no matSort');
                    }
                }),

                // Sucheingaben an die DataSource weitergeben
                switchMap(dataSource =>
                    this.searchString.pipe(
                        debounceTime(SearchQueriesDebounceTime),
                        tap(searchTerm => (dataSource.filter = searchTerm.trim().toLowerCase())),
                        map(() => dataSource),
                    ),
                ),
            )
            .subscribe(v => {
                this.DataSource$.next(v);
            });
    }
    CheckBoxSelectPersons(event: MatCheckboxChange, rowData: TableData) {
        let v = this.SelectedPersons$.getValue();
        const index = v.findIndex(v => v.Id === rowData.Person.Id);
        if (event?.checked && index > -1) {
            v.splice(index, 1);
        } else {
            v.push(rowData.Person);
        }
        this.SelectedPersons$.next(v);
    }

    RowClickSelectPersons(rowData: TableData) {
        let v = this.SelectedPersons$.getValue();
        const index = v.findIndex(v => v.Id === rowData.Person.Id);
        if (index > -1) {
            v.splice(index, 1);
        } else {
            v.push(rowData.Person);
        }
        this.SelectedPersons$.next(v);
    }

    RemoveSelectedPersons(person: PersonEntity) {
        let v = this.SelectedPersons$.getValue();
        const index = v.findIndex(v => v.Id === person.Id);
        if (index > -1) {
            v.splice(index, 1);
        }
        this.SelectedPersons$.next(v);
    }

    IsSelected = (rowData: TableData) => this.SelectedPersons$.getValue().findIndex(v => v.Id === rowData.Person.Id) > -1;

    public DoSearch(value: string | null) {
        return this.searchString.next(value);
    }


    public Close() {
        this.dialogRef.close(this.SelectedPersons$.getValue());
    }

    public CreateNewPerson() {
        this.dialog
            .open<PersonModalComponent, PersonModalComponentDialogData>(PersonModalComponent, {
                ...PersonModalComponent.DefaultConfig,
                data: {
                    newPersonDefaultValues: this.newPersonDefaultValues
                },
                // panelClass: 'custom-dialog-class-without-padding-margin-top-1-75',
            })
            .afterClosed()
            .subscribe(newPerson => {
                if (newPerson) {
                    let v = this.SelectedPersons$.getValue();
                    v.push(newPerson);
                    this.SelectedPersons$.next(v);
                }
            });
    }
}
