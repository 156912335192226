import { formatDate } from '@angular/common';
import { Component, ElementRef, Inject, Input, Optional, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, firstValueFrom, of, switchMap } from 'rxjs';
import { filter, map, shareReplay, startWith, tap } from 'rxjs/operators';
import { AccountsReceivableLedgerEntity, AccountsReceivableLedgerTypeEnum } from '../../../dave-data-module/entities/accounts-receivable-ledger.entity';
import { LedgerImportDocumentTypes } from '../../../dave-data-module/entities/ledger-import.entity';
import { QuantityTypeResolver } from '../../../dave-data-module/guards/quantity-type.resolver';
import { ResourceScheduleResolver } from '../../../dave-data-module/guards/resource-dispo/resource-scheduler.resolver';
import { ResourceTypeResolver } from '../../../dave-data-module/guards/resource-dispo/resource-type.resolver';
import { ResourceResolver } from '../../../dave-data-module/guards/resource-dispo/resource.resolver';
import { getFetched$ } from '../../../dave-data-module/helper/helper';
import { ArlDataService } from '../../../dave-data-module/services/arl-data.service';
import { State } from '../../../dave-data-module/State';
import { getAccountsReceivableLedgerDictionary, getAccountsReceivableLedgersFetched, getQuantityTypeDictionary, getQuantityTypes, getQuantityTypesFetched } from '../../../dave-data-module/State/selectors/accounting.selector';
import { getLedgerImports, getLedgerImportsFetched } from '../../../dave-data-module/State/selectors/ledger-import.selector';
import { getResourceSchedules, getResourceSchedulesFetched } from '../../../dave-data-module/State/selectors/resource-dispo/resource-schedule.selectors';
import { getResourceTypeDictionary, getResourceTypesFetched } from '../../../dave-data-module/State/selectors/resource-dispo/resource-type.selectors';
import { getResourceDictionary, getResources, getResourcesFetched } from '../../../dave-data-module/State/selectors/resource-dispo/resource.selectors';
import { appMatDialogDefaultConfig, isNotNullOrUndefined } from '../../../helper/helper';
import { ResourcePageMeta } from '../../../helper/page-metadata';
import { ResourceMainDataPopupComponent, ResourceMainDataPopupComponentDialogData } from '../resource-main-data-popup/resource-main-data-popup.component';
import { SelectResourcePopupComponent, SelectResourcePopupComponentDialogData } from '../select-resource-popup/select-resource-popup.component';
import { AccountsReceivableLedgerResolver } from '../../../dave-data-module/guards/accounts-receivable-ledger.resolver';

interface ResourceScheduleData {
    ResourceName?: string;
    ResourceId?: number;
    Amount?: number;
    TimeSpan?: string;
    startDate?: Date;
    QuantityType?: string;
    Quantity?: number;
    CreatedAt?: string;
    ArlId?: number;
}

export interface ResourceScheduleListCardComponentDialogData {
    CommissionId: number;
}

@Component({
    selector: 'app-resource-schedule-list-card',
    templateUrl: './resource-schedule-list-card.component.html',
    styleUrls: ['./resource-schedule-list-card.component.scss'],
})
export class ResourceScheduleListCardComponent {
    public static DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        panelClass: 'no-padding-dialog',
        width: '55rem',
        maxHeight: '90vh',
    };

    private commissionId$ = new BehaviorSubject<number | null>(null);
    public ResourcePageMeta = ResourcePageMeta;

    @Input() set CommissionId(id: number) {
        this.commissionId$.next(id);
    }

    @Input() Expanded = true;
    @Input() Expandable = false;

    protected searchForm = new FormControl<string>('');
    protected searchVisible = false;
    @ViewChild('searchInput') searchInput: ElementRef;
    public DisplayedColumns: Array<keyof ResourceScheduleData> = ['ResourceName', 'Amount', 'QuantityType', 'TimeSpan'];
    public ResourceSchedules$ = this.commissionId$.pipe(
        filter(isNotNullOrUndefined),
        switchMap((commissionId) =>
            combineLatest([
                combineLatest([
                    getFetched$(this.store, getResourceSchedulesFetched, getResourceSchedules),
                    getFetched$(this.store, getResourcesFetched, getResourceDictionary),
                    getFetched$(this.store, getResourceTypesFetched, getResourceTypeDictionary),
                ]).pipe(
                    map(([filteredSchedules, resources, resourceTypes]) =>
                        filteredSchedules.filter((schedule) => {
                            if (schedule.CommissionId !== commissionId) {
                                return false;
                            }
                            const resource = resources[schedule.ResourceId];
                            const resourceType = resource && resourceTypes[resource.ResourceTypeId];
                            return resourceType;
                        }),
                    ),
                ),
                getFetched$(this.store, getResourcesFetched, getResourceDictionary),
                getFetched$(this.store, getQuantityTypesFetched, getQuantityTypeDictionary),
                getFetched$(this.store, getAccountsReceivableLedgersFetched, getAccountsReceivableLedgerDictionary)
            ]),
        ),
        map(
            ([resourceSchedules, resources, quantityTypes, arls]) =>
                new MatTableDataSource(
                    resourceSchedules.map<ResourceScheduleData>((r) => {
                        const resource = resources[r.ResourceId];
                        const quantityType = r.ArlId
                            ? quantityTypes[arls[r.ArlId].QuantityTypeId]?.Name
                            : resource ? quantityTypes[resource.QuantityTypeId]?.Name : '';
                        return {
                            ResourceName: resource?.Name?.trim() || '',
                            ResourceId: r.ResourceId,
                            Amount: r.Amount,
                            QuantityType: quantityType,
                            TimeSpan: formatDate(r.StartDate, 'shortDate', 'de-DE') + ' - ' + formatDate(r.EndDate, 'shortDate', 'de-DE'),
                        };
                    }),
                ),
        ),
        tap(
            (dataSource) =>
                (dataSource.sortingDataAccessor = (object, key) => {
                    switch (key) {
                        case 'TimeSpan':
                            return object.startDate ? object.startDate.getTime() : null;
                        case 'Amount':
                            return object[key] || null;
                        default:
                            return object[key] || '';
                    }
                }),
        ),
        switchMap((tableData: MatTableDataSource<ResourceScheduleData>) =>
            this.searchForm.valueChanges.pipe(
                startWith(''),
                map(() => (this.searchForm.value || '').toLowerCase()),
                map((searchTerm) => {
                    if (searchTerm) {
                        const filteredData = tableData?.data?.filter((data) => data?.ResourceName?.trim().toLowerCase().includes(searchTerm));
                        return new MatTableDataSource(filteredData);
                    } else {
                        return tableData;
                    }
                }),
            ),
        ),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) DialogData: ResourceScheduleListCardComponentDialogData,
        @Optional() protected dialogRef: MatDialogRef<ResourceScheduleListCardComponent>,
        private store: Store<State>,
        resourceResolver: ResourceResolver,
        resourceScheduleResolver: ResourceScheduleResolver,
        private dialog: MatDialog,
        quantityTypeResolver: QuantityTypeResolver,
        resourceTypeResolver: ResourceTypeResolver,
        accountsReceivableLedgerResolver: AccountsReceivableLedgerResolver,
    ) {
        firstValueFrom(this.store.select(getResourceSchedulesFetched)).then((fetched) => {
            if (!fetched) {
                resourceScheduleResolver.resolve();
            }
        });
        firstValueFrom(this.store.select(getQuantityTypesFetched)).then((fetched) => {
            if (!fetched) {
                quantityTypeResolver.resolve();
            }
        });
        firstValueFrom(this.store.select(getResourceTypesFetched)).then((fetched) => {
            if (!fetched) {
                resourceTypeResolver.resolve();
            }
        });
        firstValueFrom(this.store.select(getResourcesFetched)).then((fetched) => {
            if (!fetched) {
                resourceResolver.resolve();
            }
        });
        firstValueFrom(this.store.select(getAccountsReceivableLedgersFetched)).then((fetched) => {
            if (!fetched) {
                accountsReceivableLedgerResolver.resolve();
            }
        });
        if (DialogData) {
            this.CommissionId = DialogData.CommissionId;
        }
    }
    entryClicked(row: ResourceScheduleData) {
        this.dialog.open<ResourceMainDataPopupComponent, ResourceMainDataPopupComponentDialogData>(ResourceMainDataPopupComponent, {
            ...ResourceMainDataPopupComponent.DefaultConfig,
            data: {
                ResourceId: row ? row.ResourceId : null,
            },
        });
    }

    toggleSearch() {
        this.searchVisible = !this.searchVisible;
        this.searchForm.setValue('');
        if (this.searchVisible) {
            setTimeout(() => {
                this.searchInput.nativeElement.focus();
            }, 0);
        }
    }

    openNewPopup() {
        this.dialog.open<SelectResourcePopupComponent, SelectResourcePopupComponentDialogData>(SelectResourcePopupComponent, {
            ...SelectResourcePopupComponent.DefaultConfig,
            data: {
                CommissionId: this.commissionId$.value,
            },
        });
    }

    openDialog() {
        this.dialog.open<ResourceScheduleListCardComponent, ResourceScheduleListCardComponentDialogData>(ResourceScheduleListCardComponent, {
            ...ResourceScheduleListCardComponent.DefaultConfig,
            data: {
                CommissionId: this.commissionId$.value,
            },
        });
    }
}
