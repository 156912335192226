<table mat-table [dataSource]="_lines">
    <ng-container *ngFor="let col of autoColumns" [matColumnDef]="col">
        <th mat-header-cell *matHeaderCellDef>{{ columnHeaders[col] }}</th>
        <td mat-cell *matCellDef="let element">{{ element[col] }}</td>
    </ng-container>

    <ng-container *ngFor="let col of unitColumns" [matColumnDef]="col">
        <th mat-header-cell *matHeaderCellDef>{{ columnHeaders[col] }}</th>
        <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element[col] as unitCode">{{ unitCodeNames.get(unitCode) }}</ng-container>
        </td>
    </ng-container>
    <ng-container *ngFor="let col of dateColumns" [matColumnDef]="col">
        <th mat-header-cell *matHeaderCellDef>{{ columnHeaders[col] }}</th>
        <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element[col] as date">{{ date | date }}</ng-container>
        </td>
    </ng-container>
    <ng-container matColumnDef="ProductDescription">
        <th mat-header-cell *matHeaderCellDef>{{ columnHeaders.ProductDescription }}</th>
        <td mat-cell *matCellDef="let element">
            <span *ngIf="element.ProductDescription as text">{{ text }}</span>
            <br *ngIf="element.ProductDescription && element.Note" />
            <span *ngIf="element.Note as text" class="whitespace-pre-wrap font-normal">{{ text }}</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="Taxes">
        <th mat-header-cell *matHeaderCellDef>{{ columnHeaders.Taxes }}</th>
        <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.Taxes">
                <span *ngFor="let tax of element.Taxes; let last = last">
                    {{ tax.Percent != null ? (tax.Percent / 100 | percent) : '' }}
                    <app-abbr [title]="eInvoiceTaxCategoryCodeDescriptions.get(tax.Code)">{{ eInvoiceTaxCategoryCodeNames.get(tax.Code) || '' }}</app-abbr>
                    {{ tax.CategoryCode || '' }}
                    {{ last ? '' : ', ' }}
                </span>
            </ng-container>
        </td>
    </ng-container>

    <ng-container matColumnDef="AllowanceCharges">
        <th mat-header-cell *matHeaderCellDef>{{ columnHeaders.AllowanceCharges }}</th>
        <td mat-cell *matCellDef="let element">
            <div class="flex-center" *ngIf="element.AllowanceCharges | length">
                <span *ngFor="let allowanceCharge of element.AllowanceCharges; let last = last">
                  {{ allowanceCharge.ActualAmount >= 0 ? '+' : '' }}{{ allowanceCharge.ActualAmount | currency: currencyCode }}{{ last ? '' : ', ' }}
                </span>
                <button *ngIf="element.AllowanceCharges && element.AllowanceCharges.length" mat-icon-button (click)="openAllowanceChargesDialog(element.AllowanceCharges)"><fa-icon icon="question-circle"></fa-icon></button>
            </div>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
