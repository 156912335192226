import { FileOpenerInternalOpenFileRequest } from '@dave/types/dist/proto/fileopener/internal';
import { createActionGroup, props } from '@ngrx/store';
import { FileOpenerOpenRequestEntity } from '../../entities/file-opener-open-request.entity';
import { UploadActionProps } from '../../guards/resolver.helper';
import { GatewayResponseErrorType } from '../../services/app-gateway.service';

export const FileOpenerOpenRequestActions = createActionGroup({
    source: 'FileOpenerOpenRequest',
    events: {
        'Load Request': props<UploadActionProps>(),
        'Load Success': props<{ Payload: FileOpenerOpenRequestEntity[]; updateLatestUpdatedAt?: boolean }>(),
        'Load Failure': props<{ Payload: { Errors: GatewayResponseErrorType; ToasterMessage?: string } }>(),

        'Open File Request': props<{ Payload: FileOpenerInternalOpenFileRequest }>(),
        'Open File Success': props<{ Payload: FileOpenerOpenRequestEntity }>(),
        'Open File Failure': props<{ Payload: { Errors: GatewayResponseErrorType; ToasterMessage?: string } }>(),
    },
});
