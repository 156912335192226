import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { AllowanceCharge } from '@dave/types';
import * as eInvoiceProto from '@dave/types/dist/proto/einvoicing/main';
import { UnitCodes } from '@dave/types/dist/proto/einvoicing/main';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AbbrComponent } from '../../../../abbr/abbr.component';
import { LengthPipe } from '../../../../helper/length.pipe';
import { eInvoiceTaxCategoryCodeDescriptions, eInvoiceTaxCategoryCodeNames } from '../../../services/e-invoice-data.service';
import { EInvoiceAllowanceChargesDialogComponent, EInvoiceAllowanceChargesDialogData, EInvoiceAllowanceChargesDialogReturnData } from '../e-invoice-allowance-charges-dialog/e-invoice-allowance-charges-dialog.component';

type LineColumns =
    | 'LineId'
    | 'ProductName'
    | 'ProductDescription'
    | 'GrossAmount'
    | 'GrossUnit'
    | 'GrossUnitPrice'
    | 'NetAmount'
    | 'NetUnit'
    | 'NetUnitPrice'
    | 'BilledQuantity'
    | 'BilledUnit'
    | 'Taxes'
    | 'AllowanceCharges'
    | 'BillingPeriodFrom'
    | 'BillingPeriodTo'
    | 'Total';

@Component({
    selector: 'app-e-invoice-view-lines',
    standalone: true,
    imports: [CommonModule, MatTableModule, MatButtonModule, FontAwesomeModule, LengthPipe, AbbrComponent],
    templateUrl: './e-invoice-view-lines.component.html',
    styleUrls: ['./e-invoice-view-lines.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EInvoiceViewLinesComponent {
    protected _lines: Array<eInvoiceProto.Line>;
    protected displayedColumns: Array<LineColumns>;
    protected dateColumns: Array<LineColumns> = ['BillingPeriodFrom', 'BillingPeriodTo'];
    protected unitColumns: Array<LineColumns> = ['GrossUnit', 'NetUnit', 'BilledUnit'];
    protected autoColumns: Array<LineColumns> = ['LineId', 'ProductName', 'GrossAmount', 'GrossUnitPrice', 'NetAmount', 'NetUnitPrice', 'BilledQuantity', 'Total'];

    @Input() set lines(value: Array<eInvoiceProto.Line>) {
        this._lines = value;
        const displayedColumns: Array<LineColumns> = ['LineId', 'ProductName'];
        if (this._lines.some((l) => l.ProductDescription || l.Note)) displayedColumns.push('ProductDescription');
        if (this._lines.some((l) => l.GrossAmount)) displayedColumns.push('GrossAmount');
        if (this._lines.some((l) => l.GrossUnit)) displayedColumns.push('GrossUnit');
        if (this._lines.some((l) => l.GrossUnitPrice)) displayedColumns.push('GrossUnitPrice');
        if (this._lines.some((l) => l.NetAmount)) displayedColumns.push('NetAmount');
        if (this._lines.some((l) => l.NetUnit)) displayedColumns.push('NetUnit');
        if (this._lines.some((l) => l.NetUnitPrice)) displayedColumns.push('NetUnitPrice');
        if (this._lines.some((l) => l.BilledQuantity)) displayedColumns.push('BilledQuantity');
        if (this._lines.some((l) => l.BilledUnit)) displayedColumns.push('BilledUnit');
        if (this._lines.some((l) => l.AllowanceCharges?.length)) displayedColumns.push('AllowanceCharges');
        if (this._lines.some((l) => l.Taxes?.length)) displayedColumns.push('Taxes');
        if (this._lines.some((l) => l.BillingPeriodFrom)) displayedColumns.push('BillingPeriodFrom');
        if (this._lines.some((l) => l.BillingPeriodTo)) displayedColumns.push('BillingPeriodTo');

        displayedColumns.push('Total');
        this.displayedColumns = displayedColumns;
    }
    @Input() currencyCode: string;

    protected columnHeaders: { [key in LineColumns]: string } = {
        LineId: 'Pos.',
        ProductName: 'Name',
        ProductDescription: 'Beschreibung',
        GrossAmount: 'Betrag',
        GrossUnit: 'Einheit',
        GrossUnitPrice: 'Preis/Einheit',
        NetAmount: 'netto Betrag',
        NetUnit: 'netto Einheit',
        NetUnitPrice: 'netto Preis/Einheit',
        BilledQuantity: 'abgerechnete Menge',
        BilledUnit: 'abgerechnete Einheit',
        Taxes: 'Steuern',
        AllowanceCharges: 'Zu-/ Abschläge',
        BillingPeriodFrom: 'Leistungszeitraum von',
        BillingPeriodTo: 'Leistungszeitraum bis',
        Total: 'Summe',
    };
    constructor(private dialog: MatDialog) {}
    unitCodeNames = new Map<UnitCodes, string>([
        [UnitCodes.Piece, 'Stück'], //C62 Stk.
        [UnitCodes.Day, 'Tag(e)'], //DAY
        [UnitCodes.Hectares, 'Hektar'], //HAR ha
        [UnitCodes.Hour, 'Stunde'], //HUR Std.
        [UnitCodes.kilogram, 'Kilogramm'], //KGM kg
        [UnitCodes.kilometers, 'Kilometer'], //KTM km
        [UnitCodes.KilowattHour, 'Kilowattstunde'], //KWH kWh
        [UnitCodes.FlatRate, 'Pauschale'], //LS pausch.
        [UnitCodes.Liter, 'Liter'], //LTR l
        [UnitCodes.Minute, 'Minute'], //MIN  min
        [UnitCodes.SquareMillimeter, 'Quadratmillimeter'], //MMK mm2
        [UnitCodes.Millimeter, 'Millimeter'], //MMT mm
        [UnitCodes.SquareMeter, 'Quadratmeter'], //MTK m2
        [UnitCodes.CubicMeter, 'Kubikmeter'], //MTQ m3
        [UnitCodes.Meter, 'Meter'], //MTR m
        [UnitCodes.NumberOfArticles, 'Anzahl Artikel'], //NAR Anz.
        [UnitCodes.NumberOfPairs, 'Anzahl Paare'], //NPR Pr.
        [UnitCodes.Percent, 'Prozent'], //P1 %
        [UnitCodes.Set, 'Set(s)'], //SET
        [UnitCodes.Tonne, 'Tonne (metrisch)'], //TNE t
        [UnitCodes.Week, 'Woche(n)'], //WEE
    ]);

    openAllowanceChargesDialog(AllowanceCharges: AllowanceCharge[]) {
        this.dialog.open<EInvoiceAllowanceChargesDialogComponent, EInvoiceAllowanceChargesDialogData, EInvoiceAllowanceChargesDialogReturnData>(EInvoiceAllowanceChargesDialogComponent, {
            ...EInvoiceAllowanceChargesDialogComponent.DefaultConfig,
            data: {
                AllowanceCharges,
                CurrencyCode: this.currencyCode,
            },
        });
    }

    protected readonly eInvoiceTaxCategoryCodeNames = eInvoiceTaxCategoryCodeNames;
    protected readonly eInvoiceTaxCategoryCodeDescriptions = eInvoiceTaxCategoryCodeDescriptions;
}
