import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import * as eInvoiceProto from '@dave/types/dist/proto/einvoicing/main';
import { DueDateTypeCode, InvoiceType, PaymentType } from '@dave/types/dist/proto/einvoicing/main';
import { AbbrComponent } from '../../../abbr/abbr.component';
import { CountryPipe } from '../../../helper/country.pipe';
import { LengthPipe } from '../../../helper/length.pipe';
import { HintIconComponent } from '../../../hint-icon/hint-icon.component';
import { eInvoiceTaxCategoryCodeDescriptions, eInvoiceTaxCategoryCodeNames } from '../../services/e-invoice-data.service';
import { EInvoiceAllowanceChargeViewComponent } from './e-invoice-allowance-charge-view/e-invoice-allowance-charge-view.component';
import { EInvoiceViewLinesComponent } from './e-invoice-view-lines/e-invoice-view-lines.component';
import { ISO_IEC_17_6523 } from '../../services/mocks';

@Component({
    selector: 'app-e-invoice-view',
    standalone: true,
    imports: [CommonModule, EInvoiceViewLinesComponent, CountryPipe, LengthPipe, MatChipsModule, AbbrComponent, HintIconComponent, EInvoiceAllowanceChargeViewComponent],
    templateUrl: './e-invoice-view.component.html',
    styleUrls: ['./e-invoice-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EInvoiceViewComponent {
    @Input() EInvoice: eInvoiceProto.Invoice;
    protected eInvoiceDueDateTypeCodeNames = new Map<DueDateTypeCode, string>([
        [DueDateTypeCode.DateOfIssueOfTheInvoice, 'Ausstellungsdatum des Rechnungsbelegs'],
        [DueDateTypeCode.DeliveryDate, 'Liefertermin, Ist-Zustand'],
        [DueDateTypeCode.PaidToDate, ' Bis heute bezahlt'],
    ]);
    protected eInvoiceInvoiceTypeNames = new Map<InvoiceType, string>([
        [InvoiceType.CommercialInvoice, 'Rechnung'], // Handelsrechnung
        [InvoiceType.Gutschriftanzeige, 'Gutschriftanzeige'],
        [InvoiceType.CreditNote, 'Rechnungskorrektur'],
        [InvoiceType.SelfExhibited, 'Selbst ausgestellte Rechnung'],
        [InvoiceType.SelfBilledCredit, 'Selbst berechnete Gutschrift'],
        [InvoiceType.PrepaymentInvoice, 'Vorauszahlungsrechnung'],
        [InvoiceType.PartialInvoice, 'Teilrechnung'],
    ]);

    protected eInvoiceTaxCategoryCodeNames = eInvoiceTaxCategoryCodeNames;
    protected eInvoiceTaxCategoryCodeDescriptions = eInvoiceTaxCategoryCodeDescriptions;
    protected paymentTypeNames = new Map<PaymentType, string>([
        [PaymentType.Cash, 'Bargeld'],
        [PaymentType.Check, 'Scheck'],
        [PaymentType.Transfer, 'Überweisung'],
        [PaymentType.PaymentToBankAccount, 'Nachnahme'],
        [PaymentType.CardPayment, 'Kartenzahlung'],
        [PaymentType.DirectDebit, 'Lastschrift'],
        [PaymentType.StandingOrder, 'Dauerauftrag'],
        [PaymentType.SEPACreditTransfer, 'SEPA Credit Transfer'],
        [PaymentType.SEPADirectDebit, 'SEPA Direct Debit'],
        [PaymentType.Report, 'Verrechnet'],
    ]);
    public legalOrganizationLabel = 'Juristische Organisation';
    constructor() {}

    get_ISO_IEC_17_6523_label(value: string) {
        if (value) {
            return ISO_IEC_17_6523[value] || value;
        }
        return value;
    }
}
