<app-detail-view-template [ShowButtons]="ShownButtons" *ngIf="Customer">
    <ng-container *appDetailViewButtons>
        <app-detail-list-template
            *ngIf="ShowStatus && !Editing"
            class="dark-theme"
            [Inline]="true"
            [Editing]="true"
            [Data]="StatusData"
            style="margin: 0.5rem; min-width: 6rem"
        ></app-detail-list-template>
        <button *ngIf="!Editing" app-round-button class="header-button" Inverted (click)="ShowStatus = !ShowStatus"
                    aria-label="Status ändern" matTooltip="Status ändern">
                <fa-icon [icon]="ShowStatus ? 'chevron-right' : 'info'"></fa-icon>
        </button>
        <button
            *ngIf="!Editing && Customer && !Customer.Deleted && Customer.CanEdit && (PS.Has$(PS.Permissions.EditCustomer) | async)"
            app-round-button
            Inverted
            class="header-button"
            matTooltip="Bearbeiten" aria-label="Bearbeiten"
            (click)="OpenEdit()"
        >
            <fa-icon icon="pencil-alt"></fa-icon>
        </button>
        <button *ngIf="!Editing" app-round-button Inverted class="header-button" matTooltip="E-Mail senden" aria-label="E-Mail senden" (click)="OpenMailDialog$.next()">
            <fa-icon icon="paper-plane"></fa-icon>
        </button>
        <button *ngIf="!Editing" app-round-button Inverted class="header-button" aria-label="Schnell anlegen" matTooltip="Schnell Hinzufügen" (click)="CreateEvent()">
            <div class="icon-mutiple">
                <fa-icon icon="history" size="xs"></fa-icon>
                <fa-icon icon="plus" size="xs"></fa-icon>
            </div>
        </button>

        <button *ngIf="Editing" app-round-button [class.save-active]="Editing" class="header-button" matTooltip="Speichern" aria-label="Speichern" [Disabled]="AreFormsInvalid" (click)="Save()">
            <fa-icon icon="save"></fa-icon>
        </button>
        <button *ngIf="Editing" app-round-button Inverted class="header-button" matTooltip="Zurücksetzen" aria-label="Zurücksetzen" (click)="CloseEdit()">
            <fa-icon icon="undo"></fa-icon>
        </button>
        <button
            *ngIf="Customer && !Customer.Deleted && Customer.CanDelete && (PS.Has$(PS.Permissions.DeleteCustomer) | async) && Editing"
            app-round-button
            [class.delete-active]="Editing"
            class="header-button"
            Color="red"
            matTooltip="Löschen" aria-label="Löschen"
            (click)="DeleteCustomer()"
        >
            <fa-icon icon="trash"></fa-icon>
        </button>
    </ng-container>

    <ng-container *appDetailViewLeftContent>
        <app-profile-template
            *ngIf="ProfileData"
            [Data]="ProfileData"
            [Editing]="Editing"
            [EditedImage]="EditedCustomerImage"
            (Submit)="Save()"
        >
            <div *ngIf="Customer && !Editing">
                <app-rating
                    class="rating"
                    [Clickable]="Customer.CanEdit && (PS.Has$(PS.Permissions.EditCustomer) | async)"
                    [OwnRating]="Customer.UserRating"
                    [Rating]="Customer.Rating"
                    [RatingCount]="Customer.RatingCount"
                    (OwnRatingChange)="RateCustomer($event)"
                ></app-rating>

                <div class="links">
                    <!-- history-button-wrapper für Theming -->
                    <div class="history-button-wrapper link-wrapper" *ngIf="PS.Has$(PS.Permissions.GetEvents) | async">
                        <a app-button Lighter class="wide-button" matTooltip="Chronik" [routerLink]="['/', ChronikPath, Customer.Id]">
                            <fa-icon [icon]="ChronikIcon | icon"></fa-icon>
                        </a>
                    </div>

                    <!-- admin-button-wrapper für Theming -->
                    <div class="commission-button-wrapper link-wrapper" *ngIf="PS.Has$(PS.Permissions.GetCommission) | async">
                        <a app-button Lighter class="wide-button" [matTooltip]="cls.getMultiple$('Commission') | async" [routerLink]="['/', CommissionPath, Customer.Id]">
                            <fa-icon [icon]="CommissionIcon"></fa-icon>
                        </a>
                    </div>
                    <div class="dms-button-wrapper link-wrapper" *ngIf="PS.Has$(PS.Permissions.GetFiles) | async">
                        <a app-button Lighter class="wide-button" matTooltip="DMS" [routerLink]="['/', DMSPath]" [queryParams]="{ folderId: FolderId }">
                            <fa-icon [icon]="DMSIcon"></fa-icon>
                        </a>
                    </div>
                </div>
            </div>
        </app-profile-template>
        <app-file-explorer
            PreventFileClick
            PreventFileUploadClick
            (FilterOpen$)="ShownButtons = !$event"
            [SelectedFolderId]="FolderId"
            [FileUploadButton]="Customer && !Customer.Deleted && Customer.CanEdit && (PS.Has$(PS.Permissions.EditCustomer) | async)"
            (FileUploadClicked)="UploadFolderId = $event; PreviewOpen = false; OpenUploadDialog()"
            (FileClicked)="PreviewedFileId$.next($event); PreviewOpen = true"
            (fileExplorerContext) = "ImageIds = $event"
            class="detail-card mat-card"
            style="height: 40rem; padding: 0;"
        >
        </app-file-explorer>
    </ng-container>
    <ng-container *appDetailViewRightContent>
        <app-detail-commission-event-list class="detail-card"
            *ngIf="!Editing && (PS.Has$(PS.Permissions.GetServiceHistory) | async)"
            [View]="{expanded: true, type: '', order: 0}"
            [CustomerId]="CustomerId$ | async"
        ></app-detail-commission-event-list>
        <app-comment-list class="detail-card" style="max-height: 90vh;"
            *ngIf="!!Customer"
            [HideDropzone]="true"
            [CanDelete]="IamAssignee$ | async"
            [ViewConfig]="commentView"
            [EntityId]="Customer.Id"
            [EntityType]="CommentEntityTypeEnum.Customer"
            [InlineSendMethod]="true"
            [FileUploadParams]="CommentFileUploadParams$ | async"
        ></app-comment-list>
        <mat-card *ngIf="Customer.CustomerTypeIds.length  || Editing" class="detail-card">
            <p *ngIf="!Editing && Customer.CustomerTypeIds.length > 0">
                <mat-chip-list>
                    <fa-icon *ngIf="!Editing" icon="briefcase" style="margin-left: 0.25rem"></fa-icon>
                    <mat-chip *ngFor="let Item of CustomerTypesForm.value">
                        {{ Item.Name }}
                    </mat-chip>
                </mat-chip-list>
            </p>

            <app-customer-type-chip-autocomplete *ngIf="Editing" [FormControl]="CustomerTypesForm"></app-customer-type-chip-autocomplete>
        </mat-card>
        <app-commission-busines-volume-list
            class="detail-card"
            *ngIf="!Editing && (PS.Has$(PS.Permissions.GetBusinessVolume) | async)"
            [Expandable]="true"
            [Expanded]="true"
            [CustomerId]="Customer?.Id"
            [Columns]="['businessVolumeNo', 'amount', 'createdAt']"
            [tableLayoutFixed]="true"
        >
        </app-commission-busines-volume-list>
        <ng-container *ngIf="!Editing && (ShowLedgerImport$ | async)">
            <app-ledger-import-list-card [NavigateOnClick]="true" class="detail-card" style="display: block" *ngIf="ledgerImports$ | async as lis; else loadingTemplate" [LedgerImports]="lis" (NewLedgerImportClick)="onCreateLedgerImportClick($event)" (DocumentClick)="PreviewedFileId$.next($event); PreviewOpen = true;"></app-ledger-import-list-card>
        </ng-container>

<!--    Auskommentiert, da kaputt D277-1737-->
<!--        <mat-card class="card detail-card" *ngIf="IsGericht && !Editing">-->
<!--            <div class="card-header">-->
<!--                <fa-icon class="icon" icon="users"></fa-icon>-->

<!--                <h3>Vorsitzende</h3>-->
<!--                &lt;!&ndash; ToDo 'a' statt 'button', wenn event.stopImmediatePropagation() tut &ndash;&gt;-->
<!--                <button app-round-button matTooltipPosition="left" matTooltip="Hinzufügen" (click)="OpenPersonDialog(ComplaintTypesEnum.Vorsitzender)">-->
<!--                    <app-double-icon Icon="user" Icon2="plus"></app-double-icon>-->
<!--                </button>-->
<!--            </div>-->
<!--            &lt;!&ndash; "clickable"-Klasse ist nur für den inset box shadow &ndash;&gt;-->
<!--            <ngx-simplebar class="clickable-list-container">-->
<!--                <table mat-table [dataSource]="ShownJudgeList">-->
<!--                    <ng-container matColumnDef="name">-->
<!--                        <td mat-cell *matCellDef="let rowData">-->
<!--                            {{ rowData.Title }} {{ rowData.DisplayName || 'Platzhalter' }}-->
<!--                            {{ rowData.Email }}-->
<!--                            <ng-container *ngIf="rowData.Deleted">-->
<!--                                <i-->
<!--                                    >gelöscht-->
<!--                                    <fa-icon icon="user-slash"></fa-icon>-->
<!--                                </i>-->
<!--                            </ng-container>-->
<!--                        </td>-->
<!--                    </ng-container>-->
<!--                    <tr mat-row *matRowDef="let rowData; columns: ['name']" [routerLink]="['person', rowData.Id]" routerLinkActive="active"></tr>-->
<!--                </table>-->
<!--                <table *ngIf="!(ShownJudgeList?.length > 0)" style="width: 100%">-->
<!--                    <tr>-->
<!--                        <td class="empty-table-text">-->
<!--                            Keine Vorsitzende vorhanden-->
<!--                        </td>-->
<!--                    </tr>-->
<!--                </table>-->
<!--            </ngx-simplebar>-->
<!--        </mat-card>-->
        <app-dave-list-card
            class="detail-card"
            [Headline]="Customer.AnsprechpartnerText()"
            [HeaderIcon]="ContactBookMeta.Icon"
            [EmptyText]="'Keine ' + Customer.AnsprechpartnerText() + 'vorhanden'"
            [Data]="ShownPersonList$ | async"
            [DisplayedCollums]="['custom']"
            [Clickable]="true"
            [GetRouterLinkFunction]="GetRouteForPerson"
            [CustomCellTemplates]="{ custom: personListEntry }"
            >

            <button
                headerButton
                app-round-button
                matTooltipPosition="left"
                (click)="OpenPersonDialog()"
                matTooltip="Hinzufügen"
                [Disabled]="Customer.Deleted || !(Customer.CanEdit && (PS.Has$(PS.Permissions.EditCustomer) | async))"
            >
                <app-double-icon Icon="user" Icon2="plus"></app-double-icon>
            </button>
        </app-dave-list-card>
        <app-detail-list-template
            class="detail-card"
            style="margin: 0rem; padding-left: 0rem; padding-right: 0rem; padding-bottom: 0rem;"
            [Editing]="Editing"
            [Data]="BankData"
            (Submit)="Save()"
            *ngIf="
                Headquarter &&
                (Headquarter.IBAN ||
                    Headquarter.Swift ||
                    Headquarter.BankName ||
                    Headquarter.BankLocation ||
                    Headquarter.BankPostalCode ||
                    Headquarter.BankSortCode ||
                    Customer.AutomaticAccountsReceivableLedger == 1 ||
                    Editing)
            "
        ></app-detail-list-template>


        <mat-card class="card detail-card right" *ngIf="Customer && !Editing">
            <div class="card-header" #cardHeader>
                <fa-icon class="icon" icon="users"></fa-icon>

                <h3>Bearbeiter</h3>

                <button
                    app-round-button
                    matTooltip="Auswählen"
                    matTooltipPosition="left"
                    [Disabled]="Customer.Deleted || !(Customer.CanEdit && (PS.Has$(PS.Permissions.EditCustomer) | async))"
                    (click)="OpenUserSelect()"
                >
                    <fa-icon icon="user-cog"></fa-icon>
                </button>
            </div>
            <!-- CSS-Klasse ist nur für den inset box shadow -->
            <ngx-simplebar class="clickable-list-container">
                <table mat-table [dataSource]="DisplayedUsers$">
                    <ng-container matColumnDef="image">
                        <td mat-cell *matCellDef="let rowData">
                            <!--                            <img-->
                            <!--                                mat-list-avatar-->
                            <!--                                [src]="src"-->
                            <!--                                *ngIf="rowData.User.ImageId | image | async as src; else noImage"-->
                            <!--                            />-->

                            <!--                            <ng-template #noImage>-->
                            <!--                                <fa-icon mat-list-avatar icon="user" size="2x"></fa-icon>-->
                            <!--                            </ng-template>-->
                            <fa-icon mat-list-avatar icon="user" size="2x"></fa-icon>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <!-- Assigned User: Name, JobSpecification, Email, Phonenumber -->
                        <td mat-cell *matCellDef="let rowData">
                            {{ rowData.User.DisplayName + (rowData.User.JobSpecification || rowData.User.Phonenumber || rowData.User.Email ? ', ' : '') }}

                            <span style="font-weight: lighter">
                                {{ rowData.User.JobSpecification ? rowData.User.JobSpecification : '' }}
                                <br />
                                {{ (rowData.User.Email ? rowData.User.Email : '') + (rowData.User.Phonenumber ? ', Tel: ' + rowData.User.Phonenumber : '') }}
                            </span>
                            <ng-container *ngIf="rowData.User.Deleted">
                                <i
                                    >gelöscht
                                    <fa-icon icon="user-slash"></fa-icon>
                                </i>
                            </ng-container>
                        </td>
                    </ng-container>
                    <tr
                        mat-row
                        *matRowDef="let rowData; columns: ['image', 'name']"
                        [class.is-highlighted]="rowData.ItsMe"
                        [routerLink]="['/personal_und_urlaub', rowData.User.Id]"
                        routerLinkActive="active"
                    ></tr>
                </table>

                <table *ngIf="!((DisplayedUsers$ | async)?.length > 0)" style="width: 100%">
                    <tr>
                        <td class="empty-table-text">
                            Keine Mitarbeiter vorhanden
                        </td>
                    </tr>
                </table>
            </ngx-simplebar>
        </mat-card>
    </ng-container>
</app-detail-view-template>
<mat-card *ngIf="Customer && PreviewOpen" class="new-document-overlay">
    <button Inverted matTooltip="Schließen" app-round-button class="back-button" (click)="PreviewOpen = false">
        <fa-icon icon="chevron-right"></fa-icon>
    </button>
    <app-preview *ngIf="PreviewedFileId$ | async as id" [EmailEditorDialogData]="{CustomerId: (CustomerId$ | async)}" [FileId$]="PreviewedFileId$" [fileExplorerContext]="ImageIds"  (ClosePreview)="PreviewOpen = false" class="preview"> </app-preview>
</mat-card>
<ng-template #personListEntry let-data="data">
    <app-person-list-entry [Person]="data"></app-person-list-entry>
</ng-template>

<ng-template #loadingTemplate>
    <app-loading></app-loading>
</ng-template>

<ng-template #SachkontochartOfAccountTemp >
    <div class="selectChartOfAccounts">
        <mat-form-field>
            <app-select-search
                [placeholder]="'Sachkonto'"
                [formControl]="CustomerForm.controls.Sachkonto"
                [Options]="SachkontoChartOfAccounts"
                [OptionTemplate]="gegenkontoOptionTemp"
                [CompareOptions]="CompareSelectSearchChartOfAccountsOptions"
                [SearchFunction]="SelectSearchChartOfAccountsOptionsFunction"
            >
            </app-select-search>
        </mat-form-field>
        <button app-round-button matTooltip="neues Konto anlegen" (click)="$event.preventDefault() ;newChartOfAccountPopUp(null, AccountTypeEnum.Contra)"><fa-icon icon="plus"></fa-icon></button>
    </div>
</ng-template>
<ng-template #KontochartOfAccountTemp >
    <div class="selectChartOfAccounts">
        <mat-form-field>
            <app-select-search
                [placeholder]="'Konto'"
                [formControl]="CustomerForm.controls.Konto"
                [Options]="KontoChartOfAccounts"
                [OptionTemplate]="gegenkontoOptionTemp"
                [CompareOptions]="CompareSelectSearchChartOfAccountsOptions"
                [SearchFunction]="SelectSearchChartOfAccountsOptionsFunction"
            >
            </app-select-search>
        </mat-form-field>
        <button app-round-button matTooltip="neues Konto anlegen" (click)="$event.preventDefault() ;newChartOfAccountPopUp( null, AccountTypeEnum.Normal)"><fa-icon icon="plus"></fa-icon></button>
    </div>
</ng-template>
<ng-template #gegenkontoOptionTemp let-option>
    <app-edit-gegenkonto [ChartOfAccount]="option" (editEvent)="newChartOfAccountPopUp($event)" ></app-edit-gegenkonto>
</ng-template>
