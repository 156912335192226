<div
    class="wrapper"
    *ngIf="NewSwimLanes as swimLanes"
    cdkDropListGroup
>
    <ng-container *ngFor="let lane of swimLanes; let i = index; trackBy: TrackLanes">
        <button
            *ngIf="lane.Headline === 'Erledigt'"
            (click)="toggleCompleted()"
            class="collapsedButton"
            [ngClass]="{ 'left-position': !isCompletedCollapsed }"
        >
            <fa-icon
                [icon]="isCompletedCollapsed ? 'chevron-left' : 'chevron-right'"
                [matTooltip]="isCompletedCollapsed ? 'Erledigt ausklappen' : 'Erledigt einklappen'"
                class="toggle-icon"
            ></fa-icon>

        </button>
        <div
            class="swimLane"
            *ngIf="!isCompletedCollapsed || lane.Headline !== 'Erledigt'"
            [class.collapsed]="isCompletedCollapsed && lane.Headline === 'Erledigt'"
        >
            <h4>
                <ng-container *ngIf="!(isCompletedCollapsed && lane.Headline === 'Erledigt')">
                    {{ lane.Headline }}
                </ng-container>
            </h4>
            <ng-container *ngIf="!(isCompletedCollapsed && lane.Headline === 'Erledigt')">
                <button
                    style="width: 100%"
                    (click)="OpenAddTaskDialog()"
                    mat-raised-button
                    color="primary"
                    *ngIf="ShowNewButton && i === 0"
                >
                    <div style="width: 100%; display: flex">
                        neue Aufgabe <fa-icon icon="plus" style="margin-left: auto"></fa-icon>
                    </div>
                </button>
                <div
                    class="inner-wrapper"
                    cdkDropList
                    cdkDropListSortingDisabled
                    [cdkDropListDisabled]="DisableDragNDrop$ | async"
                    [cdkDropListData]="lane.Id"
                    (cdkDropListDropped)="Drop($event)"
                >
                    <p class="no-items">keine Aufgaben vorhanden</p>
                    <app-event-card
                        [id]="taskId"
                        [cdkDragData]="taskId"
                        cdkDrag
                        (FileClicked)="FileClicked.emit($event)"
                        style="margin-top: 1rem; display: block;"
                        *ngFor="let taskId of lane.eventIds$ | async"
                        [EventId]="taskId"
                    ></app-event-card>
                </div>
            </ng-container>


        </div>
    </ng-container>

</div>
