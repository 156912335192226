import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { BusinessVolumeEntity } from '../../entities/business-volume.entity';
import { getLatestUpdatedAt } from '../../helper/reducer.helper';
import { BusinessVolumeActionTypes } from '../actions/business-volume.actions';

export interface BusinessVolumeState extends EntityState<BusinessVolumeEntity> {
    fetched: boolean;
    latestUpdatedAt: Date | null;
}

export const BUSINESS_VOLUME_KEY = 'businessVolume';

const adapter = createEntityAdapter<BusinessVolumeEntity>({
    selectId: (Entity) => Entity.Id,
});

const initialState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
});

/* ############# */
/* ## REDUCER ## */
/* ############# */

export const businessVolumeReducer = createReducer(
    initialState,
    on(BusinessVolumeActionTypes.UpdateAll, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setAll(Payload, { ...state, fetched: true, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
    on(BusinessVolumeActionTypes.UpdateMany, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setMany(Payload, { ...state, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
    on(BusinessVolumeActionTypes.UpdateOne, (state, { Payload }) =>
        adapter.setOne(Payload, { ...state }),
    ),
    on(BusinessVolumeActionTypes.RemoveOne, (state, { Payload }) =>
        adapter.removeOne(Payload, { ...state }),
    ),
);

/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllBusinessVolume = selectAll;
export const selectBusinessVolumeEntities = selectEntities;

export const selectBusinessVolumeFetched = (state: BusinessVolumeState) => state.fetched;
export const selectBusinessVolumeLatestUpdatedAt = (state: BusinessVolumeState) => state.latestUpdatedAt;
