import { CommonModule } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, firstValueFrom, Subscription, switchMap } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, tap } from 'rxjs/operators';
import {
    getAutomaticAuftragsnummerSubscription,
    getAutomaticInterneNummerSubscription,
    ICommissionDetailListTemplateEntrys,
    ICommissionForm,
    setCommissionDetailListTemplateEntries,
} from '../dave-commission-module/components/detail-commission/helper';
import { CommissionEntity } from '../dave-data-module/entities/commission.entity';
import { PartnerTypeEnum } from '../dave-data-module/entities/partner.entity';
import { DaveMutationCreateCommissionArgs } from '../dave-data-module/graphql-types';
import { CommissionResolver } from '../dave-data-module/guards/commission.resolver';
import { CommissionTypeResolver } from '../dave-data-module/guards/commissionType.resolver';
import { StatusFromBackofficeResolver } from '../dave-data-module/guards/statusFromBackoffice.resolver';
import { State } from '../dave-data-module/State';
import { BaseActionTypes } from '../dave-data-module/State/actions/base.actions';
import { CommissionActionTypes } from '../dave-data-module/State/actions/commission.actions';
import {
    getCommissionDictionary,
    getCommissionsActive,
    getCommissionsFetched,
} from '../dave-data-module/State/selectors/commission.selector';
import { getCommissionTypeDictionary, getCommissionTypes, getCommissionTypesFetched } from '../dave-data-module/State/selectors/commissionType.selectors';
import { getPartner } from '../dave-data-module/State/selectors/partners.selectors';
import { getStatusFromBackoffice, getStatusFromBackofficeFetched } from '../dave-data-module/State/selectors/statusFromBackoffice.selectors';
import { DaveLoadingModule } from '../dave-loading/dave-loading.module';
import { AppButtonModule } from '../dave-utils-module/app-button-module/app-button.module';
import { DetailListTemplateComponent, IDetailListTemplateData } from '../dave-utils-module/dave-shared-components-module/components/detail-views/detail-list-template/detail-list-template.component';
import { DaveSharedComponentsModule } from '../dave-utils-module/dave-shared-components-module/dave-shared-components.module';
import { SelectSearchData } from '../dave-utils-module/select-search/components/select-search-legacy/select-search-legacy.component';
import { SelectSearchModule } from '../dave-utils-module/select-search/select-search.module';
import { isNotNullOrUndefined } from '../helper/helper';
import { LengthPipe } from '../helper/length.pipe';
import { AllCommissionMeta, CommissionMeta } from '../helper/page-metadata';
import { CustomLabelService } from '../services/custom-label.service';
import { LoadingService } from '../services/loading.service';

export interface NewCommissionDialogComponentDialogReturnData {
    Commission?: CommissionEntity;
}
export interface NewCommissionDialogComponentDialogData {
    CreateCommissionPayload?: Partial<DaveMutationCreateCommissionArgs>;
    NavigateRelativeTo?: ActivatedRoute;
    PreventRouting?: boolean;
    ForceCommissionTypeId?: number;
}
@Component({
    selector: 'app-new-commission-dialog',
    standalone: true,
    imports: [CommonModule, MatDialogModule, AppButtonModule, DaveSharedComponentsModule, DaveLoadingModule, MatSlideToggleModule, ReactiveFormsModule, SelectSearchModule, MatInputModule, LengthPipe],
    templateUrl: './new-commission-dialog.component.html',
    styleUrls: ['./new-commission-dialog.component.scss'],
})
export class NewCommissionDialogComponent implements OnInit, OnDestroy {
    @ViewChild('listTemplate') listTemplate: DetailListTemplateComponent;
    Form: FormGroup<
        Partial<ICommissionForm> & {
            CommissionTypeId: FormControl<SelectSearchData | null>;
            AutomaticAuftragsnummer: FormControl<boolean | null>;
            Auftragsnummer: FormControl<string | null>;
            InterneNummer: FormControl<string | null>;
            AutomaticInterneNummer: FormControl<boolean | null>;
            // addResourcesFromLedgerImport: FormControl<boolean>;
        }
    > = new FormGroup({
        CommissionTypeId: new FormControl<SelectSearchData | null>(null, Validators.required),
        Auftragsnummer: new FormControl<string | null>(null),
        AutomaticAuftragsnummer: new FormControl<boolean>(true),
        InterneNummer: new FormControl<string | null>(null),
        AutomaticInterneNummer: new FormControl<boolean>(true),
        // addResourcesFromLedgerImport: new FormControl<boolean>(false),
    });
    protected addResourcesFromLedgerImportForm = new FormControl<boolean>(false);
    Data: IDetailListTemplateData;
    protected showAddResourcesFromLedgerImport = false;
    public subscriptions: Subscription[] = [];
    public parentCommissionForm = new FormControl<CommissionEntity>(null);
    protected parentCommissionTypes$ = this.store.select(getCommissionTypes).pipe(
        map((commissionTypes) => commissionTypes.filter((c) => c.AdditionalData?.CanHaveChildCommissions)),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    protected parentCommissions$ = combineLatest([
        combineLatest([this.store.select(getCommissionsFetched), this.store.select(getStatusFromBackofficeFetched)]).pipe(
            map((v) => v.every((f) => f)),
            distinctUntilChanged(),
            filter((f) => f),
            switchMap(() => this.store.select(getCommissionsActive)),
        ),
        this.parentCommissionTypes$,
    ]).pipe(map(([commissions, parentTypes]) => (parentTypes.length ? commissions.filter((c) => parentTypes.some((pt) => pt.Id === c.CommissionTypeId)) : [])));
    protected parentCommissionLabel$ = combineLatest([this.parentCommissionTypes$, this.cls.getSingle$('Commission')]).pipe(
        map(([commissionsWithChildren, commissionLabel]) => {
            if (commissionsWithChildren.length === 1) {
                return commissionsWithChildren[0].Name;
            } else {
                return commissionLabel;
            }
        }),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    @Input() forceParentId: number;
    @Input() forcePersonId: number;
    constructor(
        @Inject(MAT_DIALOG_DATA) protected dialogData: NewCommissionDialogComponentDialogData,
        private store: Store<State>,
        protected ls: LoadingService,
        private actions$: Actions,
        private dialogRef: MatDialogRef<NewCommissionDialogComponent, NewCommissionDialogComponentDialogReturnData>,
        private router: Router,
        private statusFromBoRes: StatusFromBackofficeResolver,
        private commissionTyperRes: CommissionTypeResolver,
        private commissionResolver: CommissionResolver,
        protected cls: CustomLabelService,
        // private templateRef: TemplateRef<any>,
        private elementRef: ElementRef<NewCommissionDialogComponent>,
    ) {
        if (dialogData) {
            this.showAddResourcesFromLedgerImport = !!dialogData.CreateCommissionPayload?.addResourcesFromLedgerImportId;
            if (dialogData.CreateCommissionPayload?.parentId) {
                this.forceParentId = dialogData.CreateCommissionPayload.parentId
            }
            if (dialogData.CreateCommissionPayload?.personId) {
                this.forcePersonId = dialogData.CreateCommissionPayload.personId
            }
            if (this.showAddResourcesFromLedgerImport) {
                this.addResourcesFromLedgerImportForm.setValue(true);
            }
            if (dialogData.ForceCommissionTypeId) {
                this.Form.controls.CommissionTypeId.setValue({ Id: dialogData.ForceCommissionTypeId, Name: '' });
                this.Form.controls.CommissionTypeId.disable();
            }
        }
    }
    CompareById = (a: { Id: number }, b: { Id: number }) => a.Id === b.Id;

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    ngOnInit(): void {

        firstValueFrom(
            combineLatest([
                this.store.select(getStatusFromBackofficeFetched).pipe(
                    tap((f) => {
                        if (!f) {
                            this.statusFromBoRes.resolve();
                        }
                    }),
                    filter((f) => f),
                ),
                this.store.select(getCommissionTypesFetched).pipe(
                    tap((f) => {
                        if (!f) {
                            this.commissionTyperRes.resolve();
                        }
                    }),
                    filter((f) => f),
                    switchMap(() => this.store.select(getCommissionTypes)),
                ),
                this.store.select(getPartner),
                this.cls.getSingle$('Commission'),
            ]),
        ).then(([statusesFetched, commissionTypes, partner, commissionLabel]) => {
            const obj: ICommissionDetailListTemplateEntrys = {};
            setCommissionDetailListTemplateEntries(
                new CommissionEntity(),
                obj,
                ['CommissionType', 'CommissonNumber', 'CommissonNumberAuto', 'InterneNummer', 'InterneNummerAuto'],
                this.Form as FormGroup<Partial<ICommissionForm>>,
                [],
                {
                    commissionTypes,
                    partner,
                },
                commissionLabel,
            );
            this.Data = {
                Properties: Object.values(obj).filter(isNotNullOrUndefined),
            };
            setTimeout(() => {
                this.dialogRef.afterOpened().subscribe(() => {
                    (document.querySelector('app-new-commission-dialog mat-select') as HTMLElement)?.click();
                });
            }, 1);
            this.subscriptions.push(getAutomaticAuftragsnummerSubscription(this.Form.controls), getAutomaticInterneNummerSubscription(this.Form.controls));
            this.Form.controls.AutomaticAuftragsnummer.setValue(this.Form.value.AutomaticAuftragsnummer);
            this.Form.controls.AutomaticInterneNummer.setValue(this.Form.value.AutomaticInterneNummer);
        });
    }

    Submit() {
        if (this.Form.valid) {
            this.ls.startLoading('add-commission');
            firstValueFrom(combineLatest([this.store.select(getStatusFromBackoffice), this.store.select(getPartner), this.store.select(getCommissionDictionary)]))
                .then(([status, partner, commissions]) => {
                    const statusBackofficeId = status.find((s) => s.IsDefault)?.Id;
                    const parentId = this.forceParentId || this.parentCommissionForm.value?.Id;

                    let description = '';

                    if (partner.PartnerTypeId === PartnerTypeEnum.TSB) {
                        description = parentId ? commissions[parentId]?.Description : '';
                    }


                    this.store.dispatch(
                        CommissionActionTypes.AddCommission({
                            Payload: {
                                commissionTypeId: this.Form.getRawValue().CommissionTypeId.Id,
                                description,
                                auftragsnummer: this.Form.value.Auftragsnummer,
                                automaticAuftragsnummer: this.Form.value.AutomaticAuftragsnummer,
                                interneNummer: this.Form.value.InterneNummer,
                                automaticInterneNummer: this.Form.value.AutomaticInterneNummer,
                                additionalData: JSON.stringify({
                                    wizardPage: 1,
                                }),
                                statusBackofficeId,

                                ...this.dialogData?.CreateCommissionPayload,
                                addResourcesFromLedgerImportId: this.addResourcesFromLedgerImportForm.value ? this.dialogData?.CreateCommissionPayload.addResourcesFromLedgerImportId : undefined,
                                parentId,
                                personId: this.forcePersonId
                            },
                        }),
                    );
                    return firstValueFrom(this.actions$.pipe(ofType(CommissionActionTypes.UpdateOne, BaseActionTypes.ErrorAction)));
                })
                .then((action) => {
                    this.ls.endLoading('add-commission');
                    if (this.dialogData?.CreateCommissionPayload.addResourcesFromLedgerImportId) {
                        // this.store.dispatch()
                    }
                    if (action.type === CommissionActionTypes.UpdateOne.type) {
                        if (!this.dialogData?.PreventRouting) {
                            if (this.dialogData?.NavigateRelativeTo) {
                                this.router.navigate([action.Payload.Id], { relativeTo: this.dialogData.NavigateRelativeTo });
                            } else {
                                this.router.navigate(['/', CommissionMeta.Path, AllCommissionMeta.Path, action.Payload.Id]);
                            }
                        }
                        this.dialogRef?.close({ Commission: action.Payload });
                    }
                });
        }
    }
}
