import { Pipe, PipeTransform } from '@angular/core';
import { iso3166Alpha2 } from './country-codes';

@Pipe({
    name: 'country',
    pure: true,
    standalone: true,
})
export class CountryPipe implements PipeTransform {
    transform(countryId: string, locale: 'de-DE' = 'de-DE'): string | null {
        if (countryId?.length !== 2) {
            console.error('invalid countryId: ' + countryId);
            return null;
        }
        const label = iso3166Alpha2[countryId.toUpperCase()];
        if (!label) {
            console.error('countryId not found: ' + countryId);
            return null;
        }
        return label;
    }
}
