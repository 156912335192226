import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, ResolveData } from '@angular/router';
import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { isArray } from 'chart.js/helpers';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, skip, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { CommissionEntity } from '../../../dave-data-module/entities/commission.entity';
import { EntityTypesEnum } from '../../../dave-data-module/entities/entity-role.entity';
import { PartnerSpecificationType, PartnerTypeEnum } from '../../../dave-data-module/entities/partner.entity';
import { UserToCommissionEntity } from '../../../dave-data-module/entities/user-to-commission.entity';
import { UserEntity } from '../../../dave-data-module/entities/user.entity';
import { CommissionResolver } from '../../../dave-data-module/guards/commission.resolver';
import { CommissionTypeResolver } from '../../../dave-data-module/guards/commissionType.resolver';
import { CustomerResolver } from '../../../dave-data-module/guards/customer.resolver';
import { EntityRoleResolver } from '../../../dave-data-module/guards/entity-role.resolver';
import { OfficeResolver } from '../../../dave-data-module/guards/office.resolver';
import { PartnerOfficeResolver } from '../../../dave-data-module/guards/partner-office.resolver';
import { StatusFromBackofficeResolver } from '../../../dave-data-module/guards/statusFromBackoffice.resolver';
import { TokenResolver } from '../../../dave-data-module/guards/token.resolver';
import { UserToCommissionResolver } from '../../../dave-data-module/guards/user-to-commission.resolver';
import { UserResolver } from '../../../dave-data-module/guards/user.resolver';
import { getFetched$ } from '../../../dave-data-module/helper/helper';
import { State } from '../../../dave-data-module/State';
import { ChangeSettings } from '../../../dave-data-module/State/actions/settings.actions';
import { commissionsFeatureKey } from '../../../dave-data-module/State/reducers/commission.reducer';
import { CommissionTypesFeatureKey } from '../../../dave-data-module/State/reducers/commissionType.reducer';
import { customersFeatureKey } from '../../../dave-data-module/State/reducers/customers.reducer';
import { ENTITY_ROLE_KEY } from '../../../dave-data-module/State/reducers/entity-role.reducer';
import { officeFeatureKey } from '../../../dave-data-module/State/reducers/office.reducer';
import { StatusFromBackofficeFeatureKey } from '../../../dave-data-module/State/reducers/statusFromBackoffice.reducer';
import { USER_TO_COMMISSION_KEY } from '../../../dave-data-module/State/reducers/user-to-commission.reducer';
import { usersFeatureKey } from '../../../dave-data-module/State/reducers/users.reducer';
import { getCommissionSorted } from '../../../dave-data-module/State/selectors/commission.selector';
import { getCommissionTypeDictionary, getCommissionTypes, getCommissionTypesFetched } from '../../../dave-data-module/State/selectors/commissionType.selectors';
import { getCustomerDictionary } from '../../../dave-data-module/State/selectors/customers.selectors';
import { getEmployeesActive } from '../../../dave-data-module/State/selectors/employees.selectors';
import { getEntityRole, getEntityRoleFetched } from '../../../dave-data-module/State/selectors/entity-role.selector';
import {
    getOwnPartnerOffices,
    getPartner,
    getPartnerFetched,
} from '../../../dave-data-module/State/selectors/partners.selectors';
import { getStatusFromBackoffice } from '../../../dave-data-module/State/selectors/statusFromBackoffice.selectors';
import { getUserToCommissionCommissionIdsMap, getUserToCommissionDictionary, getUserToCommissionFetched } from '../../../dave-data-module/State/selectors/user-to-commission.selector';
import {
    getSetting,
    getSettingFetched,
    getUser,
    getUserDictionary,
} from '../../../dave-data-module/State/selectors/users.selectors';
import { DaveListTableData } from '../../../dave-list/components/dave-list/dave-list.component';
import { PieceworkDemoComponent } from '../../../dave-piecework-calculation/components/piecework-demo/piecework-demo.component';
import { FilterOption, FILTER_TYPE_SEARCH_MULTI_SELECT, IFilterTypeSearchMultiSelectValue } from '../../../dave-utils-module/app-filter-module/app-filter/app-filter.component';
import { CommissionNameService } from '../../../dave-utils-module/dave-shared-components-module/services/commission-name.service';
import { PermissionService } from '../../../dave-utils-module/dave-shared-components-module/services/permission.service';
import { compareArrays, getAddressString, isNotNullOrUndefined, stringSearch, TableColumnConfig, uniqArray } from '../../../helper/helper';
import { CommissionMeta } from '../../../helper/page-metadata';
import { NewCommissionDialogComponent, NewCommissionDialogComponentDialogData } from '../../../new-commission-dialog/new-commission-dialog.component';
import { CustomLabelService } from '../../../services/custom-label.service';
import { DefaultFilterService, FilterApps, FilterTypes } from '../../../services/default-filter.service';
import { DefaultTableSortingService } from '../../../services/default-table-sorting.service';
import { LoadingService } from '../../../services/loading.service';
import { CommissionDownloadComponent } from '../commission-download/commission-download.component';

/**
 * die stehen auch im additionalData vom partner
 */
export type CommissionListColumn =
    | 'InterneNr'
    | 'AuftragsNr'
    | 'SchadenNr'
    | 'VersicherungsNr'
    | 'Projektleiter'
    | 'Vorarbeiter'
    | 'StatusBackoffice'
    | 'Auftraggeber'
    | 'LastDeadline'
    | 'AbgabeterminAG'
    | 'StartDate'
    | 'EndDate'
    | 'Address'
    | 'Sachbearbeiter'
    | 'Sache'
    | 'Auftragstyp'
    | 'Description'
    | 'Author'
    | 'CreatedAt';

function getUserNamesByRoleId(u2csByCommission: UserToCommissionEntity[], roleId: number, users: Dictionary<UserEntity>) {
    return uniqArray(u2csByCommission.filter((u2c) => u2c.RoleId === roleId).map((u2c) => u2c.UserId))
        .map((uId) => users[uId])
        .filter((user) => user && !user.Deleted)
        .map((user) => user.DisplayName)
        .join(' | ');
}

interface TableData extends DaveListTableData {
    AuftragsNr: string;
    InterneNr: string;
    SchadenNr: string;
    VersicherungsNr: string;
    StatusBackoffice: string;
    Auftraggeber: string;
    LastDeadline: string;
    AbgabeterminAG: string;
    // Projektleiter: string;
    Auftrag: CommissionEntity;
    Sache: string;
    StartDate: string;
    EndDate: string;
    Address: string;
    // Vorarbeiter: string;
    // Sachbearbeiter: string;
    // routerLink: string | any[];
    Auftragstyp: string;
    cssStyle: string;
    cssClass: string;

    CommissionTypeId: number;
    AssignUserIds: number[];
    UserId: number;
    PartnerOfficeId: number;
    StatusBackofficeId: number;
    Bezeichnung: string;
    Author: string;
    CreatedAt: string;
    roleCols: { [key: string]: string };
}
const roleKeyPrefix = 'roleId_';
@Component({
    selector: 'app-commission-list',
    templateUrl: './commission-list.component.html',
    styleUrls: ['./commission-list.component.scss'],
})
export class CommissionListComponent implements OnInit, OnDestroy, AfterViewInit {
    public static readonly RequiredResolvers: ResolveData = {
        token: TokenResolver,
        [CommissionTypesFeatureKey]: CommissionTypeResolver,
        [customersFeatureKey]: CustomerResolver,
        [officeFeatureKey]: OfficeResolver,
        [commissionsFeatureKey]: CommissionResolver,
        [StatusFromBackofficeFeatureKey]: StatusFromBackofficeResolver,
        [usersFeatureKey]: UserResolver,
        partnerOffices: PartnerOfficeResolver,
        [USER_TO_COMMISSION_KEY]: UserToCommissionResolver,
        [ENTITY_ROLE_KEY]: EntityRoleResolver,
    };
    @Input() CommissionExportButton = true;
    @Input() NewCommissionButton = true;
    @Input() AkkordDemoButton = false;
    public DataSource$: Observable<TableVirtualScrollDataSource<TableData>>;
    commissionEntityRoles$ = this.store.select(getEntityRoleFetched).pipe(
        filter((v) => v),
        switchMap(() => this.store.select(getEntityRole)),
        map((roles) => roles.filter((r) => r.Entity === EntityTypesEnum.Commission)),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public TableColumns: TableColumnConfig<TableData, CommissionListColumn>[] = [
        { header: 'InterneNr', name: 'InterneNr', width: '7rem' },
        { header: 'AuftragsNr', name: 'AuftragsNr', width: '9rem' },
        { header: 'SchadenNr', name: 'SchadenNr', width: '7rem' },
        { header: 'VersicherungsNr', name: 'VersicherungsNr', width: '' },
        // { header: 'Verantwortlich', name: 'Bearbeiter', width: '7rem' },
        { header: 'Auftraggeber', name: 'Auftraggeber', width: '12rem' },
        { header: 'Frist', name: 'LastDeadline', width: '5rem' },
        { header: 'AG', name: 'AbgabeterminAG', width: '5rem' },
        { header: 'Status', name: 'StatusBackoffice', width: '8rem' },
        { header: 'Start', name: 'StartDate', width: '5rem' },
        { header: 'Ende', name: 'EndDate', width: '5rem' },
        { header: 'Adresse', name: 'Address', width: '14rem' },
        { header: this.CNS.getName, name: 'Sache', width: '14rem' },
        { header: 'Auftragstyp', name: 'Auftragstyp', width: '14rem' },
        { header: 'Bezeichnung', name: 'Description', width: '14rem' },
        { header: 'Autor', name: 'Author', width: '12rem' },
        { header: 'Erstellt', name: 'CreatedAt', width: '5rem' },
    ];





    TableColumns$: Observable<TableColumnConfig<TableData, CommissionListColumn | string>[]> = this.commissionEntityRoles$.pipe(
        map((roles) => {
            const selectableColumns = this.TableColumns.map(c => c.name);
            const ret = this.TableColumns.filter((c) => selectableColumns.includes(c.name));
            let indexOfRoles = ret.length;
            const columnsBehindRoles: Array<CommissionListColumn> = ['Auftraggeber', 'LastDeadline', 'AbgabeterminAG', 'StatusBackoffice', 'StartDate', 'EndDate', 'Address', 'Sache', 'Auftragstyp', 'Description', 'Author'];
            ret.forEach((e, i) => {
                if (columnsBehindRoles.includes(e.name)) {
                    indexOfRoles = i;
                }
            });

            return <TableColumnConfig<TableData, CommissionListColumn | string>[]>[
                ...ret.slice(0, indexOfRoles - 1),
                ...roles.map<TableColumnConfig<TableData, CommissionListColumn | string>>((role) => ({
                    header: role.Name,
                    name: roleKeyPrefix + role.Id,
                    width: '12rem',
                    cell: (rowData: TableData) => rowData.roleCols[roleKeyPrefix + role.Id],
                })),
                ...ret.slice(indexOfRoles - 1),
            ];
        }),
    );

    selectedColumns$: Observable<Array<CommissionListColumn>> = combineLatest([
        getFetched$(this.store, getSettingFetched, getSetting),
        getFetched$(this.store, getPartnerFetched, getPartner),
        this.commissionEntityRoles$,
    ]).pipe(
        map(([ settings, partner, roles]) => {
            const selectableColumns = this.TableColumns.map(c => c.name);
            const displayedColumns = settings.CommissionListComponentDisplayedColumns
                || partner.AdditionalData?.CommissionListComponentDisplayedColumns
                || [
                    'Auftraggeber',
                    'InterneNr',
                    'Sache',
                    'AuftragsNr',
                    'Auftragstyp',
                    'Address',
                    'StartDate',
                    'EndDate',
                    'AbgabeterminAG',
                    'Projektleiter',
                    'Author',
                    'Vorarbeiter',
                    'Sachbearbeiter',
                    'LastDeadline',
                    'StatusBackoffice',
                ] as Array<CommissionListColumn>;



            return displayedColumns.filter(
                (c) => selectableColumns.includes(c as CommissionListColumn) || c.startsWith(roleKeyPrefix)
            ) as CommissionListColumn[];
        }),
        shareReplay({ bufferSize: 1, refCount: true }),
        distinctUntilChanged((a, b) => compareArrays(a, b)),
    );

    public TableColumnsSorted$ : Observable<TableColumnConfig<TableData, CommissionListColumn>[]> = this.selectedColumns$.pipe(map(selected => {
        return this.TableColumns.sort((a, b) => selected.indexOf(a.name) - selected.indexOf(b.name))
    }));

    displayedColumns$ = combineLatest([this.selectedColumns$, this.TableColumns$]).pipe(
        map(([cols, TableColumns]) => [...cols.sort((a, b) => TableColumns.findIndex((c) => c.name === a) - TableColumns.findIndex((c) => c.name === b)), 'chevron']),
    );
    @ViewChild('wrapperElement') WrapperElement: ElementRef;
    public CommissionIcon = CommissionMeta.Icon;
    public FilterValues$: BehaviorSubject<{
        [FilterTypes.Editor]: IFilterTypeSearchMultiSelectValue[];
        [FilterTypes.PartnerOfficeId]: IFilterTypeSearchMultiSelectValue[];
        [FilterTypes.Status]: IFilterTypeSearchMultiSelectValue[];
        [FilterTypes.CommissionType]: IFilterTypeSearchMultiSelectValue[];
    }> = new BehaviorSubject({
        [FilterTypes.Editor]: null,
        [FilterTypes.PartnerOfficeId]: null,
        [FilterTypes.Status]: null,
        [FilterTypes.CommissionType]: null,
    });

    public FilterSettings$: Observable<FilterOption[]> = combineLatest([
        this.store.select(getEmployeesActive),
        this.store.select(getOwnPartnerOffices),
        this.store.select(getStatusFromBackoffice),
        this.store.select(getCommissionTypes),
        this.store.select(getPartner),
    ]).pipe(
        filter(isNotNullOrUndefined),
        map(([employees, partners, statuses, commissionTypes, partner]) => {
            let commissionTypeValues: IFilterTypeSearchMultiSelectValue[] = commissionTypes
                .filter((ct) => !ct.PartnerSpecificationTypeId || ct.PartnerSpecificationTypeId === partner?.PartnerSpecificationTypeId)
                .map((ct) => ({
                    label: ct.Name,
                    id: ct.Id,
                }));

            let userValues: IFilterTypeSearchMultiSelectValue[] = employees
                .filter((e) => !!e.UserId)
                .map((e) => ({
                    label: e.DisplayName,
                    id: e.UserId,
                }));

            let partnerValues: IFilterTypeSearchMultiSelectValue[] = partners.map((partner) => ({
                label: partner.DisplayName,
                id: partner.Id,
            }));

            let statusValues: IFilterTypeSearchMultiSelectValue[] = statuses.map((status) => ({
                label: status.Name,
                id: status.Id,
            }));

            const ret: FilterOption[] = [
                {
                    Name: FilterTypes.CommissionType,
                    Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
                    Label: 'Auftragsart',
                    Values: commissionTypeValues,
                },
                {
                    Name: FilterTypes.Editor,
                    Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
                    Label: 'Bearbeiter',
                    Icon: 'user',
                    Values: userValues,
                },
            ];
            if (partners?.length > 1) {
                ret.push({
                    Name: FilterTypes.PartnerOfficeId,
                    Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
                    Label: 'Unternehmen',
                    Icon: 'building',
                    Values: partnerValues,
                });
            }
            ret.push({
                Name: FilterTypes.Status,
                Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
                Label: 'Status',
                Icon: 'check',
                Values: statusValues,
            });
            return ret;
        }),
    );

    @ViewChild('input') private inputRef?: ElementRef<HTMLInputElement>;
    @ViewChild(MatSort) private matSort?: MatSort;
    private afterViewInit$ = new Subject<void>();
    private onDestroy$ = new Subject<void>();
    private customerId: number;
    private customerId$ = this.activatedRoute.paramMap.pipe(
        map((paramMap) => +paramMap.get('customerId')),
        tap((x) => (this.customerId = x)),
    );
    public FilterAmount$ = this.FilterValues$.pipe(
        map((val) => {
            let filterAmount = 0;
            for (const [key, value] of Object.entries(val)) {
                if (value?.length) {
                    filterAmount++;
                }
            }
            return filterAmount;
        }),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    DefaultSortDirection = this.defaultTableSortingService.getSort('commissionList').direction;
    DefaultSortActive = this.defaultTableSortingService.getSort('commissionList').active as  keyof TableData;

    constructor(
        private store: Store<State>,
        private activatedRoute: ActivatedRoute,
        private dialog: MatDialog,
        public PS: PermissionService,
        public LS: LoadingService,
        public CNS: CommissionNameService,
        private defaultFilterService: DefaultFilterService,
        protected cls: CustomLabelService,
        protected defaultTableSortingService: DefaultTableSortingService,
    ) {
        // saving filter settings in store
        this.FilterValues$.pipe(takeUntil(this.onDestroy$), skip(2)).subscribe((val) => {
            if (Object.keys(val).length !== 0) {
                this.defaultFilterService.SetFilterByApp(FilterApps.CommissionList, val);
            }
        });

        this.DataSource$ = combineLatest([
            this.store.select(getCommissionSorted).pipe(map((cs) => cs?.filter((c) => !c.Deleted))),
            // this.afterViewInit$,
            getFetched$(this.store, getCommissionTypesFetched, getCommissionTypeDictionary),
            this.customerId$,
            this.store.select(getUserDictionary),
            this.store.select(getCustomerDictionary),
            combineLatest([
                this.store.select(getStatusFromBackoffice),
                getFetched$(this.store, getUserToCommissionFetched, getUserToCommissionCommissionIdsMap).pipe(switchMap((u2cCIdsMap) => this.store.select(getUserToCommissionDictionary).pipe(map((u2cDict) => ({ u2cDict, u2cCIdsMap }))))),
                this.commissionEntityRoles$,
            ]),
        ]).pipe(
            map(([commissions, commissonTypes, customerId, users, customers, [boStates, { u2cDict, u2cCIdsMap }, roles]]) => {
                let filterdCommissions: CommissionEntity[] = customerId && customerId > 0 ? commissions.filter((c) => c.CustomerId === customerId) : commissions;

                return new TableVirtualScrollDataSource(
                    filterdCommissions.map<TableData>((commission) => {
                        const routerLink = [commission.Id];
                        const now = new Date();
                        now.setDate(now.getDate() + 2);
                        const StatusBackoffice = boStates.find((s) => s.Id === commission.StatusBackofficeId);
                        const u2csByCommission = (u2cCIdsMap.get(commission.Id) || []).filter(isNotNullOrUndefined).map((id) => u2cDict[id]);
                        const customer = commission.CustomerId && customers[commission.CustomerId];

                        const roleCols: { [key: string]: string } = {};
                        roles.forEach((r) => {
                            roleCols[roleKeyPrefix + r.Id] = getUserNamesByRoleId(u2csByCommission, r.Id, users);
                        });

                        return {
                            CommissionTypeId: commission.CommissionTypeId,
                            AssignUserIds: u2csByCommission.filter((u2c) => u2c.Assignee).map((u2c) => u2c.UserId),
                            UserId: commission.UserId,
                            PartnerOfficeId: commission.PartnerOfficeId,
                            StatusBackofficeId: commission.StatusBackofficeId,

                            id: commission.Id,
                            AuftragsNr: commission.Auftragsnummer || '-',
                            Auftrag: commission,
                            InterneNr: commission.InterneNummer || '-',
                            SchadenNr: commission.Schadennummer || '-',
                            VersicherungsNr: commission.Versicherungsnummer || '-',
                            StatusBackoffice: StatusBackoffice?.Name,
                            LastDeadline: commission.LastDeadline?.toLocaleDateString('de-DE'),
                            StartDate: commission.StartDate?.toLocaleDateString('de-DE') || commission.PlannedStartDate?.toLocaleDateString('de-DE'),
                            EndDate: commission.EndDate?.toLocaleDateString('de-DE') || commission.PlannedEndDate?.toLocaleDateString('de-DE'),
                            AbgabeterminAG: commission.AbgabeterminAG?.toLocaleDateString('de-DE'),
                            Auftraggeber: customer?.DisplayName || '-',
                            routerLink,
                            Sache: commission.Description || '-',
                            Address: getAddressString(commission),
                            clickable: true,
                            cssStyle: `--app-clickable-background-color: ${commission.GetBackgroundColor(StatusBackoffice?.IsCompleted, StatusBackoffice?.Color)};`,
                            cssClass: 'app-clickable-background',
                            Auftragstyp: commissonTypes[commission.CommissionTypeId]?.Name || '-',
                            Bezeichnung: commission.Description,
                            Author: (commission.UserId && users[commission.UserId]?.DisplayName) || '',
                            CreatedAt: commission.CreatedAt.toLocaleDateString('de-DE'),
                            roleCols,
                        };
                    }),
                );
            }),
            tap(
                (dataSource) =>
                    (dataSource.sortingDataAccessor = (object, key: keyof TableData) => {
                        switch (key) {
                            case 'AuftragsNr':
                            case 'InterneNr':
                            case 'SchadenNr':
                            case 'VersicherungsNr':
                            case 'Sache':
                            case 'Bezeichnung':
                            case 'Author':
                                return `${object[key]}`.trim().toLowerCase();
                            case 'StartDate':
                                return object.Auftrag.StartDate?.getTime() || object.Auftrag.PlannedStartDate?.getTime();
                            case 'EndDate':
                                return object.Auftrag.EndDate?.getTime() || object.Auftrag.PlannedEndDate?.getTime();
                            case 'CreatedAt':
                                return object.Auftrag.CreatedAt?.getTime();
                            case 'Auftrag':
                                return '';
                            default:
                                return object[key] ? object[key].toString() : '';
                        }
                    }),
            ),
            // Vergleichsfunktion zum Freitext-Filtern
            tap((dataSource) => (dataSource.filterPredicate = (data, searchTerm) => [data.AuftragsNr, data.InterneNr, data.VersicherungsNr, data.Address, data.Auftrag.Description].some((value) => stringSearch(value || '', searchTerm)))),
            switchMap((dataSource) => {
                let dataSourceData = dataSource.data.slice();
                return this.FilterValues$.pipe(
                    map((filter) => ({
                        [FilterTypes.Editor]: filter[FilterTypes.Editor] && filter[FilterTypes.Editor].length ? filter[FilterTypes.Editor] : null,
                        [FilterTypes.PartnerOfficeId]: filter[FilterTypes.PartnerOfficeId] && filter[FilterTypes.PartnerOfficeId].length ? filter[FilterTypes.PartnerOfficeId] : null,
                        [FilterTypes.Status]: filter[FilterTypes.Status] && filter[FilterTypes.Status].length ? filter[FilterTypes.Status] : null,
                        [FilterTypes.CommissionType]: filter[FilterTypes.CommissionType] && filter[FilterTypes.CommissionType].length ? filter[FilterTypes.CommissionType] : null,
                    })),
                    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
                    map((filter) => {
                        let filterdCommissions = dataSourceData.slice();
                        if (filter.commissionType?.length && isArray(filter.commissionType)) {
                            filterdCommissions = filterdCommissions.filter((c) => filter.commissionType.some((ct) => ct.id === c.CommissionTypeId));
                        }
                        if (filter.editor?.length && isArray(filter.editor)) {
                            filterdCommissions = filterdCommissions.filter((c) => (c.AssignUserIds.length ? filter.editor.some((e) => c.AssignUserIds.includes(Number(e.id))) : filter.editor.some((e) => Number(e.id) === c.UserId)));
                        }
                        if (filter.partnerOfficeId?.length && isArray(filter.partnerOfficeId)) {
                            filterdCommissions = filterdCommissions.filter((c) => filter.partnerOfficeId.some((po) => Number(po.id) === c.PartnerOfficeId));
                        }
                        if (filter.status?.length && isArray(filter.status)) {
                            filterdCommissions = filterdCommissions.filter((c) => filter.status.some((s) => Number(s.id) === c.StatusBackofficeId));
                        }
                        dataSource.data = filterdCommissions;
                        return dataSource;
                    }),
                );
            }),
        );

    }

    ngAfterViewInit(): void {
        this.afterViewInit$.next();
    }
    public OpenDemo() {
        this.dialog.open(PieceworkDemoComponent, {
            ...PieceworkDemoComponent.DefaultConfig,
        });
    }

    ngOnInit(): void {
        combineLatest([this.store.select(getSetting), this.store.select(getUser), this.FilterSettings$, this.defaultFilterService.GetFilterByApp$(FilterApps.CommissionList)])
            .pipe(take(1))
            .subscribe(([settings, user, filterSettings, filterValues]) => {
                this.FilterValues$.next({
                    [FilterTypes.Editor]: settings.FilterCommissions
                        ? [{ id: user.Id.toString(), label: user.DisplayName }]
                        : filterValues[FilterTypes.Editor] && isArray(filterValues[FilterTypes.Editor])
                            ? filterValues[FilterTypes.Editor]
                            : [],
                    [FilterTypes.PartnerOfficeId]: filterValues[FilterTypes.PartnerOfficeId] && isArray(filterValues[FilterTypes.PartnerOfficeId]) ? filterValues[FilterTypes.PartnerOfficeId] : [],
                    [FilterTypes.Status]: filterValues[FilterTypes.Status] && isArray(filterValues[FilterTypes.Status]) ? filterValues[FilterTypes.Status] : [],
                    [FilterTypes.CommissionType]: filterValues[FilterTypes.CommissionType] && isArray(filterValues[FilterTypes.CommissionType]) ? filterValues[FilterTypes.CommissionType] : [],
                });
            });
        this.commissionEntityRoles$.subscribe((roles) => {
            const roleBasedColumns: TableColumnConfig<TableData, CommissionListColumn>[] = roles.map((role) => ({
                header: role.Name,
                name: `roleKeyPrefix${role.Id}` as CommissionListColumn,
                width: '12rem',
                cell: (rowData: TableData) => rowData.roleCols[`roleKeyPrefix${role.Id}`],
            }));


            this.TableColumns = [
                ...this.TableColumns,
                ...roleBasedColumns
            ];
        });
    }




ngOnDestroy(): void {
        this.onDestroy$.next();
    }

    public CsvDownload() {
        this.dialog.open(CommissionDownloadComponent, {
            width: '500px',
        });
    }

    public CreateCommission() {
        firstValueFrom(
            this.store.select(getPartner).pipe(switchMap((partner) => (partner.PartnerTypeId === PartnerTypeEnum.TSB ? this.store.select(getCommissionTypes).pipe(map((cts) => cts.find((ct) => ct.AdditionalData?.IsDefault))) : of(null)))),
        ).then((commissionType) => {
            this.dialog.open<NewCommissionDialogComponent, NewCommissionDialogComponentDialogData>(NewCommissionDialogComponent, {
                data: {
                    CreateCommissionPayload: {
                        customerId: this.customerId || null,
                        auftragseingangDurchAuftraggeber: new Date().toDateString(),
                    },
                    NavigateRelativeTo: this.activatedRoute,
                    ForceCommissionTypeId: commissionType?.Id,
                },
            });
        });
    }

    selectedColumnsChange(selectedColumns: Array<keyof TableData>) {
        this.store.dispatch(
            new ChangeSettings({
                CommissionListComponentDisplayedColumns: selectedColumns,
            }),
        );
    }
    disabledColumns$ = combineLatest([this.TableColumnsSorted$, this.selectedColumns$]).pipe(map(([all, selected]) => all.length - selected.length || undefined));
}
