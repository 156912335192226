import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    FILE_OPENER_TOKEN_KEY,
    FileOpenerTokenState, selectAllFileOpenerToken,
    selectFileOpenerTokenEntities, selectFileOpenerTokenFetched, selectFileOpenerTokenLatestUpdatedAt,
} from '../reducers/file-opener-token.reducer';

const selectState = createFeatureSelector<FileOpenerTokenState>(FILE_OPENER_TOKEN_KEY);
export const getFileOpenerTokenDictionary = createSelector(selectState, selectFileOpenerTokenEntities);
export const getFileOpenerTokenLatestUpdatedAt = createSelector(selectState, selectFileOpenerTokenLatestUpdatedAt);
export const getFileOpenerTokenFetched = createSelector(selectState, selectFileOpenerTokenFetched);
export const getFileOpenerTokenes = createSelector(selectState, selectAllFileOpenerToken);
export const getFileOpenerTokenesActive = createSelector(getFileOpenerTokenes, (entities) => entities.filter((entity) => !entity.DeletedAt));
export const getFileOpenerTokenById = (props: { id: number }) => createSelector(getFileOpenerTokenDictionary, (dictionary) => props.id && dictionary[props.id]);
