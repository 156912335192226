import { CurrencyPipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, HostListener, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, ResolveData, Router } from '@angular/router';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, filter, first, map, shareReplay, skip, startWith, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { NewOnSiteEventComponent, NewOnSiteEventComponentDialogData } from 'src/app/dave-commission-module/components/new-on-site-event/new-on-site-event.component';
import { CommentEntityTypeEnum } from 'src/app/dave-data-module/entities/comment.entity';
import { ViewStyleConfig } from 'src/app/dave-data-module/entities/viewStyleSetting.entity';
import { AccountTypeEnum, ChartOfAccountEntity } from '../../../dave-data-module/entities/chart-of-Account.entity';
import { ComplaintTypesEnum } from '../../../dave-data-module/entities/complaintType.entity';
import { CustomerTypeEntity } from '../../../dave-data-module/entities/customer-type.entity';
import { CustomerEntity, CustomerStatusEnum, CustomerStatusTypeNames, TaxTypeNames, TaxTypes } from '../../../dave-data-module/entities/customer.entity';
import { CustomerSpecificationTypeEntity } from '../../../dave-data-module/entities/customerSpecificationType.entity';
import { EmployeeEntity } from '../../../dave-data-module/entities/employee.entity';
import { FolderEntity, FolderTypes } from '../../../dave-data-module/entities/folder.entity';
import { LedgerImportDocumentTypes } from '../../../dave-data-module/entities/ledger-import.entity';
import { ProductNames } from '../../../dave-data-module/entities/license.entity';
import { MandateTypeEntity } from '../../../dave-data-module/entities/mandate-type.entity';
import { OfficeEntity } from '../../../dave-data-module/entities/office.entity';
import { PartnerEntity } from '../../../dave-data-module/entities/partner.entity';
import { PersonEntity } from '../../../dave-data-module/entities/person.entity';
import { Person2EntityEntity, Person2EntityEntityTypeEnum } from '../../../dave-data-module/entities/person2entity.entity';
import { UserRoles } from '../../../dave-data-module/entities/user.entity';
import { DaveMutationChangeCustomerArgs } from '../../../dave-data-module/graphql-types';
import { BookmarkResolver } from '../../../dave-data-module/guards/bookmark.resolver';
import { ChartOfAccountResolver } from '../../../dave-data-module/guards/chartOfAccount.resolver';
import { CommentResolver } from '../../../dave-data-module/guards/comment.resolver';
import { CommissionResolver } from '../../../dave-data-module/guards/commission.resolver';
import { CustomerResolver } from '../../../dave-data-module/guards/customer.resolver';
import { EntityRoleResolver } from '../../../dave-data-module/guards/entity-role.resolver';
import { EventTypeResolver } from '../../../dave-data-module/guards/event-type.resolver';
import { LedgerImportResolver } from '../../../dave-data-module/guards/ledger-import.resolver';
import { ComponentCanDeactivate } from '../../../dave-data-module/guards/pending-changes.guard';
import { PersonResolver } from '../../../dave-data-module/guards/person.resolver';
import { Person2EntityResolver } from '../../../dave-data-module/guards/person2Entity.resolver';
import { SFDTTemplateResolver } from '../../../dave-data-module/guards/sfdt-template.resolver';
import { UserResolver } from '../../../dave-data-module/guards/user.resolver';
import { getFetched$ } from '../../../dave-data-module/helper/helper';
import { FolderDataService } from '../../../dave-data-module/services/folder-data.service';
import { HttpService } from '../../../dave-data-module/services/http.service';
import { State } from '../../../dave-data-module/State';
import { ChartOfAccountActionTypes } from '../../../dave-data-module/State/actions/chartOfAccount.actions';
import { CustomerActionTypes } from '../../../dave-data-module/State/actions/customers.actions';
import { FilesActionTypes, FileUploadParams } from '../../../dave-data-module/State/actions/files.actions';
import { OfficeActionTypes } from '../../../dave-data-module/State/actions/office.actions';
import { Person2EntityActionTypes } from '../../../dave-data-module/State/actions/person2entity.action';
import { getAccountsReceivableLedgers } from '../../../dave-data-module/State/selectors/accounting.selector';
import { getToken } from '../../../dave-data-module/State/selectors/base.selectors';
import { getChartOfAccounts, getChartOfAccountsFetched } from '../../../dave-data-module/State/selectors/chartOfAccount.selectors';
import { getComplaintTypes } from '../../../dave-data-module/State/selectors/complaintType.selectors';
import { getCustomerById, getCustomers, getCustomerTypes } from '../../../dave-data-module/State/selectors/customers.selectors';
import { getCustomerSpecificationTypes } from '../../../dave-data-module/State/selectors/customerSpecificationType.selectors';
import { getActiveEmployeesSortedByNameStartWithMe } from '../../../dave-data-module/State/selectors/employees.selectors';
import { getLedgerImports } from '../../../dave-data-module/State/selectors/ledger-import.selector';
import { getMandateTypes } from '../../../dave-data-module/State/selectors/mandate-types.selectors';
import { getOffices } from '../../../dave-data-module/State/selectors/offices.selectors';
import { getOwnPartnerOffices, getPartner } from '../../../dave-data-module/State/selectors/partners.selectors';
import { getPersonDictionary } from '../../../dave-data-module/State/selectors/person.selectors';
import { getPerson2Entities } from '../../../dave-data-module/State/selectors/person2entity.selectors';
import { getLicenses, getUserProducts } from '../../../dave-data-module/State/selectors/products.selectors';
import { getUserToCustomers, getUserToCustomersFetched } from '../../../dave-data-module/State/selectors/user-to-customer.selectors';
import { getManagedUsers, getUser } from '../../../dave-data-module/State/selectors/users.selectors';
import { EmailEditorComponent, EmailEditorComponentDialogConfig } from '../../../dave-email-module/components/email-editor/email-editor.component';
import { SelectPersonsComponent, SelectPersonsComponentDialogData } from '../../../dave-person-module/components/select-persons/select-persons.component';
import { NewBookingOptionComponent, NewOptionDialogData } from '../../../dave-reports/components/new-booking-option/new-booking-option.component';
import { NewClosingInvoiceComponent, NewClosingInvoiceComponentDialogData, NewClosingInvoiceComponentDialogReturnData } from '../../../dave-reports/components/new-closing-invoice/new-closing-invoice.component';
import { NewPartialInvoiceComponent, NewPartialInvoiceComponentDialogData, NewPartialInvoiceComponentDialogReturnData } from '../../../dave-reports/components/new-partial-invoice/new-partial-invoice.component';
import { NewReportDialogComponent, NewReportDialogComponentDialogData } from '../../../dave-reports/components/new-report-dialog/new-report-dialog.component';
import { SelectUserCustomerDialogComponent, SelectUserCustomerDialogComponentDialogData } from '../../../dave-select-user/components/select-user-dialogs/select-user-customer-dialog/select-user-customer-dialog.component';
import { AppDialogService } from '../../../dave-utils-module/app-dialog-module/app-dialog.service';
import { IDetailListTemplateData } from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/detail-list-template/detail-list-template.component';
import { CustomPropertyType, IProfileTemplateData, IProfileTemplateDataProperty } from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/profile-template/profile-template.component';
import { CustomerNameService } from '../../../dave-utils-module/dave-shared-components-module/services/customer-name.service';
import { GoogleMapsService } from '../../../dave-utils-module/dave-shared-components-module/services/google-maps.service';
import { PermissionService } from '../../../dave-utils-module/dave-shared-components-module/services/permission.service';
import { SelectSearchOption } from '../../../dave-utils-module/select-search/components/select-search/select-search.component';
import { DetailListDialogReturn, DetailListTemplateDialogComponent, DetailListTemplateDialogData } from '../../../detail-list-template-dialog/components/detail-list-template-dialog.component';
import { Address, getAddressString, isNotNullOrUndefined, stringSearch, uniqArray } from '../../../helper/helper';
import { AllReportsMeta, BookingPositionMetaIcon, CommissionMeta, ContactBookMeta, CustomerAdministrationMeta, DMSPageMeta, HistoryMeta, InvoiceEditorMeta, ReportsPageMeta } from '../../../helper/page-metadata';
import { decimalValidator, phoneNumberValidator } from '../../../helper/validation.helper';
import { CustomLabelService } from '../../../services/custom-label.service';
import { DaveFileUploadDialogComponent, DaveFileUploadDialogComponentDialogData } from '../../templates/new-document-view/component/dave-file-upload-dialog/dave-file-upload-dialog.component';
import { NewReportDialogService } from '../../../dave-reports/components/new-report-dialog/new-report-dialog.service';

@Component({
    selector: 'app-detail-customer',
    templateUrl: './detail-customer.component.html',
    styleUrls: ['./detail-customer.component.scss'],
    providers: [CurrencyPipe],
})
export class DetailCustomerComponent implements OnDestroy, AfterViewInit, ComponentCanDeactivate {
    public static readonly RequiredResolvers: ResolveData = {
        commissions: CommissionResolver,
        templates: SFDTTemplateResolver,
        entityRoles: EntityRoleResolver,
        person2Entity: Person2EntityResolver,
        // für events
        EventType: EventTypeResolver,
        Person: PersonResolver,
        Bookmark: BookmarkResolver,
        Customer: CustomerResolver,
        Users: UserResolver,
        Comment: CommentResolver,
    };
    public commentView: ViewStyleConfig = {
        expanded: false,
        order: 1,
        headline: 'Kommentare',
        type: '',
    };
    public ShownButtons = true;
    public ShowStatus = false;
    public ChronikPath = HistoryMeta.Path;
    public ChronikIcon = HistoryMeta.Icon;
    public DMSPath = DMSPageMeta.Path;
    public DMSIcon = DMSPageMeta.Icon;
    public CommissionPath = CommissionMeta.Path;
    public CommissionIcon = CommissionMeta.Icon;
    public Partner: PartnerEntity = null;
    public SachkontoChartOfAccounts: Array<ChartOfAccountEntity & { optionLabel: string }>;
    public KontoChartOfAccounts: Array<ChartOfAccountEntity & { optionLabel: string }>;
    public ChartOfAccounts: Array<ChartOfAccountEntity & { optionLabel: string }>;
    @ViewChild('SachkontochartOfAccountTemp') SachkontochartOfAccountTemp: TemplateRef<any>;
    @ViewChild('KontochartOfAccountTemp') KontochartOfAccountTemp: TemplateRef<any>;
    public ShowLedgerImport$ = combineLatest([this.store.select(getUserProducts), this.store.select(getLicenses), this.store.select(getUser)]).pipe(
        map(([userProducts, licenses, user]) => {
            const license = licenses.find((l) => l.ProductName === ProductNames.Accounting);
            const userProduct = userProducts.find((l) => l.Name === ProductNames.Accounting);
            const role = user.Roles.find((r) => r === UserRoles.AccountingUser || r === UserRoles.AccountingAdmin);
            return isNotNullOrUndefined(license) && isNotNullOrUndefined(userProduct) && isNotNullOrUndefined(role);
        }),
        tap((v) => {
            if (v) {
                this.liResolver.resolve();
            }
        }),
    );
    // Customer FormControls
    public CustomerIdForm = new UntypedFormControl(null);
    public AutomaticCustomerIdForm = new UntypedFormControl(false);
    public DescriptionForm = new UntypedFormControl(null);
    public CustomerSpecificationTypeIdForm = new UntypedFormControl(null, Validators.required);
    public NameForm = new UntypedFormControl(null, [Validators.required]);
    public ShowNotFoundError = true;
    public HomePageForm = new UntypedFormControl(null);
    public MandateTypeIdForm = new UntypedFormControl(null);
    public GerichtsortForm = new UntypedFormControl({ value: null });
    public PartnerOfficeForm = new UntypedFormControl(null);
    public DebitorNoForm: FormControlTyped<string> = new UntypedFormControl(null);
    public TaxTypeForm: FormControlTyped<TaxTypes> = new UntypedFormControl(null);
    public StatusForm: FormControlTyped<CustomerStatusEnum> = new UntypedFormControl(CustomerStatusEnum.Aktiv, Validators.required);

    // Headquarter FormControls
    public PhoneNumberForm = new UntypedFormControl(null, phoneNumberValidator());
    public EmailForm = new UntypedFormControl(null, Validators.email);
    public InvoiceEmailForm = new UntypedFormControl(null, Validators.email);
    public MobileNumberForm = new UntypedFormControl(null, phoneNumberValidator());
    public CustomerForm = new FormGroup({
        Sales: new FormControl<number | null>(null),
        EmployeeNumber: new FormControl<number | null>(null),
        Sachkonto: new FormControl<SelectSearchOption<ChartOfAccountEntity> | null>(null),
        Konto: new FormControl<SelectSearchOption<ChartOfAccountEntity> | null>(null),
        PaymentTargetInDays: new FormControl<number | null>(null, decimalValidator()),
        PaymentDiscount: new FormControl<number | null>(null, [Validators.min(0), Validators.max(100), decimalValidator(4)]),
    });
    public FaxNumberForm = new UntypedFormControl(null, phoneNumberValidator());
    BookingPositionMetaIcon = BookingPositionMetaIcon;
    public HeadquarterLocationForm = this.fb.group({
        Street: null,
        PostalCode: null,
        City: null,
        Country: null,
    }) as FormGroupTyped<Address>;
    // ImageId Upload FormGroup
    public UploadImageForm = new UntypedFormGroup({
        uploadImage: new UntypedFormControl(null, []),
    });
    public CustomerTypesForm: FormControlTyped<CustomerTypeEntity[]> = new UntypedFormControl([]);
    public Deleted = false;
    public Customer: CustomerEntity;
    public EditedCustomerImage: SafeUrl;
    public Editing = false;
    public Headquarter: OfficeEntity;

    private personToCustomer = this.store.select(getPerson2Entities).pipe(
        map((p2e) => p2e.filter((p2e) => p2e.EntityType === Person2EntityEntityTypeEnum.Customer)),
        shareReplay({ bufferSize: 1, refCount: true }),
    );
    public ShownPersonList$: Observable<PersonEntity[]> = this.route.paramMap.pipe(
        map((paramMap) => +paramMap.get('customerId')),
        distinctUntilChanged(),
        switchMap((customerId) =>
            this.personToCustomer.pipe(
                map((person2Entities) => {
                    return uniqArray(person2Entities.filter((p2e) => p2e.EntityId === customerId).map((p2e) => p2e.PersonId));
                }),
            ),
        ),
        switchMap((personIds) =>
            this.store.select(getPersonDictionary).pipe(
                map((persons) =>
                    personIds
                        .map((id) => persons[id])
                        .filter(isNotNullOrUndefined)
                        .sort((a, b) => {
                            if (a.Deleted && !b.Deleted) {
                                return 1;
                            }
                            if (!a.Deleted && b.Deleted) {
                                return -1;
                            }
                            if (a.DisplayName.toLowerCase() < b.DisplayName.toLowerCase()) {
                                return -1;
                            }
                            if (a.DisplayName.toLowerCase() > b.DisplayName.toLowerCase()) {
                                return 1;
                            }
                            return 0;
                        }),
                ),
            ),
        ),
    );
    public CommentEntityTypeEnum = CommentEntityTypeEnum;
    public Folder$: Observable<FolderEntity>;
    public FolderId: number;
    public UploadFolderId: number;
    // wird benötigt da in diesem html template das Observable nicht tut -.-
    public IsGericht: boolean;
    public ShownJudgeList: PersonEntity[];
    /** Alle CustomerTypes aus dem Store */
    public CustomerTypes: CustomerTypeEntity[] = [];
    public MandateType?: MandateTypeEntity;
    public DisplayedUsers$: Observable<{ User: EmployeeEntity; ItsMe: boolean }[]>;
    // ToDo Lizenz beachten
    public HasAccountingLicense$: Observable<boolean>;
    public HasAccountingRole$: Observable<boolean>;
    public MandateTypeList: MandateTypeEntity[] = [];
    public PreviewOpen = false;
    public PreviewedFileId$ = new BehaviorSubject<number | null>(null);
    public ImageIds: number[];
    public ProfileData: IProfileTemplateData;
    public BankData: IDetailListTemplateData;
    public CustomerId$ = this.route.paramMap.pipe(
        map((paramMap) => +paramMap.get('customerId')),
        distinctUntilChanged(),
    );
    public Customer$: Observable<CustomerEntity> = this.route.paramMap.pipe(
        map((paramMap) => +paramMap.get('customerId')),
        switchMap((customerId) => this.store.select(getCustomerById({ id: customerId }))),
        tap((customer) => !customer && console.error('Could not find CustomerEntity')),
        filter(isNotNullOrUndefined),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    private userToCustomerFromCustomer$ = this.Customer$.pipe(
        switchMap((c) => this.store.select(getUserToCustomers).pipe(map((u2cs) => u2cs.filter((u2c) => u2c.CustomerId === c.Id)))),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public IamAssignee$ = combineLatest([this.userToCustomerFromCustomer$, this.store.select(getUser)]).pipe(map(([u2cs, user]) => u2cs.some((u2c) => u2c.UserId === user.Id)));

    private directChangeStatusForm = new FormControl<CustomerStatusEnum | null>(null, Validators.required);
    public StatusData: IDetailListTemplateData = {
        Properties: [
            {
                key: 'Status',
                value: '',
                formControl: this.directChangeStatusForm,
                options: {
                    specialInput: {
                        select: Object.values(CustomerStatusEnum).map((val) => ({
                            optionValue: val,
                            optionLabel: CustomerStatusTypeNames.get(val),
                        })),
                    },
                },
            },
        ],
    };

    ledgerImports$ = this.CustomerId$.pipe(
        switchMap((customerId) =>
            this.store.select(getLedgerImports).pipe(
                filter(isNotNullOrUndefined),
                map((LedgerImports) => LedgerImports.filter((b) => b.CustomerId === customerId && !b.IsTemplate)),
            ),
        ),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public OpenMailDialog$: Subject<void> = new Subject<void>();
    public NewBookingPositionClick$ = new Subject();
    protected person2Entity: Person2EntityEntity[] = [];
    private afterViewInit$: Subject<void> = new Subject<void>();
    private customers$: Observable<CustomerEntity[]>;
    private selectedProfileByUrl$ = new Subject<number>();
    public CustomerSpecificationType$: Observable<CustomerSpecificationTypeEntity> = combineLatest([this.store.select(getCustomerSpecificationTypes), this.selectedProfileByUrl$]).pipe(
        map(([csts]) => csts.find((c) => c.Id === this.Customer.CustomerSpecificationTypeId)),
        tap((v) => {
            this.IsGericht = v.Name.toLowerCase().includes('gericht');
        }),
    );

    // NgRx Data
    private subscriptions: Subscription[] = [];
    private customerList: CustomerEntity[] = null;
    private officeList: OfficeEntity[] = null;
    private token: string = null;
    // ToDo
    private fileList: FileList = null;
    private bankDataForm = this.fb.group({
        IBAN: null,
        Swift: null,
        BankName: null,
        BankLocation: null,
        BankPostalCode: null,
        BankSortCode: null,
        AutomaticAccountsReceivableLedger: false,
        UstId: null,
        Steuernummer: null,
    }) as FormGroupTyped<{
        IBAN: string | null;
        Swift: string | null;
        BankName: string | null;
        BankLocation: string | null;
        BankPostalCode: string | null;
        BankSortCode: string | null;
        AutomaticAccountsReceivableLedger: boolean;
        UstId: string | null;
        Steuernummer: string | null;
    }>;
    public CommentFileUploadParams$: Observable<FileUploadParams>;

    constructor(
        private appDialog: AppDialogService,
        private dialog: MatDialog,
        private fb: UntypedFormBuilder,
        private store: Store<State>,
        private route: ActivatedRoute,
        private http: HttpClient,
        private api: HttpService,
        private sanitize: DomSanitizer,
        private apiToasterService: ToastrService,
        private cdr: ChangeDetectorRef,
        private googleGeocoderService: GoogleMapsService,
        public PS: PermissionService,
        private router: Router,
        public CN: CustomerNameService,
        private personResolver: PersonResolver,
        private liResolver: LedgerImportResolver,
        protected cls: CustomLabelService,
        chartOfAccountResolver: ChartOfAccountResolver,
        private actions$: Actions,
        folderDataService: FolderDataService,
        private newReportDialogService: NewReportDialogService,
    ) {
        firstValueFrom(this.store.select(getChartOfAccountsFetched)).then((fetched) => {
            if (!fetched) {
                chartOfAccountResolver.resolve();
            }
        });
        this.subscriptions.push(
            this.store
                .select(getChartOfAccountsFetched)
                .pipe(
                    filter((f) => f),
                    switchMap(() => getFetched$(this.store, getChartOfAccountsFetched, getChartOfAccounts).pipe(map((ca) => ca.filter((p) => !p.DeletedAt)))),
                )
                .subscribe((chartAcc) => {
                    this.SachkontoChartOfAccounts = chartAcc.filter((ca) => ca.AccountType === AccountTypeEnum.Contra).map((r) => Object.assign(r.Clone()));
                    this.KontoChartOfAccounts = chartAcc.filter((ca) => ca.AccountType === AccountTypeEnum.Normal).map((r) => Object.assign(r.Clone()));
                    this.ChartOfAccounts = chartAcc.map((r) => Object.assign(r.Clone()));
                }),
        );

        this.customers$ = this.store.select(getCustomers);
        this.HasAccountingLicense$ = combineLatest([this.store.select(getUserProducts), this.store.select(getLicenses)]).pipe(
            map(([userProducts, licenses]) => {
                var license = licenses.find((l) => l.ProductName == ProductNames.Accounting);
                var userProduct = userProducts.find((l) => l.Name == ProductNames.Accounting);
                return isNotNullOrUndefined(license) && isNotNullOrUndefined(userProduct);
            }),
        );
        this.Folder$ = this.selectedProfileByUrl$.pipe(
            switchMap((customerId) => folderDataService.getFolderFromEntity(customerId, FolderTypes.customer)),
            filter(isNotNullOrUndefined),
            distinctUntilChanged((a, b) => a.Id === b.Id),
            tap((v) => (this.FolderId = v.Id)),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
        this.CommentFileUploadParams$ = this.Folder$.pipe(
            map(
                (folder) =>
                    folder &&
                    new Map([
                        ['folder_id', folder.Id.toString()],
                        ['hidden', 'true'],
                    ]),
            ),
        );
        this.HasAccountingRole$ = combineLatest([this.store.select(getUser)]).pipe(
            map(([user]) => {
                return isNotNullOrUndefined(user.Roles.find((r) => r == UserRoles.AccountingUser || r == UserRoles.AccountingAdmin));
            }),
        );
        this.subscriptions.push(
            this.directChangeStatusForm.valueChanges.pipe(distinctUntilChanged()).subscribe((status) => {
                firstValueFrom(this.Customer$).then((customer) => {
                    this.ShowStatus = false;
                    this.store.dispatch(CustomerActionTypes.ModifyCustomer({ Payload: { id: customer.Id, customerStatus: status, gerichtsort: customer.Gerichtsort, partnerOfficeId: customer.PartnerOfficeId } }));
                });
            }),
            this.Customer$.subscribe((customer) => {
                if (customer && customer.CustomerStatus !== this.directChangeStatusForm.value) {
                    this.directChangeStatusForm.reset(customer.CustomerStatus, { emitEvent: false });
                }
            }),
            this.HasAccountingRole$.subscribe(),
            this.HasAccountingLicense$.subscribe(),
            this.Folder$.subscribe(),
            this.selectedProfileByUrl$.pipe(filter(isNotNullOrUndefined)).subscribe(),

            this.OpenMailDialog$.pipe(
                switchMap(() => this.personResolver.resolve()),
                tap(() => {
                    this.dialog.open(EmailEditorComponent, {
                        ...EmailEditorComponentDialogConfig,
                        data: {
                            EmailHtml: `
                                <p>${this.Customer.Name}</p>
                                `,
                            CustomerId: this.Customer.Id,
                        },
                    });
                }),
            ).subscribe(),
            this.CustomerSpecificationType$.subscribe(),
            // this.PreviewedFileId$.subscribe(id => {
            //     if (id && id > -1) {
            //         this.PreviewOpen = true;
            //         this.UploadOpen = false;
            //     } else if (id === null) {
            //         this.PreviewOpen = false;
            //     }
            // }),
            this.CustomerSpecificationTypeIdForm.valueChanges
                .pipe(
                    withLatestFrom(this.store.select(getCustomerSpecificationTypes)),
                    tap(([id, customerSpecificationTypes]) => {
                        const v = customerSpecificationTypes.find((cst) => cst.Id === id);
                        if (v.Name.toLocaleLowerCase().includes('gericht') && this.GerichtsortForm.disabled) {
                            this.GerichtsortForm.enable();
                        } else if (!v.Name.toLocaleLowerCase().includes('gericht') && this.GerichtsortForm.enabled) {
                            this.GerichtsortForm.disable();
                            this.GerichtsortForm.setValue('');
                        }
                    }),
                )
                .subscribe(),
            this.AutomaticCustomerIdForm.valueChanges.subscribe((v) => {
                if (v && this.CustomerIdForm.enabled) {
                    this.CustomerIdForm.disable();
                    this.CustomerIdForm.setValue(null);
                } else if (!v && this.CustomerIdForm.disabled) {
                    this.CustomerIdForm.enable();
                    this.CustomerIdForm.setValue(this.Customer.CustomerNo);
                }
            }),
        );
        this.MandateTypeIdForm.valueChanges.subscribe(() => {
            this.updateDebitorOrCreditorLabel();
        });
    }

    updateDebitorOrCreditorLabel() {
        if (this.ProfileData && this.ProfileData.Properties) {
            const debitorOrCreditorField = this.ProfileData.Properties.find((prop) => prop.key === 'Debitorennummer' || prop.key === 'Kreditorennummer');

            if (debitorOrCreditorField) {
                debitorOrCreditorField.key = this.debitorOrCreditorLabel;
                this.cdr.detectChanges();
            }
        }
    }

    public CompareSelectSearchChartOfAccountsOptions = (a: ChartOfAccountEntity & SelectSearchOption, b: ChartOfAccountEntity & SelectSearchOption) => a.Id === b.Id;

    public SelectSearchChartOfAccountsOptionsFunction = (search: string, option: ChartOfAccountEntity) => [option.Name, option.Number.toString()].some((v) => v && stringSearch(v, search));

    get AreFormsInvalid() {
        let isInvalid =
            this.NameForm.invalid ||
            this.CustomerIdForm.invalid ||
            this.DescriptionForm.invalid ||
            this.HomePageForm.invalid ||
            this.MandateTypeIdForm.invalid ||
            this.CustomerSpecificationTypeIdForm.invalid ||
            this.DebitorNoForm.invalid ||
            this.TaxTypeForm.invalid ||
            this.CustomerForm.invalid ||
            this.GerichtsortForm.invalid;

        if (this.Headquarter) {
            isInvalid =
                isInvalid ||
                this.HeadquarterLocationForm.invalid ||
                this.EmailForm.invalid ||
                this.InvoiceEmailForm.invalid ||
                this.PhoneNumberForm.invalid ||
                this.bankDataForm.invalid ||
                this.MobileNumberForm.invalid ||
                this.FaxNumberForm.invalid;
        }

        return isInvalid;
    }

    public get IBANForm() {
        return this.bankDataForm.get('IBAN');
    }

    public get SwiftForm() {
        return this.bankDataForm.get('Swift');
    }

    public get BankNameForm() {
        return this.bankDataForm.get('BankName');
    }

    public get BankLocationForm() {
        return this.bankDataForm.get('BankLocation');
    }

    public get BankPostalCodeForm() {
        return this.bankDataForm.get('BankPostalCode');
    }

    public get BankSortCodeForm() {
        return this.bankDataForm.get('BankSortCode');
    }

    public get UstIdForm() {
        return this.bankDataForm.get('UstId');
    }

    public get SteuernummerForm() {
        return this.bankDataForm.get('Steuernummer');
    }

    public get AutomaticAccountsReceivableLedgerForm() {
        return this.bankDataForm.get('AutomaticAccountsReceivableLedger');
    }

    ngAfterViewInit(): void {
        this.afterViewInit$.next();
        this.subscriptions.push(
            // combineLatest's Definitionen unterstützen nur maximal 6 Input-Observables
            combineLatest([
                combineLatest([this.store.select(getCustomers), this.store.select(getOffices), this.store.select(getPartner), this.store.select(getToken)]),
                this.store.select(getMandateTypes),
                this.store.select(getCustomerTypes),
                this.store.select(getCustomerSpecificationTypes),
                combineLatest([
                    this.CN.GetMultiple$(),
                    this.store.select(getOwnPartnerOffices),
                    this.store.select(getPerson2Entities).pipe(
                        filter(isNotNullOrUndefined),
                        map((pes) => pes.filter((pe) => pe.EntityType === Person2EntityEntityTypeEnum.Customer)),
                    ),
                ]),
                getFetched$(this.store, getChartOfAccountsFetched, getChartOfAccounts),
            ]).subscribe(([[customers, offices, partner, token], mandateTypes, customerTypes, customerSpecificationTypes, [customerNameMultiple, ownPartnerOffices, p2e], charts]) => {
                this.person2Entity = p2e.filter((p2e) => p2e.EntityType === Person2EntityEntityTypeEnum.Customer);
                this.customerList = customers;
                this.officeList = offices;
                this.Partner = partner;
                this.token = token;
                this.MandateTypeList = mandateTypes;
                this.CustomerTypes = customerTypes;
                this.selectProfileByUrl();
                const Sachkonto = charts?.find((ca) => ca?.Id === this.Customer?.SachkontoCOAId);
                const Konto = charts?.find((ca) => ca?.Id === this.Customer?.KontoCOAId);
                // this.customerSpecificationTypes = customerSpecificationTypes;
                const customerSpecificationType = this.Customer?.CustomerSpecificationTypeId ? customerSpecificationTypes.find((cst) => cst.Id === this.Customer.CustomerSpecificationTypeId) || null : null;
                const PartnerOffice = this.Customer.PartnerOfficeId ? ownPartnerOffices.find((po) => po.Id === this.Customer.PartnerOfficeId) || null : null;
                if (!customerSpecificationType?.Name.toLocaleLowerCase().includes('gericht')) {
                    this.GerichtsortForm.disable();
                }
                const customerLabel = this.Customer.IsSupplier ? 'Lieferanten' : customerNameMultiple;
                this.BankData = this.Headquarter
                    ? {
                          HeaderIcon: 'university' as IconProp,
                          Headline: 'Bankdaten',
                          Properties: [
                              {
                                  key: 'IBAN',
                                  formControl: this.IBANForm,
                                  value: this.Headquarter.IBAN || '',
                              },
                              {
                                  key: 'Swift',
                                  formControl: this.SwiftForm,
                                  value: this.Headquarter.Swift || '',
                              },
                              {
                                  key: 'Bankname',
                                  formControl: this.BankNameForm,
                                  value: this.Headquarter.BankName || '',
                              },
                              {
                                  key: 'Bank Ort',
                                  formControl: this.BankLocationForm,
                                  value: this.Headquarter.BankLocation || '',
                              },
                              {
                                  key: 'Bank PLZ',
                                  formControl: this.BankPostalCodeForm,
                                  value: this.Headquarter.BankPostalCode || '',
                              },
                              {
                                  key: 'BLZ',
                                  formControl: this.BankSortCodeForm,
                                  value: this.Headquarter.BankSortCode || '',
                              },

                              // {
                              //     key: 'Automatische Buchungspositionen',
                              //     formControl: this.AutomaticAccountsReceivableLedgerForm,
                              //     value: this.Customer.AutomaticAccountsReceivableLedger == 1,
                              //     options: {
                              //         type: CustomPropertyType.Boolean,
                              //         specialInput: {boolean: true},
                              //         showHint: 'Sollen Buchungspositionen für Events dieses Kunden automatisch erzeugt werden?'
                              //     }
                              // },
                          ],
                      }
                    : null;

                const Properties: IProfileTemplateDataProperty[] = [
                    {
                        icon: 'building' as IconProp,
                        value: this.Customer ? this.Customer.Name : null,
                        key: CustomerEntity.EntityPropertyNames.get('Name'),
                        formControl: this.NameForm,
                        options: {
                            type: CustomPropertyType.Title,
                        },
                    },
                    {
                        icon: 'sticky-note' as IconProp,
                        value: this.Customer ? this.Customer.Description : null,
                        key: CustomerEntity.EntityPropertyNames.get('Description'),
                        formControl: this.DescriptionForm,
                    },
                ];
                if (ownPartnerOffices?.length > 1) {
                    Properties.push({
                        value: PartnerOffice !== null ? ownPartnerOffices?.find((po) => po.Id === PartnerOffice.Id).Name : null,
                        key: 'Unternehmen',
                        formControl: this.PartnerOfficeForm,
                        options: {
                            specialInput: {
                                select: ownPartnerOffices?.map((c) => ({
                                    optionValue: c.Id,
                                    optionLabel: c.Name,
                                })),
                            },
                        },
                    });
                }
                Properties.push(
                    {
                        icon: 'hashtag' as IconProp,
                        value: this.Customer ? this.Customer.CustomerNo : null,
                        key: customerLabel + 'nummer',
                        formControl: this.CustomerIdForm,
                        options: {
                            type: CustomPropertyType.Subtitle,
                        },
                    },
                    {
                        key: customerLabel + 'nummer automatisch vergeben',
                        formControl: this.AutomaticCustomerIdForm,
                        options: {
                            type: CustomPropertyType.Boolean,
                            specialInput: { boolean: true },
                            showHint: 'Erzeugt eine ' + customerLabel + ' aus Jahr, Monat und einer fortlaufenden Nummer nach dem Muster K202001-1.',
                        },
                    },
                    {
                        icon: 'hashtag' as IconProp,
                        value: customerSpecificationType?.Name,
                        key: customerLabel + 'art',
                        formControl: this.CustomerSpecificationTypeIdForm,
                        options: {
                            specialInput: {
                                select: customerSpecificationTypes.map((m) => ({
                                    optionValue: m.Id,
                                    optionLabel: m.Name,
                                })),
                            },
                        },
                    },
                    {
                        icon: 'map-marker-alt',
                        key: 'Standort',
                        options: {
                            type: CustomPropertyType.Location,
                            specialInput: {
                                location: {
                                    value: this.Headquarter,
                                    formGroup: this.HeadquarterLocationForm,
                                },
                            },
                        },
                        value: getAddressString(this.Headquarter),
                    },

                    {
                        icon: 'phone' as IconProp,
                        value: this.Headquarter ? this.Headquarter.PhoneNumber : null,
                        key: 'Telefonnummer',
                        formControl: this.PhoneNumberForm,
                        options: { type: CustomPropertyType.Tel },
                    },
                    {
                        icon: 'fax' as IconProp,
                        value: this.Headquarter ? this.Headquarter.FaxNumber : null,
                        key: 'Faxnummer',
                        formControl: this.FaxNumberForm,
                        options: { type: CustomPropertyType.Tel },
                    },
                    {
                        icon: 'envelope' as IconProp,
                        value: this.Headquarter ? this.Headquarter.Email : null,
                        key: 'E-Mail',
                        formControl: this.EmailForm,
                        options: {
                            type: CustomPropertyType.Mailto,
                        },
                    },
                    {
                        icon: 'envelope' as IconProp,
                        value: this.Headquarter ? this.Headquarter.InvoiceEmail : null,
                        key: 'Rechnungs E-Mail',
                        formControl: this.InvoiceEmailForm,
                        options: {
                            type: CustomPropertyType.Mailto,
                        },
                    },
                    {
                        icon: 'globe' as IconProp,
                        value: this.Customer ? this.Customer.Homepage : null,
                        key: CustomerEntity.EntityPropertyNames.get('Homepage'),
                        formControl: this.HomePageForm,
                        options: {
                            type: CustomPropertyType.Link,
                        },
                    },
                    {
                        icon: 'mobile-alt' as IconProp,
                        value: this.Headquarter ? this.Headquarter.MobileNumber : null,
                        key: 'Mobilnummer',
                        formControl: this.MobileNumberForm,
                        options: { type: CustomPropertyType.Tel },
                    },
                    {
                        icon: 'funnel-dollar' as IconProp,
                        value: this.Customer.TaxType ? TaxTypeNames.get(this.Customer.TaxType) : '',
                        key: 'Steuerart',
                        formControl: this.TaxTypeForm,
                        options: {
                            specialInput: {
                                select: Object.values(TaxTypes).map((value) => ({ optionValue: value, optionLabel: TaxTypeNames.get(value) })),
                            },
                        },
                    },

                    {
                        icon: 'university' as IconProp,
                        value: this.MandateType ? this.MandateType.Name : null,
                        key: 'Mandatentyp',
                        formControl: this.MandateTypeIdForm,
                        options: {
                            specialInput: {
                                select: this.MandateTypeList.map((m) => ({
                                    optionValue: m.Id,
                                    optionLabel: m.Name,
                                })),
                            },
                        },
                    },
                    {
                        icon: 'credit-card' as IconProp,
                        value: this.Customer ? this.Customer.DebitorNo : null,
                        key: this.debitorOrCreditorLabel,
                        formControl: this.DebitorNoForm,
                    },
                    {
                        value: this.Customer ? this.Customer.UstId : null,
                        key: 'Umsatzsteueridentifikationsnummer',
                        formControl: this.UstIdForm,
                    },
                    {
                        value: this.Customer ? this.Customer.Steuernummer : null,
                        key: CustomerEntity.EntityPropertyNames.get('Steuernummer'),
                        formControl: this.SteuernummerForm,
                    },
                    {
                        value: isNotNullOrUndefined(this.Customer.Sales) ? this.Customer.Sales + '' : null,
                        key: CustomerEntity.EntityPropertyNames.get('Sales'),
                        formControl: this.CustomerForm.controls.Sales,
                        options: {
                            specialInput: {
                                number: true,
                            },
                        },
                    },
                    {
                        value: isNotNullOrUndefined(Sachkonto) ? Sachkonto?.Name + ' / ' + Sachkonto?.Number : null,
                        key: 'Sachkonto',
                        formControl: this.CustomerForm.controls.Sachkonto,
                        options: {
                            specialInput: {
                                customTemplate: this.SachkontochartOfAccountTemp,
                            },
                            // pattern: '[0-9]',
                        },
                    },
                    {
                        value: isNotNullOrUndefined(Konto) ? Konto?.Name + ' / ' + Konto?.Number : null,
                        key: 'Konto',
                        formControl: this.CustomerForm.controls.Konto,
                        options: {
                            specialInput: {
                                customTemplate: this.KontochartOfAccountTemp,
                            },
                            // pattern: '[0-9]',
                        },
                    },
                    {
                        value: isNotNullOrUndefined(this.Customer.PaymentTargetInDays) ? this.Customer.PaymentTargetInDays + ` Tag${this.Customer.PaymentTargetInDays === 1 ? '' : 'e'}` : null,
                        key: 'Zahlungsziel',
                        formControl: this.CustomerForm.controls.PaymentTargetInDays,
                        options: {
                            specialInput: {
                                number: true,
                            },
                            // pattern: '[0-9]',
                            suffix: 'Tage',
                        },
                    },
                    {
                        value: isNotNullOrUndefined(this.Customer.PaymentDiscount) ? this.Customer.PaymentDiscount + ' %' : null,
                        key: 'Skonto',
                        formControl: this.CustomerForm.controls.PaymentDiscount,
                        options: {
                            specialInput: {
                                number: true,
                            },
                            suffix: '%',
                        },
                    },
                    {
                        value: isNotNullOrUndefined(this.Customer.EmployeeNumber) ? this.Customer.EmployeeNumber + '' : null,
                        key: CustomerEntity.EntityPropertyNames.get('EmployeeNumber'),
                        formControl: this.CustomerForm.controls.EmployeeNumber,
                        options: {
                            specialInput: {
                                number: true,
                            },
                        },
                    },
                    {
                        key: 'Status',
                        formControl: this.StatusForm,
                        value: this.Customer.CustomerStatus && CustomerStatusTypeNames.get(this.Customer.CustomerStatus),
                        options: {
                            specialInput: {
                                select: Object.values(CustomerStatusEnum).map((val) => ({
                                    optionValue: val,
                                    optionLabel: CustomerStatusTypeNames.get(val),
                                })),
                            },
                        },
                    },
                );
                this.ProfileData = this.Customer
                    ? {
                          Icon: 'building',
                          Properties,
                      }
                    : null;
                this.cdr.detectChanges();
            }),
            this.NewBookingPositionClick$.pipe(
                withLatestFrom(this.CustomerId$),
                tap(([, customerId]) => {
                    this.dialog.open<NewBookingOptionComponent, NewOptionDialogData>(NewBookingOptionComponent, {
                        panelClass: 'custom-dialog-class-without-padding',
                        data: customerId
                            ? {
                                  newOptionDefaultValues: {
                                      CustomerId: customerId,
                                  },
                              }
                            : null,
                    });
                }),
            ).subscribe(),
        );

        this.DisplayedUsers$ = combineLatest([
            this.store.select(getManagedUsers),
            this.route.paramMap.pipe(
                filter((params) => params.has('customerId')),
                switchMap((params) =>
                    combineLatest([
                        this.store.select(getCustomerById({ id: +params.get('customerId') })),
                        this.store.select(getUserToCustomersFetched).pipe(
                            filter((v) => !!v),
                            switchMap(() => this.store.select(getUserToCustomers)),
                            map((u2cs) => u2cs.filter((u2c) => u2c.CustomerId === +params.get('customerId'))),
                        ),
                    ]),
                ),
            ),
            this.store.select(getUser),
            this.store.select(getActiveEmployeesSortedByNameStartWithMe),
        ]).pipe(
            map(([users, [Customer, u2cs], user, employees]) => {
                if (this.customerList == null) {
                    return;
                }
                const UsersIds = users.filter((u) => u.PartnerId === this.Customer.PartnerId && u2cs.some((u2c) => u2c.UserId === u.Id)).map((u) => u.Id);

                return employees
                    .filter((e) => UsersIds.includes(e.UserId))
                    .map((e) => {
                        return { User: e, ItsMe: e.UserId === user.Id };
                    });
            }),
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    GetRouteForPerson = ({ Id }: { Id: number }) => ['/', ContactBookMeta.Path, Id];

    @HostListener('window:beforeunload')
    // tslint:disable-next-line:naming-convention
    canDeactivate(): Observable<boolean> | boolean {
        const pendingChanges =
            this.Editing &&
            (this.NameForm.dirty ||
                this.CustomerIdForm.dirty ||
                this.DescriptionForm.dirty ||
                this.HomePageForm.dirty ||
                this.MandateTypeIdForm.dirty ||
                this.CustomerSpecificationTypeIdForm.dirty ||
                this.GerichtsortForm.dirty ||
                this.HeadquarterLocationForm.dirty ||
                this.EmailForm.dirty ||
                this.PhoneNumberForm.dirty ||
                this.bankDataForm.dirty ||
                this.MobileNumberForm.dirty ||
                this.FaxNumberForm.dirty ||
                this.DebitorNoForm.dirty ||
                this.TaxTypeForm.dirty ||
                this.CustomerForm.dirty);
        return !pendingChanges;
    }
    public CreateEvent() {
        var dialogRef = this.dialog.open(NewOnSiteEventComponent, {
            ...NewOnSiteEventComponent.DefaultConfig,
            data: {
                CustomerId: this.Customer.Id,
                IsNotOrtstermin: true,
                Street: this.Headquarter.Street,
                PostalCode: this.Headquarter.PostalCode,
                City: this.Headquarter.City,
                Country: this.Headquarter.Country,
            } as NewOnSiteEventComponentDialogData,
        });
        dialogRef.componentInstance.AddPersonClicked.pipe(takeUntil(dialogRef.afterClosed())).subscribe(() => this.OpenPersonDialog());
    }

    get debitorOrCreditorLabel(): string {
        return this.MandateTypeIdForm.value === 2 ? 'Kreditorennummer' : 'Debitorennummer';
    }

    public OpenEdit() {
        this.Editing = true;

        this.CustomerForm.reset({
            EmployeeNumber: this.Customer.EmployeeNumber,
            Sales: this.Customer.Sales,
            Sachkonto: this.Customer?.SachkontoCOAId ? this.SachkontoChartOfAccounts?.find((charts) => charts?.Id === this.Customer?.SachkontoCOAId) : null,
            Konto: this.Customer.KontoCOAId ? this.KontoChartOfAccounts?.find((chart) => chart?.Id === this.Customer?.KontoCOAId) : null,
            PaymentTargetInDays: this.Customer.PaymentTargetInDays,
            PaymentDiscount: this.Customer.PaymentDiscount,
        });
        this.DescriptionForm.setValue(this.Customer.Description);
        this.NameForm.setValue(this.Customer.Name);
        this.CustomerSpecificationTypeIdForm.setValue(this.Customer.CustomerSpecificationTypeId);
        this.CustomerIdForm.setValue(this.Customer.CustomerNo);
        this.AutomaticCustomerIdForm.setValue(false);
        this.CustomerTypesForm.setValue(this.Customer.CustomerTypeIds.map((id) => this.CustomerTypes.find((c) => c.Id === id)).filter(isNotNullOrUndefined));
        this.GerichtsortForm.setValue(this.Customer.Gerichtsort);
        this.HomePageForm.setValue(this.Customer.Homepage);
        this.MandateTypeIdForm.setValue(this.Customer.MandateTypeId);
        this.PartnerOfficeForm.setValue(this.Customer.PartnerOfficeId);
        this.DebitorNoForm.setValue(this.Customer.DebitorNo);
        this.TaxTypeForm.setValue(this.Customer.TaxType);
        this.StatusForm.setValue(this.Customer.CustomerStatus);
        if (this.Headquarter) {
            this.HeadquarterLocationForm.setValue({
                Street: this.Headquarter.Street,
                PostalCode: this.Headquarter.PostalCode,
                City: this.Headquarter.City,
                Country: this.Headquarter.Country,
            });
            this.PhoneNumberForm.setValue(this.Headquarter.PhoneNumber);
            this.EmailForm.setValue(this.Headquarter.Email);
            this.InvoiceEmailForm.setValue(this.Headquarter.InvoiceEmail);
            this.bankDataForm.setValue({
                BankLocation: this.Headquarter.BankLocation,
                BankName: this.Headquarter.BankName,
                BankPostalCode: this.Headquarter.BankPostalCode,
                BankSortCode: this.Headquarter.BankSortCode,
                IBAN: this.Headquarter.IBAN,
                Swift: this.Headquarter.Swift,
                UstId: this.Customer.UstId,
                Steuernummer: this.Customer.Steuernummer,
                AutomaticAccountsReceivableLedger: this.Customer.AutomaticAccountsReceivableLedger == 1,
            });
            this.MobileNumberForm.setValue(this.Headquarter.MobileNumber);
            this.FaxNumberForm.setValue(this.Headquarter.FaxNumber);
        }
    }

    public CloseEdit() {
        this.Editing = false;
        this.selectProfileByUrl();
        this.EditedCustomerImage = null;
    }

    public Save() {
        if (this.AreFormsInvalid) {
            console.log('invalid ');
            return;
        }
        const changeCustomerArgs: DaveMutationChangeCustomerArgs = {
            customerNo: this.CustomerIdForm.value,
            automaticCustomerNo: this.AutomaticCustomerIdForm.value,
            customerTypeIds: this.CustomerTypesForm.value && this.CustomerTypesForm.value.map((v) => v.Id),
            description: this.DescriptionForm.value,
            employeeNumber: this.CustomerForm.value.EmployeeNumber,
            homepage: this.HomePageForm.value,
            hqLocation: this.HeadquarterLocationForm.value.City ? this.HeadquarterLocationForm.value.City.trim() : null,
            id: this.Customer.Id,
            mandateTypeIds: this.MandateTypeIdForm.value ? [this.MandateTypeIdForm.value] : [],
            partnerOfficeId: this.PartnerOfficeForm.value,
            name: this.NameForm.value,
            sales: this.CustomerForm.value.Sales,
            customerSpecificationTypeId: this.CustomerSpecificationTypeIdForm.value,
            gerichtsort: this.GerichtsortForm.value,
            ustId: this.UstIdForm.value,
            steuernummer: this.SteuernummerForm.value,
            automaticAccountsReceivableLedger: this.AutomaticAccountsReceivableLedgerForm.value ? 1 : 2,
            debitorNo: this.DebitorNoForm.value,
            taxType: this.TaxTypeForm.value as any,
            customerStatus: this.StatusForm.value,
            kontoCOAId: this.CustomerForm.value.Konto?.Id ? this.CustomerForm.value?.Konto?.Id : null,
            paymentTargetInDays: this.CustomerForm.value.PaymentTargetInDays,
            paymentDiscount: this.CustomerForm.value.PaymentDiscount,
            sachkontoCOAId: this.CustomerForm.controls.Sachkonto.value?.Id ? this.CustomerForm.value?.Sachkonto?.Id : null,
        };
        const fileList: FileList = this.fileList;
        if (fileList && fileList.length) {
            const file: File = fileList[0];

            const formData: FormData = new FormData();
            formData.append('files', file, file.name);
            formData.append('CustomerProfileId', this.Customer.Id.toString());
            const headers = new HttpHeaders().append('Accept', 'application/json').append('Authorization', `Bearer ${this.token}`);
            if (this.Customer.ImageId) {
                this.store.dispatch(
                    FilesActionTypes.DeleteFileRequest({
                        Payload: { DocumentId: this.Customer.ImageId.toString() },
                    }),
                );
                this.Customer = this.Customer.Clone();
            }
            const subs = this.http.post(this.api.GetUrl(`files`, 'gateway'), formData, { headers });
            subs.pipe(
                catchError((error) => {
                    console.error(error);
                    return of(error);
                }),
            );
            subs.subscribe(() => {
                this.store.dispatch(
                    // refresh customer image
                    CustomerActionTypes.ModifyCustomer({
                        Payload: changeCustomerArgs,
                    }),
                );
            });
        }

        this.store.dispatch(CustomerActionTypes.ModifyCustomer({ Payload: changeCustomerArgs }));

        if (!this.Headquarter) {
            // return early if no Headquarter
            return this.CloseEdit();
        } // else, edit Headquarter

        this.HeadquarterLocationForm.setValue({
            Street: this.HeadquarterLocationForm.value.Street ? this.HeadquarterLocationForm.value.Street.trim() : this.HeadquarterLocationForm.value.Street,
            PostalCode: this.HeadquarterLocationForm.value.PostalCode ? this.HeadquarterLocationForm.value.PostalCode.trim() : this.HeadquarterLocationForm.value.PostalCode,
            City: this.HeadquarterLocationForm.value.City ? this.HeadquarterLocationForm.value.City.trim() : this.HeadquarterLocationForm.value.City,
            Country: this.HeadquarterLocationForm.value.Country ? this.HeadquarterLocationForm.value.Country.trim() : this.HeadquarterLocationForm.value.Country,
        });

        if (
            this.Headquarter.Country !== this.HeadquarterLocationForm.value.Country ||
            this.Headquarter.Street !== this.HeadquarterLocationForm.value.Street ||
            this.Headquarter.City !== this.HeadquarterLocationForm.value.City ||
            this.Headquarter.PostalCode !== this.HeadquarterLocationForm.value.PostalCode
        ) {
            this.googleGeocoderService.Geocode(getAddressString(this.HeadquarterLocationForm.value)).subscribe(({ Latitude, Longitude }) => {
                this.dispatchModifyOffice(Latitude, Longitude);
                this.CloseEdit();
            });
        } else {
            this.dispatchModifyOffice();
            this.CloseEdit();
        }
    }

    DeleteCustomer(): void {
        this.appDialog
            .OpenConfirmationDialog({
                paragraph: `${this.CN.GetSingle()} ${this.Customer.Name} wirklich löschen?`,
                styleDelete: true,
            })
            .subscribe(([result]) => {
                if (result) {
                    this.ShowNotFoundError = false;
                    this.customers$
                        .pipe(skip(1), first()) // Erst aus der Komponente navigieren,
                        .subscribe(() => {
                            // nachdem die neue Customerliste vom Server zurückgekommen ist
                            this.router.navigateByUrl('/' + CustomerAdministrationMeta.Path);
                        });
                    this.store.dispatch(
                        CustomerActionTypes.DeleteCustomer({
                            Payload: this.Customer.Id,
                        }),
                    );
                }
            });
    }

    public RateCustomer(userRanking: number) {
        this.store.dispatch(
            CustomerActionTypes.ModifyCustomer({
                Payload: {
                    id: this.Customer.Id,
                    userRanking,
                    gerichtsort: this.Customer.Gerichtsort,
                    partnerOfficeId: this.Customer.PartnerOfficeId,
                },
            }),
        );
    }

    public OpenUserSelect() {
        this.dialog.open<SelectUserCustomerDialogComponent, SelectUserCustomerDialogComponentDialogData>(SelectUserCustomerDialogComponent, {
            ...SelectUserCustomerDialogComponent.DefaultConfig,
            data: {
                customerId: this.Customer.Id,
            },
        });
    }

    public OpenPersonDialog(complaintType: ComplaintTypesEnum | null = null) {
        this.store
            .select(getPerson2Entities)
            .pipe(
                take(1),
                map((pes) => pes.filter((pe) => pe.EntityType === Person2EntityEntityTypeEnum.Customer && pe.EntityId === this.Customer.Id)),
                map((pes) => pes.map((pe) => pe.PersonId)),
            )
            .subscribe((selectedIds) => {
                this.dialog
                    .open<SelectPersonsComponent, SelectPersonsComponentDialogData, PersonEntity[]>(SelectPersonsComponent, {
                        ...SelectPersonsComponent.DefaultConfig,
                        data: {
                            HeaderText: "Ansprechpartner verwalten",
                            ButtonText: this.Customer.AnsprechpartnerText(),
                            SelectedPersonIds: selectedIds,
                            newPersonDefaultValues: {
                                Organisation: this.Customer.DisplayName,
                            },
                        },
                    })
                    .afterClosed()
                    .pipe(
                        filter(isNotNullOrUndefined),
                        withLatestFrom(
                            this.store.select(getPerson2Entities).pipe(
                                filter(isNotNullOrUndefined),
                                map((pes) => pes.filter((pe) => pe.EntityType === Person2EntityEntityTypeEnum.Customer && pe.EntityId === this.Customer.Id)),
                            ),
                            this.store.select(getComplaintTypes),
                        ),
                    )
                    .subscribe(([selectedPersons, personEntities, personTypes]) => {
                        let addIds: number[] = [];
                        let delIds: number[] = [];

                        selectedPersons.forEach((p) => {
                            if (personEntities.filter((pe) => pe.PersonId === p.Id).length === 0) {
                                addIds.push(p.Id);
                            }
                        });

                        personEntities.forEach((pe) => {
                            if (selectedPersons.filter((p) => p.Id === pe.PersonId).length === 0) {
                                delIds.push(pe.Id);
                            }
                        });

                        addIds.forEach((pId) => {
                            this.store.dispatch(
                                Person2EntityActionTypes.AddPerson2Entity({
                                    Payload: {
                                        personId: pId,
                                        entityId: this.Customer.Id,
                                        entityType: Person2EntityEntityTypeEnum.Customer,
                                        complaintTypeId: complaintType ? personTypes?.find((p) => p.Name === complaintType)?.Id : null,
                                    },
                                }),
                            );
                        });

                        delIds.forEach((id) => {
                            this.store.dispatch(
                                Person2EntityActionTypes.DeletePerson2Entity({
                                    Payload: {
                                        id: id,
                                    },
                                }),
                            );
                        });
                    });
            });
    }

    private dispatchModifyOffice(Latitude: number = null, Longitude: number = null) {
        this.store.dispatch(
            OfficeActionTypes.Modify({
                Payload: {
                    bankLocation: this.BankLocationForm.value,
                    bankName: this.BankNameForm.value,
                    bankPostalCode: this.BankPostalCodeForm.value,
                    bankSortCode: this.BankSortCodeForm.value,
                    city: this.HeadquarterLocationForm.value.City,
                    country: this.HeadquarterLocationForm.value.Country,
                    customerId: this.Headquarter.CustomerId,
                    email: this.EmailForm.value,
                    invoiceEmail: this.InvoiceEmailForm.value,
                    faxNumber: this.FaxNumberForm.value,
                    iBAN: this.IBANForm.value,
                    id: this.Headquarter.Id,
                    isHeadquarter: this.Headquarter.IsHeadquarter,
                    latitude: Latitude !== null ? Latitude : +this.Headquarter.Latitude,
                    longitude: Longitude !== null ? Longitude : +this.Headquarter.Longitude,
                    mobileNumber: this.MobileNumberForm.value,
                    phoneNumber: this.PhoneNumberForm.value,
                    postalCode: this.HeadquarterLocationForm.value.PostalCode,
                    street: this.HeadquarterLocationForm.value.Street,
                    swift: this.SwiftForm.value,
                },
            }),
        );
    }

    private selectProfileByUrl() {
        const customerId: number = +this.route.snapshot.paramMap.get('customerId');
        if (this.customerList == null) {
            return;
        }
        this.Customer = this.customerList.find((value) => value.Id === customerId);

        if (!this.Customer && this.ShowNotFoundError) {
            return this.apiToasterService.error('Es konnte kein ' + this.CN.GetSingle() + ' gefunden werden.');
        }

        this.CustomerTypesForm.setValue(this.Customer.CustomerTypeIds.map((id) => this.CustomerTypes.find((c) => c.Id === id)).filter(isNotNullOrUndefined));

        this.Headquarter = this.officeList == null ? null : this.officeList.find((value) => value.IsHeadquarter && value.CustomerId === this.Customer.Id);

        this.MandateType = this.MandateTypeList && this.MandateTypeList.find((mandateType) => mandateType.Id === this.Customer.MandateTypeId);

        this.selectedProfileByUrl$.next(this.Customer.Id);
    }
    public OpenUploadDialog() {
        this.dialog.open<DaveFileUploadDialogComponent, DaveFileUploadDialogComponentDialogData>(DaveFileUploadDialogComponent, {
            ...DaveFileUploadDialogComponent.DefaultConfig,
            data: {
                CustomerId: this.Customer.Id,
                FolderId: this.UploadFolderId,
            },
        });
    }

    protected readonly ContactBookMeta = ContactBookMeta;

    newChartOfAccountPopUp(entity?: ChartOfAccountEntity, accountType?: AccountTypeEnum) {
        const chartOfAccount = entity ? this.ChartOfAccounts.find((chart) => chart.Id === entity.Id) : null;

        const name = new FormControl<string>(chartOfAccount?.Name ? chartOfAccount.Name : '');
        const description = new FormControl<string>(chartOfAccount?.Description ? chartOfAccount.Description : '');
        const number = new FormControl<number>(chartOfAccount?.Number ? chartOfAccount.Number : null, Validators.required);

        this.dialog
            .open<DetailListTemplateDialogComponent, DetailListTemplateDialogData, DetailListDialogReturn>(DetailListTemplateDialogComponent, {
                ...DetailListTemplateDialogComponent.DefaultConfig,
                data: {
                    DisableSaveButton$: number.statusChanges.pipe(
                        startWith(number.status),
                        map((state) => state !== 'VALID'),
                    ),
                    Editing: true,
                    DeleteButton: !!chartOfAccount,
                    Data: {
                        Headline: chartOfAccount ? (chartOfAccount.Name ? chartOfAccount.Name + ' bearbeiten' : 'Konto bearbeiten') : 'Neues Konto anlegen',
                        Properties: [
                            {
                                key: 'Name',
                                formControl: name,
                            },
                            {
                                key: 'Nummer',
                                formControl: number,
                                options: {
                                    specialInput: {
                                        number: true,
                                    },
                                },
                            },
                            {
                                key: 'Bezeichnung',
                                formControl: description,
                            },
                        ].filter(isNotNullOrUndefined),
                    },
                },
            })
            .afterClosed()
            .subscribe((ret) => {
                if (ret.Action === 'delete') {
                    this.store.dispatch(ChartOfAccountActionTypes.Delete({ Payload: { id: chartOfAccount.Id } }));
                }
                if (ret.Action === 'save') {
                    if (!chartOfAccount) {
                        this.store.dispatch(
                            ChartOfAccountActionTypes.Add({
                                Payload: {
                                    description: description.value,
                                    name: name.value,
                                    number: number.value,
                                    accountType: accountType,
                                },
                            }),
                        );
                        this.actions$.pipe(ofType(ChartOfAccountActionTypes.AddSuccess)).subscribe((res) => {
                            if (res.Payload) {
                                if (res.Payload.AccountType === AccountTypeEnum.Contra) {
                                    this.CustomerForm.controls.Sachkonto.setValue(Object.assign(res.Payload));
                                }
                                if (res.Payload.AccountType === AccountTypeEnum.Normal) {
                                    this.CustomerForm.controls.Konto.setValue(Object.assign(res.Payload));
                                }
                            }
                        });
                    } else {
                        this.store.dispatch(
                            ChartOfAccountActionTypes.ChangeChartOfAccount({
                                Payload: {
                                    id: chartOfAccount.Id,
                                    description: description.value,
                                    name: name.value,
                                    number: number.value,
                                    accountType: chartOfAccount.AccountType,
                                },
                            }),
                        );
                        this.actions$.pipe(ofType(ChartOfAccountActionTypes.ChangeSuccess)).subscribe((res) => {
                            if (res.Payload) {
                                if (res.Payload.AccountType === AccountTypeEnum.Contra) {
                                    this.CustomerForm.controls.Sachkonto.setValue(Object.assign(res.Payload));
                                }
                                if (res.Payload.AccountType === AccountTypeEnum.Normal) {
                                    this.CustomerForm.controls.Konto.setValue(Object.assign(res.Payload));
                                }
                            }
                        });
                    }
                }
            });
    }

    protected readonly AccountTypeEnum = AccountTypeEnum;
    onCreateLedgerImportClick(docType: LedgerImportDocumentTypes) {
        if (docType) {
            firstValueFrom(combineLatest([this.Customer$, this.store.select(getAccountsReceivableLedgers)])).then(([customer, bookingPositions]) => {
                switch (docType) {
                    case LedgerImportDocumentTypes.PartialInvoice:
                        this.dialog
                            .open<NewPartialInvoiceComponent, NewPartialInvoiceComponentDialogData, NewPartialInvoiceComponentDialogReturnData>(NewPartialInvoiceComponent, {
                                ...NewPartialInvoiceComponent.DefaultConfig,
                                data: {
                                    FilterLedgerImports: {
                                        CustomerId: customer.Id,
                                    },
                                },
                            })
                            .afterClosed()
                            .subscribe((ret) => {
                                console.log(ret);
                                if (ret?.LedgerImportCreated) {
                                    this.router.navigate(['/', ReportsPageMeta.Path, AllReportsMeta.Path, InvoiceEditorMeta.Path, ret.LedgerImportCreated]);
                                }
                            });
                        break;
                    case LedgerImportDocumentTypes.ClosingInvoice:
                        this.dialog
                            .open<NewClosingInvoiceComponent, NewClosingInvoiceComponentDialogData, NewClosingInvoiceComponentDialogReturnData>(NewClosingInvoiceComponent, {
                                ...NewClosingInvoiceComponent.DefaultConfig,
                                data: {
                                    FilterLedgerImports: {
                                        CustomerId: customer.Id,
                                    },
                                },
                            })
                            .afterClosed()
                            .subscribe((ret) => {
                                console.log(ret);
                                if (ret?.LedgerImportCreated) {
                                    this.router.navigate(['/', ReportsPageMeta.Path, AllReportsMeta.Path, InvoiceEditorMeta.Path, ret.LedgerImportCreated]);
                                }
                            });
                        break;
                    default:
                        this.dialog.open<NewReportDialogComponent, NewReportDialogComponentDialogData>(NewReportDialogComponent, {
                            ...NewReportDialogComponent.DefaultConfig,
                            data: {
                                DefaultValues: {
                                    accountsReceivableLedgerIds: docType !== LedgerImportDocumentTypes.Offer ? bookingPositions?.filter((b) => b.CustomerId === customer.Id && !b.LedgerImportId)?.map((b) => b.Id) : [],
                                    customerId: customer.Id,
                                    documentType: docType,
                                    partnerOfficeId: customer?.PartnerOfficeId,
                                },
                            },
                        });
                }
            });
        } else {
            firstValueFrom(this.Customer$).then(customer => {
                this.newReportDialogService.OpenNewReportDialog({ DefaultValues: { customerId: customer.Id } });
            });
        }
    }
}
