import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog, MatDialogConfig, MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Store } from '@ngrx/store';
import { map, startWith, switchMap, tap } from 'rxjs/operators';
import { CustomerResolver } from '../../../dave-data-module/guards/customer.resolver';
import { getFetched$ } from '../../../dave-data-module/helper/helper';
import { MaterialToSupplierDataService } from '../../../dave-data-module/services/material-to-supplier-data.service';
import { State } from '../../../dave-data-module/State';
import {
    getCustomersFetched,
    getNotDeletedCustomers,
    getNotDeletedSuppliers,
} from '../../../dave-data-module/State/selectors/customers.selectors';
import { getMaterialGroups } from '../../../dave-data-module/State/selectors/material-group.selectors';
import { AppButtonModule } from '../../../dave-utils-module/app-button-module/app-button.module';
import { SelectSearchOption } from '../../../dave-utils-module/select-search/components/select-search/select-search.component';
import { SelectSearchModule } from '../../../dave-utils-module/select-search/select-search.module';
import { appMatDialogDefaultConfig, isNotNullOrUndefined } from '../../../helper/helper';
import { HintIconComponent } from '../../../hint-icon/hint-icon.component';
import { LoadingService } from '../../../services/loading.service';
import {
    MaterialPriceHistoryDataService
} from '../../../dave-data-module/services/material-price-history-data.service';

export interface EditMaterialToSupplierDialogComponentDialogData {
    customer?: SelectSearchOption<{     Id: number;   }>;
    articlenumber?: string;
    cost?: number;
    productGroup?: SelectSearchOption<{     Id: number;   }>;
    materialId: number;
}

export type EditMaterialToSupplierComponentDialogReturnData = undefined;

function requireOneControl(requiredControlNames: string[]) {
    return (formGroup: FormGroup) => {
        if (requiredControlNames.some((key) => formGroup.get(key).value)) {
            return null;
        }
        return { required: 'at least one of the items is required' };
    };
}

@Component({
    selector: 'app-edit-material-to-supplier-dialog',
    standalone: true,
    imports: [CommonModule, MatDialogModule, MatInputModule, SelectSearchModule, ReactiveFormsModule, MatCheckboxModule, AppButtonModule, MatMenuModule, FontAwesomeModule, MatTooltipModule, HintIconComponent],
    templateUrl: './edit-material-to-supplier-dialog.component.html',
    styleUrls: ['./edit-material-to-supplier-dialog.component.scss'],
})
export class EditMaterialToSupplierDialogComponent implements OnInit {
    public static readonly DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
    };
    protected form = new FormGroup({
        customer: new FormControl<SelectSearchOption<{ Id: number }>>(null, [Validators.required]),
        cost: new FormControl<number>(0),
        articlenumber: new FormControl<string>(''),
        mainProductGroup: new FormControl(null, [Validators.required]),
        productGroup: new FormControl(null, [Validators.required]),
        // materialId: new FormControl<number>(null),
    });
    protected customers$ = getFetched$(this.store, getCustomersFetched, getNotDeletedSuppliers, this.customerRes).pipe(
        map((customers) =>
            customers
                .filter((c) => true)
                .map<SelectSearchOption<{ Id: number }>>((c) => ({ Id: c.Id, optionLabel: c.DisplayName }))
        ),
    );

    protected mainProductGroups$ = this.store.select(getMaterialGroups).pipe(map((groups) => groups.filter((group) => !group.ParentId).map((group) => ({ Id: group.Id, optionLabel: group.Name }))));

    protected productGroups$ = this.form.controls.mainProductGroup.valueChanges.pipe(
        startWith(this.form.controls.mainProductGroup.value),
        switchMap((mainProductGroup) => {
            return this.store.select(getMaterialGroups).pipe(
                map((groups) => {
                    if (!mainProductGroup) {
                        return groups.filter((group) => group.ParentId).map((group) => ({ Id: group.Id, optionLabel: group.Name }));
                    }
                    return groups.filter((group) => group.ParentId === mainProductGroup.Id).map((group) => ({ Id: group.Id, optionLabel: group.Name }));
                }),
            );
        }),
    );

    constructor(
        public LS: LoadingService,
        private store: Store<State>,
        private dialog: MatDialog,
        private customerRes: CustomerResolver,
        protected ls: LoadingService,
        @Inject(MAT_DIALOG_DATA) public data: EditMaterialToSupplierDialogComponentDialogData,
        private materialToSupplierDataService: MaterialToSupplierDataService,
        private materialPriceHistoryDataService: MaterialPriceHistoryDataService,
    ) {
        if (this.data) {
            this.form.patchValue({
                customer: this.data.customer,
                cost: this.data.cost || 0,
                articlenumber: this.data.articlenumber || '',
                mainProductGroup: null,
                productGroup: this.data.productGroup || null,
                // materialId: this.data.MaterialId || null,
            });

            if (this.data.productGroup) {
                this.store
                    .select(getMaterialGroups)
                    .pipe(
                        map((groups) => {
                            const subGroup = groups.find((group) => group.Id === data.productGroup.Id);
                            const mainGroup = groups.find((group) => group.Id === subGroup?.ParentId);
                            return mainGroup;
                        }),
                    )
                    .subscribe((mainGroup) => {
                        if (mainGroup) {
                            this.form.controls.mainProductGroup.setValue(mainGroup);
                        }
                    });
            }
        }

        this.form.controls.productGroup.valueChanges.subscribe((productGroup) => {
            if (productGroup) {
                this.store
                    .select(getMaterialGroups)
                    .pipe(
                        map((groups) => {
                            const subGroup = groups.find((group) => group.Id === productGroup.Id);
                            const mainGroup = groups.find((group) => group.Id === subGroup?.ParentId);
                            return mainGroup;
                        }),
                    )
                    .subscribe((mainGroup) => {
                        if (mainGroup) {
                            this.form.controls.mainProductGroup.setValue(mainGroup);
                        }
                    });
            }
        });
    }
    ngOnInit() {
        if (this.data.customer) {
            this.form.controls.customer.disable();
        } else {
            this.form.controls.customer.enable();
        }
    }

    protected compareById(a: { Id: number }, b: { Id: number }) {
        return a.Id === b.Id;
    }

    onSubmitClick() {
        if (this.data.customer != null) {
            this.materialToSupplierDataService.change({
                MaterialId: String(this.data.materialId),
                SupplierId: String(this.data.customer.Id),
                Cost: String(Math.round(this.form.value.cost * 100)),
                ArticleNumber: String(this.form.value.articlenumber),
                MaterialGroupId: String(this.form.value.productGroup.Id),
            }).then(() => {
                this.materialPriceHistoryDataService.getMaterialPriceHistoryByMaterialId$(this.data.materialId, true)
            })
        } else {
            this.materialToSupplierDataService.add({
                MaterialId: this.data.materialId && String(this.data.materialId),
                SupplierId: this.form.value.customer?.Id && String(this.form.value.customer.Id),
                Cost: isNotNullOrUndefined(this.form.value.cost) && String(Math.round(this.form.value.cost * 100)),
                ArticleNumber: this.form.value.articlenumber ? String(this.form.value.articlenumber) : '',
                MaterialGroupId: this.form.value.productGroup.Id && String(this.form.value.productGroup.Id),
            }).then(() => {
                this.materialPriceHistoryDataService.getMaterialPriceHistoryByMaterialId$(this.data.materialId, true)
            })
        }
        this.dialog.closeAll();
    }

    onDelete() {
        this.materialToSupplierDataService.delete({
            MaterialId: String(this.data.materialId),
            SupplierId: String(this.data.customer.Id),
        });
        this.dialog.closeAll();
    }
}
