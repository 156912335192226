import { Injectable } from '@angular/core';
import { DueDateTypeCode, Invoice, InvoiceType, PaymentType, SpecifiedTradeAllowanceChargeCode, TaxCategoryCode, UnitCodes } from '@dave/types/dist/proto/einvoicing/main';
import { Store } from '@ngrx/store';
import { AppGatewayService } from '../../dave-data-module/services/app-gateway.service';
import { State } from '../../dave-data-module/State';
import { BaseActionTypes } from '../../dave-data-module/State/actions/base.actions';
export const eInvoiceTaxCategoryCodeNames = new Map<TaxCategoryCode, string>([
    [TaxCategoryCode.StandardRate, 'S'],
    [TaxCategoryCode.ZeroRate, 'Z'],
    [TaxCategoryCode.TaxExempt, 'E'],
    [TaxCategoryCode.ReversalTaxLiability, 'AE'],
    [TaxCategoryCode.NoVat, 'K'],
    [TaxCategoryCode.TaxNotLeviedExportOutsideEu, 'G'],
    [TaxCategoryCode.OutsideScopeTaxation, 'O'],
    [TaxCategoryCode.Igic, 'L'],
    [TaxCategoryCode.Ipsi, 'M'],
]);
export const eInvoiceTaxCategoryCodeDescriptions = new Map<TaxCategoryCode, string>([
    [TaxCategoryCode.StandardRate, 'Umsatzsteuer fällt mit Normalsatz an'],
    [TaxCategoryCode.ZeroRate, 'nach dem Nullsatz zu versteuernde Waren'],
    [TaxCategoryCode.TaxExempt, 'Steuerbefreit'],
    [TaxCategoryCode.ReversalTaxLiability, 'Umkehrung der Steuerschuldnerschaft'],
    [TaxCategoryCode.NoVat, 'Kein Ausweis der Umsatzsteuer bei innergemeinschaftlichen Lieferungen'],
    [TaxCategoryCode.TaxNotLeviedExportOutsideEu, 'Steuer nicht erhoben aufgrund von Export außerhalb der EU'],
    [TaxCategoryCode.OutsideScopeTaxation, ' Außerhalb des Steueranwendungsbereichs'],
    [TaxCategoryCode.Igic, 'IGIC (Kanarische Inseln)'],
    [TaxCategoryCode.Ipsi, 'IPSI (Ceuta/ Melilla)'],
]);
@Injectable({
    providedIn: 'root',
})
export class EInvoiceDataService {
    constructor(private gatewayService: AppGatewayService, private store: Store<State>) {}

    async getEInvoiceFromVersion(id: number) {
        // return of(this.getMockEInvoice());
        try {
            let res = await this.gatewayService.Request({ EInvoicingGetInvoiceData: { VersionId: id.toString() } });
            if (Object.keys(res?.Errors || {}).length === 0) {
                console.log(res.EInvoicingGetInvoiceData.Invoice)
                return res.EInvoicingGetInvoiceData.Invoice;
            } else {
                throw res.Errors;
            }
        } catch (err) {
            this.store.dispatch(
                BaseActionTypes.ErrorAction({
                    Payload: {
                        ToasterMessage: 'E-Rechnung abrufen fehlgeschlagen',
                        Err: err,
                    },
                }),
            );
        }
    }

    getMockEInvoice(): Invoice {
        const AllowanceCharges = [
            {
                ChargeIndicator: true,
                CalculationPercent: 0.3,
                BasisAmount: 15,
                ActualAmount: 17,
                ReasonCode: SpecifiedTradeAllowanceChargeCode.Discount,
                Reason: 'Geschenkgutschein',
                TaxCode: 'AAC',
                TaxCategoryCode: TaxCategoryCode.TaxExempt,
                RateApplicablePercent: 0.19,
            },
            {
                ChargeIndicator: false,
                CalculationPercent: 0.3,
                BasisAmount: 15,
                ActualAmount: 17,
                ReasonCode: SpecifiedTradeAllowanceChargeCode.Discount,
                Reason: 'Geschenkgutschein',
                TaxCode: 'AAC',
                TaxCategoryCode: TaxCategoryCode.TaxExempt,
                RateApplicablePercent: 0.19,
            },
        ];
        return Invoice.create({
            Type: InvoiceType.PrepaymentInvoice,
            BuyerReference: 'BuyerReference',
            InvoiceNumber: 'InvoiceNumber',
            InvoiceDate: new Date().getTime().toString(),
            PaymentTerms: 'Die Zahlung muss bar erfolgen',
            BillingPeriodFrom: new Date().getTime().toString(),
            BillingPeriodTo: new Date().getTime().toString(),
            CommissionNumber: 'Auftragsnummer',
            ContractNumber: 'Vertragsnummer',
            ShippingDate: new Date().getTime().toString(),
            DeliveryNoteNumber: 'ln 123',
            Note: `Hallo,

                Lorem ipsum dolor sit amet, consetetur sadipscing elitr,sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat`,
            CurrencyCode: 'EUR',
            OrderNumber: 'bestellnummer',
            DueDate: new Date().getTime().toString(),
            TotalAmount: 100,
            ChargeTotalAmount: 10,
            AllowanceTotalAmount: 10,
            TaxBasisTotalAmount: 100,
            TaxTotalAmount: 19,
            GrandTotalAmount: 119,
            TotalPrepaidAmount: 50,
            DuePayableAmount: 69,
            InvoiceReferencedId: 'VorhRechNr',
            InvoiceReferencedDate: new Date().getTime().toString(),
            TradeAccountingAccountIds: ['920354', 'Kommission uwe'],
            Seller: {
                SupplierNumber: 'Lieferantennummer',
                Name: 'verkäuferName',
                TaxInfo: [
                    {
                        Id: 'steuerId 1',
                        Schema: 'Schema',
                    },
                    {
                        Id: 'steuerId 2',
                        Schema: 'Schema',
                    },
                ],
                Description: 'lorem ipsum',
                Email: 'sales@verkäufe.com',
                UstId: 'de 1342097ß75',
                ContactPerson: {
                    Name: 'Uwe Tömia',
                    Phone: '+49 123 12345 12',
                    Email: 'uwe@seller.de',
                },
                Address: {
                    PostcodeCode: '46395',
                    LineOne: 'affenstraße 3',
                    LineTwo: 'zu Hd Uwe',
                    LineThree: undefined,
                    City: 'Gescher',
                    /** ISO 3166-1 */
                    CountryId: 'DE',
                },
                // todo label rausfinden
                LegalOrganization: {
                    Name: 'HandelsRegName',
                    Id: 'hr 1231423',
                    IdSchema: 'IdSchema',
                },
                Ids: [
                    {
                        Id: 'globalId 1',
                        scheme: 'globalId 1 scheme',
                    },
                    {
                        Id: 'globalId 2',
                        scheme: 'globalId 2 scheme',
                    },
                ],
            },
            Buyer: {
                Name: 'KäuferName',
                TaxInfo: [
                    {
                        Id: 'steuerId 1',
                        Schema: 'Schema',
                    },
                    {
                        Id: 'steuerId 2',
                        Schema: 'Schema',
                    },
                ],
                Email: 'dispo@käufer.com',
                UstId: 'de 1342097ß75',
                ContactPerson: {
                    Name: 'Günni Tömia',
                    Phone: '+49 123 12345 12',
                    Email: 'günni@buyer.de',
                },
                Address: {
                    PostcodeCode: '46395',
                    LineOne: 'affenstraße 3',
                    LineTwo: 'zu Hd Uwe',
                    LineThree: undefined,
                    City: 'Gescher',
                    /** ISO 3166-1 */
                    CountryId: 'DE',
                },
                CustomerNumber: 'Kundennummer',
                // todo label rausfinden
                LegalOrganization: {
                    Name: 'HandelsRegName',
                    Id: 'hr 1231423',
                    IdSchema: 'IdSchema',
                },
            },
            PaymentInfo: {
                ReceiverId: ['kx21', 'kx20', 'a123'],
                ReceiverName: 'Firma GmbH',
                ReceiverLegalId: 'hr123546',
                ReceiverLegalIdSchema: '0007',
                // ReceiverSchemeId?: string | undefined, todo toask kann man ignorieren?
                // ReceiverSchemeIdSchema?: string | undefined, todo toask kann man ignorieren?
                CreditorReferenceId: 'DE 02 TUR 01234567890',
                PaymentReference: 'RE 1234',
                PaymentType: PaymentType.CardPayment,
                PaymentMandateReference: 'L10013DE59R10E1R98VC00D2DX',
                DebitorIban: 'DE01 0123 0123 0123 01',
                DebitorBic: 'DE01 0123 0123 0123 01',
                CreditorIban: 'DE01 0123 0123 0123 01',
                CreditorBic: 'DE01 0123 0123 0123 01',
                Note: 'Lerom ipsum dolor sit amet',
                CardAccountNumberId: '012 1230 1234 123',
                CardAccountNetworkId: 'CardAccountNetworkId', // todo label rausfinden
                CardAccountHolderName: 'Uwe Müller',
            },
            TaxInfos: [
                {
                    Code: 'Code 1',
                    CalculatedAmount: 1337,
                    BasisAmount: 1337,
                    ExemptionReason: 'ExemptionReason',
                    ExemptionReasonCode: 'ExemptionReasonCode',
                    DueDateTypeCode: DueDateTypeCode.DeliveryDate,
                    CategoryCode: TaxCategoryCode.NoVat,
                    RateApplicablePercent: 0.19,
                },
                {
                    Code: 'Code 2',
                    CalculatedAmount: 1337,
                    BasisAmount: 1337,
                    ExemptionReason: 'ExemptionReason',
                    ExemptionReasonCode: 'ExemptionReasonCode',
                    DueDateTypeCode: DueDateTypeCode.DateOfIssueOfTheInvoice,
                    CategoryCode: TaxCategoryCode.StandardRate,
                    RateApplicablePercent: 0.19,
                },
                {
                    Code: 'Code 3',
                    CalculatedAmount: 1337,
                    BasisAmount: 1337,
                    ExemptionReason: 'ExemptionReason',
                    ExemptionReasonCode: 'ExemptionReasonCode',
                    DueDateTypeCode: DueDateTypeCode.PaidToDate,
                    CategoryCode: TaxCategoryCode.ReversalTaxLiability,
                    RateApplicablePercent: 0.19,
                },
            ],
            AllowanceCharges,
            Lines: [
                {
                    LineId: 'LineId',
                    ProductName: 'ProductName',
                    ProductDescription: 'ProductDescription',
                    GrossAmount: 10,
                    GrossUnit: UnitCodes.Piece,
                    GrossUnitPrice: 12,
                    NetAmount: 10,
                    NetUnit: UnitCodes.Piece,
                    NetUnitPrice: 11,
                    BilledQuantity: 2,
                    BilledUnit: UnitCodes.Piece,
                    Taxes: [
                        {
                            CategoryCode: '123',
                            Code: TaxCategoryCode.TaxExempt,
                            Percent: 0.19,
                        },
                        {
                            CategoryCode: '456',
                            Code: TaxCategoryCode.NoVat,
                            Percent: 0.07,
                        },
                    ],
                    AllowanceCharges,
                    BillingPeriodFrom: new Date().getTime().toString(),
                    BillingPeriodTo: new Date().getTime().toString(),
                    Total: 17,
                },
            ],
            ShippingParty: {
                Id: ['sd', 'asd'],
                GlobalId: [
                    {
                        Id: 'globalId 1',
                        scheme: 'globalId 1 scheme',
                    },
                    {
                        Id: 'globalId 2',
                        scheme: 'globalId 2 scheme',
                    },
                ],
                Name: 'test name',
                TaxInfo: [
                    {
                        Id: 'steuerId 1',
                        Schema: 'Schema',
                    },
                    {
                        Id: 'steuerId 2',
                        Schema: 'Schema',
                    },
                ],
                Email: 'lorem@ipsum.dia',
                ContactPerson: {
                    Name: 'Uwe Tömia',
                    Phone: '+49 123 12345 12',
                    Email: 'uwe@seller.de',
                },
                Address: {
                    PostcodeCode: '46395',
                    LineOne: 'affenstraße 3',
                    LineTwo: 'zu Hd Uwe',
                    LineThree: undefined,
                    City: 'Gescher',
                    /** ISO 3166-1 */
                    CountryId: 'DE',
                },
                LegalOrganization: {
                    Name: 'HandelsRegName',
                    Id: 'hr 1231423',
                    IdSchema: 'IdSchema',
                },
            }
        });
    }
}
