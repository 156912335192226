import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatDialogConfig, MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllowanceCharge } from '@dave/types';
import { DaveLoadingModule } from '../../../../dave-loading/dave-loading.module';
import { AppButtonModule } from '../../../../dave-utils-module/app-button-module/app-button.module';
import { appMatDialogDefaultConfig } from '../../../../helper/helper';
import { EInvoiceAllowanceChargeViewComponent } from '../e-invoice-allowance-charge-view/e-invoice-allowance-charge-view.component';
export interface EInvoiceAllowanceChargesDialogData {
    AllowanceCharges: AllowanceCharge[];
    CurrencyCode: string;
}
export type EInvoiceAllowanceChargesDialogReturnData = undefined;
@Component({
    selector: 'app-e-invoice-allowance-charges-dialog',
    standalone: true,
    imports: [CommonModule, AppButtonModule, DaveLoadingModule, MatDialogModule, EInvoiceAllowanceChargeViewComponent],
    templateUrl: './e-invoice-allowance-charges-dialog.component.html',
    styleUrls: ['./e-invoice-allowance-charges-dialog.component.scss'],
})
export class EInvoiceAllowanceChargesDialogComponent {
    public static DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        minWidth: '40vw',
    };
    protected allowanceCharges: AllowanceCharge[];
    protected currencyCode: string;
    constructor(@Inject(MAT_DIALOG_DATA) dialogData: EInvoiceAllowanceChargesDialogData) {
        if (dialogData) {
            this.allowanceCharges = dialogData.AllowanceCharges;
            this.currencyCode = dialogData.CurrencyCode;
        }
    }
}
