import { DatePipe, DecimalPipe, formatDate } from '@angular/common';
import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { ResolveData, Router } from '@angular/router';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, first, map, switchMap, tap } from 'rxjs/operators';
import { EmailDialogComponent, EmailDialogData } from '../../../components/detail-views/detail-event/email-dialog/email-dialog.component';
import { BookmarkEntity } from '../../../dave-data-module/entities/bookmark.entity';
import { CommentEntityTypeEnum } from '../../../dave-data-module/entities/comment.entity';
import { CommissionEntity } from '../../../dave-data-module/entities/commission.entity';
import { EventTypeEntity, EventTypeIdsEnum } from '../../../dave-data-module/entities/event-type.entity';
import { EventEntity, EventStateEnum, EventStateEnumNameMap, TaskPriorityNameColorIconMap } from '../../../dave-data-module/entities/event.entity';
import { FileEntity } from '../../../dave-data-module/entities/file.entity';
import { FolderTypes } from '../../../dave-data-module/entities/folder.entity';
import { PersonEntity } from '../../../dave-data-module/entities/person.entity';
import { Person2EntityEntityTypeEnum } from '../../../dave-data-module/entities/person2entity.entity';
import { BookmarkResolver } from '../../../dave-data-module/guards/bookmark.resolver';
import { CommentResolver } from '../../../dave-data-module/guards/comment.resolver';
import { CommissionResolver } from '../../../dave-data-module/guards/commission.resolver';
import { CustomerResolver } from '../../../dave-data-module/guards/customer.resolver';
import { EventTypeResolver } from '../../../dave-data-module/guards/event-type.resolver';
import { EventResolver } from '../../../dave-data-module/guards/event.resolver';
import { PersonResolver } from '../../../dave-data-module/guards/person.resolver';
import { Person2EntityResolver } from '../../../dave-data-module/guards/person2Entity.resolver';
import { ProcessResolver } from '../../../dave-data-module/guards/process.resolver';
import { UserResolver } from '../../../dave-data-module/guards/user.resolver';
import { EmailDataService } from '../../../dave-data-module/services/email-data.service';
import { FileDataService } from '../../../dave-data-module/services/file-data.service';
import { State } from '../../../dave-data-module/State';
import { BaseActionTypes } from '../../../dave-data-module/State/actions/base.actions';
import { BookmarkActionTypes } from '../../../dave-data-module/State/actions/bookmarks.actions';
import { EventsActionTypes } from '../../../dave-data-module/State/actions/events.actions';
import { BOOKMARK_KEY } from '../../../dave-data-module/State/reducers/bookmarks.reducer';
import { COMMENTS_FEATURE_KEY } from '../../../dave-data-module/State/reducers/comment.reducer';
import { commissionsFeatureKey } from '../../../dave-data-module/State/reducers/commission.reducer';
import { customersFeatureKey } from '../../../dave-data-module/State/reducers/customers.reducer';
import { EVENT_TYPE_FEATURE_KEY } from '../../../dave-data-module/State/reducers/event-types.reducer';
import { eventsFeatureKey } from '../../../dave-data-module/State/reducers/events.reducer';
import { personsFeatureKey } from '../../../dave-data-module/State/reducers/person.reducer';
import { person2EntityFeatureKey } from '../../../dave-data-module/State/reducers/person2Entity.reducer';
import { PROCESS_FEATURE_KEY } from '../../../dave-data-module/State/reducers/process.reducer';
import { usersFeatureKey } from '../../../dave-data-module/State/reducers/users.reducer';
import { getBookmarkByEventId } from '../../../dave-data-module/State/selectors/bookmarks.selectors';
import { getCommentByEntityTypeEntityId, getCommentFetched } from '../../../dave-data-module/State/selectors/comment.selector';
import { getCommissionById } from '../../../dave-data-module/State/selectors/commission.selector';
import { getCustomerById } from '../../../dave-data-module/State/selectors/customers.selectors';
import { getEventTypeById } from '../../../dave-data-module/State/selectors/event-type.selector';
import { getEventById } from '../../../dave-data-module/State/selectors/events.selectors';
import { getPersonById, getPersonDictionary } from '../../../dave-data-module/State/selectors/person.selectors';
import { getPerson2Entities } from '../../../dave-data-module/State/selectors/person2entity.selectors';
import { getProcessById } from '../../../dave-data-module/State/selectors/process.selector';
import { getUserDictionary } from '../../../dave-data-module/State/selectors/users.selectors';
import { DetailTasksComponent, DetailTasksComponentDialogData } from '../../../dave-event-card/components/detail-tasks/detail-tasks.component';
import { PermissionService } from '../../../dave-utils-module/dave-shared-components-module/services/permission.service';
import { IconPipe } from '../../../dave-utils-module/icon-pipe/icon.pipe';
import { isNotNullOrUndefined, sameDay, uniqArray } from '../../../helper/helper';
import {
    AllCommissionMeta,
    AllEventsMeta,
    AllProcessMeta,
    CommissionMeta,
    CustomerAdministrationMeta,
    EmailPageMeta,
    HistoryMeta,
    ProcessMeta,
    TaskPageMeta,
} from '../../../helper/page-metadata';
import { CustomLabelService } from '../../../services/custom-label.service';
import { MatCheckboxChange } from "@angular/material/checkbox";
import { FolderDataService } from '../../../dave-data-module/services/folder-data.service';
import { CustomerEntity } from '../../../dave-data-module/entities/customer.entity';

interface EventListEntry {
    event: EventEntity;
    EventType: EventTypeEntity;
    Person: PersonEntity;
    FileCount: number;
    bookmark: BookmarkEntity;
    name: string;
    tooltip: string;
    priority?: { name: string; color: string; icon: IconProp };
    icon: IconProp;
    commission?: CommissionEntity;
    customer?: CustomerEntity;
    state?: string;
    routerLink?: any[];
    isUnseen: boolean;
    hasComment: boolean;
    unseenComment: boolean;
    processStepIndex:number | null;
    processStepMainTask: boolean | null;
    // fileTooltip$: Observable<string> | null;
    cssClass: string | null;
    isDone: boolean;
}
function removeCommissionDetails(text: string, commission?: CommissionEntity) {
    if (!commission) {
        return text;
    }
    let filteredText = text;
    if (commission.Auftragsnummer) {
        filteredText = filteredText.replace(commission.Auftragsnummer.trim(), '...');
        // filteredText = filteredText.replace(commission.Auftragsnummer.trim(), `<abbr title="${commission.Auftragsnummer}">...</abbr>`)
    }
    if (commission.InterneNummer) {
        filteredText = filteredText.replace(commission.InterneNummer.trim(), '...');
        // filteredText = filteredText.replace(commission.InterneNummer.trim(), `<abbr title="${commission.InterneNummer}">...</abbr>`)
    }
    return filteredText;
}
@Component({
    selector: 'app-event-list-entry',
    templateUrl: './event-list-entry.component.html',
    styleUrls: ['./event-list-entry.component.scss'],
    providers: [DecimalPipe, IconPipe, DatePipe],
})
export class EventListEntryComponent implements OnDestroy {
    public static readonly RequiredResolvers: ResolveData = {
        [eventsFeatureKey]: EventResolver,
        [EVENT_TYPE_FEATURE_KEY]: EventTypeResolver,
        [personsFeatureKey]: PersonResolver,
        [BOOKMARK_KEY]: BookmarkResolver,
        [customersFeatureKey]: CustomerResolver,
        [commissionsFeatureKey]: CommissionResolver,
        [usersFeatureKey]: UserResolver,
        [person2EntityFeatureKey]: Person2EntityResolver,
        [COMMENTS_FEATURE_KEY]: CommentResolver,
        [PROCESS_FEATURE_KEY]: ProcessResolver,
    };
    public readonly CommissionMeta = CommissionMeta;
    public readonly EmailPageMeta = EmailPageMeta;
    public loadingFiles = false;
    public Event$: Observable<EventListEntry>;
    public SetToDone = false;
    private eventId: number;
    @ViewChild('fileTooltip') fileTooltip: MatTooltip;
    @Input() set EventId(id: number) {
        this.setEvent(id, null);
    }
    @Input() set Event(event: EventEntity) {
        this.setEvent(null, event);
    } //workarround für die events der bookmarks, die stehen nicht im EventState
    @Input() ShowRoutingButtons = true;
    @Input() SetToDoneDirectly = false;
    @Input() Active: boolean;
    @Input() RouteToProcess = true;
    // @Input() MoreHeight = false;
    @Input() ShowUnseenBadge = true;
    @Input() EscapeCommissionDetails = false;
    @Input() EscapeProcessIcons = false;
    private inTwoDays: Date | null = null;
    private hoverOnFileTooltip = false;
    protected bookmarkLoading$ = new BehaviorSubject<boolean>(false);
    public CustomerPath = CustomerAdministrationMeta.Path;
    constructor(
        public PS: PermissionService,
        private store: Store<State>,
        private decimalPipe: DecimalPipe,
        private datePipe: DatePipe,
        private dialog: MatDialog,
        private iconPipe: IconPipe,
        private router: Router,
        private emailDataService: EmailDataService,
        private fileDataService: FileDataService,
        private actions$: Actions,
        protected cls: CustomLabelService,
        private folderDataService: FolderDataService,
    ) {
        this.inTwoDays = new Date();
        this.inTwoDays.setDate(this.inTwoDays.getDate() + 2);
    }

    ngOnDestroy(): void {
        this.resetComponent();
    }

    protected resetComponent() {
        if (this.SetToDone && this.eventId) {
            firstValueFrom(this.Event$).then((event) => {
                if (event.event.State !== EventStateEnum.Done) {
                    this.store.dispatch(EventsActionTypes.ModifyEvent({ Payload: { id: event.event.Id, state: EventStateEnum.Done } }));
                }
            });
        }
        this.SetToDone = false;
        this.eventId = null;
    }

    protected onDoneCheckboxChange(val: MatCheckboxChange) {
        if (val.checked) {
            this.store.dispatch(EventsActionTypes.ModifyEvent({ Payload: { id: this.eventId, state: EventStateEnum.Done } }));
        }
    }
    public BookmarkEvent(e: EventListEntry) {
        this.bookmarkLoading$.next(true);
        firstValueFrom(this.actions$.pipe(ofType(BookmarkActionTypes.RemoveMany, BookmarkActionTypes.SetOne, BaseActionTypes.ErrorAction))).then(() => {
            this.bookmarkLoading$.next(false);
        });
        if (e.bookmark) {
            this.store.dispatch(BookmarkActionTypes.RemoveBookmark({ BookmarkIds: [e.bookmark.Id] }));
        } else {
            this.store.dispatch(BookmarkActionTypes.AddBookmark({ EventId: e.event.Id }));
        }
    }
    public GoToCommission(c: CommissionEntity, e: Event) {
        e.stopPropagation();
        this.router.navigate(['/', CommissionMeta.Path, c.CustomerId ? c.CustomerId : AllCommissionMeta.Path, c.Id]);
    }
    onMouseLeaveFileCount() {
        this.hoverOnFileTooltip = false;
        if (this.fileTooltip?._isTooltipVisible()) {
            this.fileTooltip.hide();
        }
    }
    onMouseEnterFileCount(event: EventListEntry) {
        this.hoverOnFileTooltip = true;
        if (!this.loadingFiles && event.FileCount && !this.fileTooltip.message) {
            this.loadingFiles = true;
            firstValueFrom(
                this.folderDataService.getFolderFromEntity(event.event.Id, FolderTypes.event).pipe(
                    map((folders) => folders && !folders.Hidden ? folders.Id : null),
                    distinctUntilChanged(),
                    switchMap((fId) => (fId ? this.fileDataService.GetFilesByFolderIdNoCache$(fId, true) : of([] as FileEntity[]))),
                    map((files) => files.map((f) => f.Name).join('\n')),
                ),
            )
                .then((tooltip) => {
                    this.fileTooltip.message = tooltip;
                    if (!this.fileTooltip._isTooltipVisible() && this.hoverOnFileTooltip) {
                        this.fileTooltip.show();
                    }
                })
                .finally(() => {
                    this.loadingFiles = false;
                });
        }
    }
    public ShowEmail(e: EventListEntry) {
        if (e.event.EmailId) {
            this.emailDataService
                .GetEmailById$(e.event.EmailId)
                .pipe(filter(isNotNullOrUndefined), first())
                .subscribe((email) => {
                    this.dialog.open(EmailDialogComponent, {
                        ...EmailDialogComponent.DefaultConfig,
                        data: {
                            email,
                            event: e.event,
                        } as EmailDialogData,
                    });
                });
        }
    }

    public EventClick(e: EventListEntry) {
        if (e.event.IsTask && !e.processStepMainTask) {
            this.Active = true;
            this.dialog
                .open<DetailTasksComponent, DetailTasksComponentDialogData>(DetailTasksComponent, {
                    ...DetailTasksComponent.DefaultConfig,
                    data: { EventId: e.event?.Id },
                })
                .afterClosed()
                .subscribe(() => {
                    document.addEventListener('click', () => (this.Active = false), { once: true });
                });

        }
        if (!e.event.IsTask || (this.RouteToProcess && e.event.ProcessId) || e.processStepMainTask) {
            this.router.navigate(e.routerLink);
        }
    }
    private getColorClass(event: EventEntity) {
        if (!event.IsTask) {
            return null;
        } else if (event.State === EventStateEnum.Done) {
            return 'app-color-approved';
        } else if (!!event.EventEndDate && event.EventEndDate <= this.inTwoDays) {
            return 'app-color-late';
        } else {
            return null;
        }
    }
    private setEvent(id: number, eventEntity: EventEntity) {
        this.resetComponent();
        this.eventId = eventEntity?.Id || id;
        if (this.fileTooltip?.message) {
            this.fileTooltip.message = '';
        }
        this.loadingFiles = false;
        this.Event$ = (eventEntity ? of(eventEntity) : this.store.select(getEventById({ id }))).pipe(
            filter(isNotNullOrUndefined),
            switchMap((event) =>
                combineLatest([
                    of(event),
                    this.store.select(getEventTypeById({ id: event.EventTypeId })),
                    this.store.select(getPersonById({ id: event.PersonId })),
                    this.store.select(getBookmarkByEventId({ id: event.Id })),
                    this.store.select(getCustomerById({ id: event.CustomerId })),
                    this.store.select(getCommissionById({ id: event.CommissionId })),
                    this.store.select(getUserDictionary),
                    this.store.select(getPerson2Entities).pipe(
                        filter(isNotNullOrUndefined),
                        map((v) => v.filter((p2e) => p2e.EntityType === Person2EntityEntityTypeEnum.Event && p2e.EntityId === event.Id)),
                    ),
                    this.store.select(getPersonDictionary),
                    this.store.select(getCommentFetched).pipe(
                        filter((v) => !!v),
                        switchMap(() => this.store.select(getCommentByEntityTypeEntityId({ entityId: event.Id, entityType: CommentEntityTypeEnum.Event }))),
                    ),
                    of(null).pipe(switchMap(() => (event.EmailId && this.EscapeCommissionDetails ? this.emailDataService.GetEmailById$(event.EmailId) : of(null)))),
                    this.cls.getSingle$('Commission'),
                    this.cls.getSingle$('Customer'),
                    this.store.select(getProcessById({ id: event.ProcessId })),
                    this.store.select(getProcessById({ id: event.ProcessId })).pipe(switchMap(process => process?.DocumentId ? this.fileDataService.GetFileById$(process.DocumentId) : of(null))),
                    this.PS.Has$(this.PS.Permissions.CanSeeProcesses),
                ]),
            ),
            map(([event, eventType, person, bookmark, customer, commission, users, person2Events, personDictionary, comments, email, commissionLabel, customerLabel, process, processFile, canSeeProcesses]) => {
                const state = event.IsTask ? EventStateEnumNameMap.get(event?.State) : null;

                let processStepIndex = process ? process.Steps.findIndex((s) => s.TaskId === event.Id || s.TaskIds?.includes(event.Id)) : null;
                if (processStepIndex === -1) {
                    processStepIndex = null;
                }
                const processStepMainTask = isNotNullOrUndefined(processStepIndex) && process?.Steps[processStepIndex].TaskId === event.Id;

                const userNames = event.UserIds?.map((u) => users[u]?.DisplayName)
                    .filter((un) => un !== '')
                    .join('; ');
                let name = '';
                if (event.IsTask && process/*StepMainTask*/) {
                    name += `
                    ${event.EventEndDate ? 'Frist: ' + this.datePipe.transform(event.EventEndDate) + '<br/>' : ''}
                    <b>${event.Name || ''}</b>&nbsp<I>${process.Name}</I> <br/>
                    ${processFile ? processFile.Name + '<br/>' : ''}
                    ${userNames ? `<span><i>${userNames}</i></span><br/>` : ''}
                    `;
                } else if (event.IsTask) {
                    name += `
                    ${event.EventEndDate ? 'Frist: ' + this.datePipe.transform(event.EventEndDate) + '<br/>' : ''}
                    <b>${event.Name || ''}</b> <br/>
                    ${commission && !this.EscapeCommissionDetails ? commissionLabel + ': ' + (commission.Description ? commission.Description : commission.Auftragsnummer ? commission.Auftragsnummer : '--') + '<br/>' : ''}
                    ${this.EscapeCommissionDetails && email ? 'Betreff: ' + removeCommissionDetails(email.Subject, commission) + '<br/>' : ''}
                    ${state ? 'Status: ' + state + '<br/>' : ''}
                    `;
                } else {
                    const date = [event.EventDate, event.EventEndDate]
                        .filter((v) => v)
                        .filter((value, index, array) => index === array.findIndex((v) => sameDay(value, v)))
                        .map((d) => formatDate(d, 'shortDate', 'de-DE'))
                        .join(' - ');

                    if (event.Name) {
                        name += event.Name + ' ';
                    }
                    name += `<span>${date}</span>`;

                    if (eventType?.Name.toLowerCase().includes('telefonat')) {
                        const Time =
                            this.decimalPipe.transform(moment(event.EventEndDate).diff(moment(event.EventDate), 'hours'), '2.0-0') +
                            ':' +
                            this.decimalPipe.transform(moment(event.EventEndDate).diff(moment(event.EventDate), 'minutes') - moment(event.EventEndDate).diff(moment(event.EventDate), 'hours') * 60, '2.0-0');
                        name += `&nbsp;<span>${Time}</span>`;
                    }
                    name += `<br/>`;
                    switch (event.EventTypeId) {
                        case EventTypeIdsEnum.Email: {
                            name += `${person ? `<span><b>${person.DisplayName}</b></span><br/>` : event.From ? `<span><b>${event.From}</b></span><br/>` : ''}
                            ${event.To ? `<span><i>${event.To}</i></span><br/>` : ''}`;
                            break;
                        }
                        case EventTypeIdsEnum.OutgoingEmail: {
                            name += `${event.From ? `<span><b>${event.From}</b></span><br/>` : ''}
                            ${person ? `<span><i>${person.DisplayName}</i></span><br/>` : event.To ? `<span><i>${event.To}</i></span><br/>` : ''}`;
                            break;
                        }
                        default: {
                            name += `${person ? `<span><b>${person.DisplayName}</b></span><br/>` : customer ? `<span><b>${customer.Name}</b></span><br/>` : ''}
                            ${userNames ? `<span><i>${userNames}</i></span><br/>` : ''}`;
                            break;
                        }
                    }
                    name += `
                            ${commission && !this.EscapeCommissionDetails ? `<span>${commissionLabel}: ${commission.Description ? commission.Description : commission.Auftragsnummer}</span>` : ''}
                            ${this.EscapeCommissionDetails && email ? '<span>Betreff: ' + removeCommissionDetails(email.Subject, commission) + '</span>' : ''}
                    `;
                }

                let icon: IconProp = event.IsTask ? TaskPageMeta.Icon : this.iconPipe.transform(eventType.ImagePath);

                const personNames = uniqArray(person2Events.map((p2e) => p2e.PersonId))
                    .map((id) => personDictionary[id])
                    .map((p) => p?.DisplayName)
                    .filter((pn) => pn)
                    .join('; ');
                let tooltip = (userNames?.length > 0 ? ' Zugewiesen: ' + userNames + '\n' : '') + (personNames.length > 0 ? ' Beteiligte: ' + personNames + '\n' : '');
                tooltip += bookmark || !event.EventEndDate ? '' : ' Frist: ' + event.EventEndDate.toLocaleDateString() + '\n';
                tooltip += event.IsTask ? 'Autor: ' + (users[event.UserId]?.DisplayName || '') + '\n' : '';

                if (commission) {
                    commission.CustomerName ? (tooltip += customerLabel + ': ' + commission.CustomerName + '\n') : '';
                    commission.Description && commission.Auftragsnummer ? (tooltip += commissionLabel + ': ' + commission.Auftragsnummer + '\n') : '';
                    commission.InterneNummer ? (tooltip += 'Internenummer: ' + commission.InterneNummer + '\n') : '';
                    commission.Schadennummer ? (tooltip += 'Schadensnummer: ' + commission.Schadennummer + '\n') : '';
                }

                // const process$ = event.ProcessId ? this.store.select(getProcessById({ id: event.ProcessId })).pipe(filter(isNotNullOrUndefined)) : null;
                // const processStepIndex$ = process$?.pipe(map((process) => process.Steps.findIndex((s) => s.TaskId === event.Id || s.TaskIds?.includes(event.Id)) + 1 || null));
                // const processStepMainTask$ = process$?.pipe(map((process) => process.Steps.some((s) => s.TaskId === event.Id)));
                return {
                    hasComment: !!comments.length,
                    unseenComment: !!comments.length && comments.some((c) => !c.LastSeenAt || c.LastSeenAt < c.UpdatedAt),
                    cssClass: this.getColorClass(event),
                    commission,
                    customer,
                    name,
                    tooltip,
                    event,
                    EventType: eventType,
                    Person: person,
                    bookmark,
                    FileCount: event.FileCount,
                    priority: event.AdditionalData?.Priority ? TaskPriorityNameColorIconMap.get(event.AdditionalData.Priority) : null,
                    icon,
                    state,
                    routerLink:
                        event.ProcessId && this.RouteToProcess && canSeeProcesses
                            ? ['/' + ProcessMeta.Path, AllProcessMeta.Path, event.ProcessId]
                            : customer
                            ? ['/' + HistoryMeta.Path, customer.Id, event.Id]
                            : ['/' + HistoryMeta.Path, AllEventsMeta.Path, event.Id],
                    isUnseen: !event.LastSeenAt || event.LastSeenAt < event.UpdatedAt,
                    processStepIndex,
                    processStepMainTask,
                    isDone: event.State === EventStateEnum.Done,
                };
            }),
        );
    }

    protected readonly ProcessMeta = ProcessMeta;
    protected readonly EventStateEnum = EventStateEnum;
    protected readonly CustomerAdministrationMeta = CustomerAdministrationMeta;
}
