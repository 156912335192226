import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, firstValueFrom, map, merge, Observable, of, Subscription, switchMap } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, shareReplay, skip, tap } from 'rxjs/operators';
import { CommissionResolver } from '../../../../dave-data-module/guards/commission.resolver';
import { CommissionTypeResolver } from '../../../../dave-data-module/guards/commissionType.resolver';
import { CustomerResolver } from '../../../../dave-data-module/guards/customer.resolver';
import { OfficeResolver } from '../../../../dave-data-module/guards/office.resolver';
import { UserToCommissionResolver } from '../../../../dave-data-module/guards/user-to-commission.resolver';
import { getFetched$ } from '../../../../dave-data-module/helper/helper';
import { State } from '../../../../dave-data-module/State';
import { BaseActionTypes } from '../../../../dave-data-module/State/actions/base.actions';
import { CommissionActionTypes } from '../../../../dave-data-module/State/actions/commission.actions';
import { getCommissionById, getCommissionsFetched, getCommissionSorted } from '../../../../dave-data-module/State/selectors/commission.selector';
import { getCommissionTypes, getCommissionTypesFetched } from '../../../../dave-data-module/State/selectors/commissionType.selectors';
import { getCustomerById, getCustomersFetched } from '../../../../dave-data-module/State/selectors/customers.selectors';
import { getStatusFromBackoffice, getStatusFromBackofficeFetched } from '../../../../dave-data-module/State/selectors/statusFromBackoffice.selectors';
import { FilterOption, FILTER_TYPE_MULTI_SELECT } from '../../../../dave-utils-module/app-filter-module/app-filter/app-filter.component';
import { CommissionNameService } from '../../../../dave-utils-module/dave-shared-components-module/services/commission-name.service';
import { CustomerNameService } from '../../../../dave-utils-module/dave-shared-components-module/services/customer-name.service';
import {
    compareArrays,
    isNotNullOrUndefined,
    SearchQueriesDebounceTime,
    stringSearch,
} from '../../../../helper/helper';
import { CommissionMeta } from '../../../../helper/page-metadata';
import { NewCommissionDialogComponent, NewCommissionDialogComponentDialogData, NewCommissionDialogComponentDialogReturnData } from '../../../../new-commission-dialog/new-commission-dialog.component';
import { CommissionWizardDialogService } from '../../../../services/commission-wizard-dialog.service';
import { CustomLabelService } from '../../../../services/custom-label.service';
import { DefaultFilterService, FilterApps } from '../../../../services/default-filter.service';
import { LoadingService } from '../../../../services/loading.service';
import { ISelectedElement, ITableConfig, TableContentType } from '../../multi-select-table/multi-select-table.component';
import { SelectUserDialogBase } from '../select-user-dialog-base';
import { DaveMutationChangeCommissionArgs } from '../../../../dave-data-module/graphql-types';
import { CommissionEntity } from '../../../../dave-data-module/entities/commission.entity';
import { StatusFromBackofficeResolver } from '../../../../dave-data-module/guards/statusFromBackoffice.resolver';

export interface SelectCommissionDialogComponentDialogData {
    selectedCommissionIds$: Observable<number[]>;
    newCommissionDefaultValues?: NewCommissionDialogComponentDialogData['CreateCommissionPayload'];
    commissionFilter?: (commission: CommissionEntity) => boolean;
}


interface ITableData {
    commissionTypeId: number;
    commissionId: number;
    selectedForm: FormControl<boolean>;
    auftragsnummer: TableContentType<any> & { __typename: 'string' };
    interneNummer: TableContentType<any> & { __typename: 'string' };
    description: TableContentType<any> & { __typename: 'string' };
    type: TableContentType<any> & { __typename: 'string' };
    cssStyle?: string;
    cssClass?: string | string[];
}
interface SelectedCommissionsType extends ISelectedElement {
    commissionId: number;
}
enum filterNames {
    commissionType = 'commissionType',
}
interface IFilter {
    [filterNames.commissionType]: string[];
}

@Component({
    selector: 'app-select-commission-dialog',
    templateUrl: './select-commission-dialog.component.html',
    styleUrls: ['./select-commission-dialog.component.scss'],
})
export class SelectCommissionDialogComponent implements OnInit, OnDestroy {
    selectedCommissionIds$ = new BehaviorSubject<number[]>([])
    @Input() set commissionIds(v: number[]) {
        if(compareArrays(v, this.selectedCommissionIds$.value)) {
            this.selectedCommissionIds$.next(v);
        }
    }
    @Input() newCommissionDefaultValues: NewCommissionDialogComponentDialogData['CreateCommissionPayload'];
    @Input() commissionFilter: (commission: CommissionEntity) => boolean;
    @Output() addCommissions = new EventEmitter<number[]>();
    @Output() removeCommission = new EventEmitter<number>();

    protected CommissionMeta = CommissionMeta;
    public static readonly DefaultConfig: MatDialogConfig = SelectUserDialogBase.DefaultConfig;
    public FilterSettings$: Observable<FilterOption[]> = this.store.select(getCommissionTypesFetched).pipe(
        filter((f) => f),
        switchMap(() => this.store.select(getCommissionTypes)),
        map((commissionTypes) => {
            const ctValues = {};
            commissionTypes.forEach((c) => {
                ctValues[c.Id.toString()] = c.Name;
            });
            return [
                {
                    Name: filterNames.commissionType,
                    Type: FILTER_TYPE_MULTI_SELECT,
                    Label: 'Auftragsart',
                    Values: ctValues,
                },
            ];
        }),
    );
    public FilterValues$: BehaviorSubject<IFilter> = new BehaviorSubject({
        [filterNames.commissionType]: [],
    });

    public FilterAmount$ = this.FilterValues$.pipe(map((v) => (v[filterNames.commissionType]?.length ? 1 : 0)));
    public SearchString: BehaviorSubject<string> = new BehaviorSubject<string>('');

    SelectedCommissions$: Observable<SelectedCommissionsType[]>;

    TableDataConfig: ITableConfig[] = [
        {
            header: 'Auftragsnummer',
            id: 'auftragsnummer',
            sortAccessor: (cell: TableContentType<any> & { __typename: 'string' }) => cell.content || '',
        },
        {
            header: 'Interne Nummer',
            id: 'interneNummer',
            sortAccessor: (cell: TableContentType<any> & { __typename: 'string' }) => cell.content || '',
        },
        {
            header: 'Beschreibung',
            id: 'description',
            sortAccessor: (cell: TableContentType<any> & { __typename: 'string' }) => cell.content || '',
        },
        {
            header: 'Art',
            id: 'type',
            sortAccessor: (cell: TableContentType<any> & { __typename: 'string' }) => cell.content || '',
        },
    ];
    FilteredTableData$: Observable<ITableData[]>;
    TableData$: Observable<ITableData[]>;

    private subscriptions: Subscription[] = [];
    public ContentResolved$: Observable<boolean>;
    protected dirty = false;
    constructor(
        private store: Store<State>,
        @Inject(MAT_DIALOG_DATA) public Dialogdata: SelectCommissionDialogComponentDialogData,
        private actions$: Actions,
        public LS: LoadingService,
        private defaultFilter: DefaultFilterService,
        private dialog: MatDialog,
        commissionRes: CommissionResolver,
        commissionTypeRes: CommissionTypeResolver,
        protected customerRes: CustomerResolver,
        statusFromBackOfficeRes: StatusFromBackofficeResolver,
        protected cls: CustomLabelService,
        private commissionWizardDialogService: CommissionWizardDialogService,
    ) {
        this.ContentResolved$ = combineLatest([
            this.store.select(getCommissionsFetched).pipe(
                distinctUntilChanged(),
                tap((fetched) => {
                    if (!fetched) {
                        commissionRes.resolve();
                    }
                }),
            ),
            this.store.select(getStatusFromBackofficeFetched).pipe(
                distinctUntilChanged(),
                tap((fetched) => {
                    if (!fetched) {
                        statusFromBackOfficeRes.resolve();
                    }
                }),
            ),
            this.store.select(getCommissionTypesFetched).pipe(
                distinctUntilChanged(),
                tap((fetched) => {
                    if (!fetched) {
                        commissionTypeRes.resolve();
                    }
                }),
            ),
        ]).pipe(
            map((values) => values.every((v) => v)),
            distinctUntilChanged(),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        this.SelectedCommissions$ = this.store.select(getCommissionSorted).pipe(switchMap((commissions) => this.Dialogdata.selectedCommissionIds$.pipe(map(ids => {
            return commissions.filter(c => ids.includes(c.Id)).map((c) => ({ label: c.DisplayName, commissionId: c.Id }))
        }))))
        // this.SelectedCommissions$ = this.store.select(getCommissionSorted).pipe(map((commissions) => commissions.filter((c) => c.ParentId === this.Dialogdata.commissionId).map((c) => ({ label: c.DisplayName, commissionId: c.Id }))));
        this.TableData$ = combineLatest([
            this.store.select(getCommissionTypesFetched).pipe(
                filter((v) => !!v),
                switchMap(() => this.store.select(getCommissionTypes)),
            ),
            this.store.select(getCommissionsFetched).pipe(
                filter((v) => !!v),
                switchMap(() => this.store.select(getCommissionSorted)),
                map((commissions) => {
                    if (this.Dialogdata.commissionFilter) {
                        return commissions.filter(this.Dialogdata.commissionFilter);
                    }
                    return commissions;
                    // commissions.filter((c) => (!c.ParentId || c.ParentId === this.Dialogdata.commissionId) && c.Id !== this.Dialogdata.commissionId && !c.Deleted)
                }),
            ),
            this.store.select(getStatusFromBackofficeFetched).pipe(
                filter((v) => !!v),
                switchMap(() => this.store.select(getStatusFromBackoffice)),
            ),
        ]).pipe(
            map(([types, commissions, statusBackoffices]) => {
                return commissions.map<ITableData>((c) => {
                    const type = c.CommissionTypeId && types.find((ct) => ct.Id === c.CommissionTypeId);
                    const statusBackoffice = c.StatusBackofficeId && statusBackoffices.find((s) => s.Id === c.StatusBackofficeId);
                    return {
                        cssStyle: `--app-clickable-background-color: ${c.GetBackgroundColor(statusBackoffice?.IsCompleted, statusBackoffice?.Color)};`,
                        cssClass: ['app-clickable-background', 'clickable-list-item'],
                        commissionId: c.Id,
                        commissionTypeId: c.CommissionTypeId,
                        selectedForm: new FormControl<boolean>(false),
                        auftragsnummer: {
                            __typename: 'string',
                            content: c.Auftragsnummer,
                        },
                        interneNummer: {
                            __typename: 'string',
                            content: c.InterneNummer,
                        },
                        description: {
                            __typename: 'string',
                            content: c.Description,
                        },
                        type: {
                            __typename: 'string',
                            content: type?.Name || '',
                        },
                    };
                });
            }),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        this.FilteredTableData$ = this.TableData$.pipe(
            switchMap((rows) =>
                this.FilterValues$.pipe(map((filter) => (filter[filterNames.commissionType]?.length ? rows.filter((row) => row.commissionTypeId && filter[filterNames.commissionType].includes(row.commissionTypeId.toString())) : rows))),
            ),
            switchMap((rows) => {
                return this.SearchString.pipe(
                    debounceTime(SearchQueriesDebounceTime),
                    map((searchString) => (!searchString && rows) || rows.filter((r) => [r.auftragsnummer.content, r.interneNummer.content, r.description.content].filter(isNotNullOrUndefined).some((s) => stringSearch(s, searchString)))),
                );
            }),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
    }
    ngOnInit(): void {
        firstValueFrom(this.defaultFilter.GetFilterByApp$(FilterApps.SelectCommissionPopup)).then((filterValues) => {
            let temp = {
                [filterNames.commissionType]: isNotNullOrUndefined(filterValues[filterNames.commissionType]) ? filterValues[filterNames.commissionType] : [],
            };
            this.FilterValues$.next(temp);
        });

        this.subscriptions.push(
            this.FilterValues$.pipe(skip(2)).subscribe((val) => {
                if (Object.keys(val).length !== 0) {
                    this.defaultFilter.SetFilterByApp(FilterApps.SelectCommissionPopup, val);
                }
            }),
            this.TableData$.pipe(
                // tap(() => this.dirty = false),
                switchMap((td) => merge(...td.map((t) => t.selectedForm.valueChanges.pipe(map(() => t.selectedForm.dirty))))),
            ).subscribe((v) => {
                if (v) {
                    this.dirty = true;
                }
            }),
            this.TableData$.pipe(
                switchMap((data) =>
                    this.SelectedCommissions$.pipe(
                        tap((selectedCommissions) =>
                            data.forEach((d) => {
                                const isSelected = selectedCommissions.some((u) => u.commissionId === d.commissionId);

                                if (isSelected && (d.selectedForm.enabled || !d.selectedForm.value)) {
                                    d.selectedForm.setValue(true, { emitEvent: false });
                                    d.selectedForm.disable();
                                } else if (!isSelected && (d.selectedForm.disabled || d.selectedForm.value)) {
                                    d.selectedForm.setValue(false, { emitEvent: false });
                                    d.selectedForm.enable();
                                }
                            }),
                        ),
                    ),
                ),
            ).subscribe(),
        );
    }
    RemoveSelected(entity: SelectedCommissionsType) {
        this.removeCommission.emit(entity.commissionId)
        // this.LS.startLoading('remove-parent-commission');
        // firstValueFrom(this.actions$.pipe(ofType(CommissionActionTypes.UpdateMany, BaseActionTypes.ErrorAction))).then(() => {
        //     this.LS.endLoading('remove-parent-commission');
        // });
        // this.store.dispatch(
        //     CommissionActionTypes.ModifyCommission({
        //         Payload: {
        //             id: entity.commissionId,
        //             parentId: null,
        //         },
        //     }),
        // );
    }
    Submit() {
        // this.LS.startLoading('set-commissions-parent-id');


        //***CODE zum Patchen.....
        firstValueFrom(combineLatest([this.TableData$, this.SelectedCommissions$])).then(([data, selected]) => {
            const toAdd = data.filter((d) => d.selectedForm.value && selected.every((s) => s.commissionId !== d.commissionId));
            if (!toAdd.length) {
                this.dirty = false;
                // this.LS.endLoading('set-commissions-parent-id');
                return;
            }




            // firstValueFrom(this.actions$.pipe(ofType(CommissionActionTypes.UpdateMany, BaseActionTypes.ErrorAction), skip(toAdd.length - 1))).then(() => {
            //     this.dirty = false;
            //     this.LS.endLoading('set-commissions-parent-id');
            // });
            this.addCommissions.emit(toAdd.map(c => c.commissionId))
            // toAdd.forEach(({ commissionId }) =>
            //     this.store.dispatch(
            //         CommissionActionTypes.ModifyCommission({
            //             Payload: {
            //                 id: commissionId,
            //                 parentId: this.Dialogdata.commissionId,
            //             },
            //         }),
            //     ),
            // );
            this.dirty = false
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
    async newCommissionClick() {
        this.dialog
            .open<NewCommissionDialogComponent, NewCommissionDialogComponentDialogData, NewCommissionDialogComponentDialogReturnData>(NewCommissionDialogComponent, {
                data: {
                    CreateCommissionPayload: {
                        //PersonId
                        // parentId: this.Dialogdata.commissionId,
                        // customerId: customer?.Id,
                        // auftragseingangDurchAuftraggeber: new Date().toDateString(),
                        ...this.Dialogdata.newCommissionDefaultValues
                    },
                    PreventRouting: true,
                },
            })
            .afterClosed()
            .subscribe((ret) => {
                console.log('closed', ret);
                if (ret?.Commission?.Id) {
                    this.commissionWizardDialogService.OpenDialog(ret.Commission.Id);
                }
            });
    }
}
