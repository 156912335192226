import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop,DragDropModule,moveItemInArray } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule,formatCurrency,formatDate } from '@angular/common';
import {
    AfterViewInit,
    Component,
    ElementRef,
    HostListener,
    Inject,
    Input,
    OnInit,
    Optional,
    ViewChild,
} from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxChange,MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog,MatDialogConfig,MatDialogModule,MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableDataSource,MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Actions,ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Moment } from 'moment';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject,combineLatest,firstValueFrom,Observable,of,switchMap } from 'rxjs';
import { filter,map,shareReplay,take,tap } from 'rxjs/operators';
import { BusinessVolumeCostStateEnum,BusinessVolumeEntity,BusinessVolumeStatusEnumNameMap } from '../../dave-data-module/entities/business-volume.entity';
import { BVCustomNameResolver } from '../../dave-data-module/guards/b-v-custom-name.resolver';
import { BusinessVolumeResolver } from '../../dave-data-module/guards/business-volume.resolver';
import { CommissionResolver } from '../../dave-data-module/guards/commission.resolver';
import { CustomerResolver } from '../../dave-data-module/guards/customer.resolver';
import { FrontendDate } from '../../dave-data-module/helper/backend-frontend-conversion.helper';
import { getFetched$ } from '../../dave-data-module/helper/helper';
import { State } from '../../dave-data-module/State';
import { BusinessVolumeActionTypes } from '../../dave-data-module/State/actions/business-volume.actions';
import { ChangeSettings } from '../../dave-data-module/State/actions/settings.actions';
import { getBVCustomNameDictionary,getBVCustomNameFetched,getBVCustomNamesActive } from '../../dave-data-module/State/selectors/b-v-custom-name.selector';
import { getBusinessVolume,getBusinessVolumeById,getBusinessVolumeFetched } from '../../dave-data-module/State/selectors/business-volume.selector';
import {
getChartOfAccountDictionary,
getChartOfAccountsFetched
} from '../../dave-data-module/State/selectors/chartOfAccount.selectors';
import {
    getCommissionById,
    getCommissionDictionary, getCommissionsActive, getCommissionsFetched,
} from '../../dave-data-module/State/selectors/commission.selector';
import {
    getCustomerById,
    getCustomerDictionary,
    getCustomers,
    getCustomersFetched,
} from '../../dave-data-module/State/selectors/customers.selectors';
import { getPartner,getPartnerFetched } from '../../dave-data-module/State/selectors/partners.selectors';
import { getSetting,getSettingFetched } from '../../dave-data-module/State/selectors/users.selectors';
import {
DaveFilePreviewComponent,
DaveFilePreviewComponentDialogData
} from '../../dave-file-preview-dialog/components/dave-file-preview/dave-file-preview.component';
import { DaveListCardModule } from '../../dave-list-card/dave-list-card.module';
import { DaveLoadingModule } from '../../dave-loading/dave-loading.module';
import { AppButtonModule } from '../../dave-utils-module/app-button-module/app-button.module';
import { AppFilterModule } from '../../dave-utils-module/app-filter-module/app-filter.module';
import { FilterOption,FILTER_TYPE_SEARCH_MULTI_SELECT,FILTER_TYPE_SELECT,FILTER_TYPE_TIMESPAN,IFilterTypeSearchMultiSelectValue } from '../../dave-utils-module/app-filter-module/app-filter/app-filter.component';
import { DaveSharedComponentsModule } from '../../dave-utils-module/dave-shared-components-module/dave-shared-components.module';
import { appMatDialogDefaultConfig, MathRound, TableColumnConfig } from '../../helper/helper';
import { CustomLabelService } from '../../services/custom-label.service';
import { FilterTypes } from '../../services/default-filter.service';
import { LoadingService } from '../../services/loading.service';
import { TableConfigFormComponent } from '../../table-config-form/table-config-form.component';
import { BusinessVolumeDialogComponent,BusinessVolumeDialogComponentDialogData } from '../business-volume-dialog/business-volume-dialog.component';
import { PermissionService } from '../../dave-utils-module/dave-shared-components-module/services/permission.service';
import { CustomerEntity } from '../../dave-data-module/entities/customer.entity';
import { MatSort } from '@angular/material/sort';


export interface ExportBusinessVolumeDialogComponentDialogData {
    CommissionId?: number;
    CustomerId?: number;
    FromDate: Moment;
    ToDate: Moment;
}
interface exportDataRequest{
    bvIds: number[];
    exportPeriodStart: Moment;
    exportPeriodEnd: Moment;
}

interface BvFilterValues {
    type?: BusinessVolumeCostStateEnum;
    status: IFilterTypeSearchMultiSelectValue[];
    customNames: IFilterTypeSearchMultiSelectValue[];
}

type tableRow = {
    amountNumber: number;
    amount: string;
    payedAmountNumber: number;
    payedAmount: string;
    label: string;
    id: number;
    bvEntity: BusinessVolumeEntity;
    cssClass?: string;
    businessVolumeNo: string;
    name: string;
    status: string;
    bVCustomNames: string;
    description: string;
    createdAt: string;
    customerName: string;
    customerNr_KontoNr: string;
    type: string;
    consolidatedDeliveryDate: string;
    exportDate: string;
    exportTimespan: string;
    lastPaymentDate: string;
    lastPaymentNote: string;
    lastPaymentGross: string;
    lastPaymentNet: string;
    payedAmountGross: string;
    amountGross: string;
    discountValue: string;
    discountValueGross: string;
};


@Component({
    selector: 'app-export-business-volumes-dialog',
    templateUrl: './export-business-volumes-dialog.component.html',
    styleUrls: ['./export-business-volumes-dialog.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        AppButtonModule,
        FontAwesomeModule,
        MatMenuModule,
        MatTooltipModule,
        DaveSharedComponentsModule,
        MatDialogModule,
        MatTableModule,
        MatCheckboxModule,
        DaveListCardModule,
        DaveLoadingModule,
        MatSortModule,
        ScrollingModule,
        TableVirtualScrollModule,
        MatBadgeModule,
        AppFilterModule,
        MatCardModule,
        DragDropModule,
        MatSidenavModule,
        TableConfigFormComponent,
    ],
})
export class ExportBusinessVolumesDialogComponent implements OnInit, AfterViewInit {
    @ViewChild(MatSort) sort: MatSort;
    public static DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        panelClass: ['custom-dialog-class-without-padding', 'custom-dialog-class-overflow-visible', ...appMatDialogDefaultConfig.panelClass],
        width: '92vw',
        maxWidth: '100vw',
        maxHeight: '95vh',
    };



    public TableColumns: TableColumnConfig<tableRow>[] = [
        { header: 'Kundenname', name: 'customerName' },
        { header: 'Kundennr./Kontonr.', name: 'customerNr_KontoNr' },
        { header: 'Name', name: 'name' },
        { header: 'Typ', name: 'type' },
        { header: 'BV-Benutzernamen', name: 'bVCustomNames' },
        { header: 'Beschreibung', name: 'description' },
        { header: 'Geschäftsvolumen-Nr.', name: 'businessVolumeNo' },
        { header: 'Status', name: 'status' },
        { header: 'Betrag netto', name: 'amount' },
        { header: 'Bezahlter Betrag netto', name: 'payedAmount' },
        { header: 'Erstellt am', name: 'createdAt' },
        { header: 'Leistungsdatum', name: 'consolidatedDeliveryDate' },
        { header: 'Exportiertes Datum', name: 'exportDate' },
        { header: 'Export-Zeitraum', name: 'exportTimespan' },
        { header: 'Letztes Zahlungsdatum', name: 'lastPaymentDate' },
        { header: 'Letzte Zahlungseingangs Notiz', name: 'lastPaymentNote' },
        { header: 'Letzter Zahlungsbetrag Brutto', name: 'lastPaymentGross' },
        { header: 'Letzter Zahlungsbetrag Netto', name: 'lastPaymentNet' },
        { header: 'Bezahlter Betrag brutto', name: 'payedAmountGross' },
        { header: 'Betrag brutto', name: 'amountGross' },
        { header: 'Skonto netto', name: 'discountValue' },
        { header: 'Skonto brutto', name: 'discountValueGross' },
    ];

    public availableColumns = ['customerName', 'customerNr_KontoNr', 'name', 'type', 'bVCustomNames', 'description', 'businessVolumeNo', 'status', 'amount', 'payedAmount', 'createdAt','consolidatedDeliveryDate', 'exportDate', 'exportTimespan', 'lastPaymentDate', 'lastPaymentNote', 'lastPaymentGross', 'lastPaymentNet', 'payedAmountGross', 'amountGross', 'discountValue', 'discountValueGross'];
    public displayedColumns: string[];
    public selectedColumns$: Observable<(keyof tableRow)[]>;
    public disabledColumns$: Observable<number>;
    public TableColumnsSorted$: Observable<TableColumnConfig<tableRow>[]>

    public SelectedBvs$ = new BehaviorSubject<BusinessVolumeEntity[]>([]);
    public selection = new SelectionModel<tableRow>(true, []);
    private CommissionId$ = new BehaviorSubject<number | null>(null);
    private CustomerId$ = new BehaviorSubject<number>(null);
    public Multiselect = false;

    @Input() set commissionId(id: number | null) {
        this.CommissionId$.next(id);
    }

    get commissionId() {
        return this.CommissionId$.value;
    }

    @Input() set customerId(id: number | null) {
        this.CustomerId$.next(id);
    }

    get customerId() {
        return this.CustomerId$.value;
    }
    protected hasCreatePermission$ = this.ps.Has$(this.ps.Permissions.CreateBusinessVolume);
    constructor(
        @Inject(MAT_DIALOG_DATA) public DialogData: ExportBusinessVolumeDialogComponentDialogData,
        @Optional() protected dialogRef: MatDialogRef<ExportBusinessVolumesDialogComponent>,
        private store: Store<State>,
        protected dialog: MatDialog,
        protected ls: LoadingService,
        protected commissionResolver: CommissionResolver,
        protected customerResolver: CustomerResolver,
        protected cls: CustomLabelService,
        protected bvsResolver: BusinessVolumeResolver,
        private actions$: Actions,
        private apiToasterService: ToastrService,
        private bVCustomNameResolver: BVCustomNameResolver,
        private ps: PermissionService,
    ) {
        if (DialogData) {
            this.commissionId = DialogData.CommissionId;
            this.customerId = DialogData.CustomerId;
        }
        firstValueFrom(this.store.select(getBVCustomNameFetched)).then((f) => {
            if (!f) {
                this.bVCustomNameResolver.resolve();
            }
        });

        this.selectedColumns$ = combineLatest([
            getFetched$(this.store, getSettingFetched, getSetting),
            getFetched$(this.store, getPartnerFetched, getPartner)
        ]).pipe(
            map(([settings, partner]) => {
                let columns: string[];
                if (settings.ExportBusinessVolumesDialogComponentDisplayedColumns) {
                    columns = settings.ExportBusinessVolumesDialogComponentDisplayedColumns;
                } else if (partner.AdditionalData?.ExportBusinessVolumesDialogComponentDisplayedColumns) {
                    columns = partner.AdditionalData.ExportBusinessVolumesDialogComponentDisplayedColumns;
                } else {
                    columns = this.availableColumns;
                }
                return columns.filter(col => this.availableColumns.includes(col)) as (keyof tableRow)[];
            }),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
        this.selectedColumns$.subscribe((columns) => {
            this.availableColumns = columns;
            this.displayedColumns = columns.slice();
        });

        this.TableColumnsSorted$ = this.selectedColumns$.pipe(
            map((selected) => {
                return this.TableColumns.sort((a, b) => selected.indexOf(a.name) - selected.indexOf(b.name));
            }),
        );

        this.disabledColumns$ = combineLatest([this.TableColumnsSorted$, this.selectedColumns$]).pipe(
            map(([all, selected]) => all.length - selected.length || undefined),
        );
    }

    ngOnInit(): void {
        this.selectedColumns$.subscribe((columns) => {
            this.availableColumns = columns;
            this.displayedColumns = columns.slice();
        });
    }

    ngAfterViewInit(): void {
        this.Data$.subscribe((dataSource) => {
            if (dataSource instanceof MatTableDataSource) {
                dataSource.sort = this.sort;
            }
        });
    }


    ToggleMultiselect() {
        this.Multiselect = !this.Multiselect;
        this.SelectedBvs$.next([]);
        if (this.availableColumns.includes('select')) {
            this.availableColumns.splice(this.availableColumns.indexOf('select'), 1);
        } else {
            this.availableColumns.unshift('select');
        }
    }

    private BVS$ = combineLatest([
        getFetched$(this.store, getBusinessVolumeFetched, getBusinessVolume, this.bvsResolver),
        getFetched$(this.store, getCustomersFetched, getCustomerDictionary, this.customerResolver),
        getFetched$(this.store, getCommissionsFetched, getCommissionDictionary, this.commissionResolver),
    ]).pipe(
        map(([bvs, customers, commissions]) => {
            const validBvs = bvs.filter((bv) => this.DialogData.FromDate.isSameOrBefore(bv.CreatedAt) && this.DialogData.ToDate.isSameOrAfter(bv.CreatedAt));
            const customerToBvs = this.DialogData.CustomerId ? validBvs.filter((bv) => bv.CustomerId === customers[this.DialogData?.CustomerId]?.Id) : validBvs;

            const bvsToCommissions = this.DialogData.CommissionId ? validBvs.filter((bv) => bv.CommissionId === commissions[this.DialogData.CommissionId]?.Id) : validBvs;

            return validBvs.filter((bv) => customerToBvs.some((cBv) => cBv.Id === bv.Id) && bvsToCommissions.some((comBv) => comBv.Id === bv.Id));
        }),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    public BvFilterSettings: Observable<FilterOption<keyof BvFilterValues>[]> = combineLatest([
        this.store.select(getBVCustomNameFetched).pipe(
            filter((f) => f),
            switchMap(() => this.store.select(getBVCustomNamesActive)),
        ),
        getFetched$(this.store, getCommissionsFetched, getCommissionsActive, this.commissionResolver),
        getFetched$(this.store, getCustomersFetched, getCustomers, this.customerResolver),
        this.CommissionId$,
        this.CustomerId$,
        this.cls.getMultiple$('Commission'),
        this.cls.getMultiple$('Customer'),
    ]).pipe(
        map(([customNames, commissions, customers, commissionId, customerId, commissionLabel, customerLabel]) => {
            const filters = [];
            console.log(' filter ');
            if (!customerId) {
                const filterValues: IFilterTypeSearchMultiSelectValue[] = customers.map((r) => ({
                    label: r.DisplayName,
                    id: r.Id,
                }));
                filters.push({
                    Name: FilterTypes.Customers,
                    Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
                    Label: customerLabel,
                    Values: filterValues,
                });
            }
            if (!commissionId) {
                const filterValues: IFilterTypeSearchMultiSelectValue[] = commissions.map((r) => ({
                    label: r.DisplayName,
                    id: r.Id,
                }));
                filters.push({
                    Name: FilterTypes.Commissions,
                    Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
                    Label: commissionLabel,
                    Values: filterValues,
                });
            }
            filters.push(
                {
                    Name: FilterTypes.Date,
                    Type: FILTER_TYPE_TIMESPAN,
                    Label: 'Erstellt',
                },
                {
                    Name: 'type',
                    Type: FILTER_TYPE_SELECT,
                    Label: 'Typ',
                    Values: {
                        [BusinessVolumeCostStateEnum.Income]: 'Einnahmen',
                        [BusinessVolumeCostStateEnum.Costs]: 'Kosten',
                    },
                },
                {
                    Name: 'status',
                    Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
                    Label: 'Status',
                    Values: Array.from(BusinessVolumeStatusEnumNameMap.keys()).map<IFilterTypeSearchMultiSelectValue<string>>((v) => ({
                        id: v,
                        label: BusinessVolumeStatusEnumNameMap.get(v),
                    })),
                },
                {
                    Name: 'customNames',
                    Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
                    Label: 'Art',
                    Values: customNames.map((r) => ({
                        label: r.Name,
                        id: r.Id,
                    })),
                },
            );
            return filters;
        }),
    );

    public BvFilterValues$: BehaviorSubject<BvFilterValues> = new BehaviorSubject({
        status: [],
        customNames: [],
    });
    public FilterAmount$ = this.BvFilterValues$.pipe(
        map((filter) => {
            let amount = 0;
            if (filter.status?.length) {
                amount += 1;
            }
            if (filter.customNames?.length) {
                amount += 1;
            }
            if (filter.type) {
                amount += 1;
            }
            return amount;
        }),
    );

    public Data$ = combineLatest([
        this.BVS$,
        this.store.select(getBVCustomNameDictionary),
        getFetched$(this.store, getCustomersFetched, getCustomerDictionary),
        getFetched$(this.store, getCommissionsFetched, getCommissionDictionary),
        getFetched$(this.store, getChartOfAccountsFetched, getChartOfAccountDictionary),
    ]).pipe(
        map(([bvs, customNames, customers, commissions, chartDic]) =>
            bvs.map<tableRow>((b) => {
                const customer = customers ? customers[b.CustomerId] : null;
                const customerNr = customer ? (customer.CustomerNo !== null && customer.CustomerNo !== undefined ? customer.CustomerNo : '') : '';
                const kontoNr = customer
                    ? customer.KontoCOAId === null || chartDic[customer.KontoCOAId] === null || customer.KontoCOAId === undefined || chartDic[customer.KontoCOAId] === undefined
                        ? ''
                        : chartDic[customer.KontoCOAId]?.Number
                    : '';

                const separator = kontoNr !== '' ? ' / ' : '';
                const customerNr_KontoNr = customerNr + separator + kontoNr;
                const exportTimespan = b ? (b.ExportPeriodStart && b.ExportPeriodEnd ? formatDate(b.ExportPeriodStart, 'mediumDate', 'de-DE') + ' - ' + formatDate(b.ExportPeriodEnd, 'mediumDate', 'de-DE') : '') : '';

                const paymentInfos = b?.PaymentInformations || [];
                const lastPayment = paymentInfos.length > 0 ? paymentInfos[paymentInfos.length - 1] : null;

                return {
                    id: b.Id,
                    label: b.Name,
                    amount: b.Amount ? formatCurrency(b.Amount / 100, 'de-DE', '€', 'EUR') : '',
                    amountNumber: b.Amount,
                    payedAmount: b.PayedAmount ? formatCurrency(b.PayedAmount / 100, 'de-DE', '€', 'EUR') : '',
                    payedAmountNumber: b.PayedAmount,
                    bvEntity: b,
                    businessVolumeNo: b.BusinessVolumeNo,
                    name: b.Name,
                    type: b.BVType === BusinessVolumeCostStateEnum.Income ? 'Einnahmen' : b.BVType === BusinessVolumeCostStateEnum.Costs ? 'Kosten' : '',
                    status: b.Status ? BusinessVolumeStatusEnumNameMap.get(b.Status) : undefined,
                    bVCustomNames: b.BVCustomNamesIds.map((id) => customNames[id]?.Name).join(', '),
                    description: b.Description,
                    createdAt: formatDate(b.CreatedAt, 'dd.MM.yyyy', 'de-DE'),
                    customerName: customer ? customer.Name : '',
                    customerNr_KontoNr: customerNr_KontoNr,
                    commissionLabel: b.CommissionId ? commissions[b.CommissionId]?.GetDisplayName() : '',
                    consolidatedDeliveryDate: b ? (b.ConsolidatedDeliveryDate ? formatDate(b.ConsolidatedDeliveryDate, 'mediumDate', 'de-DE') : '') : '',
                    exportDate: b ? (b.ExportDate ? formatDate(b.ExportDate, 'mediumDate', 'de-DE') : '') : '',
                    exportTimespan: exportTimespan,
                    lastPaymentDate: lastPayment ? formatDate(lastPayment.Zahlungsdatum, 'dd.MM.yyyy', 'de-DE') : '',
                    lastPaymentNote: lastPayment ? lastPayment.ZahlungseingangZusatz : '',
                    lastPaymentGross: lastPayment ? formatCurrency(lastPayment.ZahlungseingangBetragBrutto/100, 'de-DE', '€', 'EUR') : '',
                    lastPaymentNet: lastPayment ? formatCurrency(lastPayment.ZahlungseingangBetragNetto/100, 'de-DE', '€', 'EUR') : '',
                    payedAmountGross: b.PayedAmountGross ? formatCurrency(b.PayedAmountGross / 100, 'de-DE', '€', 'EUR') : '',
                    amountGross: b.AmountGross ? formatCurrency(b.AmountGross / 100, 'de-DE', '€', 'EUR') : '',
                    discountValue: b.DiscountValue ? formatCurrency(b.DiscountValue , 'de-DE', '€', 'EUR') : '',
                    discountValueGross: b.DiscountValueGross ? formatCurrency(MathRound(b.DiscountValueGross / 100, true) , 'de-DE', '€', 'EUR') : '',
                };
            }),
        ),
        switchMap((data) =>
            this.BvFilterValues$.pipe(
                map((filterValues) =>
                    data.filter(
                        (d) =>
                            (!filterValues.type || filterValues.type === d.bvEntity.BVType) &&
                            (!filterValues.status?.length || filterValues.status.some((s) => s.id === d.bvEntity.Status)) &&
                            (!filterValues.customNames?.length || filterValues.customNames.some((s) => d.bvEntity.BVCustomNamesIds?.includes(+s.id))) &&
                            (!filterValues[FilterTypes.Commissions]?.length || (d.bvEntity.CommissionId && filterValues[FilterTypes.Commissions].some((f) => f.id === d.bvEntity.CommissionId))) &&
                            (!filterValues[FilterTypes.Customers]?.length || (d.bvEntity.CustomerId && filterValues[FilterTypes.Customers].some((f) => f.id === d.bvEntity.CustomerId))) &&
                            (!filterValues[FilterTypes.Date]?.from || (filterValues[FilterTypes.Date].from as Moment).isSameOrBefore(d.bvEntity.CreatedAt)) &&
                            (!filterValues[FilterTypes.Date]?.to || (filterValues[FilterTypes.Date].to as Moment).isSameOrAfter(d.bvEntity.CreatedAt)),
                    ),
                ),
            ),
        ),
        map((data) => new MatTableDataSource<tableRow>(data)),
        tap(
            (dataSource) =>
                (dataSource.sortingDataAccessor = (object, key) => {
                    switch (key) {
                        case 'payedAmount':
                            return object.payedAmountNumber;
                        case 'amount':
                            return object.amountNumber;
                        default:
                            return object[key];
                    }
                }),
        ),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    IsSelected = (rowData: tableRow) => this.SelectedBvs$.getValue().findIndex((v) => v.Id === rowData.bvEntity.Id) > -1;

    CheckBoxSelectBv(event: MatCheckboxChange, rowData: tableRow) {
        const currentSelected = this.SelectedBvs$.getValue();
        let newSelected = [...currentSelected];
        const index = newSelected.findIndex((v) => v.Id === rowData.bvEntity.Id);

        if (!event.checked && index > -1) {
            newSelected.splice(index, 1);
        } else if (event.checked && index === -1) {
            newSelected.push(rowData.bvEntity);
        }
        this.SelectedBvs$.next(newSelected);
    }

    toggleAllSelections() {
        this.isAllSelected()
            .pipe(
                take(1),
                switchMap((isAllSelected) => {
                    if (isAllSelected) {
                        return of([]);
                    } else {
                        return this.Data$.pipe(
                            take(1),
                            map((data) => data.data.map((row) => row.bvEntity)),
                        );
                    }
                }),
            )
            .subscribe((newSelection) => {
                this.SelectedBvs$.next(newSelection);
            });
    }

    isAllSelected(): Observable<boolean> {
        return this.Data$.pipe(
            map((data) => {
                const selectedIds = new Set(this.SelectedBvs$.getValue().map((bv) => bv.Id));
                return data.data.every((row) => selectedIds.has(row.bvEntity.Id));
            }),
        );
    }

    openEditDialog(rowData?: tableRow) {
        let id = rowData.id;
        firstValueFrom(this.store.select(getBusinessVolumeById({ id }))).then((bve) => {
            this.dialog.open<BusinessVolumeDialogComponent, BusinessVolumeDialogComponentDialogData>(BusinessVolumeDialogComponent, {
                ...BusinessVolumeDialogComponent.DefaultConfig,
                data: {
                    BvId: id,
                    ShowCommissionForm: !this.CommissionId$.value,
                },
                autoFocus: !id,
            });
        });
    }

    exportAll() {
        this.Data$.pipe(
            take(1),
            map((data) => data.data.map((row) => row.bvEntity)),
        ).subscribe((allBvs) => {
            this.SelectedBvs$.next(allBvs);
        });
        this.export();
    }

    export() {
        this.ls.startLoading('export-businessVolumes-csv');
        firstValueFrom(this.actions$.pipe(ofType(BusinessVolumeActionTypes.ExportFailure, BusinessVolumeActionTypes.ExportSuccess))).then((action) => {
            this.ls.endLoading('export-businessVolumes-csv');
            if (action.type === BusinessVolumeActionTypes.ExportSuccess.type) {
                this.apiToasterService.success('erfolgreich exportiert');
                this.dialogRef.close();
                this.dialog.open<DaveFilePreviewComponent, DaveFilePreviewComponentDialogData>(DaveFilePreviewComponent, {
                    ...DaveFilePreviewComponent.DefaultConfig,
                    data: {
                        fileId: action.Payload.Id,
                    },
                });
            }
        });
        this.store.dispatch(
            BusinessVolumeActionTypes.ExportRequest({
                Payload: {
                    startDate: FrontendDate(this.DialogData.FromDate.toDate(), true),
                    endDate: FrontendDate(this.DialogData.ToDate.toDate(), true),
                    bvIds: this.SelectedBvs$.getValue().map((bv) => bv.Id),
                },
            }),
        );
    }

    drop(event: CdkDragDrop<string[]>) {
        if (event.previousIndex !== event.currentIndex) {
            const updatedColumns = [...this.availableColumns];
            moveItemInArray(updatedColumns, event.previousIndex, event.currentIndex);
            this.availableColumns = updatedColumns;
            this.displayedColumns = this.availableColumns.slice();
            this.saveColumnOrder();
        }
    }

    saveColumnOrder() {
        this.store.dispatch(
            new ChangeSettings({
                ExportBusinessVolumesDialogComponentDisplayedColumns: this.availableColumns,
            }),
        );
    }

    selectedColumnsChange(selectedColumns: Array<keyof tableRow>) {
        this.store.dispatch(
            new ChangeSettings({
                ExportBusinessVolumesDialogComponentDisplayedColumns: selectedColumns,
            }),
        );
        this.availableColumns = selectedColumns;
        this.displayedColumns = selectedColumns.slice();
    }


    addBV() {
        this.openEditBvDialog();
    }

    async openEditBvDialog(id?: number) {
        const customerId = this.customerId;
        const commissionId = this.commissionId;

        let customer: CustomerEntity;
        if (customerId) {
            customer = await firstValueFrom(getFetched$(this.store, getCustomersFetched, getCustomerById({id: customerId}), this.customerResolver));
        } else if (commissionId) {
            const commission = await firstValueFrom(getFetched$(this.store, getCommissionsFetched, getCommissionById({id: commissionId}), this.commissionResolver))
            if (commission?.CustomerId) {
                customer = await firstValueFrom(getFetched$(this.store, getCustomersFetched, getCustomerById({id: commission.CustomerId}), this.customerResolver));
            }
        }
        this.dialog.open<BusinessVolumeDialogComponent, BusinessVolumeDialogComponentDialogData>(BusinessVolumeDialogComponent, {
            ...BusinessVolumeDialogComponent.DefaultConfig,
            data: {
                BvId: id,
                ShowCommissionForm: !commissionId,
                ShowCustomerForm: !customerId,
                AddBusinessVolumeDefaultValues: { commissionId, customerId, sachkontoCOAId: customer?.SachkontoCOAId },
            },
            autoFocus: !id,
        });
    }

}



