import { ScrollingModule } from '@angular/cdk/scrolling';
import { AsyncPipe, formatDate, NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, ElementRef, Inject, Input, Optional, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatDialog, MatDialogConfig, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GetTimestampFromTime } from '@dave/types/dist/convert';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import moment, { Moment } from 'moment';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, switchMap } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, startWith, tap } from 'rxjs/operators';
import { MaterialToCommissionEntity, WorkTypeEnum } from '../../../dave-data-module/entities/material-to-commission.entity';
import { MaterialEntity } from '../../../dave-data-module/entities/material.entity';
import { MaterialListResolver } from '../../../dave-data-module/guards/material-list.resolver';
import { MaterialResolver } from '../../../dave-data-module/guards/material.resolver';
import { QuantityTypeResolver } from '../../../dave-data-module/guards/quantity-type.resolver';
import { getFetched$ } from '../../../dave-data-module/helper/helper';
import { State } from '../../../dave-data-module/State';
import { MaterialToCommissionActionTypes } from '../../../dave-data-module/State/actions/material-to-commission.actions';
import {
    getAccountsReceivableLedgerById, getAccountsReceivableLedgerDictionary, getAccountsReceivableLedgersFetched,
    getQuantityTypes,
    getQuantityTypesFetched,
} from '../../../dave-data-module/State/selectors/accounting.selector';
import { getMaterialLists, getMaterialListsFetched } from '../../../dave-data-module/State/selectors/material-list.selector';
import { getMaterialDictionary, getMaterialsActive, getMaterialsFetched } from '../../../dave-data-module/State/selectors/material.selector';
import { getMaterialToCommission, getMaterialToCommissionById, getMaterialToCommissionFetched } from '../../../dave-data-module/State/selectors/resource-to-commission.selector';
import { getUserDictionary, getUsersFetched } from '../../../dave-data-module/State/selectors/users.selectors';
import { DaveDoubleIconModule } from '../../../dave-double-icon/dave-double-icon.module';
import { DaveListCardModule } from '../../../dave-list-card/dave-list-card.module';
import { DaveListModule } from '../../../dave-list/dave-list.module';
import { DaveLoadingModule } from '../../../dave-loading/dave-loading.module';
import { AppButtonModule } from '../../../dave-utils-module/app-button-module/app-button.module';
import { IDetailListTemplateDataProperty } from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/detail-list-template/detail-list-template.component';
import { DaveSharedComponentsModule } from '../../../dave-utils-module/dave-shared-components-module/dave-shared-components.module';
import { DetailListDialogReturn, DetailListTemplateDialogComponent, DetailListTemplateDialogData } from '../../../detail-list-template-dialog/components/detail-list-template-dialog.component';
import { appMatDialogDefaultConfig, compareArrays, isNotNullOrUndefined, sortByDateProperty, uniqArray } from '../../../helper/helper';
import { MaterialPageMeta } from '../../../helper/page-metadata';
import { ResourceMainDataPopupComponent, ResourceMainDataPopupComponentDialogData } from '../resource-main-data-popup/resource-main-data-popup.component';
import { SelectMaterialPopupComponent, SelectMaterialPopupComponentDialogData } from '../select-material-popup/select-material-popup.component';
import { AccountsReceivableLedgerResolver } from '../../../dave-data-module/guards/accounts-receivable-ledger.resolver';

interface MaterialListData {
    MaterialName: string;
    MaterialId: number;
    QuantityType: string;
    Quantity: number;
    CreatedAt: string;
    User: string;
    Status: string;
}
interface MaterialToCommissionTableData {
    QuantityType: string;
    MaterialToCommissionId: number;
    Quantity: number;
    Name: string;
    Date: string;
    User: string;
}
interface MaterialToCommissionData {
    MaterialName: string;
    MaterialId: number;
    TargetSum: number;
    CurrentSum$: Observable<number>;
    m2cs$: Observable<MaterialToCommissionTableData[]>;
}

export interface MaterialListComponentDialogData {
    CommissionId: number;
}

@Component({
    selector: 'app-material-list',
    templateUrl: './material-list.component.html',
    styleUrls: ['./material-list.component.scss'],
    standalone: true,
    imports: [
        AppButtonModule,
        FontAwesomeModule,
        MatTabsModule,
        AsyncPipe,
        DaveDoubleIconModule,
        DaveListCardModule,
        MatTooltipModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatListModule,
        DaveListModule,
        MatDialogModule,
        MatTableModule,
        NgIf,
        DaveLoadingModule,
        ScrollingModule,
        MatSortModule,
        TableVirtualScrollModule,
        DaveSharedComponentsModule,
        MatExpansionModule,
        MatCardModule,
        NgTemplateOutlet,
        MatButtonModule,
        ReactiveFormsModule,
        MatOptionModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatMenuModule,
        NgForOf,
    ],
})
export class MaterialListComponent {
    public static DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        panelClass: 'no-padding-dialog',
        width: '55rem',
        maxHeight: '90vh',
    };
    private commissionId$ = new BehaviorSubject<number | null>(null);
    @Input() set CommissionId(id: number) {
        this.commissionId$.next(id);
    }

    @Input() Expanded = true;
    @Input() Expandable = false;
    protected searchForm = new FormControl<string>('');
    protected searchVisible = false;
    @ViewChild('searchInput') searchInput: ElementRef;
    public MaterialColumns: Array<keyof MaterialListData> = ['MaterialName', 'Quantity', 'QuantityType', 'CreatedAt'];
    public MaterialToCommissionColumns: Array<keyof MaterialToCommissionTableData | 'editButton'> = ['Name', 'Date', 'Quantity' /*'QuantityType', 'User'*/, 'editButton'];
    public visibility = { Lieferant: true, Status: true, User: true };

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public DialogData: MaterialListComponentDialogData,
        @Optional() protected dialogRef: MatDialogRef<MaterialListComponent>,
        private store: Store<State>,
        private actions$: Actions,
        private dialog: MatDialog,
        quantityTypeResolver: QuantityTypeResolver,
        materialListResolver: MaterialListResolver,
        materialResolver: MaterialResolver,
        accountsReceivableLedgerResolver: AccountsReceivableLedgerResolver,
    ) {
        firstValueFrom(this.store.select(getQuantityTypesFetched)).then((fetched) => {
            if (!fetched) {
                quantityTypeResolver.resolve();
            }
        });
        firstValueFrom(this.store.select(getMaterialsFetched)).then((fetched) => {
            if (!fetched) {
                materialResolver.resolve();
            }
        });
        firstValueFrom(this.store.select(getMaterialListsFetched)).then((fetched) => {
            if (!fetched) {
                materialListResolver.resolve();
            }
        });
        firstValueFrom(this.store.select(getAccountsReceivableLedgersFetched)).then((fetched) => {
            if (!fetched) {
                accountsReceivableLedgerResolver.resolve();
            }
        });
        if (DialogData) {
            this.CommissionId = DialogData.CommissionId;
        }
    }
    private filteredMaterialLists$ = this.commissionId$.pipe(
        filter(isNotNullOrUndefined),
        switchMap((commissionId) => {
            return getFetched$(this.store, getMaterialListsFetched, getMaterialLists).pipe(map((materialList) => materialList.filter((mListEntry) => mListEntry.CommissionId === commissionId && !mListEntry.DeletedAt)));
        }),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public materialLists$ = this.commissionId$.pipe(
        filter(isNotNullOrUndefined),
        switchMap((commissionId) =>
            combineLatest([
                getFetched$(this.store, getQuantityTypesFetched, getQuantityTypes),
                getFetched$(this.store, getUsersFetched, getUserDictionary),
                this.filteredMaterialLists$,
                getFetched$(this.store, getMaterialsFetched, getMaterialDictionary),
                getFetched$(this.store, getAccountsReceivableLedgersFetched, getAccountsReceivableLedgerDictionary)
            ]),
        ),
        map(([quantityTypes, users, filteredMaterialList, materialDic, arls]) => {
            return new MatTableDataSource(
                filteredMaterialList.sort(sortByDateProperty('CreatedAt', false)).map((r) => {
                    const material = materialDic[r?.MaterialId];
                    const quantityType = r.ArlId
                        ? quantityTypes.find((q) => q.Id === arls[r.ArlId].QuantityTypeId)?.Name
                        : material ? quantityTypes.find((q) => q.Id === material.QuantityTypeId)?.Name : null;
                    return {
                        MaterialName: material?.Name?.trim(),
                        MaterialId: material ? material.Id : null,
                        Quantity: r.Amount,
                        QuantityType: quantityType,
                        CreatedAt: formatDate(r.CreatedAt, 'shortDate', 'de-DE'),
                        Status: r?.Status,
                        User: users[r.UserId]?.DisplayName,
                        createdAt: r.CreatedAt.getTime(),
                    };
                }),
            );
        }),
        tap(
            (dataSource) =>
                (dataSource.sortingDataAccessor = (object, key: keyof MaterialListData) => {
                    switch (key) {
                        case 'MaterialName':
                            return object.MaterialName || '';
                        case 'CreatedAt':
                            return object.createdAt;
                        case 'Quantity':
                            return object[key] || null;
                        default:
                            return object[key] || '';
                    }
                }),
        ),
        switchMap((tableData: MatTableDataSource<MaterialListData>) =>
            this.searchForm.valueChanges.pipe(
                startWith(''),
                map(() => (this.searchForm.value || '').toLowerCase()),
                map((searchTerm) => {
                    if (searchTerm) {
                        const filteredData = tableData?.data?.filter((data) => data?.MaterialName?.trim().toLowerCase().includes(searchTerm));
                        return new MatTableDataSource(filteredData);
                    } else {
                        return tableData;
                    }
                }),
            ),
        ),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    private filteredMaterialToCommissions$ = this.commissionId$.pipe(
        filter(isNotNullOrUndefined),
        switchMap((commissionId) =>
            getFetched$(this.store, getMaterialToCommissionFetched, getMaterialToCommission).pipe(map((m2c) => m2c.filter((m) => m.CommissionId === commissionId && !m.DeletedAt && m.WorkType !== WorkTypeEnum.FreeWork))),
        ),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public materialToCommissions$: Observable<MaterialToCommissionData[]> = this.commissionId$.pipe(
        filter(isNotNullOrUndefined),
        switchMap((commissionId) =>
            combineLatest([
                getFetched$(this.store, getQuantityTypesFetched, getQuantityTypes),
                getFetched$(this.store, getUsersFetched, getUserDictionary),
                getFetched$(this.store, getMaterialListsFetched, getMaterialLists).pipe(map((materialList) => materialList.filter((mListEntry) => mListEntry.CommissionId === commissionId && !mListEntry.DeletedAt))),
                combineLatest([this.filteredMaterialToCommissions$, this.filteredMaterialLists$]).pipe(
                    map(([filteredMaterialToCommissions, filteredMaterialList]) => uniqArray([...filteredMaterialToCommissions.map((f) => f.MaterialId), ...filteredMaterialList.map((f) => f.MaterialId)]).sort()),
                    distinctUntilChanged(compareArrays),
                ),
                getFetched$(this.store, getMaterialsFetched, getMaterialDictionary),
            ]),
        ),
        map(([quantityTypes, users, filteredMaterialList, materialIds, materialDic]) => {
            return materialIds
                .map<MaterialToCommissionData>((materialId) => {
                    const material = materialDic[materialId];
                    const m2cByMaterialId$ = this.filteredMaterialToCommissions$.pipe(
                        map((filteredMaterialToCommissions) => filteredMaterialToCommissions.filter((f) => f.MaterialId === materialId)),
                        shareReplay({ refCount: true, bufferSize: 1 }),
                    );
                    return {
                        MaterialName: material?.Name?.trim() || '',
                        MaterialId: material ? material.Id : null,
                        TargetSum: filteredMaterialList.filter((f) => f.MaterialId === materialId).reduce((p, c) => p + (c.Amount || 0), 0),
                        CurrentSum$: m2cByMaterialId$.pipe(map((m2cByMaterialId) => m2cByMaterialId.reduce((p, c) => p + (c.Value || 0), 0))),
                        m2cs$: m2cByMaterialId$.pipe(
                            map((m2cByMaterialId) =>
                                m2cByMaterialId.sort(sortByDateProperty('CreatedAt', false)).map((m2c) => {
                                    return {
                                        Name: m2c.Name,
                                        Date: formatDate(m2c.Date, 'shortDate', 'de-DE'),
                                        Quantity: m2c.Value,
                                        QuantityType: m2c.QuantityTypeId ? quantityTypes.find((q) => q.Id === material.QuantityTypeId)?.Name : null,
                                        MaterialToCommissionId: m2c.Id,
                                        // CreatedAt: formatDate(m2c.CreatedAt, 'shortDate', 'de-DE'),
                                        User: users[m2c.UserId]?.DisplayName,
                                    };
                                }),
                            ),
                        ),
                    };
                })
                .sort((a, b) => a.MaterialName.localeCompare(b.MaterialName));
        }),

        switchMap((data: MaterialToCommissionData[]) =>
            this.searchForm.valueChanges.pipe(
                startWith(''),
                map(() => (this.searchForm.value || '').toLowerCase()),
                map((searchTerm) => {
                    if (searchTerm) {
                        return data?.filter((d) => d?.MaterialName?.trim().toLowerCase().includes(searchTerm));
                    } else {
                        return data;
                    }
                }),
            ),
        ),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    toggleSearch() {
        this.searchVisible = !this.searchVisible;
        this.searchForm.setValue('');
        if (this.searchVisible) {
            setTimeout(() => {
                this.searchInput.nativeElement.focus();
            }, 0);
        }
    }
    entryClicked(row: MaterialListData) {
        this.dialog.open<ResourceMainDataPopupComponent, ResourceMainDataPopupComponentDialogData>(ResourceMainDataPopupComponent, {
            ...ResourceMainDataPopupComponent.DefaultConfig,
            data: {
                ResourceId: row ? row.MaterialId : null,
            },
        });
    }
    openDialog() {
        this.dialog.open<MaterialListComponent, MaterialListComponentDialogData>(MaterialListComponent, {
            ...MaterialListComponent.DefaultConfig,
            data: {
                CommissionId: this.commissionId$.value,
            },
        });
    }

    toggleColumn(column: 'Status' | 'User') {
        if (this.visibility[column]) {
            this.MaterialColumns.push(column);
            if (column === 'User') {
                this.MaterialToCommissionColumns = [...this.MaterialToCommissionColumns.slice(0,2),'User', ...this.MaterialToCommissionColumns.slice(2)];
            }
        } else {
            this.MaterialColumns = this.MaterialColumns.filter((col) => col !== column);
            if (column === 'User') {
                this.MaterialToCommissionColumns = this.MaterialToCommissionColumns.filter((col) => col !== column);
            }
        }
        this.visibility[column] = !this.visibility[column];
    }

    protected readonly MaterialPageMeta = MaterialPageMeta;

    openNewPopup() {
        this.dialog.open<SelectMaterialPopupComponent, SelectMaterialPopupComponentDialogData>(SelectMaterialPopupComponent, {
            ...SelectMaterialPopupComponent.DefaultConfig,
            data: {
                CommissionId: this.commissionId$.value,
            },
        });
    }

    private getMaterialToCommissionEditDialogData(m2c?: MaterialToCommissionEntity, materialId?: number) {
        return firstValueFrom(
            combineLatest([getFetched$(this.store, getQuantityTypesFetched, getQuantityTypes), getFetched$(this.store, getMaterialsFetched, getMaterialsActive), getFetched$(this.store, getMaterialsFetched, getMaterialDictionary)]),
        ).then(([quantityTypes, materials, materialDict]) => {
            const quantityTypeOptions = quantityTypes.map((res) => ({ Id: res.Id, optionLabel: res.Name }));
            const materialOptions = !materialId ? materials.map((res) => ({ Id: res.Id, optionLabel: res.DisplayName })) : [];

            const mIdForForm = m2c?.MaterialId || materialId;
            const materialForForm = mIdForForm && materialDict[mIdForForm];

            const materialToCommissionForm = new FormGroup({
                Date: new FormControl<Moment | null>(m2c ? (m2c?.Date ? moment(m2c.Date) : null) : moment(), Validators.required),
                Name: new FormControl<string>(m2c?.Name || null, Validators.required),
                Value: new FormControl<number>(m2c?.Value || null, Validators.required),
                QuantityType: new FormControl<{
                    Id: number;
                    optionLabel: string;
                } | null>(
                    m2c ? (m2c.QuantityTypeId ? quantityTypeOptions.find((o) => o.Id === m2c.QuantityTypeId) : null) : materialForForm ? quantityTypeOptions.find((o) => o.Id === materialForForm.QuantityTypeId) : null,
                    Validators.required,
                ),
                Material: new FormControl<{ Id: number; optionLabel: string } | null>(materialForForm ? { Id: materialForForm.Id, optionLabel: materialForForm.DisplayName } : null, Validators.required),
            });
            const Properties: IDetailListTemplateDataProperty[] = [
                {
                    key: 'Datum',
                    formControl: materialToCommissionForm.controls.Date,
                    options: {
                        specialInput: {
                            date: true,
                        },
                    },
                },
                {
                    key: 'Bezeichnung',
                    formControl: materialToCommissionForm.controls.Name,
                },
                {
                    key: MaterialEntity.EntityName,
                    formControl: materialToCommissionForm.controls.Material,
                    hideFormControl: !!mIdForForm,
                    options: {
                        specialInput: {
                            singleSelectSearch: {
                                options: materialOptions,
                                compareOptions: (a, b) => a?.Id === b?.Id,
                            },
                        },
                    },
                },
                {
                    key: 'Menge',
                    formControl: materialToCommissionForm.controls.Value,
                    options: {
                        specialInput: {
                            number: true,
                        },
                    },
                },
                {
                    key: 'Einheit',
                    formControl: materialToCommissionForm.controls.QuantityType,
                    options: {
                        specialInput: {
                            singleSelectSearch: {
                                options: quantityTypeOptions,
                                compareOptions: (a, b) => a?.Id === b?.Id,
                            },
                        },
                    },
                },
            ];
            return { Properties, materialToCommissionForm };
        });
    }
    editMaterialToCommission(m2cId: number) {
        firstValueFrom(this.store.select(getMaterialToCommissionById({ id: m2cId })))
            .then((m2c) => {
                return this.getMaterialToCommissionEditDialogData(m2c);
            })
            .then(({ Properties, materialToCommissionForm }) => {
                this.dialog
                    .open<DetailListTemplateDialogComponent, DetailListTemplateDialogData, DetailListDialogReturn>(DetailListTemplateDialogComponent, {
                        ...DetailListTemplateDialogComponent.DefaultConfig,
                        width: '25rem',
                        data: {
                            DisableSaveButton$: materialToCommissionForm.statusChanges.pipe(
                                startWith(materialToCommissionForm.status),
                                map((state) => state !== 'VALID'),
                            ),
                            Data: {
                                Headline: 'Materialverbrauch bearbeiten',
                                Properties,
                            },
                            DeleteButton: true,
                            Editing: true,
                        },
                    })
                    .afterClosed()
                    .subscribe((ret) => {
                        if (ret?.Action === 'save') {
                            this.store.dispatch(
                                MaterialToCommissionActionTypes.Change({
                                    Payload: {
                                        Id: m2cId.toString(),
                                        Date: GetTimestampFromTime(materialToCommissionForm.value.Date.toDate()) + '',
                                        Name: materialToCommissionForm.value.Name,
                                        Value: materialToCommissionForm.value.Value,
                                        QuantityTypeId: materialToCommissionForm.value.QuantityType?.Id ? materialToCommissionForm.value.QuantityType.Id.toString() : null,
                                    },
                                }),
                            );
                        } else if (ret?.Action === 'delete') {
                            this.store.dispatch(
                                MaterialToCommissionActionTypes.Delete({
                                    Payload: {
                                        Id: m2cId.toString(),
                                    },
                                }),
                            );
                        }
                    });
            });
    }

    createMaterialToCommission(element: Pick<MaterialToCommissionData, 'MaterialId' | 'MaterialName'>) {
        this.getMaterialToCommissionEditDialogData(null, element?.MaterialId).then(({ Properties, materialToCommissionForm }) => {
            this.dialog
                .open<DetailListTemplateDialogComponent, DetailListTemplateDialogData, DetailListDialogReturn>(DetailListTemplateDialogComponent, {
                    ...DetailListTemplateDialogComponent.DefaultConfig,
                    width: '25rem',
                    data: {
                        DisableSaveButton$: materialToCommissionForm.statusChanges.pipe(
                            startWith(materialToCommissionForm.status),
                            map((state) => state !== 'VALID'),
                        ),
                        Data: {
                            Headline: element?.MaterialName ? 'Verbrauch von ' + element.MaterialName + ' anlegen' : 'Materialverbrauch anlegen',
                            Properties,
                        },
                        DeleteButton: false,
                        Editing: true,
                    },
                })
                .afterClosed()
                .subscribe((ret) => {
                    if (ret?.Action === 'save') {
                        this.store.dispatch(
                            MaterialToCommissionActionTypes.Create({
                                Payload: {
                                    CommissionId: this.commissionId$.value.toString(),
                                    MaterialId: element?.MaterialId ? element.MaterialId.toString() : materialToCommissionForm.value.Material?.Id.toString(),
                                    Date: GetTimestampFromTime(materialToCommissionForm.value.Date.toDate()) + '',
                                    Name: materialToCommissionForm.value.Name,
                                    Value: materialToCommissionForm.value.Value,
                                    QuantityTypeId: materialToCommissionForm.value.QuantityType?.Id ? materialToCommissionForm.value.QuantityType.Id.toString() : null,
                                },
                            }),
                        );
                    }
                });
        });
    }
}
