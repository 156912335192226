<div class="header" [class.header-colorful]="HeaderColorful">
    <fa-icon class="icon" icon="calendar"></fa-icon>
    <h3 *ngIf="Headline" class="headline">Schichtplan</h3>
    <button
        class="header-button"
        app-round-button
        Inverted
        [matBadge]="FilterAmount$ | async"
        [matBadgeHidden]="!(FilterAmount$ | async)"
        matTooltip="Filter"
        [matBadgeOverlap]="true"
        (click)="filter.Open()"
    >
        <fa-icon icon="filter"></fa-icon>
    </button>
    <a
        class="header-button"
        app-round-button
        Inverted
        [matTooltip]="cls.getSingle$('RessourcePlan') | async"
        [routerLink]="['../', ResourcePlanMeta.Path]"
        *ngIf="ShowResourcePlanButton"
    >
        <fa-icon [icon]="PageMeta.Icon"></fa-icon>
    </a>
    <button class="header-button" app-round-button *ngFor="let button of AdditionalButtons" Inverted (click)="button.onClick($event)">
        <fa-icon [icon]="button.icon" [transform]="button.iconTransform" [matTooltip]="button.tooltip"></fa-icon>
    </button>
</div>
<div style="height: 0; flex-grow: 1; position: relative; display: flex; flex-direction: column;">
    <button app-round-button class="hide-left-row-button" (click)="LS.shiftPlanCollapsed = !LS.shiftPlanCollapsed"><fa-icon [icon]="LS.shiftPlanCollapsed ? 'chevron-right' : 'chevron-left'"></fa-icon></button>
    <ejs-schedule
        #scheduleObj
        width="100%"
        height="100%"
        (popupOpen)="onPopupOpen($event)"
        [class.hide-left-row]="LS.shiftPlanCollapsed && (BS.MobileQuery | async)"
        [enableAdaptiveUI]="false"
        [eventSettings]="Data$ | async"
        (eventRendered)="ApplyCategoryColor($event)"
        [selectedDate]="SelectedDate"
        (actionBegin)="onActionBegin($event)"
        (actionComplete)="actionComplete($event)"
        [group]="Group"
        [rowAutoHeight]="true"
        (dragStart)="onDragStart($event)"
        (resizeStart)="onResizeStart($event)"
        (dragStop)="onDragStop($event)"
        (resizeStop)="onResizeStop($event)"
        (dataBound)="dataBound()"
        cssClass="schedule-cell-dimension"
    >
        <e-resources>
            <e-resource field="EmployeeId" name="Employee" title="Employees" textField="Text" idField="Id" [dataSource]="EmployeeDataSource$ | async"></e-resource>
            <!--        <e-resource field="GroupId" name="Resources1" title="Name" textField="Name" idField="Id" colorField="Color" [dataSource]="ResourceDataSource"></e-resource>-->
        </e-resources>
        <e-views>
            <e-view option="TimelineWorkWeek" displayName="Arbeitswoche" startHour="6:00" endHour="20:00" [timeScale]='{ enable: true, interval: 30, slotCount: 1 }' [isSelected]="View === 'TimelineWorkWeek'"></e-view>
            <e-view option="TimelineMonth" displayName="Monat" [isSelected]="View === 'TimelineMonth'"></e-view>
            <e-view option="TimelineMonth" displayName="3 Monate" [interval]="3" [isSelected]="View === 'Timeline3Month'"></e-view>
            <e-view option="TimelineYear" displayName="Jahr" orientation="Vertical" [group]="Group" [isSelected]="View === 'TimelineYear'"></e-view>
        </e-views>
        <ng-template #dateHeaderTemplate let-data>
            <div>{{data.date | date: 'd.'}}</div>
            <div>{{data.date | date: 'EE'}}</div>
        </ng-template>
        <!-- Header template -->
        <!--    <ng-template #quickInfoTemplatesHeader let-data>-->
        <!--        <div *ngIf="data.elementType == 'cell' || data.elementType == 'event'">-->
        <!--            <div class="e-popup-header">-->
        <!--                <div class="e-header-icon-wrapper">-->
        <!--                    <div *ngIf="data.elementType == 'event'" class="subject">{{data.Subject}}</div>-->
        <!--                    <button class="e-close e-close-icon e-icons" title="Close" ></button>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->
        <!--    </ng-template>-->

        <ng-template #quickInfoTemplatesHeader let-data>
            <div class="e-popup-header" [ngStyle]="{'background-color': data.CategoryColor?.primary ? data.CategoryColor.primary : 'unset'}">
                <div class="e-header-icon-wrapper">
                    <ng-container *ngIf="AdditionalHeaderButtons">
                        <ng-container *ngFor="let button of AdditionalHeaderButtons">
                            <a *ngIf="button.routerLink" class="e-edit e-btn e-lib e-flat e-round e-icon-btn" [routerLink]="button.routerLink" [title]="button.tooltip$ | async">
                                <span class="e-btn-icon e-icons"><fa-icon [icon]="button.icon"></fa-icon></span>
                            </a>
                            <a *ngIf="button.URL" class="e-edit e-btn e-lib e-flat e-round e-icon-btn" [href]="button.URL" [title]="button.tooltip$ | async">
                                <span class="e-btn-icon e-icons"><fa-icon [icon]="button.icon"></fa-icon></span>
                            </a>
                            <button *ngIf="!button.routerLink && !button.URL" class="e-edit e-btn e-lib e-flat e-round e-icon-btn" [title]="button.tooltip$ | async" (click)="button.onClick($event)">
                                <span class="e-btn-icon e-icons"><fa-icon [icon]="button.icon"></fa-icon></span>
                            </button>
                        </ng-container>
                    </ng-container>
                    <button [disabled]="data.isReadonly" class="e-edit e-btn e-lib e-flat e-round e-icon-btn " title="Bearbeiten"></button>
                    <button [disabled]="data.isReadonly" class="e-delete e-btn e-lib e-flat e-round e-icon-btn" title="Löschen"></button>
                    <button class="e-close e-btn e-lib e-flat e-round e-icon-btn" title="Schließen"></button>
                </div>
                <div class="e-subject-wrap">
                    <div class="e-subject e-text-ellipsis" [title]="data.Subject">{{ data.Subject }}</div>
                </div>
            </div>
        </ng-template>
    </ejs-schedule>
</div>

<app-filter-card UnlimitedHeight #filter [Settings]="FilterSettings$ | async" [Values]="FilterValues$ | async" (ValuesChange)="FilterValues$.next($event)"></app-filter-card>
<mat-menu #menu style="min-width: 17rem; max-width: 26rem;">
    <button *ngFor="let button of AddButtons$ | async" mat-menu-item (click)="button.onClick($event)" [disabled]="button.isDisabled()" style="height: 4rem; line-height: 4rem;
">
        <fa-icon [icon]="button.icon" style="margin-right: 0.5rem"></fa-icon>
        <span>{{ button.label }}</span>
    </button>
</mat-menu>
<button #matMenuTrigger style="visibility: hidden; position: fixed" [matMenuTriggerFor]="menu"></button>
