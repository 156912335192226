<h1 mat-dialog-title>{{cls.getSingle$('Commission') | async}} anlegen</h1>
<app-detail-list-template #listTemplate *ngIf="Data; else loading" [Data]="Data" (Submit)="Submit()" [Editing]="true" [Inline]="true" [AutofocusIndex]="0"></app-detail-list-template>
<mat-form-field *ngIf="!forceParentId && (parentCommissionTypes$ | async | length)" class="full-width">
    <mat-label>{{parentCommissionLabel$ | async}}</mat-label>
    <app-select-search
        [formControl]="parentCommissionForm"
        [Options]="parentCommissions$ | async"
        [CompareOptions]="CompareById">
    </app-select-search>
</mat-form-field>
<mat-slide-toggle class="m05" *ngIf="showAddResourcesFromLedgerImport" [formControl]="addResourcesFromLedgerImportForm">Buchungspositionen als Ressourcen anlegen</mat-slide-toggle>
<mat-dialog-actions>
    <button app-button mat-dialog-close Inverted Color="cancel">Abbrechen</button>
    <button app-button Color="green" (click)="Submit()" [Disabled]="Form.invalid" [IsLoading]="ls.IsLoading$ | async" style="margin-left: auto">Speichern</button>
</mat-dialog-actions>
<ng-template #loading><app-loading></app-loading></ng-template>
