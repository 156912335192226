import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { FileOpenerTokenEntity } from '../../entities/file-opener-token.entity';
import { getLatestUpdatedAt } from '../../helper/reducer.helper';
import { FileOpenerTokenActions } from '../actions/file-opener-token.actions';

export const FILE_OPENER_TOKEN_KEY = 'file_opener_token';

export interface FileOpenerTokenState extends EntityState<FileOpenerTokenEntity> {
    fetched: boolean;
    latestUpdatedAt: Date | null;
}

const adapter = createEntityAdapter<FileOpenerTokenEntity>({
    selectId: (Entity) => Entity.Id,
});

const initialState: FileOpenerTokenState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
});

/* ############# */
/* ## REDUCER ## */
/* ############# */

export const fileOpenerTokenReducer = createReducer(
    initialState,
    // empty payload dosen#t trigger selectors
    on(FileOpenerTokenActions.loadSuccess, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setMany(Payload, {
            ...state,
            fetched: true,
            latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
        }),
    ),
    on(FileOpenerTokenActions.invitationacceptSuccess, (state, { Payload }) => adapter.setOne(Payload, state)),
    on(FileOpenerTokenActions.removeSuccess, (state, { Payload }) => adapter.removeOne(Payload, state)),
);

/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllFileOpenerToken = selectAll;
export const selectFileOpenerTokenEntities = selectEntities;

export const selectFileOpenerTokenFetched = (state: FileOpenerTokenState) => state.fetched;
export const selectFileOpenerTokenLatestUpdatedAt = (state: FileOpenerTokenState) => state.latestUpdatedAt;
