import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, of, Subject, Subscription, switchMap } from 'rxjs';
import { filter, first, map, shareReplay, take, tap, withLatestFrom } from 'rxjs/operators';
import { EmailEntity } from '../../../../dave-data-module/entities/email.entity';
import { EmailFolderEntityType } from '../../../../dave-data-module/entities/emailFolder.entity';
import { EventEntity } from '../../../../dave-data-module/entities/event.entity';
import { FileEntity } from '../../../../dave-data-module/entities/file.entity';
import { PersonEntity } from '../../../../dave-data-module/entities/person.entity';
import { CommissionResolver } from '../../../../dave-data-module/guards/commission.resolver';
import { EmailConnectionResolver } from '../../../../dave-data-module/guards/email-connection.resolver';
import { EmailFolderResolver } from '../../../../dave-data-module/guards/email-folder.resolver';
import { EventResolver } from '../../../../dave-data-module/guards/event.resolver';
import { EmailDataService } from '../../../../dave-data-module/services/email-data.service';
import { FileDataService } from '../../../../dave-data-module/services/file-data.service';
import { State } from '../../../../dave-data-module/State';
import { BaseActionTypes } from '../../../../dave-data-module/State/actions/base.actions';
import { EmailActionTypes } from '../../../../dave-data-module/State/actions/email.actions';
import { getCommissions, getCommissionsFetched } from '../../../../dave-data-module/State/selectors/commission.selector';
import { getEmailConnections, getEmailConnectionsFetched, getUndeletedEmailConnections } from '../../../../dave-data-module/State/selectors/email-connection.selectors';
import { getEmailFolders, getEmailFoldersFetched } from '../../../../dave-data-module/State/selectors/email-folders.selectors';
import { getAllEvents, getEventFetched } from '../../../../dave-data-module/State/selectors/events.selectors';
import { getPersons } from '../../../../dave-data-module/State/selectors/person.selectors';
import { DetailTasksComponent, DetailTasksComponentDialogData } from '../../../../dave-event-card/components/detail-tasks/detail-tasks.component';
import { DaveFilePreviewComponent, DaveFilePreviewComponentDialogData } from '../../../../dave-file-preview-dialog/components/dave-file-preview/dave-file-preview.component';
import { PermissionService } from '../../../../dave-utils-module/dave-shared-components-module/services/permission.service';
import { isNotNullOrUndefined } from '../../../../helper/helper';
import { AllCommissionMeta, CommissionMeta, ContactBookMeta, CustomerAdministrationMeta, HistoryMeta, ProcessMeta, TaskPageMeta } from '../../../../helper/page-metadata';
import { StartProcessDialogComponent, StartProcessDialogComponentDialogData } from '../../../../process/components/start-process-dialog/start-process-dialog.component';
import { CustomLabelService } from '../../../../services/custom-label.service';
import { MoveFileFolderService } from '../../../../services/move-file-folder.service';
import { ProcessService } from '../../../../services/process.service';
import { EmailEditorComponent, EmailEditorComponentDialogConfig, EmailEditorComponentDialogData } from '../../email-editor/email-editor.component';
import { EventFromEmailComponent, EventFromEmailComponentDialogData } from '../../event-from-email/event-from-email.component';

interface ShowEmailOld {
    email: EmailEntity;
    event: EventEntity;
    task: EventEntity;
    canOpenCommission: boolean;
    emailConnectionId: number;
    from: { name: string; email: string };
    emailTextPreview: string;
    isDraft: boolean;
    personsByEmail$: Observable<{
        from: PersonEntity | undefined;
        to: {
            email: string;
            person: PersonEntity | undefined;
        }[];
        cc: {
            email: string;
            person: PersonEntity | undefined;
        }[];
        bcc: {
            email: string;
            person: PersonEntity | undefined;
        }[];
    }>;
    documentsShow: FileEntity[];
    inlineDocumentsShow: FileEntity[];
}

@Component({
    selector: 'app-email-preview',
    templateUrl: './email-preview.component.html',
    styleUrls: ['./email-preview.component.scss'],
})
export class EmailPreviewComponent {
    @Input() set EmailId(d: number) {
        this.emailId$.next(d);
    }

    get EmailId() {
        return this.emailId$.value;
    }

    public ContactPath = ContactBookMeta.Path;
    public CustomerPath = CustomerAdministrationMeta.Path;
    public ChronikPath = HistoryMeta.Path;
    public CommissionPath = CommissionMeta.Path;
    public AllCommissionPath = AllCommissionMeta.Path;
    public TaskMeta = TaskPageMeta;
    public FilterSeen$ = new BehaviorSubject<boolean | null>(null);
    protected triggerSearch$ = new Subject<void>(); // ???
    private subscriptions: Subscription[] = [];
    public AnswerAll$ = new Subject<EmailEntity>();
    public emailTasks$: Observable<EventEntity[]>;
    protected fileMultiSelect = false;
    protected selectedFiles: number[] = [];

    protected emailId$ = new BehaviorSubject<number | null>(null);
    public PreviewedEmail$: Observable<ShowEmailOld> = this.emailId$.pipe(
        switchMap((id) =>
            id
                ? combineLatest([
                      this.emailDataService.GetEmailById$(id).pipe(filter(isNotNullOrUndefined)),
                      this.store.select(getEventFetched).pipe(
                          tap(() => this.eventRes.resolve()),
                          filter((v) => v),
                          switchMap(() => this.store.select(getAllEvents)),
                      ),
                      this.store.select(getEmailFoldersFetched).pipe(
                          tap(() => this.emailFolderRes.resolve()),
                          filter((v) => v),
                          switchMap(() => this.store.select(getEmailFolders)),
                      ),
                      this.store.select(getCommissionsFetched).pipe(
                          tap(() => this.commissionRes.resolve()),
                          filter((v) => v),
                          switchMap(() => this.store.select(getCommissions)),
                      ),
                      this.store.select(getEmailConnectionsFetched).pipe(
                          tap(() => this.emailConnectionRes.resolve()),
                          filter((v) => v),
                          switchMap(() => this.store.select(getEmailConnections)),
                      ),
                  ]).pipe(
                      switchMap(([email, events, emailFolders, commissions, connections]) => {
                          const eventsByEmail = events.filter((e) => e.EmailId === email.Id);
                          this.emailTasks$ = of(eventsByEmail).pipe(map((data) => data.filter((tasks) => tasks.IsTask)));

                          let emailFolder = emailFolders.find((ef) => ef.Id == email.FolderId);
                          let isDraft = isNotNullOrUndefined(emailFolder) && emailFolder.Type.toLowerCase() == EmailFolderEntityType.Drafts.toLowerCase();

                          const event = eventsByEmail.find((e) => !e.IsTask);
                          const prevEmail: ShowEmailOld = {
                              email: email,
                              event,
                              task: eventsByEmail.find((e) => e.IsTask),
                              canOpenCommission: email?.CommissionId && commissions.some((c) => c.Id === email.CommissionId),
                              emailConnectionId: email.EmailConnectionId,
                              emailTextPreview: email.PreviewText,
                              isDraft: isDraft,
                              personsByEmail$: this.store.select(getPersons).pipe(
                                  map((persons) => ({
                                      from: email.From ? persons.find((p) => p.Email && p.Email.toLowerCase() == email.From.toLowerCase()) : null,
                                      to: email.To?.length
                                          ? email.To.map((e) => ({
                                              email: e,
                                              person: persons.find((p) => p.Email && p.Email.toLowerCase() == e.toLowerCase()),
                                          }))
                                          : null,
                                      cc: email.CarbonCopy?.length
                                          ? email.CarbonCopy.map((e) => ({
                                              email: e,
                                              person: persons.find((p) => p.Email && p.Email.toLowerCase() == e.toLowerCase()),
                                          }))
                                          : null,
                                      bcc: email.BlindCarbonCopy?.length
                                          ? email.BlindCarbonCopy.map((e) => ({
                                              email: e,
                                              person: persons.find((p) => p.Email && p.Email.toLowerCase() == e.toLowerCase()),
                                          }))
                                          : null,
                                  })),
                                  shareReplay({ refCount: true, bufferSize: 1 }),
                              ),
                              from: email.fromHeaderParsed,
                              documentsShow: [],
                              inlineDocumentsShow: [],
                          };
                          if (email.DocumentIds) {
                              return this.fileDataService.GetFilesById(email.DocumentIds, { withEmailInline: true }).pipe(
                                  take(1),
                                  map((files) => {
                                      email.DocumentIds.forEach((id) => {
                                          const file = files.find((f) => f?.Id === id);
                                          if (file) {
                                              if (!file.IsEmailInline) {
                                                  prevEmail.documentsShow.push(file);
                                              } else {
                                                  prevEmail.inlineDocumentsShow.push(file);
                                              }
                                          }
                                      });
                                      return prevEmail;
                                  }),
                              );
                          }
                          return of(prevEmail);
                      }),
                  )
                : of(null),
        ),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    private filePreviewDialog: MatDialogRef<DaveFilePreviewComponent>;

    constructor(
        private store: Store<State>,
        public PS: PermissionService,
        private dialog: MatDialog,
        private emailDataService: EmailDataService,
        private actions$: Actions,
        private fileDataService: FileDataService,
        private eventRes: EventResolver,
        private emailFolderRes: EmailFolderResolver,
        private commissionRes: CommissionResolver,
        private emailConnectionRes: EmailConnectionResolver,
        protected cls: CustomLabelService,
        private processService: ProcessService,
        private moveFileFolderService: MoveFileFolderService,
    ) {
        this.subscriptions.push(
            this.AnswerAll$.pipe(
                withLatestFrom(this.store.select(getEmailConnections)),
                tap(([email, connections]) => {
                    const emailconnection = connections.find((c) => c.Id === email.EmailConnectionId);
                    this.openEmailDialog({
                        TargetEmails: [email.From, ...email.To].filter((e) => e && e.toLowerCase() !== emailconnection?.Address.toLowerCase()),
                        EmailSubject: 'Re: ' + email.Subject,
                        EmailId: email.Id,
                        CCEmails: email.CarbonCopy,
                        EmailSourceConnectionId: email.EmailConnectionId,
                        focusElement: 'emailBody',
                    });
                }),
            ).subscribe(),
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    public ToggleEllipsisClass(e: HTMLElement) {
        if (e.classList.contains('ellipsis')) {
            e.classList.remove('ellipsis');
        } else {
            e.classList.add('ellipsis');
        }
    }

    public IsEllipsisActive(e: HTMLElement) {
        return e.offsetWidth < e.scrollWidth;
    }

    public addEvent() {
        firstValueFrom(this.PreviewedEmail$).then((email) =>
            this.dialog.open(EventFromEmailComponent, {
                ...EventFromEmailComponent.DefaultConfig,
                data: {
                    Email: email.email,
                } as EventFromEmailComponentDialogData,
            }),
        );
    }

    public editEvent() {
        firstValueFrom(this.PreviewedEmail$).then((email) =>
            this.dialog.open(EventFromEmailComponent, {
                ...EventFromEmailComponent.DefaultConfig,
                data: {
                    Email: email.email,
                } as EventFromEmailComponentDialogData,
            }),
        );
    }

    public toggleSeen() {
        firstValueFrom(this.PreviewedEmail$).then((d) => {
            if (!d.email.Seen) {
                this.store.dispatch(
                    EmailActionTypes.ModifyEmail({
                        Payload: {
                            id: d.email.Id,
                            seen: true,
                        },
                    }),
                );
            } else {
                this.store.dispatch(
                    EmailActionTypes.ModifyEmail({
                        Payload: {
                            id: d.email.Id,
                            seen: false,
                        },
                    }),
                );
            }
            if (this.FilterSeen$.value != null) {
                this.emailDataService.ClearCache([d.email.Id]);
            }
            this.actions$
                .pipe(ofType(EmailActionTypes.UpdateOneEmail, BaseActionTypes.ErrorAction))
                .pipe(first())
                .subscribe(() => {
                    this.triggerSearch$.next();
                });
        });
    }

    public DeleteEmail(email: EmailEntity) {
        this.store.dispatch(EmailActionTypes.DeleteEmails({ Payload: { ids: [email.Id] } }));
        this.actions$
            .pipe(ofType(EmailActionTypes.UpdateSomeEmails, BaseActionTypes.ErrorAction))
            .pipe(first())
            .subscribe(() => {
                this.emailDataService.CleanPages([email.Id]);
                this.triggerSearch$.next();
            });
    }

    CreateTaskDialog(email: EmailEntity) {
        this.dialog.open<DetailTasksComponent, DetailTasksComponentDialogData>(DetailTasksComponent, {
            ...DetailTasksComponent.DefaultConfig,
            data: {
                InitialValues: {
                    PersonIds: [email.PersonId],
                    DocumentIds: email.DocumentIds,
                    EmailId: email.Id,
                    CommissionId: email.CommissionId,
                    Name: email.Subject,
                },
            },
        });
    }

    EditTaskDialog(id: number) {
        this.dialog.open<DetailTasksComponent, DetailTasksComponentDialogData>(DetailTasksComponent, {
            ...DetailTasksComponent.DefaultConfig,
            data: {
                EventId: id,
            },
        });
    }

    protected readonly HistoryMeta = HistoryMeta;
    protected readonly TaskPageMeta = TaskPageMeta;
    protected readonly CustomerAdministrationMeta = CustomerAdministrationMeta;

    OpenDocumentPreview(fileId: number) {
        if (this.filePreviewDialog?.getState() === MatDialogState.OPEN) {
            this.filePreviewDialog.close();
        }
        this.PreviewedEmail$.pipe(take(1)).subscribe((data) => {
            this.filePreviewDialog = this.dialog.open<DaveFilePreviewComponent, DaveFilePreviewComponentDialogData>(DaveFilePreviewComponent, {
                ...DaveFilePreviewComponent.DefaultConfig,
                data: {
                    fileId,
                    fileExplorerContext: {
                        directoryFileIds: data.documentsShow.map((d) => d.Id),
                    },
                },
            });
        });
    }

    openEmailDialog(dialogData: EmailEditorComponentDialogData) {
        firstValueFrom(this.store.select(getUndeletedEmailConnections)).then((connections) => {
            const connectionAvailable = dialogData.Id || !dialogData.EmailSourceConnectionId || connections.some((c) => c.Id === dialogData.EmailSourceConnectionId);
            this.dialog.open(EmailEditorComponent, {
                ...EmailEditorComponentDialogConfig,
                data: connectionAvailable ? dialogData : { ...dialogData, EmailSourceConnectionId: null },
            });
        });
    }

    answerEmail(email: EmailEntity) {
        this.openEmailDialog({
            TargetEmails: [email.From],
            EmailSubject: 'Re: ' + email.Subject,
            EmailId: email.Id,
            EmailSourceConnectionId: email.EmailConnectionId,
            CommissionId: email.CommissionId,
            CustomerId: email.CustomerId,
            focusElement: 'emailBody',
        });
    }

    editEmail(email: EmailEntity) {
        this.openEmailDialog({
            Id: email.Id,
            TargetEmails: email.To,
            CCEmails: email.CarbonCopy,
            BCCEmails: email.BlindCarbonCopy,
            EmailSubject: email.Subject,
            EmailHtml: email.Body,
            EmailSourceConnectionId: email.EmailConnectionId,
            FileAttachmntFileIds: email.DocumentIds,
            GeneratedFileAttachmntFileIds: email.GeneratedDocumentIds,
            CommissionId: email.CommissionId,
            CustomerId: email.CustomerId,
        });
    }

    forwardEmail(email: EmailEntity) {
        this.openEmailDialog({
            EmailSubject: 'Fwd: ' + email.Subject,
            EmailId: email.Id,
            FileAttachmntFileIds: email.DocumentIds,
            GeneratedFileAttachmntFileIds: email.GeneratedDocumentIds,
            CommissionId: email.CommissionId,
            CustomerId: email.CustomerId,
            focusElement: 'targetEmail',
        });
    }

    fileMultiselectToggle(fileId: number) {
        if (this.selectedFiles.includes(fileId)) {
            this.selectedFiles = this.selectedFiles.filter((id) => id !== fileId);
        } else {
            this.selectedFiles = [...this.selectedFiles, fileId];
        }
    }

    fileMultiselectSetAll(check: boolean, files: FileEntity[]) {
        this.selectedFiles = check ? files.map((f) => f.Id) : [];
    }

    sendFiles() {
        if (this.selectedFiles.length) {
            this.dialog.open<EmailEditorComponent, EmailEditorComponentDialogData>(EmailEditorComponent, {
                ...EmailEditorComponent.DefaultConfig,
                data: {
                    FileAttachmntFileIds: this.selectedFiles.slice(),
                },
            });
            this.fileMultiselectSetAll(false, []);
        }
    }

    addFilesToProcess() {
        if (this.selectedFiles.length) {
            this.processService.openAddFileToProcessDialog(this.selectedFiles.slice());
            this.fileMultiselectSetAll(false, []);
        }
    }

    protected onStartProcessClick() {
        this.dialog.open<StartProcessDialogComponent, StartProcessDialogComponentDialogData>(StartProcessDialogComponent, {
            ...StartProcessDialogComponent.DefaultConfig,
            data: {
                fileId: this.selectedFiles.length === 1 ? this.selectedFiles[0] : null,
                fileIds: this.selectedFiles.length > 1 ? this.selectedFiles : null,
            },
        });
    }

    moveFiles() {
        if (this.selectedFiles.length) {
            this.moveFileFolderService.MoveFileFolder(this.selectedFiles.slice(), []);
        }
        this.fileMultiselectSetAll(false, []);
    }

    protected readonly ProcessMeta = ProcessMeta;
    protected readonly CommissionMeta = CommissionMeta;
}
