import { CommonModule } from '@angular/common';
import { Component,Input } from '@angular/core';
import { AllowanceCharge } from '@dave/types';

import { SpecifiedTradeAllowanceChargeCode } from '@dave/types/dist/proto/einvoicing/main';
import { AbbrComponent } from '../../../../abbr/abbr.component';
import { HintIconComponent } from '../../../../hint-icon/hint-icon.component';
import { eInvoiceTaxCategoryCodeDescriptions,eInvoiceTaxCategoryCodeNames } from '../../../services/e-invoice-data.service';

@Component({
    selector: 'app-e-invoice-allowance-charge-view',
    standalone: true,
    imports: [CommonModule, AbbrComponent, HintIconComponent],
    templateUrl: './e-invoice-allowance-charge-view.component.html',
    styleUrls: ['./e-invoice-allowance-charge-view.component.scss'],
})
export class EInvoiceAllowanceChargeViewComponent {
    constructor() {}
    @Input() charge: AllowanceCharge;
    @Input() currencyCode: string;
    protected eInvoiceTaxCategoryCodeNames = eInvoiceTaxCategoryCodeNames;
    protected eInvoiceTaxCategoryCodeDescriptions = eInvoiceTaxCategoryCodeDescriptions;
    // https://www.xrepository.de/details/urn:xoev-de:kosit:codeliste:untdid.5189_1
    protected eInvoiceSpecifiedTradeAllowanceChargeCodeNames = new Map<SpecifiedTradeAllowanceChargeCode, string>([
        [SpecifiedTradeAllowanceChargeCode.BonusAheadOfSchedule, 'Bonus für frühzeitige Fertigstellung'],
        [SpecifiedTradeAllowanceChargeCode.OtherBonus, 'Sonstiger Bonus'],
        [SpecifiedTradeAllowanceChargeCode.ManufacturerConsumerDiscount, 'Herstellerrabatt'],
        [SpecifiedTradeAllowanceChargeCode.DueToMilitaryStatus, 'Militärstatuszuschuss'],
        [SpecifiedTradeAllowanceChargeCode.DueToWorkAccident, 'Arbeitsunfallentschädigung'],
        [SpecifiedTradeAllowanceChargeCode.SpecialAgreement, 'Sondervereinbarung'],
        [SpecifiedTradeAllowanceChargeCode.ProductionErrorDiscount, 'Produktionsfehler'],
        [SpecifiedTradeAllowanceChargeCode.NewOutletDiscount, 'Neueröffnungsrabatt'],
        [SpecifiedTradeAllowanceChargeCode.SampleDiscount, 'Ausstellungsstück'],
        [SpecifiedTradeAllowanceChargeCode.EndOfRangeDiscount, 'Produktlebenszyklus in der Nachlaufphase (EOL)'],
        [SpecifiedTradeAllowanceChargeCode.IncotermDiscount, 'Incoterm'],
        [SpecifiedTradeAllowanceChargeCode.PointOfSalesThresholdAllowance, 'Ziele des Verkaufsortes erreicht'],
        [SpecifiedTradeAllowanceChargeCode.MaterialSurchargeDeduction, 'Mehr-/Minderverbrauch von Material'],
        [SpecifiedTradeAllowanceChargeCode.Discount, 'Ermäßigung'],
        [SpecifiedTradeAllowanceChargeCode.SpecialRebate, 'teilweise Rückerstattung'],
        [SpecifiedTradeAllowanceChargeCode.FixedLongTerm, 'fester, langfristiger Zuschlag/Rabatt'],
        [SpecifiedTradeAllowanceChargeCode.Temporary, 'zeitlich begrenzter Zuschlag/Rabatt'],
        [SpecifiedTradeAllowanceChargeCode.Standard, 'Standard Zuschlag/Rabatt'],
        [SpecifiedTradeAllowanceChargeCode.YearlyTurnover, 'Jährlicher Zuschlag/Rabatt'],
    ]);
}
