import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../State';
import { ProcessActions } from '../State/actions/process.actions';
import { getProcessFetched, getProcessLatestUpdatedAt } from '../State/selectors/process.selector';
import { BaseEntityReloadResolver2 } from './resolver.helper';
import { GetTimestampFromTime } from "@dave/types";
import {
    getFileOpenerTokenFetched,
    getFileOpenerTokenLatestUpdatedAt,
} from '../State/selectors/file-opener-token.selector';
import { FileOpenerTokenActions } from '../State/actions/file-opener-token.actions';

@Injectable({
    providedIn: 'root',
})
export class FileOpenerTokenResolver extends BaseEntityReloadResolver2 {
    constructor(store: Store<State>) {
        super(getFileOpenerTokenFetched, getFileOpenerTokenLatestUpdatedAt, FileOpenerTokenActions.loadRequest, store, (d) => GetTimestampFromTime(d)+'');
    }
}
