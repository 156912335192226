import { CdkDragDrop,CdkDragEnd,CdkDragStart,DragDropModule,moveItemInArray } from '@angular/cdk/drag-drop';
import { CommonModule,formatCurrency,formatNumber,getCurrencySymbol } from '@angular/common';
import { AfterViewInit,ChangeDetectionStrategy,Component,DoCheck,EventEmitter,Inject,Input,LOCALE_ID,OnDestroy,OnInit,Output,QueryList,ViewChild,ViewChildren } from '@angular/core';

import { FormControl,ReactiveFormsModule,ɵFormGroupValue } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTable,MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject,combineLatest,firstValueFrom,merge,Observable,of,Subject,Subscription,switchMap } from 'rxjs';
import { filter,first,map,shareReplay,startWith,takeUntil,tap,withLatestFrom } from 'rxjs/operators';
import { ARLCustomProperties } from '../../../../dave-data-module/entities/accounts-receivable-ledger-template.entity';
import { AccountsReceivableLedgerEntity,AccountsReceivableLedgerTypeEnum,CalcAccountsReceivableLedgerAmount,isBookingPositionTypeEnum,NettoCost } from '../../../../dave-data-module/entities/accounts-receivable-ledger.entity';
import { LedgerImportDocumentTypes } from '../../../../dave-data-module/entities/ledger-import.entity';
import { MaterialEntity } from '../../../../dave-data-module/entities/material.entity';
import { ResourceEntity } from '../../../../dave-data-module/entities/resource-dispo/resource.entity';
import { DaveMutationChangeAccountsReceivableLedgerArgs,DaveMutationCreateAccountsReceivableLedgerArgs } from '../../../../dave-data-module/graphql-types';
import { ArlDataService } from '../../../../dave-data-module/services/arl-data.service';
import { MaterialToSupplierDataService } from '../../../../dave-data-module/services/material-to-supplier-data.service';
import { State } from '../../../../dave-data-module/State';
import { AccountsReceivableLedgerActionTypes } from '../../../../dave-data-module/State/actions/accounting.actions';
import { getAccountsReceivableLedgerById,getAccountsReceivableLedgerDictionary,getQuantityTypes } from '../../../../dave-data-module/State/selectors/accounting.selector';
import { getMaterialById } from '../../../../dave-data-module/State/selectors/material.selector';
import { getResourceById } from '../../../../dave-data-module/State/selectors/resource-dispo/resource.selectors';
import { DaveDoubleIconModule } from '../../../../dave-double-icon/dave-double-icon.module';
import { AppButtonModule } from '../../../../dave-utils-module/app-button-module/app-button.module';
import { BreakpointObserverService } from '../../../../dave-utils-module/dave-shared-components-module/services/breakpoint-observer.service';
import { PermissionService } from '../../../../dave-utils-module/dave-shared-components-module/services/permission.service';
import { compareArrays,isNotNullOrUndefined,TableColumnConfig,uniqArray } from '../../../../helper/helper';
import { LengthPipe } from '../../../../helper/length.pipe';
import { ResourcePageMeta } from '../../../../helper/page-metadata';
import { NumberTransformInputComponent } from '../../../../number-transform-input/number-transform-input.component';
import { LoadingService } from '../../../../services/loading.service';
import { TableConfigFormComponent } from '../../../../table-config-form/table-config-form.component';
import { ArlInformationInputComponent } from '../../arl-information-input/arl-information-input.component';
import { MaterialPriceDetailsPopupComponent,MaterialPriceDetailsPopupComponentDialogData,MaterialPriceDetailsPopupComponentDialogReturnData } from '../../material-price-details-popup/material-price-details-popup.component';
import { ArlCalculationDataService } from '../arl-calculation-data.service';
import { calcPositionStrings } from '../arl-calculation.helper';
import { ArlFormDataService,arlTableDataArlForm } from '../arl-form-data.service';
import { ArlTableDetailRowComponent } from '../arl-table-detail-row/arl-table-detail-row.component';
import { calculationArlForm } from '../calculation-arl-form-data.service';

interface arlTableData<T = AccountsReceivableLedgerEntity> {
    entity: T;
    isExpanded$: Observable<boolean>;
    animationTrigger$: Observable<'collapsed' | 'expanded'>;
    calculatedAmount: FormControl<number>;
    amount$: Observable<number>;
    amountWithTax$: Observable<number>;
    position$: Observable<string>;
    resource$: Observable<ResourceEntity>;
    material$: Observable<MaterialEntity>;
    form: arlTableDataArlForm;
    position: string;
}
interface groupEndPlaceholder {
    groupId: number;
    amount$?: Observable<number>;
    amountWithTax$?: Observable<number>;
    amount?: number;
    information$: Observable<string>;
}
type dragButtonType = 'group' | 'text' | 'arl' | 'resource-arl' | 'material-arl';
export function determineIfIsArlTableDataOrGroupEnd<T = AccountsReceivableLedgerEntity>(toBeDetermined: arlTableData<T> | groupEndPlaceholder): toBeDetermined is arlTableData<T> {
    return !!(toBeDetermined as arlTableData<T>).entity;
}
export type ArlTableComponentDisplayedColumns = 'Position' | 'BookingText' | 'Information' | 'Quantity' | 'QuantityType' | 'BaseCost' | 'CompleteCost'
@Component({
    selector: 'app-arl-table',
    standalone: true,
    imports: [
        CommonModule,
        DragDropModule,
        MatTableModule,
        FontAwesomeModule,
        MatCheckboxModule,
        MatButtonModule,
        AppButtonModule,
        MatTooltipModule,
        LengthPipe,
        ArlTableDetailRowComponent,
        MatInputModule,
        MatSelectModule,
        ReactiveFormsModule,
        NumberTransformInputComponent,
        ArlInformationInputComponent,
        DaveDoubleIconModule,
        MatSidenavModule,
        TableConfigFormComponent,
        MatMenuModule,
    ],
    templateUrl: './arl-table.component.html',
    styleUrls: ['./arl-table.component.scss'],
    // animations: [trigger('detailExpand', [state('collapsed', style({ height: '0px', minHeight: '0' })), state('expanded', style({ height: '*' })), transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))])],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArlTableComponent implements DoCheck, OnInit, OnDestroy, AfterViewInit {
    @ViewChild('bookingPositionTable') bookingPositionTable: MatTable<any>;
    @ViewChildren(ArlTableDetailRowComponent) detailRows: QueryList<ArlTableDetailRowComponent>;
    expandedElement$ = new BehaviorSubject<number>(null);
    private showBruttoColumn$ = new BehaviorSubject(false);
    // @Output() selectedArlIdsChange = new EventEmitter<number[]>();
    @Input() AddARLDefaultValues: DaveMutationCreateAccountsReceivableLedgerArgs;
    @Input() FilterMaterialsByCustomerId: number | null = null;
    @Input() isOrder = false;
    @Input() UseAmountFromMaterialToSupplier: boolean = false;
    @Input() set showBruttoColumn(v: boolean) {
        if (!!v !== this.showBruttoColumn$.value) {
            this.showBruttoColumn$.next(!!v);
        }
    }
    @Output() AddBookingPosition = new EventEmitter<number>();
    @Output() FormValueChange = new EventEmitter<void>();

    private formValueChangeSubscription: Subscription;
    // @Output() selectedGroupIdsChange = new EventEmitter<number[]>();
    _arlIds: number[] = null;
    _arlIds$ = new BehaviorSubject<number[]>([]);
    onDestroy$ = new Subject<void>();
    tableData2$ = this._arlIds$.pipe(
        takeUntil(this.onDestroy$),
        // distinctUntilChanged((a, b) => {
        //     console.log('vs', a, b);
        //     return JSON.stringify(a) === JSON.stringify(b);
        // }),
        tap((a) => console.log('JSON.stringify _arlIds$', JSON.stringify(a), 'JSON.stringify(this._arlIds)', JSON.stringify(this._arlIds))),
        filter((arlIds) => JSON.stringify(arlIds) !== JSON.stringify(this._arlIds)),
        tap((arlIds) => {
            console.log(' set this._arlIds to ', arlIds.slice());
            this._arlIds = arlIds.slice();
        }),
        switchMap((arlIds) =>
            arlIds?.length
                ? combineLatest(arlIds.map((id) => this.store.select(getAccountsReceivableLedgerById({ id })))).pipe(
                      // this.store.select(getAccountsReceivableLedgerDictionary).pipe(
                      // map(arls => arls.filter(isNotNullOrUndefined).reduce((p, c) => p[c.Id] = c, {})),
                      first(), // reparieren in dem die daten in eine map arlId => arlTable data in eine map in einem service ausgelagert werden, der auch bei Änderungen von außerhalb den kram aktualisiert. hier werden dann nur children gesucht und in richtige reihenfolge gebracht, dann mit filter auf aktuelle Reihenfolge die daten aus dem service dran gebaut
                      withLatestFrom(this.store.select(getAccountsReceivableLedgerDictionary)), // todo fix das der store hier für alle triggert, da muss eigentlich alle arls by id abgerufen werden
                      map(([arlArray, allArls]) => {
                          console.group('create tableData2$');
                          const getArlChildrenTableData: (id: number) => (arlTableData<AccountsReceivableLedgerEntity> | groupEndPlaceholder)[] = (id: number) => {
                              const arl = allArls[id];
                              if (!arl) {
                                  console.error('arl not found, id; ' + id);
                                  return [];
                              }
                              console.log('getArlChildrenTableData', id, arl);
                              const childArlIds = this.arlFormDataService.getForm(id)?.value.ARLIds || uniqArray(arl.ArlIds);
                              if (arl.Type === AccountsReceivableLedgerTypeEnum.Group) {
                                  const ret = [
                                      this.getArlTableData(arl),
                                      ...childArlIds
                                          .map(
                                              (i) => getArlChildrenTableData(i),
                                              // _getArlChildren(i)
                                              //     .map((a) => this.getArlTableData(a))
                                              //     .flat(),
                                          )
                                          .flat(),
                                      this.getGroupEnd(arl),
                                  ];
                                  console.log('ret', ret, childArlIds);
                                  return ret;
                              }
                              return [this.getArlTableData(arl)];
                          };
                          // this.arlFormDataService.clearAllForms(); todo rausfinden ob das so muss macht das schwierig weil dies ding getriggert wird wenn man eine neue arl (extern) hinzufügt
                          console.log(JSON.stringify(arlIds) /*arlArray*/);
                          console.log('##########');
                          const arlswithchildren = arlIds.map((id) => getArlChildrenTableData(id)).flat();
                          console.log({ arlIds, arlIdsParsed: JSON.stringify(arlIds), arlswithchildren });
                          if (arlswithchildren.some((arl, i) => determineIfIsArlTableDataOrGroupEnd(arl) && i !== arlswithchildren.findIndex((a) => determineIfIsArlTableDataOrGroupEnd(a) && a.entity.Id === arl.entity.Id))) {
                              console.log({ arlswithchildren });
                              throw 'arls doppelt zugewiesen';
                          }
                          console.groupEnd();
                          return arlswithchildren;
                      }),
                      // distinctUntilChanged(
                      //     (a, b) =>
                      //         a.length === b.length &&
                      //         a.every((e) => (determineIfIsArlTableDataOrGroupEnd(e) ? b.some((v) => determineIfIsArlTableDataOrGroupEnd(v) && v.entity.Id === e.entity.Id && v.entity.UpdatedAt === e.entity.UpdatedAt) : true)),
                      // ),
                  )
                : of([] as Array<arlTableData>),
        ),
        tap((a) => console.log('---tabledarta2$', a)),
        shareReplay(1),
    );
    protected tableData$ = this.tableData2$.pipe(
        switchMap((data) =>
            this.arlIdsChange.pipe(
                map(() => data),
                startWith(data),
            ),
        ),
        tap((data) => {
            console.log('tableData$', data);
            this.bookingPositionTable?.renderRows();
        }),
    );

    @Input() set arlIds(v: number[]) {
        console.log('set arlIds *****');
        console.log(v.slice(), this._arlIds?.slice());
        this._arlIds$.next(v.slice());
        // firstValueFrom(this.tableData2$).then(tableData => {
        //     if (tableData.some()) {
        //
        //     }
        //     this._arlIds$.next(v);
        // });
    }
    get arlIds() {
        return this._arlIds$.value;
    }
    @Output() arlIdsChange = new EventEmitter<any>();
    @Output() DeleteClick = new EventEmitter<any>();
    protected _disabled = false;
    @Input() set disabled(v: boolean) {
        this._disabled = v;
        console.warn('*** set disavled', v);
        firstValueFrom(this.tableData2$).then((td) => {
            if (this._disabled) {
                td.filter(determineIfIsArlTableDataOrGroupEnd).forEach((d) => this.disableForm(d.form));
            } else {
                td.filter(determineIfIsArlTableDataOrGroupEnd).forEach((d) => this.enableForm(d.form));
            }
        });
    }
    get disabled() {
        return this._disabled;
    }
    // @Input() NewARLDefaultValues: DaveMutationCreateAccountsReceivableLedgerArgs;

    ngDoCheck$ = new Subject<void>();
    private someDetailRowDirty$: Observable<boolean>;
    public SomeFormDirty$: Observable<boolean>;
    public AllFormValueChanges$ = this.tableData$.pipe(switchMap((data) => merge(...data.filter(determineIfIsArlTableDataOrGroupEnd).map((d) => d.form.valueChanges))));

    public AutoColumns: Array<ArlTableComponentDisplayedColumns> = ['BookingText', /*'Information',*/ 'Quantity', 'BaseCost' /*'CompleteCost',*/ /*'Position'*/];

    public AutoColumnHeaders = {
        BookingText: 'Art-Nr.',
        Information: 'Bezeichnung',
        BaseCost: 'Preis',
        Quantity: 'Menge',
        QuantityType: 'Einheit',
        CompleteCost: 'Summe',
        Position: 'Pos.',
    };
    protected columnConfig: TableColumnConfig<arlTableData, ArlTableComponentDisplayedColumns>[] = [
        { name: 'Position', header: this.AutoColumnHeaders.Position },
        { name: 'BookingText', header: this.AutoColumnHeaders.BookingText },
        { name: 'Information', header: this.AutoColumnHeaders.Information },
        { name: 'Quantity', header: this.AutoColumnHeaders.Quantity },
        { name: 'QuantityType', header: this.AutoColumnHeaders.QuantityType },
        { name: 'BaseCost', header: this.AutoColumnHeaders.BaseCost },
        { name: 'CompleteCost', header: this.AutoColumnHeaders.CompleteCost },
    ];

    public AutoColumnWithClass = {
        DragButton: 'small-fix',
        Position: 'small',
        BookingText: '',
        Information: 'wide',
        Quantity: 'small',
        QuantityType: '',
        BaseCost: '',
        CompleteCost: 'small',
        CompleteCostWithoutTax: 'small',
        IsVisible: 'small-fix',
        InheritFromChildren: 'small-fix',
        EditButton: 'medium-fix',
    };
    protected displayedColumns$ = new BehaviorSubject<Array<ArlTableComponentDisplayedColumns>>(['Position', 'BookingText', 'Information', 'Quantity', 'QuantityType', 'BaseCost', 'CompleteCost']);
    @Input() set displayedColumns(value: Array<ArlTableComponentDisplayedColumns>) {
        if (Array.isArray(value) && !compareArrays(this.displayedColumns$.value, value)) {
            this.displayedColumns$.next(value.slice());
        }
    }
    get displayedColumns() {
        return this.displayedColumns$.value;
    }
    @Output() displayedColumnsChange = new EventEmitter<Array<ArlTableComponentDisplayedColumns>>();

    DisplayedColumns$ = combineLatest([this.displayedColumns$, this.bs.MobileQuery, this.showBruttoColumn$]).pipe(
        map(([cols, mobile, showBruttoColumn]) => {
            if (!mobile) {
                return ['DragButton', ...cols, showBruttoColumn ? 'CompleteCostWithoutTax' : null, 'IsVisible', 'InheritFromChildren', 'EditButton'].filter(isNotNullOrUndefined);
            } else {
                return ['DragButton', 'Information', 'CompleteCost', 'EditButton'];
            }
        }),
    );
    // DisplayedColumns$ = combineLatest([this.bs.MobileQuery, this.showBruttoColumn$]).pipe(
    //     map(([mobile, showBruttoColumn]) => {
    //         if (!mobile) {
    //             return [
    //                 'DragButton',
    //                 'Position',
    //                 'BookingText',
    //                 'Information',
    //                 'Quantity',
    //                 'QuantityType',
    //                 'BaseCost',
    //                 'CompleteCost',
    //                 showBruttoColumn ? 'CompleteCostWithoutTax' : null,
    //                 'IsVisible',
    //                 'InheritFromChildren',
    //                 'EditButton',
    //             ].filter(isNotNullOrUndefined);
    //         } else {
    //             return ['DragButton', 'Information', 'CompleteCost', 'EditButton'];
    //         }
    //     }),
    // );
    addButtons: { type: dragButtonType; label: string }[] = [
        { type: 'group', label: 'Titel' },
        { type: 'text', label: 'Text' },
        { type: 'arl', label: 'Leistung' },
        { type: 'resource-arl', label: ResourceEntity.EntityName },
        { type: 'material-arl', label: MaterialEntity.EntityName },
    ];
    positionStrings$ = this.tableData$.pipe(
        map((tableData) => {
            const positions = calcPositionStrings(
                tableData,
                determineIfIsArlTableDataOrGroupEnd,
                (v) => !determineIfIsArlTableDataOrGroupEnd(v),
                (v) => determineIfIsArlTableDataOrGroupEnd(v) && v.entity.Type === AccountsReceivableLedgerTypeEnum.Group,
            );
            const positionIdMap = new Map<number, string>();
            tableData.forEach((v, i) => {
                if (determineIfIsArlTableDataOrGroupEnd(v)) {
                    positionIdMap.set(v.entity.Id, positions[i]);
                }
            });
            console.log({ positions, positionIdMap, tableData });
            return positionIdMap;
        }),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    determineIfIsArlTableDataOrGroupEnd = determineIfIsArlTableDataOrGroupEnd;
    protected isInvalidDragEvent = true;
    constructor(
        protected bs: BreakpointObserverService,
        public PS: PermissionService,
        @Inject(LOCALE_ID) public locale: string,
        private store: Store<State>,
        protected arlCalculationDataService: ArlCalculationDataService,
        protected ls: LoadingService,
        private actions$: Actions,
        private arlDataService: ArlDataService,
        private arlFormDataService: ArlFormDataService,
        private materialToSupplierDataService: MaterialToSupplierDataService,
        private dialog: MatDialog,
    ) {}

    ngOnDestroy(): void {
        this.formValueChangeSubscription.unsubscribe();
        this.onDestroy$.next();
    }

    ngOnInit(): void {
        this.formValueChangeSubscription = this.AllFormValueChanges$.subscribe((v) => {
            console.log('ValueChanged');
            this.FormValueChange.emit();
        });
    }
    ngAfterViewInit() {
        this.SomeFormDirty$ = of(false);
        this.someDetailRowDirty$ = this.bookingPositionTable.contentChanged.pipe(
            startWith(null),
            switchMap(() => (this.detailRows.length ? combineLatest(this.detailRows.map((r) => r.someFormDirty$)) : of([]))),
            map((v) => v.some((d) => !!d)),
            tap((dirty) => console.log('someDetailRowDirty$', dirty)),
        );
        this.SomeFormDirty$ = combineLatest([this.tableData2$, this.bookingPositionTable.contentChanged.pipe(startWith(null))]).pipe(
            switchMap(([data]) =>
                data.length
                    ? combineLatest([
                          this.someDetailRowDirty$,
                          this.ngDoCheck$.pipe(
                              startWith(null),
                              map(() => {
                                  const ret = data.some((d) => determineIfIsArlTableDataOrGroupEnd(d) && d.form.dirty);
                                  return ret;
                              }),
                          ),
                      ]).pipe(map((f) => f.some((v) => !!v)))
                    : of(false),
            ),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }
    submitColumnChange(cols: Array<ArlTableComponentDisplayedColumns>) {
        if (!compareArrays(this.displayedColumns, cols)) {
            this.displayedColumnsChange.emit(cols);
        }
    }
    getArlCalculationForms(): Promise<Map<number, Array<ɵFormGroupValue<calculationArlForm> & { getBaseCost: () => number }>>> {
        return firstValueFrom(this.detailRows.length ? combineLatest(this.detailRows.map((d) => d.arlCalculationViewComponent.getChildFormValues())) : of([])).then((forms) => {
            const map = new Map<number, Array<ɵFormGroupValue<calculationArlForm> & { getBaseCost: () => number }>>([]);
            this.detailRows.forEach((d, i) => {
                if (forms[i]) {
                    map.set(d.arl.Id, forms[i]);
                }
            });
            return map;
        });
    }
    private getGroupEnd = (e: AccountsReceivableLedgerEntity): groupEndPlaceholder => {
        const form = this.arlFormDataService.getForm(e.Id)?.controls.Information;
        if (!form) {
            throw 'form not found ' + e.Id;
        }
        return {
            groupId: e.Id,
            amount$: this.tableData$.pipe(
                switchMap((tableData) => {
                    const sum: Observable<number>[] = [];
                    let a = false;
                    tableData.forEach((td) => {
                        if (determineIfIsArlTableDataOrGroupEnd(td) && td.entity.Id === e.Id) {
                            a = true;
                        } else if (!determineIfIsArlTableDataOrGroupEnd(td) && td.groupId === e.Id) {
                            a = false;
                        }
                        if (a && determineIfIsArlTableDataOrGroupEnd(td) && td.entity.Type !== AccountsReceivableLedgerTypeEnum.Group) {
                            sum.push(td.amount$.pipe(switchMap(amount => combineLatest([
                                td.form.controls.IsOptional.valueChanges.pipe(startWith(null), map(() => td.form.controls.IsOptional.getRawValue())),
                                td.form.controls.CrossedOut.valueChanges.pipe(startWith(null), map(() => td.form.controls.CrossedOut.getRawValue())),
                            ]).pipe(map(([optional, crossed]) => optional || crossed ? 0 : amount)))));
                        }
                    });
                    return sum.length ? combineLatest(sum).pipe(map((amounts) => amounts.reduce((p, c) => p + (c || 0), 0))) : of(0);
                }),
            ),
            amountWithTax$: this.tableData$.pipe(
                switchMap((tableData) => {
                    const sum: Observable<number>[] = [];
                    let a = false;
                    tableData.forEach((td) => {
                        if (determineIfIsArlTableDataOrGroupEnd(td) && td.entity.Id === e.Id) {
                            a = true;
                        } else if (!determineIfIsArlTableDataOrGroupEnd(td) && td.groupId === e.Id) {
                            a = false;
                        }
                        if (a && determineIfIsArlTableDataOrGroupEnd(td) && td.entity.Type !== AccountsReceivableLedgerTypeEnum.Group) {
                            sum.push(td.amountWithTax$.pipe(switchMap(amount => combineLatest([
                                td.form.controls.IsOptional.valueChanges.pipe(startWith(null), map(() => td.form.controls.IsOptional.getRawValue())),
                                td.form.controls.CrossedOut.valueChanges.pipe(startWith(null), map(() => td.form.controls.CrossedOut.getRawValue())),
                            ]).pipe(map(([optional, crossed]) => optional || crossed ? 0 : amount)))));
                        }
                    });
                    return sum.length ? combineLatest(sum).pipe(map((amounts) => amounts.reduce((p, c) => p + (c || 0), 0))) : of(0);
                }),
            ),
            amount: undefined,
            information$: combineLatest([
                this.positionStrings$.pipe(map((positions) => positions.get(e.Id))),
                form.valueChanges.pipe(
                    startWith(null),
                    map(() => form.value),
                ),
            ]).pipe(map(([position, information]) => position + ': ' + information)),
        };
    };
    private enableForm(form: arlTableDataArlForm): void {
        if (form.disabled) {
            form.enable();
        }
        if (form.controls.ARLIds.value.length === 0) {
            form.controls.childrenVisible.disable();
            form.controls.childrenVisible.markAsPristine();
        }
    }
    private disableForm(form: arlTableDataArlForm): void {
        if (form.enabled) {
            const pristine = form.pristine;
            form.disable();
            if (pristine) {
                form.markAsPristine();
            }
        }
    }
    private getArlTableData = (e: AccountsReceivableLedgerEntity): arlTableData<AccountsReceivableLedgerEntity> => {
        if (!this.arlFormDataService.getForm(e.Id)) {
            this.arlFormDataService.setFormByEntity(e);
        }
        const form: arlTableDataArlForm = this.arlFormDataService.getForm(e.Id);
        if (form.controls.ARLIds.value.length === 0) {
            form.controls.childrenVisible.disable();
            form.controls.childrenVisible.markAsPristine();
        }
        if (this.disabled) {
            this.disableForm(form);
        }
        console.log('ARLIds', form.value.ARLIds);
        const calculatedAmount = new FormControl<number>(null);

        return {
            position: '',
            entity: e,
            isExpanded$: this.expandedElement$.pipe(map((exp) => exp === e.Id)),
            animationTrigger$: this.expandedElement$.pipe(map((exp) => (exp === e.Id ? 'expanded' : 'collapsed'))),
            calculatedAmount, /// deprecated, use basecost
            form,
            position$: this.positionStrings$.pipe(map((positions) => positions.get(e.Id))),
            resource$: form.controls.ResourceId.valueChanges.pipe(
                startWith(null),
                switchMap(() => (form.controls.ResourceId.value ? this.store.select(getResourceById({ id: form.controls.ResourceId.value })) : of(null))),
            ),
            material$: form.controls.MaterialId.valueChanges.pipe(
                startWith(null),
                switchMap(() => (form.controls.MaterialId.value ? this.store.select(getMaterialById({ id: form.controls.MaterialId.value })) : of(null))),
            ),
            amount$: combineLatest([
                form.controls.InheritFromChildren.valueChanges.pipe(startWith(null)),
                form.controls.Quantity.valueChanges.pipe(startWith(null)),
                form.controls.BaseCost.valueChanges.pipe(startWith(null)),
                // calculatedAmount.valueChanges.pipe(startWith(null)),
            ]).pipe(
                map(() => {
                    return NettoCost(form.controls.Quantity.value, /*form.controls.InheritFromChildren.value ? calculatedAmount.value : */ form.controls.BaseCost.value);
                }),
                tap((a) => console.log('amount$', a, e)),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
            amountWithTax$: combineLatest([
                form.controls.InheritFromChildren.valueChanges.pipe(startWith(null)),
                form.controls.Quantity.valueChanges.pipe(startWith(null)),
                form.controls.BaseCost.valueChanges.pipe(startWith(null)),
                form.controls.Tax.valueChanges.pipe(startWith(null)),
                // calculatedAmount.valueChanges.pipe(startWith(null)),
            ]).pipe(
                map(() => {
                    return CalcAccountsReceivableLedgerAmount(form.controls.Quantity.value, form.controls.Tax.value, form.controls.BaseCost.value);
                }),
                tap((a) => console.log('amountWithTax$', a, e)),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        };
    };
    private setArlIds = (array: (arlTableData<AccountsReceivableLedgerEntity> | groupEndPlaceholder)[]) => {
        console.log('setArlIds');
        this._arlIds = array
            .filter((v) => determineIfIsArlTableDataOrGroupEnd(v) && !v.form.value.ParentId)
            .filter(determineIfIsArlTableDataOrGroupEnd)
            .map((c) => c.entity.Id);
        this.arlIdsChange.emit(this._arlIds.slice());
        console.log('setArlIds', this._arlIds.slice());
    };
    private updateParentIds = (array: (arlTableData<AccountsReceivableLedgerEntity> | groupEndPlaceholder)[]) => {
        let currentParent: number[] = [];
        let indexFromParent: number[] = [];
        array.forEach((d) => {
            if (determineIfIsArlTableDataOrGroupEnd(d)) {
                if (d.form.value.ParentId !== (currentParent.length ? currentParent[currentParent.length - 1] : null)) {
                    d.form.controls.ParentId.setValue(currentParent.length ? currentParent[currentParent.length - 1] : null);
                    d.form.controls.ParentId.markAsDirty();
                }
                const parent: arlTableData = d.form.value.ParentId && (array.find((a) => determineIfIsArlTableDataOrGroupEnd(a) && d.form.value.ParentId === a.entity.Id) as arlTableData);
                console.log('parent', parent);
                if (parent && indexFromParent.length && indexFromParent[indexFromParent.length - 1] !== parent.form.value.ARLIds.indexOf(d.entity.Id)) {
                    const missing = (parent.form.value.ARLIds.length - 1 - indexFromParent[indexFromParent.length - 1]) * -1;
                    const old = parent.form.value.ARLIds.slice();
                    console.log(old, missing, parent.form.value.ARLIds.length, indexFromParent[indexFromParent.length - 1]);
                    if (missing > 0) {
                        for (let k = missing; k > 0; k--) {
                            old.push(null);
                        }
                    }
                    parent.form.controls.ARLIds.setValue(old.map((id, i) => (i === indexFromParent[indexFromParent.length - 1] ? d.entity.Id : id)));
                    parent.form.controls.ARLIds.markAsDirty();
                    console.log('parent.arlIds', parent.form.controls.ARLIds.value);
                }
                if (indexFromParent.length) {
                    indexFromParent[indexFromParent.length - 1]++;
                }
                if (d.entity.Type === AccountsReceivableLedgerTypeEnum.Group) {
                    currentParent.push(d.entity.Id);
                    indexFromParent.push(0);
                }
            } else {
                const groupStart: arlTableData = array.find((e) => determineIfIsArlTableDataOrGroupEnd(e) && e.entity.Id === d.groupId) as arlTableData;

                const toMuchChilds = (indexFromParent[indexFromParent.length - 1] - groupStart.form.value.ARLIds.length) * -1;
                console.log(toMuchChilds);
                if (toMuchChilds > 0) {
                    groupStart.form.controls.ARLIds.setValue(groupStart.form.value.ARLIds.slice(0, indexFromParent[indexFromParent.length - 1]));
                    groupStart.form.controls.ARLIds.markAsDirty();
                }
                currentParent.pop();
                indexFromParent.pop();
            }
        });
        console.log('update parents');
        console.log(array.filter(determineIfIsArlTableDataOrGroupEnd).map((a) => JSON.parse(JSON.stringify(a.form.value))));
    };

    removeArlsFromTable(ids: number[]) {
        return firstValueFrom(this.tableData2$).then((array) => {
            const indexes: number[] = [];
            array.forEach((e, i) => {
                if (determineIfIsArlTableDataOrGroupEnd(e)) {
                    if (ids.includes(e.entity.Id)) {
                        indexes.push(i);
                    }
                } else if (ids.includes(e.groupId)) {
                    indexes.push(i);
                }
            });
            indexes.forEach((index, i) => array.splice(index - i, 1));
            this.updateParentIds(array);
            this.setArlIds(array);
        });
    }
    private addArlToArray(array: (arlTableData<AccountsReceivableLedgerEntity> | groupEndPlaceholder)[], arl: AccountsReceivableLedgerEntity, index?: number) {
        array.push(this.getArlTableData(arl));
        if (isNotNullOrUndefined(index)) {
            moveItemInArray(array, array.length - 1, index);
        }
        this.updateParentIds(array);
        this.setArlIds(array);
    }
    public addArlsToTable(arls: AccountsReceivableLedgerEntity[]) {
        return firstValueFrom(this.tableData2$).then((array) => {
            arls.forEach((arl) => {
                this.addArlToArray(array, arl);
            });
        });
    }
    OnListDrop(event?: CdkDragDrop<arlTableData | groupEndPlaceholder | dragButtonType>) {
        console.group('OnListDrop');
        console.log(event);
        const offset = 0; // drag button
        this.isInvalidDragEvent = true;
        firstValueFrom(this.tableData2$).then((array) => {
            if (event.previousContainer === event.container) {
                if (event.currentIndex === event.previousIndex) {
                    return;
                }
                // event.item.data : TableData<T, K>
                console.log(event.item.data);
                // check is group
                if (!determineIfIsArlTableDataOrGroupEnd(event.item.data) || (event.item.data as arlTableData).entity?.Type === AccountsReceivableLedgerTypeEnum.Group) {
                    // check is group start before end
                    let otherIndex = array.findIndex(
                        (v) =>
                            (determineIfIsArlTableDataOrGroupEnd(v) && !determineIfIsArlTableDataOrGroupEnd(event.item.data) && event.item.data.groupId === v.entity?.Id) ||
                            (!determineIfIsArlTableDataOrGroupEnd(v) && determineIfIsArlTableDataOrGroupEnd(event.item.data) && v.groupId === event.item.data.entity?.Id),
                    );
                    const goesDown = event.currentIndex > event.previousIndex;
                    console.log(otherIndex, event.currentIndex, determineIfIsArlTableDataOrGroupEnd(event.item.data));
                    if (!determineIfIsArlTableDataOrGroupEnd(event.item.data) && !goesDown && otherIndex >= event.currentIndex) {
                        return;
                    } else if (determineIfIsArlTableDataOrGroupEnd(event.item.data) && goesDown && otherIndex <= event.currentIndex) {
                        return;
                    }

                    //count groups between
                    const testArray = array.slice();
                    console.log(testArray);
                    console.log(JSON.stringify(testArray.map((a) => ({ type: determineIfIsArlTableDataOrGroupEnd(a) ? 'arl' : 'group-end', id: determineIfIsArlTableDataOrGroupEnd(a) ? a.entity.Id : a.groupId }))));
                    moveItemInArray(testArray, event.previousIndex - offset, event.currentIndex - offset);
                    console.log(testArray);
                    console.log(JSON.stringify(testArray.map((a) => ({ type: determineIfIsArlTableDataOrGroupEnd(a) ? 'arl' : 'group-end', id: determineIfIsArlTableDataOrGroupEnd(a) ? a.entity.Id : a.groupId }))));

                    const otherIndexAfterMove = testArray.findIndex(
                        (v) =>
                            (determineIfIsArlTableDataOrGroupEnd(v) && !determineIfIsArlTableDataOrGroupEnd(event.item.data) && event.item.data.groupId === v.entity?.Id) ||
                            (!determineIfIsArlTableDataOrGroupEnd(v) && determineIfIsArlTableDataOrGroupEnd(event.item.data) && v.groupId === event.item.data.entity?.Id),
                    );

                    const startIndex = otherIndexAfterMove < event.currentIndex ? otherIndexAfterMove : event.currentIndex;
                    const endIndex = otherIndexAfterMove > event.currentIndex ? otherIndexAfterMove : event.currentIndex;
                    const subArr = testArray.slice(startIndex + 1, endIndex);
                    const groupCount = subArr.filter((v, index) => determineIfIsArlTableDataOrGroupEnd(v) && v.entity.Type === AccountsReceivableLedgerTypeEnum.Group).length;
                    const groupEndCount = subArr.filter((v, index) => !determineIfIsArlTableDataOrGroupEnd(v)).length;
                    console.log({
                        subArr,
                        startIndex,
                        endIndex,
                        groupCount,
                        groupEndCount,
                        otherIndexAfterMove,
                    });
                    if (groupCount !== groupEndCount) {
                        return;
                    }
                }
                moveItemInArray(array, event.previousIndex - offset, event.currentIndex - offset);
                this.updateParentIds(/*startIndex, endIndex,*/ array);
                this.setArlIds(array);
                // this.arlIdsChange.emit(array.filter(determineIfIsArlTableDataOrGroupEnd).map((c) => c.entity.Id));
            } else {
                if (event.item.data === 'arl') {
                    this.ls.startLoading('arl-table-component-create-arls');
                    this.arlDataService
                        .addArls([{ ...this.AddARLDefaultValues, information: 'Neue Leistung', type: AccountsReceivableLedgerTypeEnum.Arl as any }])
                        .then((arls) => {
                            this.addArlToArray(array, arls[0], event.currentIndex);
                            // array.push(this.getArlTableData(arls[0]));
                            // moveItemInArray(array, array.length - 1, event.currentIndex);
                            // this.updateParentIds(array);
                            // this.setArlIds(array);
                            // this.arlIdsChange.emit(array.filter(determineIfIsArlTableDataOrGroupEnd).map((c) => c.entity.Id));
                        })
                        .finally(() => this.ls.endLoading('arl-table-component-create-arls'));
                } else if (event.item.data === 'resource-arl') {
                    this.ls.startLoading('arl-table-component-create-resource-arl');
                    this.arlDataService
                        .addArls([{ ...this.AddARLDefaultValues, information: 'Neues ' + ResourceEntity.EntityName, type: AccountsReceivableLedgerTypeEnum.ResourceArl }])
                        .then((arls) => {
                            this.addArlToArray(array, arls[0], event.currentIndex);
                        })
                        .finally(() => this.ls.endLoading('arl-table-component-create-resource-arl'));
                } else if (event.item.data === 'material-arl') {
                    this.ls.startLoading('arl-table-component-create-material-arl');
                    this.arlDataService
                        .addArls([{ ...this.AddARLDefaultValues, information: 'Neues ' + MaterialEntity.EntityName, type: AccountsReceivableLedgerTypeEnum.MaterialArl }])
                        .then((arls) => {
                            this.addArlToArray(array, arls[0], event.currentIndex);
                        })
                        .finally(() => this.ls.endLoading('arl-table-component-create-material-arl'));
                } else if (event.item.data === 'group') {
                    this.ls.startLoading('arl-table-component-create-group-arls');
                    this.arlDataService
                        .addArls([{ ...this.AddARLDefaultValues, information: 'Neuer Titel', type: AccountsReceivableLedgerTypeEnum.Group }])
                        .then((arls) => {
                            array.push(this.getArlTableData(arls[0]));
                            array.push(this.getGroupEnd(arls[0]));
                            moveItemInArray(array, array.length - 2, event.currentIndex);
                            moveItemInArray(array, array.length - 1, event.currentIndex + 1);
                            this.updateParentIds(array);
                            this.setArlIds(array);
                            // this.arlIdsChange.emit(array.filter(determineIfIsArlTableDataOrGroupEnd).map((c) => c.entity.Id));
                            console.log(array);
                        })
                        .finally(() => this.ls.endLoading('arl-table-component-create-group-arls'));
                } else if (event.item.data === 'text') {
                    this.ls.startLoading('arl-table-component-create-text-arls');
                    this.arlDataService
                        .addArls([{ ...this.AddARLDefaultValues, information: 'Neuer Text', type: AccountsReceivableLedgerTypeEnum.Text }])
                        .then((arls) => {
                            array.push(this.getArlTableData(arls[0]));
                            moveItemInArray(array, array.length - 1, event.currentIndex);
                            this.updateParentIds(array);
                            this.setArlIds(array);
                            // this.arlIdsChange.emit(array.filter(determineIfIsArlTableDataOrGroupEnd).map((c) => c.entity.Id));
                        })
                        .finally(() => this.ls.endLoading('arl-table-component-create-text-arls'));
                }
            }
            // recalculate positions
            // recalculate groupEndSums

            // console.log({ array });
            // console.log({ newOrder: array.filter(determineIfIsArlTableDataOrGroupEnd).map(c => c.entity.Id) });
            // // this.tableData2$.next(array);
            // // this.arlsChange.emit(this.tableData$.value.map((a) => (determineIfIsArlTableDataOrGroupTableData(a) ? a.entity : a.group)));
            // this.arlIdsChange.emit(array.filter(determineIfIsArlTableDataOrGroupEnd).map(c => c.entity.Id));
        });
        console.groupEnd();
    }

    getQuantity = ({ entity, form }: arlTableData<any>): string => {
        return isBookingPositionTypeEnum(form.getRawValue().Type) && isNotNullOrUndefined(form.getRawValue().Quantity) ? formatNumber(form.getRawValue().Quantity, this.locale) : '';
    };
    getQuantityType$ = this.store.select(getQuantityTypes).pipe(
        map(
            (qt) =>
                ({ entity, form }: arlTableData<any>) =>
                    isNotNullOrUndefined(form.getRawValue().QuantityTypeId) ? qt.find((t) => t.Id === form.getRawValue().QuantityTypeId)?.Name || '' : '',
        ),
    );

    getBaseCost = ({ entity, form }: arlTableData<any>): string => {
        return isBookingPositionTypeEnum(form.getRawValue().Type) && isNotNullOrUndefined(form.getRawValue().BaseCost)
            ? formatCurrency(form.getRawValue().BaseCost, this.locale, getCurrencySymbol(entity.CurrencyCode, 'narrow', this.locale), entity.CurrencyCode)
            : '';
    };
    dataAccessor(data: arlTableData<AccountsReceivableLedgerEntity> | groupEndPlaceholder, name: string): string {
        if (determineIfIsArlTableDataOrGroupEnd(data)) {
            switch (name) {
                case 'Quantity':
                    return this.getQuantity(data);
                case 'BaseCost':
                    return this.getBaseCost(data);
                case 'BookingText':
                    return data.form.getRawValue().BookingText;
            }
        }
        return '';
    }
    Submit(): Promise<{ someWasChanged: boolean; arls: AccountsReceivableLedgerEntity[] }> {
        console.log(this.detailRows, this.detailRows.length);
        return firstValueFrom(this.detailRows.length ? combineLatest(this.detailRows.map((detailRow) => detailRow.Submit())) : of([])).then(() => {
            return firstValueFrom(combineLatest([this.tableData$, this.store.select(getAccountsReceivableLedgerDictionary)])).then(([tableData, arlsFromState]) => {
                if (tableData.length === 0) {
                    return { someWasChanged: false, arls: [] };
                }
                const toChange: DaveMutationChangeAccountsReceivableLedgerArgs[] = [];
                const toReturn: AccountsReceivableLedgerEntity[] = [];
                tableData.forEach((td) => {
                    if (determineIfIsArlTableDataOrGroupEnd(td)) {
                        if (td.form.dirty) {
                            console.log(td.entity.Id, JSON.stringify(td.form.value));
                            toChange.push(this.getChangeMutationPayload(td));
                        } else {
                            if (!arlsFromState[td.entity.Id]) {
                                console.error('arlNotFound', td.entity.Id);
                            }
                            toReturn.push(arlsFromState[td.entity.Id]);
                        }
                    }
                });
                const markAllPristine = () => {
                    tableData.forEach((td) => {
                        if (determineIfIsArlTableDataOrGroupEnd(td)) {
                            if (td.form.dirty) {
                                td.form.markAsPristine(); // (dirty) fix bug, beim speichern klicken werden die groups dirty, die controls darin aber nicht
                            }
                        }
                    });
                };
                console.log({ toChange, toReturn });
                return toChange.length
                    ? this.arlDataService.changeArls(toChange).then((changed) => {
                          markAllPristine();
                          return { someWasChanged: true, arls: [...changed, ...toReturn] };
                      })
                    : { someWasChanged: false, arls: toReturn };
            });
        });
    }
    getChangeMutationPayload(element: arlTableData): DaveMutationChangeAccountsReceivableLedgerArgs {
        const additionalData: ARLCustomProperties = { ...element.entity.CustomProperties, ZuschlaegeDefault: element.form.value.ZuschlaegeDefault, ZuschlaegeResourcen: element.form.value.ZuschlaegeResourcen };
        return {
            id: element.entity.Id,
            // amount: <- rechnet das Backend aus
            information: element.form.value.Information,
            bookingText: element.form.value.BookingText,
            baseCost: /*element.form.value.InheritFromChildren ? element.calculatedAmount.value : */ element.form.value.BaseCost || 0,
            quantity: element.form.value.Quantity || 0,
            quantityTypeId: element.form.value.QuantityTypeId,
            isVisible: true, //!element.form.value.InheritFromChildren || element.form.value.IsVisible,
            inheritFromChildren: element.form.value.InheritFromChildren,
            arlIds: element.form.value.ARLIds,
            parentId: element.form.value.ParentId,
            customProperties: JSON.stringify(additionalData),
            // type: element.form.value.Type,
            // todo add type wenn be ready
            tax: element.form.value.Tax,

            multiplikator: element.entity.Multiplier,
            showLongInformation: element.form.value.ShowLongtext || false,
            longInformation: element.form.value.Longtext,
            resourceId: element.form.value.ResourceId,
            materialId: element.form.value.MaterialId,
            isOptional: element.form.value.IsOptional,
            isCrossedOut: element.form.value.CrossedOut,
        };
    }
    onSaveClick(element: arlTableData<any>) {
        if (element.form.valid) {
            this.store.dispatch(AccountsReceivableLedgerActionTypes.ChangeAccountsReceivableLedger({ Payload: this.getChangeMutationPayload(element) }));
            // if erfolgreich
            element.form.markAsPristine();
        }
    }
    // isExpanded(element: TableData<T>) {
    //     return this.expandedElement === element;
    // }
    expandRow(element: null | arlTableData) {
        if (!element) {
            this.expandedElement$.next(null);
        } else if (this.expandedElement$.value !== element.entity.Id) {
            this.expandedElement$.next(element.entity.Id);
        }
    }
    onAmountCalculated(element: arlTableData<any>, amount: number) {
        element.calculatedAmount.setValue(amount);
        if (element.form.controls.InheritFromChildren.value && amount !== element.form.controls.BaseCost.getRawValue()) {
            element.form.controls.BaseCost.setValue(amount);
            element.form.controls.BaseCost.markAsDirty();
        }
    }
    onAbortClick(element: arlTableData<any>) {
        element.form.reset();
        this.expandRow(null);
    }
    onDuplicateClick(element: arlTableData<any>) {
        alert('not implemented');
    }
    onDeleteClick(element: arlTableData<any>) {
        if (element.entity) {
            this.DeleteClick.emit(element.entity);
            this.expandedElement$.next(null);
        }
    }
    onAbortGroupClick(element: arlTableData) {
        this.onAbortClick(element);
        // element.form.reset({
        //     name: element.group.name,
        // });
    }

    protected readonly window = window;
    bodyElement: HTMLElement = document.body;
    onCdkDragStarted($event: CdkDragStart) {
        // this.bodyElement.classList.add('inheritCursors');
        this.bodyElement.style.cursor = 'grabbing';
    }

    onCdkDragEnded($event: CdkDragEnd) {
        // this.bodyElement.classList.remove('inheritCursors');
        this.bodyElement.style.cursor = 'unset';
    }

    ngDoCheck(): void {
        this.ngDoCheck$.next();
    }

    protected readonly ARLTypeEnum = AccountsReceivableLedgerTypeEnum;

    onSetEntityId(data: arlTableData, id: number) {
        const type = data.form.controls.Type.value;

        data.form.patchValue({
            ResourceId: type === AccountsReceivableLedgerTypeEnum.ResourceArl ? id : null,
            MaterialId: type === AccountsReceivableLedgerTypeEnum.MaterialArl ? id : null,
        });
        data.form.controls.ResourceId.markAsDirty();
        data.form.controls.MaterialId.markAsDirty();

        if (id) {
            switch (type) {
                case AccountsReceivableLedgerTypeEnum.ResourceArl:
                    firstValueFrom(data.resource$).then((resource) => {
                        if (resource?.Id === id) {
                            if (isNotNullOrUndefined(resource.MaxChargeRate)) {
                                data.form.controls.BaseCost.setValue(parseFloat((resource.MaxChargeRate / 100).toFixed(4)));
                            }
                            if (resource.QuantityTypeId) {
                                data.form.controls.QuantityTypeId.setValue(resource.QuantityTypeId);
                            }
                            if (resource.ArticleNumber) {
                                data.form.controls.BookingText.setValue(resource.ArticleNumber);
                            } else {
                                data.form.controls.BookingText.setValue('');
                            }
                        }
                    });
                    break;
                case AccountsReceivableLedgerTypeEnum.MaterialArl:
                    firstValueFrom(data.material$).then((material) => {
                        if (material?.Id === id) {
                            if (this.UseAmountFromMaterialToSupplier && this.FilterMaterialsByCustomerId) {
                                firstValueFrom(this.materialToSupplierDataService.getMaterialToSupplierBySupplierId$(this.FilterMaterialsByCustomerId)).then((m2s) => {
                                    const m = m2s.find((m) => m.MaterialId === id);
                                    data.form.controls.BaseCost.setValue(parseFloat(((m.Cost || 0) / 100).toFixed(4)));
                                });
                            } else {
                                const v = this.isOrder ? material.Cost : material.SellPrice;
                                if (isNotNullOrUndefined(v)) {
                                    data.form.controls.BaseCost.setValue(parseFloat((v / 100).toFixed(4)));
                                }
                            }
                            if (material.QuantityTypeId) {
                                data.form.controls.QuantityTypeId.setValue(material.QuantityTypeId);
                            }
                            if (material.ArticleNumber) {
                                data.form.controls.BookingText.setValue(material.ArticleNumber);
                            } else {
                                data.form.controls.BookingText.setValue('');
                            }
                        }
                    });
                    break;
            }
        }
    }

    protected readonly ResourcePageMeta = ResourcePageMeta;

    onPriceInformationClick(rowData: arlTableData) {
        if (rowData.form.value.MaterialId) {
            this.dialog.open<MaterialPriceDetailsPopupComponent, MaterialPriceDetailsPopupComponentDialogData, MaterialPriceDetailsPopupComponentDialogReturnData>(MaterialPriceDetailsPopupComponent, {
                ...MaterialPriceDetailsPopupComponent.DefaultConfig,
                data: {
                    materialId: rowData.form.value.MaterialId,
                    supplierId: this.FilterMaterialsByCustomerId,
                    ledgerImportDocumentType: this.isOrder ? LedgerImportDocumentTypes.Order : LedgerImportDocumentTypes.Offer,
                    showOtherSuppliersList: this.isOrder,
                },
            });
        }
    }

    /**
     *
     * @param tax in percent 19% => 19
     */
    async setAllTax(tax: number) {
        // firstValueFrom(this.tableData$).then((tableData) => {
        //     tableData.forEach(t => t.)
        //
        // })

        await firstValueFrom(this.store.select(getAccountsReceivableLedgerDictionary)).then((arls) => {
            this.arlIds
                .map((arlId) => this.arlFormDataService.getChildIds(arlId))
                .flat()
                .forEach((arlId) => {
                    let form = this.arlFormDataService.getForm(arlId);
                    if (!form) {
                        this.arlFormDataService.setFormByEntity(arls[arlId]);
                        form = this.arlFormDataService.getForm(arlId);
                    }
                    form.controls.Tax.setValue(tax);
                    form.controls.Tax.markAsDirty();
                });
        });
    }

    protected readonly BreakpointObserverService = BreakpointObserverService;

    dragStarted(event: CdkDragStart) {
        if (this.isInvalidDragEvent) {
            document.dispatchEvent(new Event('mouseup'));
        }
    }

    onToggleOptional(element: arlTableData<any>) {
        element.form.controls.IsOptional.setValue(!element.form.value.IsOptional);
        element.form.controls.IsOptional.markAsDirty()
    }
    onToggleCrossedOut(element: arlTableData<any>) {
        element.form.controls.CrossedOut.setValue(!element.form.value.CrossedOut);
        element.form.controls.CrossedOut.markAsDirty()
    }
}
