import { BusinessVolumeType } from '../graphql-types';
import { BackendDate } from '../helper/backend-frontend-conversion.helper';
export enum BusinessVolumeCostStateEnum {
    Costs = 'COSTS',
    Income = 'INCOME',
    Notset = 'NOTSET'
}

export enum BusinessVolumeStatusEnum {
    Notset = 'NotSet',
    Booked = 'BOOKED',
    Cancellation = 'CANCELLATION',
    Notbooked = 'NOTBOOKED',
    Open = 'OPEN',
    Payed = 'PAYED'
}
export const AvailableBusinessVolumeStatusByType = new Map<BusinessVolumeCostStateEnum, BusinessVolumeStatusEnum[]>([
    [BusinessVolumeCostStateEnum.Income, [BusinessVolumeStatusEnum.Open, BusinessVolumeStatusEnum.Booked, BusinessVolumeStatusEnum.Notbooked , BusinessVolumeStatusEnum.Cancellation]],
    [BusinessVolumeCostStateEnum.Costs, [BusinessVolumeStatusEnum.Open, BusinessVolumeStatusEnum.Payed , BusinessVolumeStatusEnum.Cancellation]],
]);
export const BusinessVolumeStatusEnumNameMap: Map<BusinessVolumeStatusEnum, string> = new Map<BusinessVolumeStatusEnum, string>([
    [BusinessVolumeStatusEnum.Open, 'offen'],
    [BusinessVolumeStatusEnum.Booked, 'bezahlt'],
    [BusinessVolumeStatusEnum.Notbooked, 'nicht abgerechnet'],
    [BusinessVolumeStatusEnum.Payed, 'bezahlt'],
    [BusinessVolumeStatusEnum.Cancellation, 'storniert'],
]);
export interface businessVolumePaymentInformation {
    "ZahlungseingangBetragBrutto": number | null,
    "ZahlungseingangBetragNetto": number | null,
    "Zahlungsdatum": Date | null,
    "ZahlungseingangZusatz": string | null,
}
export class BusinessVolumeEntity {
    constructor(
        public Id: number = null,
        public Amount: number = null,
        public CommissionId: number = null,
        public CreatedAt: Date = null,
        public DeletedAt: Date = null,
        public LedgerImportId: number = null,
        public PartnerId: number = null,
        public Status: BusinessVolumeStatusEnum = null,
        public UpdatedAt: Date = null,
        public UserId: number = null,
        // wurde nicht ins Backend eingebaut, alle bvs die eine CommissionId aber keine LedgerimportId haben sind aus arls entstanden
        // public AccountsReceivableLedgerId: number = null,
        public CustomerId: number = null,
        public BVCustomNamesIds: number[] = null,
        public BVType: BusinessVolumeCostStateEnum = null,
        public BusinessVolumeNo: string = null,
        public PayedAmount: number = null,
        public Name: string = null,
        public Description: string = null,
        public DocumentIds: number[] = null,
        public ARLIds: number[] = null,
        public ConsolidatedDate: Date = null,
        public ConsolidatedDeliveryDate: Date = null,
        public TaxValue: number = null,
        public TaxPercentageValue: number = null,
        public DiscountValue: number = null,
        public DiscountPercentageValue: number = null,
        public ExportDate: Date = null,
        public ExportPeriodStart: Date = null,
        public ExportPeriodEnd: Date = null,
        public OrderNumber: string = null,
        public Kostenstelle: number = null,
        public SachkontoCOAId: number = null,
        public PayedTaxValue: number = null,
        public PaymentInformations: businessVolumePaymentInformation[] = null,
        public AmountGross: number = null,
        public PayedAmountGross: number = null,
        public DiscountValueGross: number = null,

    ) {}
    public static readonly GqlFields: Array<keyof BusinessVolumeType> = [
        'amount',
        'commissionId',
        'customerId',
        'createdAt',
        'deletedAt',
        'id',
        'ledgerImportId',
        'partnerId',
        'status',
        'updatedAt',
        'userId',
        'bVCustomNamesIds',
        'bVType',
        'businessVolumeNo',
        'payedAmount',
        'name',
        'description',
        'documentIds',
        'aRLIds',
        'consolidatedDate',
        'consolidatedDeliveryDate',
        'taxValue',
        'taxPercentageValue',
        'discountValue',
        'discountPercentageValue',
        'exportDate',
        'exportPeriodStart',
        'exportPeriodEnd',
        'orderNumber',
        'kostenstelle',
        'sachkontoCOAId',
        'payedTaxValue',
        'paymentInformations',
        'amountGross',
        'payedAmountGross',
        'discountValueGross',

    ];
    public Clone(override: Partial<BusinessVolumeEntity> = {}): BusinessVolumeEntity {
        const attr = { ...this, ...override };
        return Object.assign(new BusinessVolumeEntity(), attr);
    }
}

export function BusinessVolumeEntityFromBackend(res: BusinessVolumeType): BusinessVolumeEntity {
    if (res.aRLIds?.length > 1) {
        console.warn('BusinessVolumeEntityFromBackend', res.aRLIds);
    }
    let paymentInformations: businessVolumePaymentInformation[] = null;
    if (res.paymentInformations) {
        try {
            const parsed = JSON.parse(res.paymentInformations)
            try {

                paymentInformations = parsed.map(p => ({
                    ...p,
                    Zahlungsdatum: p.Zahlungsdatum && BackendDate(p.Zahlungsdatum)
                }));
            } catch (error) {
                console.error(error)
            }
        } catch (error) {
            console.error('BusinessVolumeEntityFromBackend: invalid paymentInformations', error);
        }
    }
    return new BusinessVolumeEntity(
        res.id,
        res.amount,
        res.commissionId,
        BackendDate(res.createdAt),
        res.deletedAt && BackendDate(res.deletedAt),
        res.ledgerImportId,
        res.partnerId,
        res.status,
        res.updatedAt && BackendDate(res.updatedAt),
        res.userId,
        res.customerId,
        res.bVCustomNamesIds,
        res.bVType,
        res.businessVolumeNo,
        res.payedAmount,
        res.name,
        res.description,
        res.documentIds,
        res.aRLIds,
        res.consolidatedDate && BackendDate(res.consolidatedDate),
        res.consolidatedDeliveryDate && BackendDate(res.consolidatedDeliveryDate),
        res.taxValue,
        res.taxPercentageValue,
        res.discountValue,
        res.discountPercentageValue,
        res.exportDate && BackendDate(res.exportDate),
        res.exportPeriodStart && BackendDate(res.exportPeriodStart),
        res.exportPeriodEnd && BackendDate(res.exportPeriodEnd),
        res.orderNumber,
        res.kostenstelle,
        res.sachkontoCOAId,
        res.payedTaxValue,
        paymentInformations,
        res.amountGross,
        res.payedAmountGross,
        res.discountValueGross
    );
}
