import { createFeatureSelector, createSelector } from '@ngrx/store';
import { accountingFeatureKey, AccountingState } from '../reducers/accounting.reducer';
import { Dictionary } from '@ngrx/entity';
import { AccountsReceivableLedgerEntity } from '../../entities/accounts-receivable-ledger.entity';
import { QuantityTypeEntity } from "../../entities/quantity-type.entity";
import { AccountsReceivableLedgerTemplateEntity } from "../../entities/accounts-receivable-ledger-template.entity";
import { ARL_FEATURE_KEY, ARLState, selectAllARL, selectARLEntities, selectARLFetched } from '../reducers/arl.reducer';

const selectARLState = createFeatureSelector<ARLState>(ARL_FEATURE_KEY);

const getAllAccountsReceivableLedgers = createSelector(selectARLState, selectAllARL);
export const getAccountsReceivableLedgers = createSelector(getAllAccountsReceivableLedgers, (data) => data.filter((a) => !a.GeneratedARLId));
export const getAccountsReceivableLedgersFetched = createSelector(selectARLState, selectARLFetched);
export const getAccountsReceivableLedgerDictionary = createSelector(selectARLState, selectARLEntities);
export const getARLsWithGeneratedARLId = createSelector(getAllAccountsReceivableLedgers, (data) => data.filter((a) => !!a.GeneratedARLId));


export const selectAccounting = createFeatureSelector<AccountingState>(accountingFeatureKey);

export const getAccountsReceivableLedgerTemplatesFetched = createSelector(selectAccounting, (data) => !!data.accountsReceivableLedgerTemplates);
export const getAccountsReceivableLedgerTemplates = createSelector(selectAccounting, (data) => data.accountsReceivableLedgerTemplates);
export const getARLTemplateTypes = createSelector(selectAccounting, (data) => data.aRLTemplateTypes);
export const getARLTemplateTypesFetched = createSelector(selectAccounting, (data) => !!data.aRLTemplateTypes);

export const getQuantityTypesFetched = createSelector(selectAccounting, (data) => !!data.quantityTypes);
export const getQuantityTypes = createSelector(selectAccounting, (data) => data.quantityTypes);
export const getQuantityTypeDictionary = createSelector(getQuantityTypes, (data) => {
    const d = {};
    data?.forEach(e => d[e.Id] = e);
    return d as Dictionary<QuantityTypeEntity>;
});
export const getQuantityTypeById = (props: { id: number }) => createSelector(getQuantityTypeDictionary, (dictionary) => props.id && dictionary[props.id]);
export const getSfdtTemplates = createSelector(selectAccounting, (data) => data.sfdtTemplates);
export const getSfdtTemplatesFetched = createSelector(selectAccounting, (data) => !!data.sfdtTemplates);

//by Id
export const getAccountsReceivableLedgerById = (props: { id: number }) => createSelector(getAccountsReceivableLedgers, (data) => data?.find((d) => d.Id === props.id) || null);

export const getAccountsReceivableLedgerTemplateDictionary = createSelector(getAccountsReceivableLedgerTemplates, (data) => {
    const d = {};
    data?.forEach(e => d[e.Id] = e);
    return d as Dictionary<AccountsReceivableLedgerTemplateEntity>;
})
export const getAccountsReceivableLedgerTemplateById = (props: { id: number }) =>
    createSelector(getAccountsReceivableLedgerTemplateDictionary, (data) => props.id && data[props.id]);
export const getSfdtTemplateById = (props: { id: number }) => createSelector(getSfdtTemplates, (data) => data?.find((d) => d.Id === props.id) || null);
