import { ComplaintTypeType } from './../graphql-types.d';
export enum ComplaintTypesEnum {
    Vorsitzender = 'vorsitzender',
    BeteiligtePerson = 'Beteiligte Person'
}

export enum ComplaintTypeIdEnum {
    klagender = 1,
    beklagter = 2,
    beteiligtePerson = 3,
    vorsitzender = 4,
}
export class ComplaintTypeEntity {
    constructor(
        public Id: number = null,
        public Name: string = null,
        public PartnerId: number = null,
    ) {
    }

    public Clone(override: ComplaintTypeEntityAttributes = {}): ComplaintTypeEntity {
        const attr = {...this, ...override};
        return Object.assign(new ComplaintTypeEntity(), attr);
    }
}

export function ComplaintTypeEntityFromBackend(res: ComplaintTypeType): ComplaintTypeEntity {
    return new ComplaintTypeEntity(
        res.id,
        res.name,
        res.partnerId,
    );
}


export interface ComplaintTypeEntityAttributes {
    Id?: number;
         Namme?: string;
         PartnerId?: number ;
        }
