import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, exhaustMap, of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { isNotNullOrUndefined, stringifyIfNotNullOrUndefined, stringifyIfNotUndefined } from '../../../helper/helper';
import { CommissionEntityFromBackend, CommissionEntitygqlFields } from '../../entities/commission.entity';
import { UserToCommissionEntity, UserToCommissionEntityFromBackend } from '../../entities/user-to-commission.entity';
import { HttpService } from '../../services/http.service';
import { DaveActions } from '../actions/actions';
import { BaseActionTypes } from '../actions/base.actions';
import { CommissionActionTypes } from '../actions/commission.actions';
import { DamageFlowActionTypes } from '../actions/damageflow.actions';
import { UserToCommissionActionTypes } from '../actions/user-to-commission.action';
import { UserToCommissionShiftActionTypes } from '../actions/user2CommissionShift.action';
import { State } from '../index';
import { getToken } from '../selectors/base.selectors';
import { getCommissionById, getCommissions } from '../selectors/commission.selector';
import { getPartnerMainOffice } from '../selectors/partners.selectors';
import { getStatusFromBackoffice } from '../selectors/statusFromBackoffice.selectors';
import { getUserToCommissionFetched, getUserToCommissionLatestUpdatedAt } from '../selectors/user-to-commission.selector';
import { getUserToCommissionShiftFetched } from '../selectors/users-to-commission-shift.selectors';
import {MilestoneActionTypes} from "../actions/milestone.actions";
import { getUser } from '../selectors/users.selectors';

enum ErrorCodes {
    Add = 'Aufträge Hinzufügen fehlgeschlagen',
    Load = 'Aufträge Abrufen fehlgeschlagen',
    Modify = 'Aufträge Bearbeiten fehlgeschlagen',
    Remove = 'Aufträge Löschen fehlgeschlagen',
    SetUser = 'Nutzer Setzen fehlgeschlagen',
    AddRessources = 'Ressourcen hinzufügen fehlgeschlagen',
}

@Injectable()
export class CommissionEffects {
    AddResourceCommission$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CommissionActionTypes.AddResources),
            withLatestFrom(this.store$.select(getToken)),
            concatMap(([{ Payload }, token]) => {
                const queryString = `
mutation{
  addResourcesFromLedgerImportId(commissionId: ${Payload.commissionId}, ledgerImportId: ${Payload.ledgerImportId}){id}
}`;
                return this.gatewayHttpService.graphQl({ query: queryString }, { token }).pipe(
                    map(res => {
                        if (res?.addResourcesFromLedgerImportId) {
                            return CommissionActionTypes.AddResourcesSuccess();
                        }
                        return BaseActionTypes.ErrorAction({Payload: { ToasterMessage: ErrorCodes.AddRessources }, });
                    }),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.AddRessources,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );
    RemoveCommission$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CommissionActionTypes.DeleteCommission),
            withLatestFrom(this.store$.select(getToken)),
            concatMap(([{ Payload }, token]) => {
                const queryString = `
mutation{
  deleteCommission(id: ${Payload})
}`;
                return this.gatewayHttpService.graphQl({ query: queryString }, { token }).pipe(
                    withLatestFrom(this.store$.select(getCommissions), this.store$.select(getUserToCommissionFetched), this.store$.select(getUserToCommissionShiftFetched)),
                    concatMap(([res, commissions, userToCommission, userToCommissionShift]) => {
                        const ret: Action[] =
                            res && res.deleteCommission
                                ? [
                                      CommissionActionTypes.UpdateMany({
                                          Payload: [commissions.find((val) => val.Id === Payload).Clone({ Deleted: true })],
                                      }),
                                  ]
                                : [
                                      BaseActionTypes.ErrorAction({
                                          Payload: { ToasterMessage: ErrorCodes.Remove },
                                      }),
                                  ];
                        if (userToCommission) {
                            ret.push(UserToCommissionActionTypes.RemoveByCommission({ Payload }));
                        }
                        if (userToCommissionShift) {
                            ret.push(UserToCommissionShiftActionTypes.RemoveByCommission({ Payload }));
                        }
                        return ret;
                    }),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Remove,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    ModifyCommission$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CommissionActionTypes.ModifyCommission),
            withLatestFrom(this.store$.select(getToken)),
            concatMap(([action, token]) => {
                const queryString = `
mutation{
  changeCommission(id: ${action.Payload.id}
    ${stringifyIfNotNullOrUndefined(action.Payload, 'additionalData')}
    ${stringifyIfNotNullOrUndefined(action.Payload, 'commissionTypeId')}
    ${stringifyIfNotNullOrUndefined(action.Payload, 'personId')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'schadensdatum')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'versicherungsbedingung')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'description')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'interneNummer')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'auftragsnummer')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'schadennummer')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'versicherungsnummer')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'auftragseingangDurchAuftraggeber')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'abgabeterminAG')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'abgabeterminQM')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'statusFromSVId')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'statusBackofficeId')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'ersterBerichtErstellt')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'lastDeadline')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'customerId')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'employeeId')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'aktenzeichen')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'versicherungsName')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'versicherungsPhoneNumber')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'versicherungsMobileNumber')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'versicherungsCity')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'versicherungsDescription')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'versicherungsArbeitsgebiet')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'versicherungsPostalCode')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'versicherungsStreet')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'versicherungsZusatzName')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'automaticAuftragsnummer')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'automaticInterneNummer')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'automaticAccountsReceivableLedger')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'automaticAccountsReceivableLedgerByClockIns')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'startDate')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'plannedStartDate')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'endDate')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'plannedEndDate')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'partnerOfficeId')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'city')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'postalCode')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'street')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'country')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'versicherungssumme')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'freeTextField1')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'freeTextField2')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'freeTextField3')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'orderNoCustomer')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'completeBusinessVolume')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'costsCompleteBusinessVolume')}
  ${stringifyIfNotUndefined(action.Payload, 'parentId')}
  ${stringifyIfNotUndefined(action.Payload, 'planIds')}
  ${stringifyIfNotUndefined(action.Payload, 'costCenter')}
  ${stringifyIfNotNullOrUndefined(action.Payload, 'handoverDate')}
    ){${CommissionEntitygqlFields}}
}`;
                return this.gatewayHttpService.graphQl({ query: queryString }, { token }).pipe(
                    withLatestFrom(this.store$.select(getCommissions), this.store$.select(getStatusFromBackoffice)),
                    tap(([res, commissions, statusFromBackoffices]) => {
                        if (res?.changeCommission && res.changeCommission.statusBackofficeId !== commissions.find((l) => l.Id === res.changeCommission.id)?.StatusBackofficeId) {
                            this.apiToasterService.success(`Status auf ${statusFromBackoffices?.find((sb) => res.changeCommission.statusBackofficeId === sb.Id).Name} geändert`);
                        }
                    }),
                    map(([res, commissions, statusFromBackoffices]) =>
                        res && res.changeCommission && res.changeCommission.id
                            ? CommissionActionTypes.UpdateMany({
                                  Payload: [CommissionEntityFromBackend(res.changeCommission)],
                              })
                            : BaseActionTypes.ErrorAction({
                                  Payload: { ToasterMessage: ErrorCodes.Modify },
                              }),
                    ),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Modify,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    AddCommission$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CommissionActionTypes.AddCommission),
            withLatestFrom(this.store$.select(getToken), this.store$.select(getPartnerMainOffice)),
            concatMap(([action, token, mainOffice]) => {
                const queryString =
                    `
mutation{
  createCommission` +
                    (isNotNullOrUndefined(action.Payload.customerId) || action.Payload.partnerOfficeId || mainOffice
                        ? `(
    ${stringifyIfNotNullOrUndefined(action.Payload, 'customerId')}
    ${stringifyIfNotNullOrUndefined(action.Payload, 'commissionTypeId')}
    ${stringifyIfNotNullOrUndefined(action.Payload, 'description')}
    ${stringifyIfNotNullOrUndefined(action.Payload, 'additionalData')}
    ${stringifyIfNotNullOrUndefined(action.Payload, 'statusBackofficeId')}
    ${stringifyIfNotNullOrUndefined(action.Payload, 'auftragseingangDurchAuftraggeber')}
    ${stringifyIfNotNullOrUndefined(action.Payload, 'parentId')}
    ${stringifyIfNotNullOrUndefined(action.Payload, 'personId')}
    ${stringifyIfNotNullOrUndefined(action.Payload, 'planIds')}
    ${stringifyIfNotNullOrUndefined(action.Payload, 'auftragsnummer')}
    ${stringifyIfNotNullOrUndefined(action.Payload, 'automaticAuftragsnummer')}
    ${stringifyIfNotNullOrUndefined(action.Payload, 'interneNummer')}
    ${stringifyIfNotNullOrUndefined(action.Payload, 'automaticInterneNummer')}
    ${stringifyIfNotNullOrUndefined(action.Payload, 'addResourcesFromLedgerImportId')}
    ${stringifyIfNotNullOrUndefined(action.Payload, 'handoverDate')}
    ${!action.Payload.partnerOfficeId && mainOffice ? `partnerOfficeId: ${mainOffice.Id}` : stringifyIfNotNullOrUndefined(action.Payload, 'partnerOfficeId')}
  )`
                        : `${stringifyIfNotNullOrUndefined(action.Payload, 'auftragseingangDurchAuftraggeber')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'statusBackofficeId')}`) +
                    `{${CommissionEntitygqlFields}}}`;

                return this.gatewayHttpService.graphQl({ query: queryString }, { token: token }).pipe(
                    withLatestFrom(this.store$.select(getCommissions)),
                    concatMap(([res, commissions]) => {
                        return res && res.createCommission
                            ? [
                                  CommissionActionTypes.UpdateOne({
                                      Payload: CommissionEntityFromBackend(res.createCommission),
                                  }),
                                  DamageFlowActionTypes.Load({
                                      Payload: {
                                          commissionId: res.createCommission.id,
                                      },
                                  }),
                                // UserToCommissionActionTypes.SetByCommission({
                                //     CommissionId: res.createCommission.id,
                                //     Payload: res.createCommission.user2commission.map(UserToCommissionEntityFromBackend),
                                // }),
                                  UserToCommissionActionTypes.Load({}),
                              ]
                            : [
                                  BaseActionTypes.ErrorAction({
                                      Payload: { ToasterMessage: ErrorCodes.Add },
                                  }),
                              ];
                    }),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Add,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    LoadCommissions = createEffect(
        () =>
            this.actions$.pipe(
                ofType(CommissionActionTypes.Load),
                withLatestFrom(this.store$.select(getToken)),
                exhaustMap(([{ Payload }, token]) => {
                    const queryString = `query{
              commission${Payload?.updatedSince ? `(updatedSince: "${Payload.updatedSince}")` : ''}{
                ${CommissionEntitygqlFields}
              }
            }`;
                    return this.gatewayHttpService.graphQl({ query: queryString }, { token, retry: true }).pipe(
                        tap((res) => {
                            if (!(Payload?.updatedSince && !res.commission.length)) {
                                this.store$.dispatch(
                                    res && res.commission
                                        ? Payload?.updatedSince
                                            ? CommissionActionTypes.UpdateMany({
                                                  Payload: res.commission.map((val) => CommissionEntityFromBackend(val)),
                                                  updateLatestUpdatedAt: true,
                                              })
                                            : CommissionActionTypes.UpdateAll({
                                                  Payload: res.commission.map((val) => CommissionEntityFromBackend(val)),
                                                  updateLatestUpdatedAt: true,
                                              })
                                        : BaseActionTypes.ErrorAction({
                                              Payload: { ToasterMessage: ErrorCodes.Load },
                                          }),
                                );
                            }
                        }),
                        catchError((err, caught) => {
                            this.store$.dispatch(
                                BaseActionTypes.ErrorAction({
                                    Payload: {
                                        ToasterMessage: ErrorCodes.Load,
                                        Err: err,
                                        Caught: caught,
                                    },
                                }),
                            );
                            return EMPTY;
                        }),
                    );
                }),
            ),
        { dispatch: false },
    );

    SetUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CommissionActionTypes.SetCommissionUser),
            withLatestFrom(this.store$.select(getToken)),
            concatMap(([action, token]) => {
                const queryString = `mutation{
              setUser2Commission(
                userIds: ${JSON.stringify(action.Payload.UserIds)},
                assigneeUserIds: ${JSON.stringify(action.Payload.AssigneeUserIds)},
                commissionId: ${action.Payload.CommissionId}
              ){
                ${UserToCommissionEntity.GqlFields}
              }
            }`;

                return this.gatewayHttpService.graphQl({ query: queryString }, { token }).pipe(
                    withLatestFrom(this.store$.select(getCommissionById({id: action.Payload.CommissionId})), this.store$.select(getUserToCommissionFetched), this.store$.select(getUserToCommissionLatestUpdatedAt), this.store$.select(getUser)),
                    concatMap(([res, commission, u2cFetched, u2cUpdatedAt, user]) => {
                        const ret: Action[] =
                            res && res.setUser2Commission
                                ? [
                                      CommissionActionTypes.UpdateMany({
                                          Payload: [
                                              commission.Clone({
                                                      ImAssigned: res.setUser2Commission.some((u2c) => u2c.userId === user.Id && u2c.assignee),
                                                  }),
                                          ],
                                      }),
                                  ]
                                : [BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.SetUser } })];
                        if (res && res.setUser2Commission && u2cFetched) {
                            ret.push(
                                // UserToCommissionActionTypes.SetByCommission({
                                //     CommissionId: action.Payload.CommissionId,
                                //     Payload: res.setUser2Commission.map(UserToCommissionEntityFromBackend),
                                // }),
                                UserToCommissionActionTypes.Load({ Payload: { updatedSince: moment(u2cUpdatedAt).toISOString() } }),
                            );
                        }
                        return ret;
                    }),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.SetUser,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    constructor(private actions$: Actions<DaveActions>, private store$: Store<State>, private gatewayHttpService: HttpService, private apiToasterService: ToastrService) {}
}
