<div
    class="row clickable-list-item"
    *ngIf="Event$ | async as rowData"
    [matTooltip]="rowData.tooltip"
    matTooltipPosition="above"
    [style.height]="'5.5rem'"
    (click)="EventClick(rowData)"
    [ngClass]="rowData.cssClass"
    [class.is-active]="Active"
>
    <div class="col icons" [style.align-self]="'flex-start'">
        <div class="unseen-dot" *ngIf="ShowUnseenBadge && rowData.isUnseen"></div>

        <div [class]="ProcessMeta.Theme" class="theme-override" *ngIf="!EscapeProcessIcons && (rowData.processStepIndex != null) && (PS.Has$(PS.Permissions.CanSeeProcesses) | async) else icons">
            <fa-icon *ngIf="rowData.processStepMainTask else stepIcon" size="2x" [icon]="ProcessMeta.Icon" class="app-foreground-primary"></fa-icon>
            <ng-template #stepIcon>
                <div [matBadge]="rowData.processStepIndex + 1" matBadgePosition="center" matBadgeSize="medium" matBadgeColor="primary">
                    <div class="process-step-bar vertical app-background-primary"></div>
                </div>
            </ng-template>
        </div>
        <ng-template #icons>
            <fa-icon mat-list-avatar [icon]="rowData.icon" [size]="'1x'"></fa-icon>
            <mat-checkbox matTooltip="Erledigt" *ngIf="rowData.event.IsTask && this.SetToDoneDirectly"  [disabled]="rowData.isDone" [checked]="rowData.isDone" (change)="onDoneCheckboxChange($event)" (click)="$event.stopPropagation()"></mat-checkbox>
            <mat-checkbox matTooltip="Erledigt" *ngIf="rowData.event.IsTask && !this.SetToDoneDirectly" [(ngModel)]="SetToDone" (click)="$event.stopPropagation()"></mat-checkbox>
            <div [matTooltip]="rowData.priority.name" *ngIf="rowData.priority" style="margin-top: .1rem;">
                <fa-icon [icon]="rowData.priority.icon" [style.color]="rowData.priority.color"></fa-icon>
            </div>
        </ng-template>
    </div>

    <div class="col contact-name" [innerHTML]="rowData.name" [ngStyle]="{ 'font-size': '14px' }"></div>

    <div class="col right-icons">
        <div class="inner-event-icon-wrapper">
            <div *ngIf="rowData.FileCount" #fileTooltip="matTooltip" [style.cursor]="loadingFiles ? 'progress' : 'unset'" [matTooltip]="''" [matTooltipShowDelay]="0" (mouseenter)="onMouseEnterFileCount(rowData)" (mouseleave)="onMouseLeaveFileCount()">
                {{ rowData.FileCount }}
                <fa-icon icon="paperclip"></fa-icon>
            </div>
            <fa-icon *ngIf="rowData.event.Description?.length && !rowData.hasComment" icon="sticky-note"></fa-icon>
            <fa-icon *ngIf="rowData.hasComment" icon="comment" [class.unseen]="rowData.unseenComment"></fa-icon>
            <button *ngIf="!(bookmarkLoading$ | async); else bookmarkLoading" [matTooltip]="rowData.bookmark ? 'Lesezeichen entfernen' : 'Lesezeichen hinzufügen'" aria-label="{{rowData.bookmark ? 'Lesezeichen entfernen' : 'Lesezeichen hinzufügen'}}" (click)="BookmarkEvent(rowData); $event.stopPropagation()" class="bookmark">
                <fa-icon [icon]="rowData.bookmark ? 'bookmark' : ['far', 'bookmark']"></fa-icon>
            </button>
            <ng-template #bookmarkLoading>
                <mat-progress-spinner mode="indeterminate" diameter="18"></mat-progress-spinner>
            </ng-template>
        </div>
        <div class="bottom-buttons" *ngIf="ShowRoutingButtons">
            <div *ngIf="rowData.event.EmailId" class="email-theme theme-override">
                <button app-round-button Lighter (click)="ShowEmail(rowData); $event.stopPropagation()" [matTooltip]="'E-Mail anzeigen'">
                    <fa-icon icon="{{EmailPageMeta.Icon}}"></fa-icon>
                </button>
            </div>
            <div class="commission-theme theme-override" *ngIf="rowData.commission && PS.Has(PS.Permission.GetCommission) | async">
                <button app-round-button Lighter (click)="GoToCommission(rowData.commission, $event)" [matTooltip]="(cls.getSingle$('Commission') | async) + ' öffnen'">
                    <fa-icon icon="{{CommissionMeta.Icon}}"> </fa-icon>
                </button>
            </div>
            <div class="partner-theme theme-override" *ngIf="rowData.customer">
                <button app-round-button (click)="$event.stopPropagation()" [matTooltip]="(cls.getSingle$('Customer') | async)" [routerLink]="['/', CustomerPath, rowData.customer?.Id]">
                    <fa-icon icon="{{CustomerAdministrationMeta.Icon}}"> </fa-icon>
                </button>
            </div>
        </div>
    </div>
</div>
<mat-divider></mat-divider>
