import { createActionGroup, props } from '@ngrx/store';
import { FileOpenerTokenEntity } from '../../entities/file-opener-token.entity';
import { GatewayResponseErrorType } from '../../services/app-gateway.service';
import {
    FileOpenerInternalGetTokensRequest,
    FileOpenerInternalInvitationAcceptRequest, FileOpenerInternalRemoveTokenRequest,
} from '@dave/types/dist/proto/fileopener/internal';
import { UploadActionProps } from '../../guards/resolver.helper';

export const FileOpenerTokenActions = createActionGroup({
    source: 'FileOpenerToken',
    events: {
        'Load Request': props< UploadActionProps>(),
        'Load Success': props<{ Payload: FileOpenerTokenEntity[]; updateLatestUpdatedAt?: boolean }>(),
        'Load Failure': props<{ Payload: { Errors: GatewayResponseErrorType; ToasterMessage?: string } }>(),

        'InvitationAccept Request': props<{ Payload: FileOpenerInternalInvitationAcceptRequest }>(),
        'InvitationAccept Success': props<{ Payload: FileOpenerTokenEntity }>(),
        'InvitationAccept Failure': props<{ Payload: { Errors: GatewayResponseErrorType; ToasterMessage?: string } }>(),

        'Remove Request': props<{ Payload: FileOpenerInternalRemoveTokenRequest }>(),
        'Remove Success': props<{ Payload: number }>(),
        'Remove Failure': props<{ Payload: { Errors: GatewayResponseErrorType; ToasterMessage?: string } }>(),
    },
});
