import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, EMPTY, firstValueFrom } from 'rxjs';
import { catchError, map, withLatestFrom } from 'rxjs/operators';
import { isNotNullOrUndefined, stringifyIfNotNullOrUndefined, stringifyIfNotUndefined } from '../../helper/helper';

import { AccountsReceivableLedgerTemplateEntity, AccountsReceivableLedgerTemplateEntityFromBackend } from '../entities/accounts-receivable-ledger-template.entity';
import { DaveMutationChangeAccountsReceivableLedgerTemplateArgs, DaveMutationCreateAccountsReceivableLedgerTemplateArgs } from '../graphql-types';
import { BusinessVolumeResolver } from '../guards/business-volume.resolver';
import { LedgerImportResolver } from '../guards/ledger-import.resolver';
import { State } from '../State';
import { AccountsReceivableLedgerTemplateActionTypes } from '../State/actions/accounting.actions';
import { BaseActionTypes } from '../State/actions/base.actions';
import { getAccountsReceivableLedgerTemplates } from '../State/selectors/accounting.selector';
import { HttpService } from './http.service';

enum ErrorCodes {
    Add = 'Buchungspositionsvorlage hinzufügen fehlgeschlagen',
    Modify = 'Buchungspositionsvorlage ändern fehlgeschlagen',
}
@Injectable({
    providedIn: 'root',
})
export class ArlTemplateDataService {
    constructor(private store$: Store<State>, private gatewayHttpService: HttpService, private bvResolver: BusinessVolumeResolver, private liResolver: LedgerImportResolver) {}
    public addArlTemplates(payload: DaveMutationCreateAccountsReceivableLedgerTemplateArgs[], currencyCalcFactor = 100): Promise<AccountsReceivableLedgerTemplateEntity[]> {
        //         const requestNamePrefix = 'createGeneratedDocumentsDiary';
        //
        //         const query = `mutation($commissionId: Int, $from: DateTime, $to: DateTime, $eventIds: [Int], ${payload.map((_, i) => '$generatedDocumentsDiaryTypeSlug' + i + ': GeneratedDocumentsDiaryTypeSlug').join(', ')}){
        //             ${payload.map(
        //             (_, i) => `${requestNamePrefix + i}: createGeneratedDocumentsDiary(commissionId: $commissionId, eventIds: $eventIds, from: $from, to: $to, generatedDocumentsDiaryTypeSlug: $generatedDocumentsDiaryTypeSlug${i}) {
        //             ...genDocFields
        //             }`,
        //         )}
        //         }
        //         fragment genDocFields on GeneratedDocumentsType {
        //   ${AccountsReceivableLedgerEntity.GqlFields.join(',')}
        // }`;
        //         const variables = { eventIds, commissionId, from: fromDate && FrontendDate(fromDate), to: toDate && FrontendDate(toDate) };
        //         typesToGenerate.forEach((generatedDocumentsDiaryTypeSlug, i) => {
        //             variables['generatedDocumentsDiaryTypeSlug' + i] = generatedDocumentsDiaryTypeSlug;
        //         });

        // const Payload = payload[0];
        return firstValueFrom(
            combineLatest(
                payload.map((Payload) => {
                    const queryString = `mutation {
          createAccountsReceivableLedgerTemplate(
              ${stringifyIfNotNullOrUndefined(Payload, 'tax')}
              ${stringifyIfNotNullOrUndefined(Payload, 'currencyCode')}
              ${stringifyIfNotNullOrUndefined(Payload, 'bookingText')}
              ${stringifyIfNotNullOrUndefined(Payload, 'customerId')}
              ${stringifyIfNotNullOrUndefined(Payload, 'commissionTypeId')}
              ${stringifyIfNotNullOrUndefined(Payload, 'eventTypeId')}
              ${stringifyIfNotNullOrUndefined(Payload, 'quantity')}
              ${stringifyIfNotNullOrUndefined(Payload, 'information')}
              ${stringifyIfNotNullOrUndefined(Payload, 'quantityTypeId')}
              ${isNotNullOrUndefined(Payload.costAmount) ? 'costAmount:' + (Payload.costAmount * currencyCalcFactor).toFixed(0) : ''}
              ${isNotNullOrUndefined(Payload.baseCost) ? 'baseCost:' + (Payload.baseCost * currencyCalcFactor).toFixed(0) : ''}
              multiplikator: ${JSON.stringify(Payload.multiplikator)}
              ${stringifyIfNotNullOrUndefined(Payload, 'customProperties')}
              ${stringifyIfNotNullOrUndefined(Payload, 'aRLTemplateTypeId')}
              ${stringifyIfNotNullOrUndefined(Payload, 'longInformation')}
              ${stringifyIfNotNullOrUndefined(Payload, 'showLongInformation')}
              ${stringifyIfNotNullOrUndefined(Payload, 'parentId')}
              ${stringifyIfNotNullOrUndefined(Payload, 'arlTemplateIds')}
              ${stringifyIfNotNullOrUndefined(Payload, 'inheritFromChildren')}
              ${stringifyIfNotNullOrUndefined(Payload, 'isVisible')}
              ${stringifyIfNotUndefined(Payload, 'resourceId')}
              ${stringifyIfNotUndefined(Payload, 'jobSpecificationId')}
              ${stringifyIfNotUndefined(Payload, 'materialId')}
    ${Payload.type ? `type: ${Payload.type}` : ''}

          ) {${AccountsReceivableLedgerTemplateEntity.GqlFields.join(',')}}
        }
        `;
                    return this.gatewayHttpService
                        .graphQl({ query: queryString })
                        .pipe
                        // map(res => res?.createAccountsReceivableLedger ? [res.createAccountsReceivableLedger] : null),
                        ();
                }),
            ).pipe(
                withLatestFrom(this.store$.select(getAccountsReceivableLedgerTemplates)),
                map(([res, arlTemplates]) => {
                    if (res?.every((r) => r?.createAccountsReceivableLedgerTemplate)) {
                        const entitys = res.map((r) => AccountsReceivableLedgerTemplateEntityFromBackend(r.createAccountsReceivableLedgerTemplate));
                        this.store$.dispatch(
                            AccountsReceivableLedgerTemplateActionTypes.UpdateAccountsReceivableLedgerTemplate({
                                Payload: [...arlTemplates, ...entitys],
                            }),
                        );
                        return entitys;
                    } else {
                        throw 'wrong response';
                    }
                }),
                catchError((err, caught) => {
                    this.store$.dispatch(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Add,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    );
                    return EMPTY;
                }),
            ),
        );
    }

    public changeArlTemplatess(payload: DaveMutationChangeAccountsReceivableLedgerTemplateArgs[], currencyCalcFactor = 100): Promise<AccountsReceivableLedgerTemplateEntity[]> {
        // const Payload = payload[0];
        console.log(payload);
        return firstValueFrom(
            combineLatest(
                payload.map((Payload) => {
                    const queryString = `mutation {
          changeAccountsReceivableLedgerTemplate(
    id: ${Payload.id}
    ${stringifyIfNotNullOrUndefined(Payload, 'tax')}
    ${stringifyIfNotNullOrUndefined(Payload, 'currencyCode')}
    ${stringifyIfNotNullOrUndefined(Payload, 'information')}
    ${stringifyIfNotNullOrUndefined(Payload, 'bookingText')}
    ${stringifyIfNotNullOrUndefined(Payload, 'customerId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'commissionTypeId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'eventTypeId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'quantity')}
    quantityTypeId: ${JSON.stringify(Payload.quantityTypeId)}
    ${isNotNullOrUndefined(Payload.costAmount) ? 'costAmount:' + (Payload.costAmount * currencyCalcFactor).toFixed(0) : ''}
    ${isNotNullOrUndefined(Payload.baseCost) ? 'baseCost:' + (Payload.baseCost * currencyCalcFactor).toFixed(0) : ''}
    multiplikator: ${JSON.stringify(Payload.multiplikator)}
    ${stringifyIfNotNullOrUndefined(Payload, 'customProperties')}
    aRLTemplateTypeId: ${JSON.stringify(Payload.aRLTemplateTypeId)}
              ${stringifyIfNotNullOrUndefined(Payload, 'longInformation')}
              ${stringifyIfNotNullOrUndefined(Payload, 'showLongInformation')}
              ${stringifyIfNotNullOrUndefined(Payload, 'parentId')}
              ${stringifyIfNotNullOrUndefined(Payload, 'arlTemplateIds')}
              ${stringifyIfNotNullOrUndefined(Payload, 'inheritFromChildren')}
              ${stringifyIfNotNullOrUndefined(Payload, 'isVisible')}
              ${stringifyIfNotUndefined(Payload, 'resourceId')}
              ${stringifyIfNotUndefined(Payload, 'jobSpecificationId')}
              ${stringifyIfNotUndefined(Payload, 'materialId')}

    ${Payload.type ? `type: ${Payload.type}` : ''}

          ) {${AccountsReceivableLedgerTemplateEntity.GqlFields.join(',')}}
        }
        `;
                    return this.gatewayHttpService
                        .graphQl({ query: queryString })
                        .pipe
                        // map(res => res?.createAccountsReceivableLedger ? [res.createAccountsReceivableLedger] : null),
                        ();
                }),
            ).pipe(
                withLatestFrom(this.store$.select(getAccountsReceivableLedgerTemplates)),
                map(([res, bookingPositions]) => {
                    //     if (res && res.changeAccountsReceivableLedgerTemplate) {
                    //         return AccountsReceivableLedgerTemplateActionTypes.UpdateAccountsReceivableLedgerTemplate({
                    //             Payload: [...bookingPositions.filter((bp) => bp.Id !== res.changeAccountsReceivableLedgerTemplate.id), AccountsReceivableLedgerTemplateEntityFromBackend(res.changeAccountsReceivableLedgerTemplate)],
                    //         });
                    //     } else {
                    //         return BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Modify } });
                    //     }
                    // }),
                    // map(([res]) => {
                    if (res?.every((r) => r?.changeAccountsReceivableLedgerTemplate)) {
                        const entitys = res.map((r) => AccountsReceivableLedgerTemplateEntityFromBackend(r.changeAccountsReceivableLedgerTemplate));
                        this.store$.dispatch(
                            AccountsReceivableLedgerTemplateActionTypes.UpdateAccountsReceivableLedgerTemplate({
                                Payload: [...bookingPositions.filter((bp) => entitys.every((e) => e.Id !== bp.Id)), ...entitys],
                            }),
                        );
                        return entitys;
                    } else {
                        throw 'wrong response';
                    }
                }),
                catchError((err, caught) => {
                    this.store$.dispatch(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Modify,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    );
                    return EMPTY;
                }),
            ),
        );
    }
}
