<app-detail-view-template [ShowPlaceholder]="false">
    <ng-container *appDetailViewBeforeButtons>
        <div *ngIf="SearchBarActive">
            <mat-form-field color="accent">
                <input type="text" placeholder="Suche" [formControl]="SearchForm" matInput #input/>
                <button class="clear-search" (click)="SearchForm.setValue('')" mat-icon-button *ngIf="SearchForm.value">
                    <fa-icon icon="times"></fa-icon>
                </button>
            </mat-form-field>
        </div>
    </ng-container>
    <ng-container *appDetailViewButtons>
        <button app-round-button Inverted [matTooltip]="SearchBarActive && !SearchForm.value ? 'Ausblenden' : 'Suche'" aria-label="{{SearchBarActive && !SearchForm.value ? 'Ausblenden' : 'Suche'}}" style="margin: .5rem" (click)="SearchButtonClicked()">
            <fa-icon [icon]="SearchBarActive && !SearchForm.value ? 'times' : 'search'"></fa-icon>
        </button>
        <button app-round-button Inverted matTooltip="Filter" aria-label="Filter" (click)="filter.Open()" [matBadge]="FilterAmount$ | async" [matBadgeHidden]="!(FilterAmount$ | async)" style="margin: .5rem">
            <fa-icon icon="filter"></fa-icon>
        </button>
        <button app-round-button Inverted matTooltip="Sortieren" aria-label="Sortieren" style="margin: .5rem" [matMenuTriggerFor]="sortMenu">
            <fa-icon icon="sort"></fa-icon>
        </button>
    </ng-container>
    <ng-container *appDetailViewTopContent>
        <app-tasks-board [Search]="SearchForm.value" [Filter]="TaskFilter$ | async" [PreFilter]="{authorOrUserToEventOrUserToCommission: true}" (FileClicked)="OpenFile($event)" [Sort]="TaskBoardSort"></app-tasks-board>
    </ng-container>
</app-detail-view-template>
<app-filter-card UnlimitedHeight #filter [Settings]="FilterSettings$ | async" [Values]="FilterValues$ | async" (ValuesChange)="FilterValues$.next($event)"></app-filter-card>
<mat-menu #sortMenu="matMenu">
    <button mat-menu-item (click)="TaskBoardSort = {sortBy: 'endDate', direction: 'asc'}"><fa-icon icon="chevron-up"></fa-icon> Frist</button>
    <button mat-menu-item (click)="TaskBoardSort = {sortBy: 'endDate', direction: 'dsc'}"><fa-icon icon="chevron-down"></fa-icon> Frist</button>
    <button mat-menu-item (click)="TaskBoardSort = {sortBy: 'priority', direction: 'asc'}"><fa-icon icon="chevron-up"></fa-icon> Priorität</button>
    <button mat-menu-item (click)="TaskBoardSort = {sortBy: 'priority', direction: 'dsc'}"><fa-icon icon="chevron-down"></fa-icon> Priorität</button>
</mat-menu>
