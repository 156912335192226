import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../State';
import { BaseEntityReloadResolver2 } from './resolver.helper';
import { GetTimestampFromTime } from "@dave/types";
import {
    getFileOpenerOpenRequestFetched,
    getFileOpenerOpenRequestLatestUpdatedAt,
} from '../State/selectors/file-opener-open-request.selector';
import { FileOpenerOpenRequestActions } from '../State/actions/file-opener-open-request.actions';

@Injectable({
    providedIn: 'root',
})
export class FileOpenerOpenRequestResolver extends BaseEntityReloadResolver2 {
    constructor(store: Store<State>) {
        super(getFileOpenerOpenRequestFetched, getFileOpenerOpenRequestLatestUpdatedAt, FileOpenerOpenRequestActions.loadRequest, store, (d) => GetTimestampFromTime(d)+'');
    }
}
