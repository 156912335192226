import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { PartnerSpecificationType } from '../dave-data-module/entities/partner.entity';
import { PartnerResolver } from '../dave-data-module/guards/partner.resolver';
import { State } from '../dave-data-module/State';
import { getPartner, getPartnerFetched } from '../dave-data-module/State/selectors/partners.selectors';

const IBBAU_PARTNERID: number = window["env"]["ibbau_partner_id"];
const PDA_PARTNERID: number = window["env"]["pda_partner_id"];
const HOFF_PARTNERID: number = window["env"]["hoff_partner_id"];
const STEINHOFF_PARTNERID: number = window["env"]["steinhoff_partner_id"];

const partnersWithProjects = [IBBAU_PARTNERID, HOFF_PARTNERID, PDA_PARTNERID, STEINHOFF_PARTNERID];
const partnersWithBauherr = [IBBAU_PARTNERID, HOFF_PARTNERID, STEINHOFF_PARTNERID];
const partnersWithBZP = [IBBAU_PARTNERID, HOFF_PARTNERID, STEINHOFF_PARTNERID];

interface customLabels {
    Customer: label;
    Commission: label;
    RessourcePlan: label;
    Supplier: label;
    Costs: label;
}
type fallType = 'Nominativ' | 'Dativ' | 'Genitiv' | 'Akkusativ';
interface fall {
    singular: {
        article: string;
        value: string;
    },
    plural: {
        article: string;
        value: string;
    }
}
interface label {
    /**
     * for example Auftrags-daten
     */
    wordBinding: string;
    Nominativ: fall;
    Dativ?: fall;
    Genitiv?: fall;
    Akkusativ?: fall;
}
@Injectable({
    providedIn: 'root',
})
export class CustomLabelService implements OnDestroy {
    private destroy$ = new Subject<void>();
    private labels$: Observable<customLabels> = this.store.select(getPartnerFetched).pipe(
        tap((f) => {
            if (!f) {
                this.partnerResolver.resolve();
            }
        }),
        filter((f) => f),
        switchMap(() => this.store.select(getPartner)),
        filter((f) => !!f),
        takeUntil(this.destroy$),
        map((partner) => {
            return {
                Customer: {
                    wordBinding: partnersWithBauherr.includes(partner.Id) ? 'Bauherren' : partner.PartnerSpecificationTypeId === PartnerSpecificationType.Appraiser ? 'Auftraggeber' : 'Kunden',
                    Nominativ: {
                        singular: {
                            article: 'der',
                            value: partnersWithBauherr.includes(partner.Id) ? 'Bauherr' : partner.PartnerSpecificationTypeId === PartnerSpecificationType.Appraiser ? 'Auftraggeber' : 'Kunde',
                        },
                        plural: {
                            article: 'die',
                            value: partnersWithBauherr.includes(partner.Id) ? 'Bauherren' : partner.PartnerSpecificationTypeId === PartnerSpecificationType.Appraiser ? 'Auftraggeber' : 'Kunden',
                        },
                    },
                    Dativ: {
                        singular: {
                            article: partnersWithBauherr.includes(partner.Id) ? 'dem' : partner.PartnerSpecificationTypeId === PartnerSpecificationType.Appraiser ? 'dem' : 'der',
                            value: partnersWithBauherr.includes(partner.Id) ? 'Bauherren' : partner.PartnerSpecificationTypeId === PartnerSpecificationType.Appraiser ? 'Auftraggeber' : 'Kunde',
                        },
                        plural: {
                            article: 'den',
                            value: partnersWithBauherr.includes(partner.Id) ? 'Bauherren' : partner.PartnerSpecificationTypeId === PartnerSpecificationType.Appraiser ? 'Auftraggebern' : 'Kunden',
                        },
                    },
                },
                Commission: {
                    wordBinding: partnersWithProjects.includes(partner.Id) ? 'Projekt' : 'Auftrags',
                    Nominativ: {
                        singular: {
                            article: partnersWithProjects.includes(partner.Id) ? 'das' : 'der',
                            value: partnersWithProjects.includes(partner.Id) ? 'Projekt' : 'Auftrag',
                        },
                        plural: {
                            article: 'die',
                            value: partnersWithProjects.includes(partner.Id) ? 'Projekte' : 'Aufträge',
                        },
                    },
                    Genitiv: {
                        singular: {
                            article: 'des',
                            value: partnersWithProjects.includes(partner.Id) ? 'Projekts' : 'Auftrags',
                        },
                        plural: {
                            article: 'der',
                            value: partnersWithProjects.includes(partner.Id) ? 'Projekte' : 'Aufträge',
                        },
                    },
                    Dativ: {
                        singular: {
                            article: 'dem',
                            value: partnersWithProjects.includes(partner.Id) ? 'Projekt' : 'Auftrag',
                        },
                        plural: {
                            article: 'den',
                            value: partnersWithProjects.includes(partner.Id) ? 'Projekten' : 'Aufträgen',
                        },
                    },
                    Akkusativ: {
                        singular: {
                            article: partnersWithProjects.includes(partner.Id) ? 'das' : 'den',
                            value: partnersWithProjects.includes(partner.Id) ? 'Projekt' : 'Auftrag',
                        },
                        plural: {
                            article: 'die',
                            value: partnersWithProjects.includes(partner.Id) ? 'Projekte' : 'Aufträge',
                        },
                    },
                },
                Supplier: {
                    wordBinding: 'Lieferanten',
                    Nominativ: {
                        singular: {
                            article: 'der',
                            value:  'Lieferant',
                        },
                        plural: {
                            article: 'die',
                            value:  'Lieferanten',
                        },
                    },
                    Dativ: {
                        singular: {
                            article:  'der',
                            value:  'Lieferant',
                        },
                        plural: {
                            article: 'den',
                            value: 'Lieferanten',
                        },
                    },
                },
                Costs: {
                    wordBinding: '',
                    Nominativ: {
                        singular: {
                            article: 'der',
                            value:  'Einkaufspreis',
                        },
                        plural: {
                            article: 'die',
                            value:  'Einkaufspreise',
                        },
                    },
                    Dativ: {
                        singular: {
                            article:  'der',
                            value:  'Einkaufspreis',
                        },
                        plural: {
                            article: 'den',
                            value: 'Einkaufspreise',
                        },
                    },
                },
                RessourcePlan: {
                    wordBinding: partnersWithBZP.includes(partner.Id) ? 'BZP' : 'Ressourcenplan',
                    Nominativ: {
                        singular: {
                            article: 'der',
                            value: partnersWithBZP.includes(partner.Id) ? 'BZP' : 'Ressourcenplan',
                        },
                        plural: {
                            article: 'die',
                            value: partnersWithBZP.includes(partner.Id) ? 'BZPs' : 'Ressourcenpläne',
                        },
                    },
                },
            };
        }),
        shareReplay(1),
    );
    constructor(private store: Store<State>, private partnerResolver: PartnerResolver) {}
    getLabels$(label: keyof customLabels) {
        return this.labels$.pipe(map((labels) => labels[label]));
    }
    getSingle$(label: keyof customLabels, fall: fallType = 'Nominativ') {
        return this.getLabels$(label).pipe(map((l) => l[fall]?.singular.value));
    }
    getMultiple$(label: keyof customLabels, fall: fallType = 'Nominativ') {
        return this.getLabels$(label).pipe(map((l) => l[fall]?.plural.value));
    }

    ngOnDestroy() {
        this.destroy$.next();
    }
}
