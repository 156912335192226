import { Injectable } from '@angular/core';
import { GetTimestampFromTime } from '@dave/types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, firstValueFrom, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { isNotNullOrUndefined } from '../../../helper/helper';
import { FileOpenerTokenEntity, FileOpenerTokenEntityFromBackend } from '../../entities/file-opener-token.entity';
import { AppGatewayService } from '../../services/app-gateway.service';
import { BaseActionTypes } from '../actions/base.actions';
import { FileOpenerTokenActions } from '../actions/file-opener-token.actions';
import { State } from '../index';
import { getFileOpenerTokenDictionary } from '../selectors/file-opener-token.selector';

const ErrorCodes = {
    Load: FileOpenerTokenEntity.EntityName + ' Abrufen fehlgeschlagen',
    Remove: FileOpenerTokenEntity.EntityName + ' Löschen fehlgeschlagen',
    InvitationAccept: 'Einladung akzeptieren fehlgeschlagen',
};

@Injectable()
export class FileOpenerTokenEffects {
    errors$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FileOpenerTokenActions.removeFailure, FileOpenerTokenActions.loadFailure, FileOpenerTokenActions.invitationacceptFailure),
            map((action) =>
                BaseActionTypes.ErrorAction({
                    Payload: {
                        ToasterMessage: action.Payload.ToasterMessage,
                        Err: action.Payload.Errors,
                    },
                }),
            ),
        ),
    );
    GetFileOpenerToken = createEffect(
        () =>
            this.actions$.pipe(
                ofType(FileOpenerTokenActions.loadRequest),
                switchMap(({ Payload }) =>
                    this.gatewayService
                        .Request({ FileopenerGetTokens: isNotNullOrUndefined(Payload?.updatedSince) ? { UpdatedAt: Payload.updatedSince } : {} })
                        .then((res) => {
                            if (Object.keys(res?.Errors || {}).length === 0) {
                                if (!isNotNullOrUndefined(Payload.updatedSince)) {
                                    this.store$.dispatch(FileOpenerTokenActions.loadSuccess({ Payload: res.FileopenerGetTokens.Tokens.map(FileOpenerTokenEntityFromBackend), updateLatestUpdatedAt: true }));
                                } else {
                                    if (res.FileopenerGetTokens.Tokens.length) {
                                        firstValueFrom(this.store$.select(getFileOpenerTokenDictionary)).then((fileOpenerTokenes) => {
                                            const fRes = res.FileopenerGetTokens.Tokens.filter((p) => {
                                                const fromState = fileOpenerTokenes[p.Id];
                                                return !fromState || GetTimestampFromTime(fromState.UpdatedAt).toString() !== p.UpdatedAt;
                                            });
                                            this.store$.dispatch(
                                                FileOpenerTokenActions.loadSuccess({
                                                    Payload: fRes.map(FileOpenerTokenEntityFromBackend),
                                                    updateLatestUpdatedAt: true,
                                                }),
                                            );
                                        });
                                    } else {
                                        this.store$.dispatch(
                                            FileOpenerTokenActions.loadSuccess({
                                                Payload: [],
                                                updateLatestUpdatedAt: false,
                                            }),
                                        );
                                    }
                                }
                            } else {
                                throw res.Errors;
                            }
                        })
                        .catch((err) => {
                            this.store$.dispatch(
                                FileOpenerTokenActions.loadFailure({
                                    Payload: {
                                        ToasterMessage: ErrorCodes.Load,
                                        Errors: err,
                                    },
                                }),
                            );
                            return EMPTY;
                        }),
                ),
            ),
        { dispatch: false },
    );
    EditFileOpenerToken = createEffect(() =>
        this.actions$.pipe(
            ofType(FileOpenerTokenActions.invitationacceptRequest),
            switchMap(({ Payload }) =>
                this.gatewayService
                    .Request({ FileopenerInvitationAccept: Payload })
                    .then((res) => {
                        if (Object.keys(res?.Errors || {}).length === 0) {
                            return FileOpenerTokenActions.invitationacceptSuccess({ Payload: FileOpenerTokenEntityFromBackend(res.FileopenerInvitationAccept.Token) });
                        } else {
                            throw res.Errors;
                        }
                    })
                    .catch((err) =>
                        FileOpenerTokenActions.invitationacceptFailure({
                            Payload: {
                                ToasterMessage: ErrorCodes.InvitationAccept,
                                Errors: err,
                            },
                        }),
                    ),
            ),
        ),
    );
    DeleteFileOpenerToken = createEffect(() =>
        this.actions$.pipe(
            ofType(FileOpenerTokenActions.removeRequest),
            switchMap(({ Payload }) =>
                this.gatewayService
                    .Request({ FileopenerRemoveToken: Payload })
                    .then((res) => {
                        if (Object.keys(res?.Errors || {}).length === 0) {
                            return FileOpenerTokenActions.removeSuccess({ Payload: +Payload.Id });
                        } else {
                            throw res.Errors;
                        }
                    })
                    .catch((err) =>
                        FileOpenerTokenActions.removeFailure({
                            Payload: {
                                ToasterMessage: ErrorCodes.Remove,
                                Errors: err,
                            },
                        }),
                    ),
            ),
        ),
    );
    constructor(private actions$: Actions, private store$: Store<State>, private gatewayService: AppGatewayService) {}
}
