import { AsyncPipe, CurrencyPipe, NgForOf, NgIf } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Inject, Injector, Input, LOCALE_ID, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule, MatPseudoCheckboxModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTable, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Store } from '@ngrx/store';
import { BehaviorSubject, bufferCount, combineLatest, concat, firstValueFrom, Observable, of, switchMap } from 'rxjs';
import { map, skip, startWith, take } from 'rxjs/operators';
import { ARLTemplateTypeEntity } from '../../../../dave-data-module/entities/a-r-l-template-type.entity';
import { AccountsReceivableLedgerTemplateEntity, ARLCustomProperties } from '../../../../dave-data-module/entities/accounts-receivable-ledger-template.entity';
import { ARLTypeEnumNames } from '../../../../dave-data-module/entities/accounts-receivable-ledger.entity';
import { DaveMutationChangeAccountsReceivableLedgerTemplateArgs } from '../../../../dave-data-module/graphql-types';
import { ARLTemplateTypeResolver } from '../../../../dave-data-module/guards/a-r-l-template-type.resolver';
import { AccountsReceivableLedgerTemplateResolver } from '../../../../dave-data-module/guards/accounts-receivable-ledger-template.resolver';
import { CommissionTypeResolver } from '../../../../dave-data-module/guards/commissionType.resolver';
import { QuantityTypeResolver } from '../../../../dave-data-module/guards/quantity-type.resolver';
import { currencies } from '../../../../dave-data-module/helper/helper';
import { ArlTemplateDataService } from '../../../../dave-data-module/services/arl-template-data.service';
import { State } from '../../../../dave-data-module/State';
import { AccountsReceivableLedgerTemplateActionTypes, ARLTemplateTypeActions } from '../../../../dave-data-module/State/actions/accounting.actions';
import { getAccountsReceivableLedgerTemplateDictionary, getAccountsReceivableLedgerTemplates } from '../../../../dave-data-module/State/selectors/accounting.selector';
import { AppButtonModule } from '../../../../dave-utils-module/app-button-module/app-button.module';
import { AppDialogService } from '../../../../dave-utils-module/app-dialog-module/app-dialog.service';
import { LengthPipe } from '../../../../helper/length.pipe';
import { ArlCalculationDataService } from '../arl-calculation-data.service';
import { ArlCalculationViewComponent } from '../arl-calculation-view/arl-calculation-view.component';
import { ArlTableDetailRowComponent } from '../arl-table-detail-row/arl-table-detail-row.component';
import { ArlTemplateCalculationViewComponent } from '../arl-template-calculation-view/arl-template-calculation-view.component';
import { ArlTemplateFormDataService } from '../arl-template-form-data.service';
import { ArlTemplateTableDetailRowComponent } from '../arl-template-table-detail-row/arl-template-table-detail-row.component';
import { ArlTemplateTableDataSource, ArlTemplateTableItem } from './arl-template-table-datasource';

@Component({
    selector: 'app-arl-template-table',
    templateUrl: './arl-template-table.component.html',
    styleUrls: ['./arl-template-table.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatTableModule,
        MatPaginatorModule,
        AsyncPipe,
        MatSortModule,
        MatCheckboxModule,
        MatPseudoCheckboxModule,
        NgForOf,
        FontAwesomeModule,
        MatTooltipModule,
        NgIf,
        MatButtonModule,
        AppButtonModule,
        ArlTableDetailRowComponent,
        LengthPipe,
        ArlCalculationViewComponent,
        ArlTemplateTableDetailRowComponent,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatOptionModule,
        MatSelectModule,
        CurrencyPipe,
        MatAutocompleteModule,
    ],
    providers: [ArlTemplateFormDataService],
})
export class ArlTemplateTableComponent implements AfterViewInit {
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;
    @ViewChild(MatTable) table!: MatTable<ArlTemplateTableItem>;
    dataSource: ArlTemplateTableDataSource;

    @ViewChildren(ArlTemplateTableDetailRowComponent) detailRows: QueryList<ArlTemplateTableDetailRowComponent>;

    private _filter = new BehaviorSubject<string>('');
    private _commissionTypeIdFilter = new BehaviorSubject<number | null>(null);
    private _arlTemplateTypeIdFilter = new BehaviorSubject<number | null>(null);
    private _noCommissionTypeAndNoArlTemplateType = new BehaviorSubject<boolean>(false);
    @Input() set filterQuery(v: string) {
        if (v !== this._filter.value) {
            this._filter.next(v || '');
        }
    }
    @Input() set filterCommissionType(id: number) {
        if ((id || null) !== this._commissionTypeIdFilter.value) {
            this._commissionTypeIdFilter.next(id || null);
        }
    }
    @Input() set filterARLTemplateType(id: number) {
        if ((id || null) !== this._arlTemplateTypeIdFilter.value) {
            this._arlTemplateTypeIdFilter.next(id || null);
        }
    }
    @Input() set filterNoCommissionTypeAndNoArlTemplateType(v: boolean) {
        if ((v || false) !== this._noCommissionTypeAndNoArlTemplateType.value) {
            this._noCommissionTypeAndNoArlTemplateType.next(v || false);
        }
    }

    @Input() disabledForMultiselect: number[] = [];

    @Input() selectedArlTemplates: number[] = [];
    @Output() selectedArlTemplatesChange = new EventEmitter<number[]>();
    protected expandedRow: number | null = null;
    @Input() set multiselect(v: boolean) {
        if (v && !this.displayedColumns.includes('checkbox')) {
            this.displayedColumns.unshift('checkbox');
        } else if (!v && this.displayedColumns.includes('checkbox')) {
            this.displayedColumns = this.displayedColumns.filter((v) => v !== 'checkbox');
        }
        if (!v && !this.displayedColumns.includes('expand')) {
            this.displayedColumns.push('expand');
        } else if (v && this.displayedColumns.includes('expand')) {
            this.displayedColumns = this.displayedColumns.filter((v) => v !== 'expand');
        }
    }
    public someDetailRowDirty$: Observable<boolean>;

    /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
    displayedColumns = ['BookingText', 'TemplateType', 'Information', 'Quantity', 'QuantityType', 'BaseCost', 'SumNetto', 'Hint', 'IsVisible', 'InheritFromChildren']; // ['Information', 'BookingText'];

    constructor(
        @Inject(LOCALE_ID) locale: string,
        private store: Store<State>,
        arlTemplateFormDataService: ArlTemplateFormDataService,
        protected arlCalculationDataService: ArlCalculationDataService,
        private appDialog: AppDialogService,
        private arlTemplateDataService: ArlTemplateDataService,
        injector: Injector,
        resAccountsReceivableLedgerTemplates: AccountsReceivableLedgerTemplateResolver,
        resQuantityTypes: QuantityTypeResolver,
        resARLTemplateTypes: ARLTemplateTypeResolver,
        resCommissionTypes: CommissionTypeResolver,
    ) {
        this.dataSource = new ArlTemplateTableDataSource(store, locale, arlTemplateFormDataService, resAccountsReceivableLedgerTemplates, resQuantityTypes, resARLTemplateTypes, resCommissionTypes);
        Object.values(ArlTemplateCalculationViewComponent.RequiredResolvers).forEach((resolver) => {
            injector.get(resolver).resolve();
        });
    }

    ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filter = this._filter;
        this.dataSource.commissionTypeIdFilter = this._commissionTypeIdFilter;
        this.dataSource.arlTemplateTypeIdFilter = this._arlTemplateTypeIdFilter;
        this.dataSource.noCommissionTypeAndNoArlTemplateType = this._noCommissionTypeAndNoArlTemplateType;

        this.table.dataSource = this.dataSource;
        this.table.trackBy = (index: number, item: ArlTemplateTableItem) => item.Id;
        this.someDetailRowDirty$ = this.table.contentChanged.pipe(
            startWith(null),
            switchMap(() => (this.detailRows.length ? combineLatest(this.detailRows.map((r) => r.someFormDirty$)) : of([]))),
            map((v) => v.some((d) => !!d)),
        );
    }

    isSelected(Id: number) {
        return this.selectedArlTemplates.includes(Id);
    }
    selectionChange(event: MatCheckboxChange, Id: number) {
        if (event.checked && !this.isSelected(Id)) {
            this.selectedArlTemplates.push(Id);
            this.selectedArlTemplatesChange.emit(this.selectedArlTemplates.slice());
        } else if (!event.checked && this.isSelected(Id)) {
            this.selectedArlTemplates.splice(this.selectedArlTemplates.indexOf(Id), 1);
            this.selectedArlTemplatesChange.emit(this.selectedArlTemplates.slice());
        }
    }
    selectAll(event: MatCheckboxChange) {
        firstValueFrom(this.dataSource.data$).then(data => {
            const startIndex = this.paginator?.pageIndex * this.paginator?.pageSize ;
            const pageData = data.filter((d=>
                this._arlTemplateTypeIdFilter?.value ? d.ARLTemplateTypeId === this._arlTemplateTypeIdFilter?.value : d &&
                this._commissionTypeIdFilter?.value ?  d.CommissionTypeId === this._commissionTypeIdFilter?.value : d )).slice().splice(startIndex, this.paginator?.pageSize);
            if (event.checked ) {
                this.selectedArlTemplates.push(...pageData.filter(d => !this.isSelected(d.Id) && !this.isDisabled(d.Id)).map(d => d.Id));
            } else {
                this.selectedArlTemplates = this.selectedArlTemplates.filter(id => !pageData.some(d => d.Id === id));
            }
            this.selectedArlTemplatesChange.next(this.selectedArlTemplates.slice());
        });
    }
    isAllSelected() {
        return this.dataSource.data$.pipe(
            map((data) => {
                if (this.paginator) {
                    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
                    const pageData = data.slice().splice(startIndex, this.paginator.pageSize)
                    return pageData.every(d => this.isSelected(d.Id));
                }}),
        );
    }
    expandRow(Id: number | null) {
        if (this.expandedRow !== Id) {
            this.expandedRow = Id;
        } else {
            this.expandedRow = null;
        }
    }
    isExpanded(Id: number) {
        return this.expandedRow === Id;
    }

    onAbortClick(element: ArlTemplateTableItem) {
        element.form.reset();
        this.expandRow(null);
    }
    onDuplicateClick(element: ArlTemplateTableItem) {
        alert('not implemented');
    }
    onDeleteClick(element: ArlTemplateTableItem) {
        if (element.entity$) {
            firstValueFrom(element.entity$).then((entity) => {
                if (entity && !entity.DeletedAt) {
                    this.appDialog
                        .OpenConfirmationDialog({ paragraph: `${entity.Type ? ARLTypeEnumNames.get(entity.Type) : 'Vorlage'} ${entity.Information || entity.BookingText} wirklich löschen?`, styleDelete: true })
                        .subscribe(([res]) => {
                            if (res) {
                                this.store.dispatch(AccountsReceivableLedgerTemplateActionTypes.DeleteAccountsReceivableLedgerTemplate({ Payload: { id: entity.Id } }));
                                this.expandRow(null);
                            }
                        });
                }
            });
        }
    }
    getChangeMutationPayload(element: ArlTemplateTableItem): Promise<DaveMutationChangeAccountsReceivableLedgerTemplateArgs> {
        const types$ = firstValueFrom(this.arlCalculationDataService.arlTemplateTypes$).then((types) => {
            if (!element.form.value.TemplateTypeName?.trim() || types.some((t) => t.Name === element.form.value.TemplateTypeName.trim())) {
                return types;
            }
            this.store.dispatch(
                ARLTemplateTypeActions.AddARLTemplateType({
                    Payload: {
                        name: element.form.value.TemplateTypeName.trim(),
                    },
                }),
            );
            return firstValueFrom(
                this.arlCalculationDataService.arlTemplateTypes$
                    // aktuellen Wert überspringen und auf Response warten -
                    // mit oder ohne neuem ARLTemplateType, siehe AddARLTemplateType Effect
                    .pipe(skip(1)),
            );
        });
        return firstValueFrom(combineLatest([element.entity$, types$])).then(([entity, templateTypes]) => {
            const type: ARLTemplateTypeEntity | null | undefined = element.form.value.TemplateTypeName?.trim() ? templateTypes.find((t) => t.Name === element.form.value.TemplateTypeName.trim()) : null;
            const additionalData: ARLCustomProperties = { ...entity.CustomProperties, ZuschlaegeDefault: element.form.value.ZuschlaegeDefault, ZuschlaegeResourcen: element.form.value.ZuschlaegeResourcen };
            return {
                id: element.Id,
                // amount: <- rechnet das Backend aus
                information: element.form.value.Information,
                bookingText: element.form.value.BookingText,
                baseCost: element.form.value.BaseCost || 0,
                quantity: element.form.value.Quantity || 0,
                quantityTypeId: element.form.value.QuantityTypeId,
                isVisible: true, //!element.form.value.InheritFromChildren || element.form.value.IsVisible,
                inheritFromChildren: element.form.value.InheritFromChildren,
                arlTemplateIds: element.form.value.ARLIds,
                parentId: element.form.value.ParentId,
                customProperties: JSON.stringify(additionalData),
                // todo add type wenn be ready
                tax: element.form.value.Tax || 0,
                currencyCode: element.form.value.CurrencyCode,

                multiplikator: entity.Multiplier,
                showLongInformation: element.form.value.ShowLongtext || false,
                longInformation: element.form.value.Longtext,

                aRLTemplateTypeId: type?.Id || null,
            };
        });
    }
    isDisabled(id: number) {
        return this.disabledForMultiselect.includes(id);
    }
    disableIfAllSelected(){
        return this.dataSource.data$.pipe(
            map((data) => {
                if (this.paginator) {
                    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
                    const pageData = data.slice().splice(startIndex, this.paginator.pageSize)
                    return pageData.every(d => this.isDisabled(d.Id) || this._filter.value);
                }}),
        );
    }
    onSaveClick(element: ArlTemplateTableItem) {
        if (element.form.valid) {
            this.getChangeMutationPayload(element).then((Payload) => {
                console.log('startAction');
                this.store.dispatch(AccountsReceivableLedgerTemplateActionTypes.ChangeAccountsReceivableLedgerTemplate({ Payload }));
                // if erfolgreich
                element.form.markAsPristine();
            });
        }
    }
    Submit(): Promise<{ someWasChanged: boolean; arls: AccountsReceivableLedgerTemplateEntity[] }> {
        console.log(this.detailRows, this.detailRows.length);
        return firstValueFrom(this.detailRows.length ? combineLatest(this.detailRows.map((detailRow) => detailRow.Submit())) : of([])).then(() => {
            return firstValueFrom(combineLatest([this.dataSource.data$, this.store.select(getAccountsReceivableLedgerTemplateDictionary)])).then(([tableData, arlsFromState]) => {
                if (tableData.length === 0) {
                    return { someWasChanged: false, arls: [] };
                }
                const toChange: ArlTemplateTableItem[] = [];
                const toReturn: AccountsReceivableLedgerTemplateEntity[] = [];
                tableData.forEach((td) => {
                    if (td.form.dirty) {
                        toChange.push(td);
                    } else {
                        toReturn.push(arlsFromState[td.Id]);
                    }
                });
                const markAllPristine = () => {
                    tableData.forEach((td) => {
                        if (td.form.dirty) {
                            td.form.markAsPristine(); // (dirty) fix bug, beim speichern klicken werden die groups dirty, die controls darin aber nicht
                        }
                    });
                };
                console.log({ toChange, toReturn });
                return toChange.length
                    ? firstValueFrom(concat(...toChange.map((td) => this.getChangeMutationPayload(td))).pipe(bufferCount(toChange.length)))
                          .then((payloads) => this.arlTemplateDataService.changeArlTemplatess(payloads))
                          .then((changed) => {
                              markAllPristine();
                              return { someWasChanged: true, arls: [...changed, ...toReturn] };
                          })
                    : { someWasChanged: false, arls: toReturn };
            });
        });
    }
    onAmountCalculated(element: ArlTemplateTableItem, amount: number) {
        console.log('onAmountCalculated', element, amount);
        // element.calculatedAmount.setValue(amount);
        if (element.form.controls.InheritFromChildren.value) {
            element.form.controls.BaseCost.setValue(amount);
        }
    }
    deleteArlTemplateType(option: ARLTemplateTypeEntity) {
        this.appDialog.OpenConfirmationDialog({ paragraph: `Vorlage ${option.Name} wirklich löschen?`, styleDelete: true }).subscribe(([result]) => {
            if (result) {
                this.store
                    .select(getAccountsReceivableLedgerTemplates)
                    .pipe(
                        map((arlts) => arlts.filter((arlt) => arlt.ARLTemplateTypeId == option.Id)),
                        take(1),
                    )
                    .subscribe((val) => {
                        val.map((at) => {
                            this.store.dispatch(
                                AccountsReceivableLedgerTemplateActionTypes.ChangeAccountsReceivableLedgerTemplate({
                                    Payload: {
                                        id: at.Id,
                                        quantity: at.Quantity,
                                        tax: at.Tax,
                                        currencyCode: at.CurrencyCode,
                                        bookingText: at.BookingText,
                                        baseCost: at.BaseCost,
                                        information: at.Information,
                                        quantityTypeId: at.QuantityTypeId,
                                        multiplikator: at.Multiplier,
                                        customProperties: JSON.stringify(at.CustomProperties),
                                        aRLTemplateTypeId: null,
                                    },
                                }),
                            );
                        });
                    });
                this.store.dispatch(
                    ARLTemplateTypeActions.DeleteARLTemplateType({
                        Payload: {
                            id: option.Id,
                        },
                    }),
                );
            }
        });
    }

    protected readonly currencies = currencies;
}
