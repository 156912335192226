<app-detail-view-template *ngIf="Data$ | async as data; else loadingTemplate">
    <ng-container *appDetailViewButtons>
        <button
            *ngIf="!Editing && !data.customer?.Deleted && (PS.Has(PS.Permission.EditEvents) | async)"
            app-round-button
            Inverted
            class="header-button"
            matTooltip="Bearbeiten"
            aria-label="Bearbeiten"
            (click)="OpenEdit()"
        >
            <fa-icon icon="pencil-alt"></fa-icon>
        </button>

        <button *ngIf="Editing" app-round-button Inverted class="header-button" matTooltip="Zurücksetzen"
                aria-label="Zurücksetzen" (click)="CloseEdit()">
            <fa-icon icon="undo"></fa-icon>
        </button>

        <button *ngIf="Editing" app-round-button [class.save-active]="Editing" class="header-button" matTooltip="Speichern"
                aria-label="Speichern" (click)="Save()">
            <fa-icon icon="save"></fa-icon>
        </button>
        <button app-round-button Inverted class="header-button" matTooltip="Aufgabe Erstellen"
                aria-label="Aufgabe Erstellen" (click)="CreateTaskDialog()">
            <fa-icon icon="clipboard-check"></fa-icon>
        </button>
        <button *ngIf="Email$ | async as email" app-round-button Inverted class="header-button"
                matTooltip="Text der E-Mail anzeigen" aria-label="Text der E-Mail anzeigen" (click)="OpenMailText(email)">
            <fa-icon icon="envelope"></fa-icon>
        </button>
        <button
            class="header-button"
            Inverted
            app-round-button
            matTooltipPosition="left"
            matTooltip="Antworten"
            aria-label="Antworten"
            *ngIf="Email$ | async as email"
            (click)="
                OpenEmailEditor$.next({
                    TargetEmails: [email.From],
                    EmailSubject: 'Re: ' + email.Subject,
                    EmailId: email.Id,
                    EmailSourceConnectionId: email.EmailConnectionId
                })
            "
        >
            <fa-icon icon="reply"></fa-icon>
        </button>
        <button app-round-button Inverted class="header-button" matTooltip="E-Mail senden" aria-label="E-Mail senden"
                (click)="OpenMailDialog$.next()">
            <fa-icon icon="paper-plane"></fa-icon>
        </button>
        <button
            *ngIf="!Editing"
            app-round-button
            Inverted
            class="header-button"
            [Color]="data.isBookmarked && 'highlighted'"
            [matTooltip]="'Lesezeichen ' + (data.isBookmarked ? 'entfernen' : 'hinzufügen')"
            aria-label="{{'Lesezeichen ' + (data.isBookmarked ? 'entfernen' : 'hinzufügen')}}"
            (click)="BookmarkClicked.next()"
        >
            <fa-icon [icon]="[data.isBookmarked ? 'fas' : 'far', 'bookmark']"></fa-icon>
        </button>
        <button
            *ngIf="!(Folder$ | async)"
            [Disabled]="UploadDialog?.getState() === MatDialogStateOpen"
            app-round-button
            Inverted
            class="header-button"
            matTooltip="Dokument hochladen"
            aria-label="Dokument hochladen"
            (click)="PreviewOpen = false; OpenUploadDialog()"
        >
            <fa-icon icon="upload"></fa-icon>
        </button>
        <button
            *ngIf="!data.customer?.Deleted && Editing"
            app-round-button
            [class.delete-active]="Editing"
            class="header-button"
            [Color]="data.isOwnEvent && 'red'"
            [Disabled]="!data.isOwnEvent"
            [matTooltip]="data.isOwnEvent ? 'Löschen' : 'Sie sind nicht berechtigt, dieses Ereignis zu löschen'"
            aria-label="Löschen"
            (click)="DeleteEvent()"
        >
            <fa-icon icon="trash"></fa-icon>
        </button>
    </ng-container>

    <ng-container *appDetailViewLeftContent>
        <mat-card class="event-info-card detail-card">
            <div style="display: inline-block; width: 100%;">
                <p class="text" *ngIf="!Editing && data.customer">
                    <fa-icon class="icon" icon="building"></fa-icon>
                    <a class="link" [routerLink]="['/kunden', data.customer.Id]">{{ data.customer.Name }}</a>
                </p>
                <p class="text" *ngIf="data.commission?.GetDisplayName() && !Editing">
                    <fa-icon class="icon" [icon]="CommissionIcon"></fa-icon>
                    {{ data.commission.GetDisplayName() }}
                </p>
                <p class="text" *ngIf="(process$ | async) && !Editing">
                    <fa-icon class="icon" [icon]="ProcessMeta.Icon"></fa-icon>
                    {{ (process$ | async)?.DisplayName }}
                </p>
                <p class="text" *ngIf="data?.event?.Name && !Editing">
                    <fa-icon class="icon" [icon]="'info'"></fa-icon>
                    Name: {{ data.event.Name }}
                </p>

                <p class="text" *ngIf="PS.Has(PS.Permission.GetUser) | async">
                    <fa-icon class="icon" icon="user"></fa-icon>
                    {{ data.eventUserDisplayName }}
                </p>
                <app-select-search-legacy
                    class="hover-form"
                    *ngIf="Editing && Customers$ | async as customers"
                    [Values]="customers"
                    [Placeholder]="CS.GetSingle$() | async"
                    [Control]="CustomerFormControl"
                ></app-select-search-legacy>

                <p class="text event-description" *ngIf="!Editing">
                    <ng-container *ngIf="!Editing">
                        <fa-icon *ngIf="TmpEventType.ImagePath; else letterTemplate" class="icon"
                                 [icon]="TmpEventType.ImagePath | icon"></fa-icon>

                        <ng-template #letterTemplate>
                            <span class="icon event-type-letter">{{ TmpEventType.Name[0] }}</span>
                        </ng-template>

                        <span
                        >{{ TmpEventType.IsDefault ? '' : TmpEventType.Name + ' '
                            }}{{ data.contactPerson || data.email?.From ? 'mit' : TmpEventType.IsDefault ? TmpEventType.Name : '' }}</span
                        >
                    </ng-container>

                    <ng-container *ngIf="data.contactPerson?.Image; else contactPersonIconTemplate">
                        <img *ngIf="data.contactPerson?.Image | image | async as image; else imageLoadingTemplate"
                             class="contact-person-image" [src]="image"/>

                        <ng-template #imageLoadingTemplate>
                            <fa-icon class="contact-person-image" icon="spinner" size="2x" spin="true"></fa-icon>
                        </ng-template>
                    </ng-container>

                    <ng-template #contactPersonIconTemplate>
                        <fa-icon *ngIf="data.contactPerson" class="contact-person-image" size="2x"
                                 [icon]="data.contactPerson?.Deleted ? 'user-slash' : 'user'"></fa-icon>
                    </ng-template>

                    <a
                        *ngIf="data.customer && data.contactPerson; else authorNameTemplate"
                        class="link"
                        [routerLink]="['/kunden', data.contactPerson.CustomerId, data.contactPerson.Id]"
                    >{{ data.contactPerson.DisplayName }}{{ data.contactPerson.Deleted ? ' (gelöscht)' : '' }}</a
                    >
                    <ng-template #authorNameTemplate>
                        <a class="link">{{ data.email?.From }}</a>
                    </ng-template>
                    <button
                        *ngIf="data.email?.To?.length > 0 || data.email?.CarbonCopy?.length > 0 || data.email?.BlindCarbonCopy?.length > 0"
                        app-round-button
                        matTooltip="Empfänger anzeigen"
                        (click)="ExpandRecievers = !ExpandRecievers"
                    >
                        <fa-icon [icon]="ExpandRecievers ? 'chevron-up' : 'chevron-down'"></fa-icon>
                    </button>
                </p>
                <table *ngIf="ExpandRecievers && !Editing" class="reciever-table">
                    <tr *ngIf="data.email?.To?.length > 0">
                        <td>Empfänger:</td>
                        <td>{{ data.email?.To }}</td>
                    </tr>
                    <br/>
                    <tr *ngIf="data.email?.CarbonCopy?.length > 0">
                        <td>CC:</td>
                        <td>{{ data.email?.CarbonCopy }}</td>
                    </tr>
                    <br/>
                    <tr *ngIf="data.email?.BlindCarbonCopy?.length > 0">
                        <td>BCC:</td>
                        <td>{{ data.email?.BlindCarbonCopy }}</td>
                    </tr>
                    <br/>
                </table>

                <app-select-search-legacy
                    class="hover-form"
                    *ngIf="Editing && ContactPersons$ | async as contactPersons"
                    [Values]="contactPersons"
                    [Placeholder]="Customer?.AnsprechpartnerText() || 'Ansprechpartner/Sachbearbeiter'"
                    [Control]="PersonFormControl"
                    [ValuesAreSorted]="true"
                ></app-select-search-legacy>
                <app-select-search-legacy
                    class="hover-form"
                    *ngIf="Editing && Commissions$ | async as commissions"
                    [Values]="commissions"
                    [Placeholder]="cls.getSingle$('Commission') | async"
                    [Control]="CommissionForm"
                ></app-select-search-legacy>
                <mat-form-field  *ngIf="Editing" class="hover-form" style="width: 100%;">
                    <app-select-search placeholder="Prozess" [formControl]="EventForm.controls.Process" [Options]="ProcessList$ | async" [CompareOptions]="compareById"></app-select-search>
                    <fa-icon matSuffix icon="times" style="margin-left: 3px; cursor: pointer;" (click)="EventForm.controls.Process.setValue(null);$event.stopPropagation();" *ngIf="!EventForm.controls.Process.disabled"></fa-icon>
                    <mat-error *ngIf="EventForm.controls.Process.invalid">
                        {{ getErrorMessage(EventForm.controls.Process) }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="hover-form" *ngIf="Editing"  style="width: 100%;">
                    <input matInput type="text" placeholder="Name" name="name" [formControl]="NameForm"/>
                </mat-form-field>
                <p class="text event-date" id="pClass">
                    <ng-container *ngIf="Editing; else notEditingTemplate">
                        <mat-form-field class="form-field hover-form">
                            <mat-label>Datum</mat-label>
                            <mat-date-range-input [rangePicker]="picker" required>
                                <input matStartDate placeholder="Start" [(ngModel)]="TmpEventDate" required>
                                <input matEndDate placeholder="Ende" [(ngModel)]="TmpEventEndDate">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker></mat-date-range-picker>
                        </mat-form-field>

                        <mat-form-field class="form-field hover-form" *ngIf="!TmpEventEndDate || (TmpEventDate && sameDay(TmpEventDate.toDate(), TmpEventEndDate.toDate()))">
                            <mat-label>Zeitraum</mat-label>
                            <app-time-span-picker-select>
                                <app-time-picker-select appStartTime required placeholder="Start" [(ngModel)]="EventStartTimeString"></app-time-picker-select>
                                <app-time-picker-select appEndTime required placeholder="Ende" [min]="EventStartTimeString" [offsetTimeForOptionLabel]="EventStartTimeString" [(ngModel)]="EventEndTimeString"></app-time-picker-select>
                            </app-time-span-picker-select>
                        </mat-form-field>

                        <app-detail-list-template [Editing]="true" [Data]="AddressTemplateData$ | async" [Inline]="true"
                                                  style="width: 100%"></app-detail-list-template>
                    </ng-container>
                </p>

                <p>
                    <ng-template #notEditingTemplate>
                        <fa-icon class="icon" icon="calendar-day"></fa-icon>
                        <span>{{ data.event.EventDate | date }}</span>
                        <span
                            *ngIf="(data.event.EventDate | date) < (data.event.EventEndDate | date)"> bis {{ data.event.EventEndDate | date }} </span>
                        <br/>
                        <fa-icon class="icon" icon="star-half-alt" *ngIf="data.event?.State"></fa-icon>
                        <span>{{ data.event.State }}<br/></span>

                        <ng-container *ngIf="data.isMobile"><br/><br/></ng-container>
                        <br/>
                        <fa-icon class="icon" icon="clock"></fa-icon>
                        {{ (data.event.EventDate | date: 'shortTime') + data.eventDurationString }}
                        <br/>

                        <button class="ics-button" app-button (click)="DownloadICS(data.customer, data.contactPerson)">
                            Kalenderdatei Erstellen
                        </button>
                        <br *ngIf="data.event.Street || data.event.City || data.event.PostalCode || data.event.Country"/>
                        <span
                            *ngIf="data.event.Street || data.event.City || data.event.PostalCode || data.event.Country"
                            (click)="OpenMapDialog()" class="link-style">
                            <fa-icon icon="map-marker-alt" class="icon"></fa-icon>Adresse
                        </span>
                        <br *ngIf="data.event.Street && data.event.Street !== ''"/>
                        <span class="link-style" style="margin-left: 3rem;"
                              (click)="OpenMapDialog()">{{ data.event.Street }}</span>
                        <br *ngIf="data.event.PostalCode && data.event.City"/>
                        <span class="link-style" style="margin-left: 3rem;"
                              (click)="OpenMapDialog()">{{ data.event.PostalCode }} {{ data.event.City }}</span>
                        <br *ngIf="data.event.Country && data.event.Country !== ''"/>
                        <span class="link-style" style="margin-left: 3rem;"
                              (click)="OpenMapDialog()">{{ data.event.Country }}</span>
                    </ng-template>
                </p>

                <p class="text" *ngIf="data.isOwnEvent && !Editing">
                    <fa-icon class="icon" icon="eye-slash" *ngIf="data.event.Private"></fa-icon>
                    <fa-icon class="icon" icon="eye" *ngIf="!data.event.Private"></fa-icon>
                    {{ data.event.Private ? 'Privat' : 'Öffentlich' }}
                </p>

                <mat-slide-toggle class="text hover-form" *ngIf="data.isOwnEvent && Editing"
                                  [formControl]="PrivateForm">Privat
                </mat-slide-toggle>
            </div>
            <div class="links" *ngIf="!Editing">
                <div class="admin-button-wrapper link-wrapper" *ngIf="data.customer">
                    <a app-button Lighter class="wide-button" matTooltip="Kunden"
                       [routerLink]="['/', CustomerPath, data.customer.Id]">
                        <fa-icon [icon]="CustomerIcon"></fa-icon>
                    </a>
                </div>
                <div class="commission-button-wrapper link-wrapper"
                     *ngIf="data.commission && PS.Has(PS.Permission.GetCommission) | async">
                    <a
                        app-button
                        Lighter
                        class="wide-button"
                        [matTooltip]="cls.getMultiple$('Commission') | async"
                        [routerLink]="['/', CommissionPath, data.customer?.Id || AllCommissionPath, data.commission.Id]"
                    >
                        <fa-icon [icon]="CommissionIcon"></fa-icon>
                    </a>
                </div>
                <div class="dms-button-wrapper link-wrapper" *ngIf="PS.Has$(PS.Permissions.GetFiles) | async">
                    <a app-button Lighter class="wide-button" matTooltip="DMS" [routerLink]="['/', DMSPath]">
                        <fa-icon [icon]="DMSIcon"></fa-icon>
                    </a>
                </div>
                <div class="theme-override link-wrapper" [class]="ProcessMeta.Theme" *ngIf="PS.Has$(PS.Permissions.CanSeeProcesses) | async">
                    <a app-button Lighter *ngIf="(Event$ | async)?.ProcessId as processId" class="wide-button" matTooltip="Prozess" [routerLink]="['/', ProcessMeta.Path, AllProcessMeta.Path, processId]">
                        <fa-icon [icon]="ProcessMeta.Icon"></fa-icon>
                    </a>
                </div>
            </div>
        </mat-card>

        <app-event-types-selector-card
            *ngIf="Editing"
            [CurrentEventType]="TmpEventType"
            [EventTypeList]="data.eventTypes"
            (EventTypeChange)="OnEventTypeChange($event)"
        ></app-event-types-selector-card>

        <mat-card class="note detail-card">
            <h3>
                <fa-icon icon="sticky-note"></fa-icon>
                Notiz
            </h3>

            <app-textarea *ngIf="Editing; else markdownTemplate" [Value]="TmpDescription"
                          [Fill]="!data.isMobile"></app-textarea>

            <ng-template #markdownTemplate>
                <ngx-simplebar>
                    <markdown class="markdown"
                              [data]="Linkify(data.event.Description?.replace(StyleTagRegEx, ''))"></markdown>
                </ngx-simplebar>
            </ng-template>
        </mat-card>
        <app-file-explorer
            PreventFileClick
            PreventFileUploadClick
            class="detail-card mat-card"
            *ngIf="Folder$ | async as folder"
            [SelectedFolderId]="folder.Id"
            [FileUploadButton]="true"
            (FileUploadClicked)="PreviewOpen = false; OpenUploadDialog($event)"
            (FileClicked)="PreviewedFileId$.next($event); PreviewOpen = true"
            (fileExplorerContext) = "ImageIds = $event"
        >
        </app-file-explorer>
    </ng-container>

    <ng-container *appDetailViewRightContent>
        <mat-card *ngIf="PreviewOpen" class="new-document-overlay">
            <div style="height: 100%;">
                <button app-round-button class="back-button" (click)="PreviewOpen = false">
                    <fa-icon icon="chevron-right"></fa-icon>
                </button>
                <app-preview *ngIf="PreviewedFileId$ | async as id" [FileId$]="PreviewedFileId$" [fileExplorerContext]="ImageIds"
                             (ClosePreview)="PreviewOpen = false" class="preview"></app-preview>
            </div>
        </mat-card>
        <app-dave-list-card
            class="detail-card"
            *ngIf="!Editing"
            Headline="Bearbeiter"
            HeaderIcon="users"
            EmptyText="Keine Bearbeiter vorhanden"
            [Data]="ShowBearbeiterList$"
            [DisplayedCollums]="['label']"
            [Clickable]="true"
            [GetRouterLinkFunction]="GetEmployeeRouterLink"
            [IsExpandable]="true"
            [Expanded]="true"
            style="max-height: 50vh"
        >
            <button app-round-button headerButton matTooltipPosition="left" matTooltip="Hinzufügen" (click)="OpenUserSelect()"
                    [Disabled]="!(PS.Has$(PS.Permissions.EditEvents) | async)">
                <app-double-icon Icon="user" Icon2="plus"></app-double-icon>
            </button>
        </app-dave-list-card>
        <app-dave-list-card
            class="detail-card"
            *ngIf="!Editing"
            Headline="Beteiligte Personen"
            HeaderIcon="address-book"
            EmptyText="Keine Beteiligte Personen vorhanden"
            [Data]="ShowBeteiligteList$"
            [DisplayedCollums]="['custom']"
            [Clickable]="true"
            [GetRouterLinkFunction]="GetPersonRouterLink"
            [IsExpandable]="true"
            [Expanded]="true"
            [CustomCellTemplates]="{custom: personListEntry}"
            style="max-height: 50vh"
        >
            <button
                headerButton
                app-round-button
                matTooltipPosition="left"
                matTooltip="Hinzufügen"
                (click)="OpenPersonDialog('Beteiligte Personen')"
                [Disabled]="!(PS.Has(PS.Permission.EditEvents) | async)"
            >
                <app-double-icon Icon="user" Icon2="plus"></app-double-icon>
            </button>
        </app-dave-list-card>
    </ng-container>
</app-detail-view-template>

<ng-template #loadingTemplate>
    <app-loading></app-loading>
</ng-template>

<ng-template #personListEntry let-data="data">
    <app-person-list-entry [Person]="data"></app-person-list-entry>
</ng-template>
