<mat-expansion-panel hideToggle *ngIf="Data || (Event$ | async) as task" class="panel" #panel>
    <mat-expansion-panel-header
    style="background: transparent; min-height: 5.75rem;"
    [style.height]="task.Event.Name && task.Event.From && task.Event.To ? '6.75rem' : ''"
    >
        <div class="click-wrapper" (click)="PanelClicked($event, task.Event.Id, task.Event.IsTask)">
            <div class="annotations primary">
                <div [matTooltip]="task.Priority.name" *ngIf="task.Priority" style="margin-right: auto">
                    <fa-icon [icon]="task.Priority.icon" [style.color]="task.Priority.color"></fa-icon>
                </div>
                <div class="files " *ngIf="task.Event.FileCount">{{ task.Event.FileCount }} <fa-icon icon="paperclip"></fa-icon></div>
                <div [matTooltip]="task.Event.Description" matTooltipClass="whitespace-pre-wrap" *ngIf="task.Event.Description?.length && !task.HasComment">
                    <fa-icon icon="sticky-note"></fa-icon>
                </div>
                <div *ngIf="task.HasComment">
                    <fa-icon icon="comment"></fa-icon>
                </div>
                <div [matTooltip]="'E-Mail anzeigen'" *ngIf="task.Event.EmailId" (click)="ShowEmail(task.Event); $event.stopPropagation()">
                    <fa-icon icon="envelope"></fa-icon>
                </div>
                <div [matTooltip]="'Zum Ereignis'" *ngIf="task.Event.AdditionalData?.ParentEventId" (click)="RouteTo(task.Event.AdditionalData.ParentEventId)">
                    <fa-icon [icon]="ChronikIcon | icon"></fa-icon>
                </div>
                <div
                    class="bookmark"
                    (click)="$event.stopPropagation(); BookmarkClicked(task.Event.Id)"
                    [matTooltip]="'Lesezeichen ' + ((Bookmark$ | async) ? 'entfernen' : 'hinzufügen')"
                    matTooltipPosition="above"
                    *ngIf="!(bookmarkLoading$ | async); else bookmarkLoading"
                >
                    <fa-icon [icon]="(Bookmark$ | async) ? 'bookmark' : ['far', 'bookmark']"></fa-icon>

                </div>
                <ng-template #bookmarkLoading>
                    <mat-progress-spinner mode="indeterminate" diameter="12"></mat-progress-spinner>
                </ng-template>
            </div>
            <div class="icon primary">
                <fa-icon
                    *ngIf="task.EventType?.ImagePath || task.Event.IsTask"
                    [icon]="task.EventType?.ImagePath ? (task.EventType.ImagePath | icon) : ClipboardIcon"
                    [matTooltip]="task.EventType?.Name || 'Aufgabe'"
                    matTooltipPosition="left"
                ></fa-icon>
                <span *ngIf="!task.EventType?.ImagePath && !task.Event.IsTask" [matTooltip]="task.EventType?.Name" matTooltipPosition="left">
                    {{ task.EventType?.Name[0].toUpperCase() }}
                </span>
            </div>
            <div class="content-wrapper">
                <div class="event-details">
                    <ng-container *ngIf="!task.Event.IsTask">
                        {{ task.Event.EventDate | date: 'shortTime' }}<br />
                        <b *ngIf="task.Event.Name">{{ task.Event.Name }}</b>
                        <fa-icon *ngIf="task.Person?.Deleted" class="deleted-message" icon="user-slash"></fa-icon>
                        {{ task.Person ? task.Person.DisplayName : task.From ? task.From : task.Customer?.Name }}<br />
                        <i>{{ task.User?.DisplayName ? task.User?.DisplayName : task.To ? task.To : '' }}</i>
                    </ng-container>
                    <ng-container *ngIf="task.Event.IsTask">
                        Frist: {{ task.Event.EventEndDate | date }}<br />
                        <b *ngIf="task.Event.Name">{{ task.Event.Name }}</b>
                        <a *ngIf="task.Event.From">{{ task.Event.From }}</a>
                        <i style="text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">
                            <ng-container *ngFor="let user of task.Users; let i = index"> {{ i !== 0 ? ', ' : '' }}{{ user?.DisplayName || '' }} </ng-container>
                            <ng-container *ngIf="task.Users < 1 && task.Event.To">{{ task.Event.To }}</ng-container>
                        </i>
                    </ng-container>
                </div>
                <button mat-icon-button class="expansion-initiator" *ngIf="Expandable" (click)="$event.stopPropagation(); panel.toggle();"><fa-icon icon="chevron-down" [rotate]="panel.expanded ? 180 : undefined"></fa-icon></button>
            </div>
        </div>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
        <div style="display: flex">
            <button app-round-button matTooltip="Bearbeiten" style="margin-left: auto" (click)="OpenDetailTaskDialog(task.Event.Id)">
                <fa-icon icon="pencil-alt"></fa-icon>
            </button>
        </div>
        <label>Beschreibung:</label>
        <p style="white-space: break-spaces;">{{ task.Event.Description }}</p>
        <mat-action-list style="margin: .5rem 0; max-width: 100%;">
            <button mat-list-item *ngFor="let file of Files$ | async" (click)="FileClicked.emit(file.Id)" style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                <fa-icon [icon]="file.FileIcon" style="margin-right: .5rem"></fa-icon> {{ file.Name }}
            </button>
        </mat-action-list>
        <p>Autor: {{ task.User.DisplayName }}</p>
        <mat-chip-list *ngIf="!EditUser2Event">
            <fa-icon icon="user" matTooltip="Bearbeiter" style="margin-left: .455rem;"></fa-icon>
            <mat-chip [removable]="true" *ngFor="let user of task.Users" (removed)="RemoveUser2Event(user, task.Event)" (click)="RouteToEmployee(user.Id)">
                {{ user.DisplayName }}
                <fa-icon matChipRemove icon="times"></fa-icon>
            </mat-chip>
            <mat-chip style="margin-left: auto; cursor: pointer" color="primary" matTooltip="Bearbeiter zuweisen" (click)="EditUser2Event = true" [selectable]="true"
                ><fa-icon icon="plus"></fa-icon
            ></mat-chip>
        </mat-chip-list>
        <div *ngIf="EditUser2Event">
            <fa-icon icon="user" matTooltip="Bearbeiter"></fa-icon>
            <app-chip-autocomplete
                class="hover-form"
                [formControl]="task.User2EventData.formControl"
                [MapFn]="task.User2EventData.options.specialInput.chipAutocomplete.MapFn"
                [Options]="task.User2EventData.options.specialInput.chipAutocomplete.Options"
                (EntryClick)="RouteToEmployee($event.Id)"
                [Placeholder]="task.User2EventData.key"
                [ErrorMessage]="task.User2EventData.formControl.invalid ? GetErrorMessage(task.User2EventData.formControl) : ''"
                [initialPatchDefaultValue]="true"
            ></app-chip-autocomplete>
            <mat-chip
                style="float: right; cursor: pointer"
                color="primary"
                matTooltip="Bearbeiter speichern"
                (click)="SaveUserToEvent(task.User2EventData.formControl.value, task.Event)"
                [selectable]="true"
                ><fa-icon icon="save"></fa-icon
            ></mat-chip>
        </div>

        <mat-chip-list *ngIf="!EditPerson2Entity">
            <fa-icon icon="address-book" matTooltip="Beteiligte Personen" style="margin-left: .3rem;"></fa-icon>
            <mat-chip [removable]="true" *ngFor="let person of task.Persons" (removed)="RemovePerson2Entity(person)" (click)="RouteToPerson(person.Id)">
                {{ person.DisplayName }}
                <fa-icon matChipRemove icon="times"></fa-icon>
            </mat-chip>
            <mat-chip style="margin-left: auto; cursor: pointer" color="primary" matTooltip="Beteiligte Personen zuweisen" (click)="EditPerson2Entity = true" [selectable]="true"
                ><fa-icon icon="plus"></fa-icon
            ></mat-chip>
        </mat-chip-list>
        <div *ngIf="EditPerson2Entity" style="display: inline">
            <fa-icon icon="address-book" matTooltip="Beteiligte Personen"></fa-icon>
            <app-chip-autocomplete
                class="hover-form"
                [formControl]="task.Person2EntityData.formControl"
                [MapFn]="task.Person2EntityData.options.specialInput.chipAutocomplete.MapFn"
                [Options]="task.Person2EntityData.options.specialInput.chipAutocomplete.Options"
                (EntryClick)="RouteToPerson($event.Id)"
                [Placeholder]="task.Person2EntityData.key"
                [ErrorMessage]="task.Person2EntityData.formControl.invalid ? GetErrorMessage(task.Person2EntityData.formControl) : ''"
                [initialPatchDefaultValue]="true"
            ></app-chip-autocomplete>
            <mat-chip
                style="float: right; cursor: pointer"
                color="primary"
                matTooltip="Beteiligte Personen speichern"
                (click)="SavePersonToEvent(task.Person2EntityData.formControl.value, task.Event)"
                [selectable]="true"
                ><fa-icon icon="save"></fa-icon
            ></mat-chip>
        </div>
    </ng-template>
</mat-expansion-panel>
