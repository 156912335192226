import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
    selector: 'app-abbr',
    standalone: true,
    imports: [CommonModule, MatTooltipModule],
    templateUrl: './abbr.component.html',
    styleUrls: ['./abbr.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AbbrComponent {
    constructor() {}

    @Input() title: string;
}
