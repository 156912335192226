import { ResourceScheduleType } from '../../graphql-types';
import { BackendDate } from '../../helper/backend-frontend-conversion.helper';

export enum ResourceScheduleTypeEnum {
    Commission = 'COMMISSION',
    Defekt = 'DEFEKT',
    Wartung = 'WARTUNG'
}

export class ResourceScheduleEntity {
    constructor(
        public Id: number = null,
        public ArlId: number = null,
        public Amount: number = null,
        public ResourceId: number = null,
        public Type: string = null,
        public CommissionId: number = null,
        public StartDate: Date = null,
        public EndDate: Date = null,
        public UpdatedAt: Date = null,

    ) {}
    public static readonly GqlFields: Array<keyof ResourceScheduleType> = ['id', 'arlId', 'amount', 'resourceId', 'type', 'commissionId', 'startDate', 'endDate', 'updatedAt'];
    public Clone(override: Partial<ResourceScheduleEntity> = {}): ResourceScheduleEntity {
        const attr = { ...this, ...override };
        return Object.assign(new ResourceScheduleEntity(), attr);
    }
}

export function ResourceScheduleEntityFromBackend(res: ResourceScheduleType): ResourceScheduleEntity {
    console.log("Test", res)
    return new ResourceScheduleEntity(res.id, res.arlId, res.amount, res.resourceId, res.type, res.commissionId, BackendDate(res.startDate), BackendDate(res.endDate), BackendDate(res.updatedAt));
}
