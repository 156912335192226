import { AccountsReceivableLedgerTemplateType } from "../graphql-types";
import { BackendDate } from "../helper/backend-frontend-conversion.helper";
import { AccountsReceivableLedgerTypeEnum } from "./accounts-receivable-ledger.entity";

/**
 * Interface for custom properties for ARLs and ARL-Templates
 * @
 */
export interface ARLCustomProperties {
    jveg?: boolean;
    hint?: string;
    showMultiplier?: string;
    readonly?: boolean;
    partialInvoiceId?: number;
    timeTracking?: boolean;
    timeTrackingTemplate?: boolean;
    /**
     * Taxes: taxes for each tax class `
     */
    taxesFromPartialInvoice?: {
        tax: number;
        /**
         * Amount: in cent `
         */
        amount: number;
    }[];
    rootTemplateId?: number;
    ZuschlaegeDefault?: number;
    ZuschlaegeResourcen?: number;
    ZuschlaegeMaterials?: number;
    ZuschlaegeEmployee?: number;
    ZuschlaegeExternalService?: number;
    ZuschlaegeOther?: number;
}
export class AccountsReceivableLedgerTemplateEntity {
    constructor(
        public Id: number = null,
        public Tax: number = null,
        public CurrencyCode: string = null,
        public BookingText: string = null,
        public Information: string = null,
        public BaseCost: number = null,
        public PartnerId: number = null,
        public UserId: number = null,
        public CustomerId: number = null,
        public CommissionTypeId: number = null,
        public EventTypeId: number = null,
        public CreatedAt: Date = null,
        public UpdatedAt: Date = null,
        public DeletedAt: Date = null,
        public Quantity: number = null,
        public CostAmount: number = null,
        public QuantityTypeId: number = null,
        public Multiplier: number = null,
        public CustomProperties: ARLCustomProperties = null,
        public ARLTemplateTypeId: number = null,
        public AmountCalculation: any = null,
        public InheritFromChildren: boolean = null,
        public IsVisible: boolean = null,
        public ParentId: number = null,
        public ArlTemplateIds: number[] = null,
        public ResourceId: number = null,
        public MaterialId: number = null,
        public Type: AccountsReceivableLedgerTypeEnum = null,
        public Longtext: string = null,
        public ShowLongtext: boolean = null,
        public JobSpecificationId: number = null,
    ) {}

    public static GqlFields: Array<keyof AccountsReceivableLedgerTemplateType> = [
        'baseCost',
        'bookingText',
        'commissionTypeId',
        'costAmount',
        'createdAt',
        'currencyCode',
        'customerid',
        'deletedAt',
        'eventTypeId',
        'id',
        'partnerId',
        'quantity',
        'quantityTypeId',
        'tax',
        'updatedAt',
        'userId',
        'information',
        'multiplikator',
        'customProperties',
        'aRLTemplateTypeId',
        'amountCalculation',
        'inheritFromChildren',
        'parentId',
        'arlTemplateIds',
        'resourceId',
        'isVisible',
        'longInformation',
        'showLongInformation',
        'type',
        'materialId',
        'jobSpecificationId',
    ];
    public Clone(
        override: Partial<AccountsReceivableLedgerTemplateEntity> = {},
    ): AccountsReceivableLedgerTemplateEntity {
        const attr = { ...this, ...override };
        return Object.assign(new AccountsReceivableLedgerTemplateEntity(), attr);
    }
}

export function AccountsReceivableLedgerTemplateEntityFromBackend(
    res: AccountsReceivableLedgerTemplateType,
): AccountsReceivableLedgerTemplateEntity {
    return new AccountsReceivableLedgerTemplateEntity(
        res.id,
        res.tax,
        res.currencyCode,
        res.bookingText,
        res.information,
        res.baseCost && res.baseCost / 100,
        res.partnerId,
        res.userId,
        res.customerid,
        res.commissionTypeId,
        res.eventTypeId,
        res.createdAt ? BackendDate(res.createdAt) : null,
        res.updatedAt ? BackendDate(res.updatedAt) : null,
        res.deletedAt ? BackendDate(res.deletedAt) : null,
        res.quantity,
        res.costAmount && res.costAmount / 100,
        res.quantityTypeId,
        res.multiplikator,
        res.customProperties as any,
        res.aRLTemplateTypeId,
        res.amountCalculation,
        res.inheritFromChildren,
        res.isVisible,
        res.parentId,
        res.arlTemplateIds,
        res.resourceId,
        res.materialId,
        (res.type as unknown as AccountsReceivableLedgerTypeEnum) || AccountsReceivableLedgerTypeEnum.Arl,
        res.longInformation,
        res.showLongInformation,
        res.jobSpecificationId
    );
}
