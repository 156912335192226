import { FileOpenerToken, GetTimeFromTimestamp } from '@dave/types';

export class FileOpenerTokenEntity {
    constructor(
        public Id: number = null,
        public PartnerId: number = null,
        public UserId: number = null,
        public PcName: string = null,
        public Connected: boolean = null,
        public CreatedAt: Date = null,
        public UpdatedAt: Date = null,
        public DeletedAt: Date | undefined = null,
    ) {}
    public static EntityName = 'Token';
    public static EntityPropertyNames: Map<keyof FileOpenerTokenEntity, string> = new Map([
        ['PcName', 'Computername'],
        ['Connected', 'Verbunden'],
    ]);
    public get DisplayName(): string {
        return this.PcName;
    }

    public Clone(override: Partial<FileOpenerTokenEntity> = {}): FileOpenerTokenEntity {
        const attr = { ...this, ...override };
        return Object.assign(new FileOpenerTokenEntity(), attr);
    }

}
export function FileOpenerTokenEntityFromBackend(res: FileOpenerToken): FileOpenerTokenEntity {
    return new FileOpenerTokenEntity(
        +res.Id,
        res.PartnerId && +res.PartnerId,
        res.UserId && +res.UserId,
        res.PcName,
        res.Connected,
        GetTimeFromTimestamp(+res.CreatedAt),
        GetTimeFromTimestamp(+res.UpdatedAt),
        res.DeletedAt ? GetTimeFromTimestamp(+res.DeletedAt) : null,
    );
}
