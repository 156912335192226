import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { getLatestUpdatedAt } from '../../helper/reducer.helper';
import { FileOpenerOpenRequestEntity } from '../../entities/file-opener-open-request.entity';
import { FileOpenerOpenRequestActions } from '../actions/file-opener-open-request.actions';

export const FILE_OPENER_OPEN_REQUEST_KEY = 'file_opener_open_request';

export interface FileOpenerOpenRequestState extends EntityState<FileOpenerOpenRequestEntity> {
    fetched: boolean;
    latestUpdatedAt: Date | null;
}

const adapter = createEntityAdapter<FileOpenerOpenRequestEntity>({
    selectId: (Entity) => Entity.Id,
});

const initialState: FileOpenerOpenRequestState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
});

/* ############# */
/* ## REDUCER ## */
/* ############# */

export const fileOpenerOpenRequestReducer = createReducer(
    initialState,
    // empty payload dosen#t trigger selectors
    on(FileOpenerOpenRequestActions.loadSuccess, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setMany(Payload, {
            ...state,
            fetched: true,
            latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
        }),
    ),
    on(FileOpenerOpenRequestActions.openFileSuccess, (state, { Payload }) => adapter.setOne(Payload, state)),
);

/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllFileOpenerOpenRequest = selectAll;
export const selectFileOpenerOpenRequestEntities = selectEntities;

export const selectFileOpenerOpenRequestFetched = (state: FileOpenerOpenRequestState) => state.fetched;
export const selectFileOpenerOpenRequestLatestUpdatedAt = (state: FileOpenerOpenRequestState) => state.latestUpdatedAt;
