import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, debounceTime, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AccountsReceivableLedgerEntity, AccountsReceivableLedgerEntityFromBackend } from '../../entities/accounts-receivable-ledger.entity';
import { BusinessVolumeResolver } from '../../guards/business-volume.resolver';
import { LedgerImportResolver } from '../../guards/ledger-import.resolver';
import { ArlDataService } from '../../services/arl-data.service';
import { HttpService } from '../../services/http.service';
import { AccountsReceivableLedgerActionTypes, LedgerImportActionTypes } from '../actions/accounting.actions';
import { DaveActions } from '../actions/actions';
import { BaseActionTypes } from '../actions/base.actions';
import { EventsActionTypes } from '../actions/events.actions';
import { State } from '../index';
import { getAccountsReceivableLedgers } from '../selectors/accounting.selector';
import { getBusinessVolumeFetched } from '../selectors/business-volume.selector';

enum ErrorCodes {
    Remove = 'Buchungsposition Löschen fehlgeschlagen',
}

@Injectable()
export class AccountsReceivableLedgerEffects {
    constructor(
        private actions$: Actions<DaveActions>,
        private store$: Store<State>,
        private gatewayHttpService: HttpService,
        private bvResolver: BusinessVolumeResolver,
        private liResolver: LedgerImportResolver,
        private arlDataService: ArlDataService,
    ) {}

    PollFolders$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EventsActionTypes.AddEvent, EventsActionTypes.ModifyEvent),
            debounceTime(2000),
            map(() => AccountsReceivableLedgerActionTypes.GetAccountsReceivableLedger()),
        ),
    );

    GetAllAccountsReceivableLedgerQuery$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AccountsReceivableLedgerActionTypes.GetAccountsReceivableLedger),
            tap(() => {
                this.arlDataService.getARLs();
            }),
        ),
        {dispatch: false},
    );

    AddAccountsReceivableLedger$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AccountsReceivableLedgerActionTypes.AddAccountsReceivableLedger),
                tap(({ Payload }) => {
                    this.arlDataService.addArls([Payload], 100);
                }),
            ),
        { dispatch: false },
    );

    ChangeAccountsReceivableLedger$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AccountsReceivableLedgerActionTypes.ChangeAccountsReceivableLedger),
                tap(({ Payload }) => {
                    this.arlDataService.changeArls([Payload], 100);
                }),
            ),
        { dispatch: false },
    );

    DeleteAccountsReceivableLedger$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AccountsReceivableLedgerActionTypes.DeleteAccountsReceivableLedger),
            concatMap(({ Payload }) => {
                const queryString = `mutation {
          deleteAccountsReceivableLedger(
    id: ${Payload.id}
          )
        }
        `;
                return this.gatewayHttpService.graphQl({ query: queryString }).pipe(
                    withLatestFrom(this.store$.select(getAccountsReceivableLedgers), this.store$.select(getBusinessVolumeFetched)),
                    tap(([, , bvFetched]) => {
                        if (bvFetched) {
                            this.bvResolver.resolve();
                        }
                    }),
                    concatMap(([res, bookingPositions]) => {
                        return res && res.deleteAccountsReceivableLedger
                            ? [
                                  LedgerImportActionTypes.GetLedgerImports({ Payload: {} }),
                                  AccountsReceivableLedgerActionTypes.DeleteAccountsReceivableLedgerSuccess({
                                      Payload: Payload.id,
                                  }),
                              ]
                            : [
                                  AccountsReceivableLedgerActionTypes.DeleteAccountsReceivableLedgerFailure({
                                      Payload: {
                                          Errors: 'Wrong response deleteAccountsReceivableLedger',
                                          ToasterMessage: ErrorCodes.Remove,
                                      },
                                  }),
                              ];
                    }),
                    catchError((err, caught) =>
                        of(
                            AccountsReceivableLedgerActionTypes.DeleteAccountsReceivableLedgerFailure({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Remove,
                                    Errors: err,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );
    error$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AccountsReceivableLedgerActionTypes.DeleteAccountsReceivableLedgerFailure),
            map(({ Payload }) =>
                BaseActionTypes.ErrorAction({
                    Payload: {
                        ToasterMessage: Payload.ToasterMessage,
                        Err: Payload.Errors,
                    },
                }),
            ),
        ),
    );
}
